import { TextCell } from "@silevis/reactgrid";
import { ICellTypeHandler } from "components/grecoreactgrid/ICellTypeHandler";
import { SelectAllCellTemplate } from "../customcells/SelectAllCellTemplate";

class SelectAllCellTypeHandler implements ICellTypeHandler {
  cellType = "selectall";
  isCustom = true;
  instance = new SelectAllCellTemplate();
  determineNewValue = (change: any) => {
    return (change.newCell as TextCell).text;
  };
  getEntryRow = (
    entry: any,
    columnConfig: any,
    rowsMetadataMap: any,
    keyColumn: string,
    context: {
      [key: string]: any;
    }
  ) => {
    const [
      { clUnsettledPaymentPlanSelectAll },
      { setRemindersCreateDialogStoreValues },
    ] = context["remindersStore"];
    return {
      type: "selectall",
      value: clUnsettledPaymentPlanSelectAll,
      checked: clUnsettledPaymentPlanSelectAll,
      onChecked: (checked: boolean) => {
        let metadataMapCopy = { ...rowsMetadataMap };
        for (let item in metadataMapCopy) {
          metadataMapCopy[item].selected = checked;
        }

        setRemindersCreateDialogStoreValues({
          clUnsettledPaymentPlanSelectAll: checked,
          clUnsettledPaymentPlanMetadataMap: metadataMapCopy,
        });
      },
    };
  };
}

export default SelectAllCellTypeHandler;
