import { TextCell } from "@silevis/reactgrid";
import { toast } from "components/FluentToast";
import { ICellTypeHandler } from "components/grecoreactgrid/ICellTypeHandler";
import { SelectAllCellTemplate } from "../customcells/SelectAllCellTemplate";

class SelectAllCellTypeHandler implements ICellTypeHandler {
  cellType = "selectall";
  isCustom = true;
  instance = new SelectAllCellTemplate();
  determineNewValue = (change: any) => {
    return (change.newCell as TextCell).text;
  };
  getEntryRow = (
    entry: any,
    columnConfig: any,
    rowsMetadataMap: any,
    keyColumn: string,
    context: {
      [key: string]: any;
    }
  ) => {
    const [
      { bankStatementEntriesSelectAll, bankStatementEntryMetadataMap },
      { setBankStatementsStoreValues },
    ] = context["bankStatementsStore"];
    const { t } = context;
    return {
      type: "selectall",
      value: bankStatementEntriesSelectAll,
      checked: bankStatementEntriesSelectAll,
      hasVisible: [
        ...new Map(Object.entries(bankStatementEntryMetadataMap)).values(),
      ].reduce((acc: boolean, curr: any) => acc || curr.visible, false),
      onChecked: (checked: boolean) => {
        if (
          [
            ...new Map(Object.entries(bankStatementEntryMetadataMap)).values(),
          ].reduce((acc: boolean, curr: any) => acc || curr.inEditMode, false)
        ) {
          toast.error(
            t(
              "pct.please_save_or_cancel_your_changes_before_selecting_a_row.label"
            )
          );
        } else {
          let metadataMapCopy = { ...rowsMetadataMap };
          for (let item in metadataMapCopy) {
            metadataMapCopy[item].selected = metadataMapCopy[item].visible
              ? checked
              : false;
          }

          setBankStatementsStoreValues({
            bankStatementEntriesSelectAll: checked,
            bankStatementEntryMetadataMap: metadataMapCopy,
          });
        }
      },
    };
  };
}

export default SelectAllCellTypeHandler;
