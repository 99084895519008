import { TextCell } from "@silevis/reactgrid";
import { toast } from "components/FluentToast";
import { ICellTypeHandler } from "components/grecoreactgrid/ICellTypeHandler";
import { ClearedAmountAllCellTemplate } from "../customcells/ClearedAmountAllCellTemplate";

class ClearedAmountAllCellTypeHandler implements ICellTypeHandler {
  cellType = "clearedamountall";
  isCustom = true;
  instance = new ClearedAmountAllCellTemplate();
  determineNewValue = (change: any) => {
    return (change.newCell as TextCell).text;
  };
  getEntryRow = (
    entry: any,
    columnConfig: any,
    rowsMetadataMap: any,
    keyColumn: string,
    context: {
      [key: string]: any;
    }
  ) => {
    const { sort, setSort, t, bankStatementsStore } = context;

    const [
      {
        clUnsettledPaymentPlans,
        clUnsettledPaymentPlanMetadataMap,
        clBankStatementEntry,
      },
      { setBankStatementsStoreValue },
    ] = bankStatementsStore;

    return {
      type: "clearedamountall",
      value: "bankStatementsClearedAmountAll",
      selected: rowsMetadataMap[entry[keyColumn]]?.selected ?? false,
      highlighted: rowsMetadataMap[entry[keyColumn]]?.highlighted ?? false,
      sortFn: (key, dir) => {
        setSort({
          key: columnConfig.columnId,
          dir: dir,
        });
      },
      key: columnConfig.columnId,
      sort: sort,
      sortable: columnConfig.sortable,
      onSelectAllFn: () => {
        const totalAmount = clBankStatementEntry.amount;
        // Iterate through all the plans and set the suggested payment amount to the premium debt values up to the total amount
        let total = 0;
        const updatedPlans = clUnsettledPaymentPlans.map((plan) => {
          const premiumDebt = clBankStatementEntry?.isGrEcoPayment
            ? plan.premiumDiscountDebt
            : plan.premiumDebt;

          let suggestedPaymentAmount =
            premiumDebt > totalAmount - total
              ? totalAmount - total
              : premiumDebt;
          suggestedPaymentAmount =
            Math.round(suggestedPaymentAmount * 100) / 100;
          total += suggestedPaymentAmount;
          return { ...plan, suggestedPaymentAmount };
        });
        setBankStatementsStoreValue("clUnsettledPaymentPlans", updatedPlans);
      },
      onClearAllFn: () => {
        setBankStatementsStoreValue(
          "clUnsettledPaymentPlans",
          clUnsettledPaymentPlans.map((plan) => {
            return { ...plan, suggestedPaymentAmount: 0 };
          })
        );
      },
      t,
    };
  };
}

export default ClearedAmountAllCellTypeHandler;
