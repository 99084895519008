import { TFunction } from "i18next";
import { ExpandedColumn } from "../types/columns";

export const transformRowsForExcel = ({
  rows,
  columns,
  taxonomy,
  t,
}: {
  rows: any[];
  columns: any[];
  taxonomy: any;
  t: TFunction;
}) => {
  if (!rows) return [];

  return rows.map((v) => {
    return columns
      .filter((c) => c.key !== "options")
      .reduce((transformRow, c) => {
        if (!transformRow["Status"]) transformRow["Status"] = v["Status"];
        if (c.excel && c.excel.render) {
          transformRow[c.key] = c.excel.render({
            t,
            row: v,
            taxonomy,
          });
        }
        //
        else {
          transformRow[c.key] = v[c.key];
        }
        return transformRow;
      }, {});
  });
};
