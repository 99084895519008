import { TextCell } from "@silevis/reactgrid";
import { ICellTypeHandler } from "components/grecoreactgrid/ICellTypeHandler";
import { ClearedAmountAllCellTemplate } from "../customcells/ClearedAmountAllCellTemplate";

class ClearedAmountAllCellTypeHandler implements ICellTypeHandler {
  cellType = "clearedamountall";
  isCustom = true;
  instance = new ClearedAmountAllCellTemplate();
  determineNewValue = (change: any) => {
    return (change.newCell as TextCell).text;
  };
  getEntryRow = (
    entry: any,
    columnConfig: any,
    rowsMetadataMap: any,
    keyColumn: string,
    context: {
      [key: string]: any;
    }
  ) => {
    const { sort, setSort, t, clientBalanceStore } = context;

    const [
      { clientSaldo, clUnsettledPaymentPlans, clCurrencyCodesSelectedItem },
      { setClientBalancePageStoreValue },
    ] = clientBalanceStore;

    return {
      type: "clearedamountall",
      value: "clientBalancesClearedAmountAll",
      selected: rowsMetadataMap[entry[keyColumn]]?.selected ?? false,
      highlighted: rowsMetadataMap[entry[keyColumn]]?.highlighted ?? false,
      sortFn: (key, dir) => {
        setSort({
          key: columnConfig.columnId,
          dir: dir,
        });
      },
      key: columnConfig.columnId,
      sort: sort,
      sortable: columnConfig.sortable,
      onSelectAllFn: () => {
        const totalAmount = clientSaldo?.clientSaldoAmountAndCurrency.find(
          (saldo) => saldo.currencyCode === clCurrencyCodesSelectedItem?.value
        )?.clientSaldoAmount;
        // Iterate through all the plans and set the suggested payment amount to the premium debt values up to the total amount
        let total = 0;
        const updatedPlans = clUnsettledPaymentPlans.map((plan) => {
          const premiumDebt = clientSaldo?.isGrECoClient
            ? plan.premiumDiscountDebt
            : plan.premiumDebt;

          let suggestedPaymentAmount =
            premiumDebt > totalAmount - total
              ? totalAmount - total
              : premiumDebt;
          suggestedPaymentAmount =
            Math.round(suggestedPaymentAmount * 100) / 100;
          total += suggestedPaymentAmount;
          return { ...plan, suggestedPaymentAmount };
        });
        setClientBalancePageStoreValue("clUnsettledPaymentPlans", updatedPlans);
      },
      onClearAllFn: () => {
        setClientBalancePageStoreValue(
          "clUnsettledPaymentPlans",
          clUnsettledPaymentPlans.map((plan) => {
            return { ...plan, suggestedPaymentAmount: 0 };
          })
        );
      },
      t,
    };
  };
}

export default ClearedAmountAllCellTypeHandler;
