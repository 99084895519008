import { Select } from "@fluentui/react-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { usePaymentPlanPageStore } from "store/PaymentPlanPageStore";

type Props = {
  name: string;
  required?: boolean;
  disabled?: boolean;
  value?: any;
  visible?: boolean;
  isLoading: boolean;
  onBlur?: (e: any) => void;
};

export const SearchIsBrokerPremiumCollection = ({
  name,
  required = false,
  disabled = false,
  visible,
  isLoading,
  onBlur = null,
}: Props) => {
  const { t } = useTranslation();
  const [
    { searchIsBrokerPremiumCollectionItem },
    { setPaymentPlanStoreValue },
  ] = usePaymentPlanPageStore();

  if (!visible) return null;
  return (
    <>
      <Select
        style={{ width: 200 }}
        required={required}
        disabled={disabled || isLoading}
        id={name}
        name={"searchIsBrokerPremiumCollection"}
        onBlur={(e) => {
          onBlur && onBlur(e);
        }}
        onChange={(e, data) => {
          setPaymentPlanStoreValue(
            "searchIsBrokerPremiumCollectionItem",
            data.value
          );
        }}
        value={searchIsBrokerPremiumCollectionItem || ""}
        title={t("pct.taxonomy.selectTitle.label")}
      >
        <option key={"all"} value={"all"}>
          {t("pct.isBrokerPremiumCollectionAll.label")}
        </option>
        <option key={"yes"} value={"yes"}>
          {t("pct.isBrokerPremiumCollectionYes.label")}
        </option>
        <option key={"no"} value={"no"}>
          {t("pct.isBrokerPremiumCollectionNo.label")}
        </option>
      </Select>
    </>
  );
};

export default SearchIsBrokerPremiumCollection;
