import { ICellTypeHandler } from "components/grecoreactgrid/ICellTypeHandler";
import {
  DownloadCell,
  DownloadCellTemplate,
} from "../customcells/DownloadCellTemplate";

class DownloadCellTypeHandler implements ICellTypeHandler {
  cellType = "download";
  isCustom = false;
  instance = new DownloadCellTemplate();
  determineNewValue = (change: any) => {
    return (change.newCell as DownloadCell).link;
  };
  getEntryRow = (
    entry: any,
    columnConfig: any,
    rowsMetadataMap: any,
    keyColumn: string,
    context: {
      [key: string]: any;
    }
  ) => {
    return {
      type: "download",
      selected: rowsMetadataMap[entry[keyColumn]]?.selected ?? false,
      highlighted: rowsMetadataMap[entry[keyColumn]]?.highlighted ?? false,

      link: entry[columnConfig.columnId],
    };
  };
}

export default DownloadCellTypeHandler;
