import { TextCell, TextCellTemplate } from "@silevis/reactgrid";
import {
  InsurerInfoCell,
  InsurerInfoCellTemplate,
} from "../customcells/InsurerInfoCellTemplate";
import { ICellTypeHandler } from "components/grecoreactgrid/ICellTypeHandler";

class InsurerInfoCellTypeHandler implements ICellTypeHandler {
  cellType = "insurerinfo";
  isCustom = true;
  instance = new InsurerInfoCellTemplate();
  determineNewValue = (change: any) => {
    return (change.newCell as InsurerInfoCell).text;
  };
  getEntryRow = (
    entry: any,
    columnConfig: any,
    rowsMetadataMap: any,
    keyColumn: string,
    context: {
      [key: string]: any;
    }
  ) => {
    return {
      type: "insurerinfo",
      text: "",
      selected: rowsMetadataMap[entry[keyColumn]]?.selected ?? false,
      highlighted: rowsMetadataMap[entry[keyColumn]]?.highlighted ?? false,

      value: entry[columnConfig.columnId],
      editable: rowsMetadataMap[entry[keyColumn]]?.editable ?? true,
    };
  };
}

export default InsurerInfoCellTypeHandler;
