import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  Spinner,
  Textarea,
} from "@fluentui/react-components";
import { MoneyDismissRegular } from "@fluentui/react-icons";

import { toast } from "components/FluentToast";
import { VSpace } from "components/Spacer";
import React from "react";
import { useTranslation } from "react-i18next";
import { useAppStore } from "store/AppStore";
import { usePaymentPageStore } from "store/PaymentPageStore";

export const InvalidatePaymentsDialog = () => {
  const [
    { payments, invalidatePaymentsDialog, invalidatePaymentsStatus },
    {
      setPaymentStoreValue,
      setPaymentStoreValues,
      invalidatePayment,
      loadPayments,
    },
  ] = usePaymentPageStore();
  const [{ updatedGrecoCompanies }] = useAppStore();
  const isLoading = invalidatePaymentsStatus === "loading";
  const [comment, setComment] = React.useState("");
  const { t } = useTranslation();

  return (
    <Dialog open={invalidatePaymentsDialog.isOpen}>
      <DialogSurface>
        <DialogBody>
          <DialogTitle>{t("pct.invalidatePayment.label")}</DialogTitle>
          <DialogContent>
            {t("pct.invalidatePaymentConfirm.label", [
              invalidatePaymentsDialog.paymentIds.length,
            ])}
            <VSpace height={20} />
            <div>
              <label>{t("pct.comment.label")}</label>
              <VSpace height={5} />

              <Textarea
                style={{ width: "100%" }}
                rows={6}
                value={comment}
                disabled={isLoading}
                onChange={(e) => setComment(e.target.value)}
              />
            </div>
            <VSpace height={20} />
          </DialogContent>
          <DialogActions>
            <Button
              appearance="secondary"
              disabled={isLoading}
              onClick={() => {
                setPaymentStoreValue("invalidatePaymentsDialog", {
                  isOpen: false,
                  paymentId: null,
                });
              }}
            >
              {t("pct.close.label")}
            </Button>
            <Button
              appearance="primary"
              disabled={isLoading}
              icon={
                isLoading ? (
                  <Spinner size="tiny" />
                ) : (
                  <MoneyDismissRegular fontSize={16} />
                )
              }
              onClick={() => {
                invalidatePayment(
                  {
                    Ids: invalidatePaymentsDialog.paymentIds,
                    Comment: comment,
                  },
                  () => {
                    setPaymentStoreValues({
                      invalidatePaymentsDialog: {
                        isOpen: false,
                        paymentIds: null,
                      },
                    });
                    toast.success(t("greco.success"));
                    loadPayments(updatedGrecoCompanies);
                  }
                );
              }}
            >
              {t("pct.invalidate.label")}
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};
