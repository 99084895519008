import { Select } from "@fluentui/react-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { usePaymentPageStore } from "store/PaymentPageStore";

type Props = {
  name: string;
  required?: boolean;
  disabled?: boolean;
  value?: any;
  visible?: boolean;
  isLoading: boolean;
  onBlur?: (e: any) => void;
};

export const SearchIsDiscountPaid = ({
  name,
  required = false,
  disabled = false,
  visible,
  isLoading,
  onBlur = null,
}: Props) => {
  const { t } = useTranslation();
  const [{ searchIsDiscountPaidItem }, { setPaymentStoreValue }] =
    usePaymentPageStore();

  if (!visible) return null;
  return (
    <>
      <Select
        required={required}
        disabled={disabled || isLoading}
        id={name}
        name={"searchIsDiscountPaid"}
        onBlur={(e) => {
          onBlur && onBlur(e);
        }}
        onChange={(e, data) => {
          setPaymentStoreValue("searchIsDiscountPaidItem", data.value);
        }}
        value={searchIsDiscountPaidItem || ""}
        title={t("pct.taxonomy.selectTitle.label")}
      >
        <option key={"all"} value={"all"}>
          {t("pct.isDiscountPaidAll.label")}
        </option>
        <option key={"yes"} value={"yes"}>
          {t("pct.isDiscountPaidYes.label")}
        </option>
        <option key={"no"} value={"no"}>
          {t("pct.isDiscountPaidNo.label")}
        </option>
      </Select>
    </>
  );
};

export default SearchIsDiscountPaid;
