import {
  Link,
  Toolbar,
  ToolbarRadioButton,
  ToolbarRadioGroup,
  Tooltip,
} from "@fluentui/react-components";
import {
  DocumentBulletListRegular,
  MoneyCalculatorRegular,
  PaymentRegular,
  ServiceBellRegular,
  PersonMoneyRegular,
} from "@fluentui/react-icons";
import { routes } from "config/routes";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
const MainToolbar = ({ pageName }) => {
  const navigate = useNavigate();
  const [checkedValues, setCheckedValues] = React.useState<
    Record<string, string[]>
  >({
    textOptions: [pageName],
  });
  const { t } = useTranslation();

  return (
    <Toolbar
      aria-label="with controlled Radio Button"
      checkedValues={checkedValues}
    >
      <ToolbarRadioGroup
        style={{
          display: "flex",
          gap: "8px",
        }}
      >
        <Tooltip content={t("pct.bankStatements.label")} relationship="label">
          <Link
            href={routes.bankStatements.path}
            onClick={(e) => {
              e.preventDefault();
              navigate(routes.bankStatements.path);
            }}
          >
            <ToolbarRadioButton
              aria-label="Align Center"
              name="textOptions"
              value="bankStatementPage"
              icon={<DocumentBulletListRegular />}
            />
          </Link>
        </Tooltip>
        <Tooltip content={t("pct.clientBalance.label")} relationship="label">
          <Link
            href={routes.clientBalancePage.path}
            onClick={(e) => {
              e.preventDefault();
              navigate(routes.clientBalancePage.path);
            }}
          >
            <ToolbarRadioButton
              aria-label="Align left"
              name="textOptions"
              value="clientBalancePage"
              icon={<PersonMoneyRegular />}
            />
          </Link>
        </Tooltip>{" "}
        <Tooltip content={t("pct.paymentPlans.label")} relationship="label">
          <Link
            href={routes.paymentPlanPage.path}
            onClick={(e) => {
              e.preventDefault();
              navigate(routes.paymentPlanPage.path);
            }}
          >
            <ToolbarRadioButton
              aria-label="Align left"
              name="textOptions"
              value="paymentPlanPage"
              icon={<MoneyCalculatorRegular />}
            />
          </Link>
        </Tooltip>
        <Tooltip content={t("pct.reminders.label")} relationship="label">
          <Link
            href={routes.remindersPage.path}
            onClick={(e) => {
              e.preventDefault();
              navigate(routes.remindersPage.path);
            }}
          >
            <ToolbarRadioButton
              aria-label="Align left"
              name="textOptions"
              value="remindersPage"
              icon={<ServiceBellRegular />}
            />
          </Link>
        </Tooltip>
        <Tooltip
          content={t("pct.paymentsToInsurers.label")}
          relationship="label"
        >
          <Link
            href={routes.paymentPage.path}
            onClick={(e) => {
              e.preventDefault();
              navigate(routes.paymentPage.path);
            }}
          >
            <ToolbarRadioButton
              aria-label="Align left"
              name="textOptions"
              value="paymentPage"
              icon={<PaymentRegular />}
            />
          </Link>
        </Tooltip>
      </ToolbarRadioGroup>
    </Toolbar>
  );
};

export default MainToolbar;
