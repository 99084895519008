import { ColumnConfig } from "types/bankStatements";

export const bankStatementColumnConfig: ColumnConfig[] = [
  {
    columnId: "ordinalNumber",
    width: 50,
    reorderable: false,
    resizable: true,
    label: "",
    cellType: "ordinalnumber",
    sortable: false,
  },
  {
    columnId: "select",
    width: 40,
    reorderable: false,
    resizable: false,
    label: "",
    cellType: "select",
    sortable: false,
    additionalInfo: {
      rowIdField: "bankStatementId",
    },
  },
  {
    columnId: "lastModified",
    width: 40,
    reorderable: true,
    resizable: false,
    sortable: true,
    label: "",
    cellType: "lastmodified",
  },
  {
    columnId: "status",
    width: 120,
    reorderable: true,
    resizable: false,
    sortable: false,
    label: "pct.Status.label",
    cellType: "bankstatementstatus",
  },
  {
    columnId: "paidReceivedAmount",
    width: 180,
    reorderable: true,
    resizable: true,
    sortable: false,
    label: "pct.paidReceivedAmount.label",
    cellType: "bankstatementpaidreceived",
    additionalInfo: {
      rowIdField: "paidReceivedAmount",
    },
  },
  {
    columnId: "statementCreatedAt",
    width: 170,
    reorderable: true,
    resizable: true,
    sortable: true,
    label: "pct.statementCreatedAt.label",
    cellType: "datepicker",
  },
  {
    columnId: "periodFrom",
    width: 120,
    reorderable: true,
    resizable: true,
    sortable: true,
    label: "pct.periodFrom.label",
    cellType: "datepicker",
  },
  {
    columnId: "periodTo",
    width: 120,
    reorderable: true,
    resizable: true,
    sortable: true,
    label: "pct.periodTo.label",
    cellType: "datepicker",
  },
  // {
  //   columnId: "accountId",
  //   width: 180,
  //   reorderable: true,
  //   resizable: true,
  //   sortable: false,
  //   label: "pct.accountId.label",
  //   cellType: "customtext",
  // },

  {
    columnId: "accountCurrencyCode",
    width: 150,
    reorderable: true,
    resizable: true,
    sortable: true,
    label: "pct.accountCurrencyCode.label",
    cellType: "taxonomy",
    additionalInfo: {
      taxonomy: "Currency",
    },
  },
  {
    columnId: "companyRegisterNumber",
    width: 200,
    reorderable: true,
    resizable: true,
    sortable: true,
    label: "pct.companyRegisterNumber.label",
    cellType: "grecocompany",
  },

  {
    columnId: "accountOwnerName",
    width: 400,
    reorderable: true,
    resizable: true,
    sortable: true,
    label: "pct.accountOwnerName.label",
    cellType: "customtext",
  },

  {
    columnId: "accountOPBD",
    width: 150,
    reorderable: true,
    resizable: true,
    sortable: true,
    label: "pct.accountOPBD.label",
    cellType: "customnumber",
  },
  {
    columnId: "accountCLBD",
    width: 150,
    reorderable: true,
    resizable: true,
    sortable: true,
    label: "pct.accountCLBD.label",
    cellType: "customnumber",
  },
  {
    columnId: "bankStatementComment",
    width: 350,
    reorderable: true,
    resizable: true,
    sortable: true,
    label: "pct.bankStatementComment.label",
    cellType: "customtext",
  },

  {
    columnId: "options",
    width: 80,
    reorderable: true,
    resizable: true,
    sortable: false,
    label: "pct.options.label",
    cellType: "bankstatementoptions",
    additionalInfo: {
      rowIdField: "bankStatementId",
      linkField: "bankStatementDocumentPath",
    },
  },
];
