import React, { useEffect, useRef, useMemo } from "react";
import { useAppStore } from "store/AppStore";
import {
  usePaymentPlanPageStore,
  usePaymentPlanPageStoreState,
} from "store/PaymentPlanPageStore";
import PaymentPlanPageNavigation from "./PaymentPlanPageNavigation";
import PaymentPlanPageTable from "./PaymentPlanPageTable";
import usePaymentPlanPage from "./usePaymentPlanPage";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { ExportExcel } from "./ExportExcel";
import { ThemeProvider } from "@greco/components";
import LoadingOverlay from "components/LoadingOverlay";
import { useTranslation } from "react-i18next";
import i18n from "i18n";

const PaymentPlan = () => {
  usePaymentPlanPage();
  const refExcelExport = useRef<ExcelExport>();
  const [
    { rowItems, cols, paymentPlansLoadStatus, timestamp },
    { setPaymentPlanStoreValues },
  ] = usePaymentPlanPageStore();
  const appStore = useAppStore();
  const [{ appState }, { setAppStoreValue }] = appStore;
  const { t } = useTranslation();

  useEffect(() => {
    setAppStoreValue(
      "headerTitle",
      t("pct.premiumCollectionTool.label") + " - " + t("pct.paymentPlans.label")
    );
  }, [i18n.language]);

  useEffect(() => {
    setTimeout(() => {
      var gridcols = Array.from(
        document.getElementsByClassName(
          "rg-pane"
        ) as HTMLCollectionOf<HTMLElement>
      );
      for (let i = 0; i < gridcols.length; i++) {
        if (appState.darkMode) {
          gridcols[i].style.backgroundColor = "#222222";
          gridcols[i].style.color = "#ffffff";
        } else {
          gridcols[i].style.backgroundColor = "#f8f8f8";
          gridcols[i].style.color = "#000000";
        }
      }
    }, 20);
  }, [appState.darkMode, rowItems, timestamp]);

  return (
    <ThemeProvider isDarkMode={appState.darkMode}>
      <PaymentPlanPageNavigation refExcelExport={refExcelExport?.current} />
      <PaymentPlanPageTable />
      <ExportExcel ref={refExcelExport} columns={cols} />
      {paymentPlansLoadStatus === "loading" ? <LoadingOverlay /> : null}
    </ThemeProvider>
  );
};

export default PaymentPlan;
