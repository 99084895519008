import React from "react";
import { DefaultButton } from "office-ui-fabric-react";
import Skeleton from "react-loading-skeleton";

const MyDefaultButton = ({ visible, isLoading, ...props }) => {
  //if (isLoading) return <Skeleton width={100} height={30} />;
  if (!visible) return null;

  const propsWithLoading = { ...props, disabled: props.disabled || isLoading };
  return <DefaultButton {...propsWithLoading} />;
};

export default MyDefaultButton;
