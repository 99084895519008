import * as React from "react";
import {
  CellTemplate,
  Cell,
  Compatible,
  Uncertain,
  UncertainCompatible,
  getCellProperty,
  isAlphaNumericKey,
  keyCodes,
  getCharFromKeyCode,
  isNavigationKey,
} from "@silevis/reactgrid";
import { FluentProvider, Select } from "@fluentui/react-components";
import { useTheme as useFabricTheme } from "office-ui-fabric-react";
import { useTheme } from "@emotion/react";
import { DirectionalHint, TooltipHost } from "@fluentui/react";

export type OptionType = {
  label: string;
  value: string;
};

export interface GrecoCompanyCell extends Cell {
  type: "grecocompany";
  options: OptionType[];
  selectedValue?: string;
  selectedValueText?: string;
  theme?: any;
  isDisabled?: boolean;
  editable?: boolean;
  selected?: boolean;
  highlighted?: boolean;
  t: any;
}

export class GrecoCompanyCellTemplate
  implements CellTemplate<GrecoCompanyCell>
{
  getCompatibleCell(
    uncertainCell: Uncertain<GrecoCompanyCell>
  ): Compatible<GrecoCompanyCell> {
    let selectedValue: string | undefined;
    let t: any;
    t = getCellProperty(uncertainCell, "t", "function");
    try {
      selectedValue = getCellProperty(uncertainCell, "selectedValue", "string");
    } catch {
      selectedValue = undefined;
    }

    let selectedValueText: string | undefined;
    try {
      selectedValueText = getCellProperty(
        uncertainCell,
        "selectedValueText",
        "string"
      );
    } catch {
      selectedValueText = undefined;
    }

    const options = getCellProperty(uncertainCell, "options", "object");

    let theme = null;
    if ("theme" in uncertainCell) {
      theme = getCellProperty(uncertainCell, "theme", "object");
    }
    const value = selectedValue ? parseFloat(selectedValue) : NaN;
    let isDisabled = true;
    try {
      isDisabled = getCellProperty(uncertainCell, "isDisabled", "boolean");
    } catch {
      isDisabled = false;
    }
    let editable: boolean | undefined;
    try {
      editable = getCellProperty(uncertainCell, "editable", "boolean");
    } catch {
      editable = true;
    }
    let selected: boolean | undefined;
    try {
      selected = getCellProperty(uncertainCell, "selected", "boolean");
    } catch {
      selected = true;
    }
    let highlighted: boolean | undefined;
    try {
      highlighted = getCellProperty(uncertainCell, "highlighted", "boolean");
    } catch {
      highlighted = false;
    }
    const text = selectedValue || "";
    return {
      ...uncertainCell,
      selectedValue,
      selectedValueText,
      text,
      value,
      options,
      editable,
      isDisabled,
      theme,
      selected,
      highlighted,
      t,
      // isOpen,
      // inputValue,
    };
  }

  update(
    cell: Compatible<GrecoCompanyCell>,
    cellToMerge: UncertainCompatible<GrecoCompanyCell>
  ): Compatible<GrecoCompanyCell> {
    return this.getCompatibleCell({
      ...cell,
      selectedValue: cellToMerge.selectedValue,
      // inputValue: cellToMerge.inputValue,
    });
  }

  getClassName(
    cell: Compatible<GrecoCompanyCell>,
    isInEditMode: boolean
  ): string {
    return (
      (cell.className ? cell.className : "") +
      (cell.selected ? " rg-selected" : "") +
      (cell.highlighted ? " highlighted-selected-row" : "")
    );
  }

  handleKeyDown(
    cell: Compatible<GrecoCompanyCell>,
    keyCode: number,
    ctrl: boolean,
    shift: boolean,
    alt: boolean
  ): { cell: Compatible<GrecoCompanyCell>; enableEditMode: boolean } {
    if ((keyCode === keyCodes.SPACE || keyCode === keyCodes.ENTER) && !shift) {
      return {
        cell: this.getCompatibleCell({ ...cell }),
        enableEditMode: false,
      };
    }
    const char = getCharFromKeyCode(keyCode, shift);
    if (!ctrl && !alt && isAlphaNumericKey(keyCode))
      return {
        cell: this.getCompatibleCell({
          ...cell,
          // inputValue: shift ? char : char.toLowerCase(),

          options: cell.options,
        }),
        enableEditMode: true,
      };
    return {
      cell,
      enableEditMode:
        keyCode === keyCodes.POINTER || keyCode === keyCodes.ENTER,
    };
  }

  render(
    cell: Compatible<GrecoCompanyCell>,
    isInEditMode: boolean,
    onCellChanged: (cell: Compatible<GrecoCompanyCell>, commit: boolean) => void
  ): React.ReactNode {
    if (!isInEditMode) {
      const label = cell.options.find((val: any) => {
        return val.value === cell.selectedValue;
      })?.label;
      return (
        <TooltipHost
          content={label}
          directionalHint={DirectionalHint.topAutoEdge}
          hidden={false}
        >
          {label}
        </TooltipHost>
      );
    }
    return (
      <DropdownCell
        onCellChanged={(cell) =>
          onCellChanged(this.getCompatibleCell(cell), true)
        }
        cell={cell}
      />
    );
  }
}

interface DIProps {
  onCellChanged: (...args: any[]) => void;
  cell: Record<string, any>;
}

const DropdownCell: React.FC<DIProps> = ({ onCellChanged, cell }) => {
  React.useEffect(() => {
    // if (cell.isOpen && selectRef.current) {
    //   selectRef.current.focus();
    //   setInputValue(cell.inputValue);
    // }
  }, [cell.inputValue]);
  const fabricTheme = useFabricTheme();
  const theme = useTheme();

  let emotionTheme = cell.theme;
  if (emotionTheme === null) {
    emotionTheme = theme;
  }
  return (
    <div
      style={{ width: "100%" }}
      onPointerDown={(e) => onCellChanged({ ...cell })}
    >
      <FluentProvider theme={emotionTheme}>
        <Select
          size="small"
          appearance="underline"
          disabled={!cell.editable}
          onPointerDown={(e) => e.stopPropagation()}
          onChange={(e, data) => {
            onCellChanged({
              ...cell,
              selectedValue: data.value,
            });
          }}
          onKeyDown={(e) => {
            if (isAlphaNumericKey(e.keyCode) || isNavigationKey(e.keyCode))
              e.stopPropagation();
          }}
          value={
            cell.options.find((val: any) => {
              return val.value === cell.selectedValue;
            })?.value
          }
        >
          {cell.options
            // .sort((a, b) => a.label.localeCompare(b.label))
            .map((tax) => (
              <option
                key={tax.value}
                value={tax.value}
                style={{
                  backgroundColor: fabricTheme.palette.white,
                }}
              >
                {cell.t(tax.label)}
              </option>
            ))}
        </Select>
      </FluentProvider>
    </div>
  );
};
