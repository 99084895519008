import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppStoreState } from "store/AppStore";
import {
  useRemindersStore,
  useRemindersStoreActions,
} from "store/RemindersStore";

import { useTheme } from "@emotion/react";
import { Input } from "@fluentui/react-components";
import GrecoReactGrid, {
  standardCellTypeHandlers,
} from "components/grecoreactgrid/GrecoReactGrid";
import CustomNumberCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/CustomNumberCellTypeHandler";
import CustomTextCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/CustomTextCellTypeHandler";
import DatePickerCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/DatePickerCellTypeHandler";
import SortableHeaderCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/SortableHeaderCellTypeHandler";
import TaxonomyCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/TaxonomyCellTypeHandler";
import { createReminderColumnConfig } from "./createReminderColumnConfig";
import ClearedAmountCellTypeHandler from "./customcelltypehandlers/ClearedAmountCellTypeHandler";
import SelectAllCellTypeHandler from "./customcelltypehandlers/SelectAllCellTypeHandler";
import SelectCellTypeHandler from "./customcelltypehandlers/SelectCellTypeHandler";
import { VSpace } from "components/Spacer";
import SumHeaderCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/SumHeaderCellTypeHandler";
import OrdinalNumberCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/OrdinalNumberCellTypeHandler";
import SearchClients from "./SearchClients";
import {
  useClReminderNumber,
  useClUnsettledPaymentPlans,
  useClUnsettledPaymentPlanMetadataMap,
  useClComment,
  useRemindersCreateDialogStoreActions,
  useCreateReminderSort,
  useRemindersCreateDialogStore,
  useClSearchTerm,
  useClTotal,
} from "store/RemindersCreateDialogStore";
import { formatNumberForSearch } from "utils/utils";
import moment from "moment";
import { Search } from "./Search";
import { formatNumber } from "utils/number";
import styled from "styled-components";

const CreateReminderEntriesGrid = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [{ clReminderNumber }] = useClReminderNumber();
  const [{ clComment }] = useClComment();

  const { taxonomy } = useAppStoreState();
  const remindersCreateDialogStore = useRemindersCreateDialogStore();
  const { setRemindersCreateDialogStoreValues } =
    useRemindersCreateDialogStoreActions();

  const { setRemindersCreateDialogStoreValue } =
    useRemindersCreateDialogStoreActions();

  const [clReminderNumberLocal, setClReminderNumberLocal] =
    useState<string>(clReminderNumber);
  const [{ clUnsettledPaymentPlans }] = useClUnsettledPaymentPlans();

  const [{ clUnsettledPaymentPlanMetadataMap }] =
    useClUnsettledPaymentPlanMetadataMap();

  const [{ createReminderSort }] = useCreateReminderSort();

  const [clCommentLocal, setClCommentLocal] = useState<string>(clComment);

  const [{ clTotal }] = useClTotal();

  const [{ clSearchTerm }] = useClSearchTerm();

  const handleChanges = (
    updatedRows: any[],
    updatedRowsMetadataMap: { [key: number]: any }
  ) => {
    setRemindersCreateDialogStoreValues({
      clUnsettledPaymentPlans: clUnsettledPaymentPlans.map((entry) => {
        const updatedEntry = updatedRows.find(
          (row) => row.paymentPlanEntryId === entry.paymentPlanEntryId
        );
        if (updatedEntry) {
          return updatedEntry;
        } else {
          return entry;
        }
      }),
      clUnsettledPaymentPlanMetadataMap: updatedRowsMetadataMap,
    });
  };

  const cellTypeHandlers = {
    ...standardCellTypeHandlers,
    taxonomy: new TaxonomyCellTypeHandler(),
    datepicker: new DatePickerCellTypeHandler(),
    clearedamount: new ClearedAmountCellTypeHandler(),
    sortableheader: new SortableHeaderCellTypeHandler(),
    sumheader: new SumHeaderCellTypeHandler(),
    customnumber: new CustomNumberCellTypeHandler(),
    customtext: new CustomTextCellTypeHandler(),
    select: new SelectCellTypeHandler(),
    selectall: new SelectAllCellTypeHandler(),
    ordinalnumber: new OrdinalNumberCellTypeHandler(),
  };

  useEffect(() => {
    if (!clUnsettledPaymentPlans || !clUnsettledPaymentPlanMetadataMap) {
      return;
    }
    const totalSelectedForReminderAmount = clUnsettledPaymentPlans.reduce(
      (acc, el, currentIndex, array) => {
        if (currentIndex !== array.length) {
          if (
            clUnsettledPaymentPlanMetadataMap[el.paymentPlanEntryId].selected
          ) {
            acc = [
              (acc[0] ?? 0) + (el.suggestedPaymentAmount ?? 0),
              (acc[1] ?? 0) + 1,
            ];
          } else {
            acc = [acc[0] ?? 0, acc[1] ?? 0];
          }
        }
        return acc;
      },
      [0, 0]
    );
    setRemindersCreateDialogStoreValues({
      clTotal: totalSelectedForReminderAmount,
    });
  }, [clUnsettledPaymentPlans, clUnsettledPaymentPlanMetadataMap]);

  useEffect(() => {
    const retVal = {};
    let matchCount = 0;
    for (const key in clUnsettledPaymentPlanMetadataMap) {
      retVal[key] = {
        ...clUnsettledPaymentPlanMetadataMap[key],
        highlighted: false,
      };
    }
    clUnsettledPaymentPlans.forEach((el) => {
      let isMatch = false;
      if (clSearchTerm && clSearchTerm !== "") {
        isMatch =
          // el.insurerName.toLowerCase().includes(clSearchTerm.toLowerCase()) ||
          el.policyName
            ?.toString()
            .toLowerCase()
            .includes(clSearchTerm.toLowerCase()) ||
          // el.clientName
          //   ?.toString()
          //   .toLowerCase()
          //   .includes(clSearchTerm.toLowerCase()) ||
          el.insurerName
            ?.toString()
            .toLowerCase()
            .includes(clSearchTerm.toLowerCase()) ||
          el.policyNumber
            ?.toString()
            .toLowerCase()
            .includes(clSearchTerm.toLowerCase()) ||
          el.policySubNumber
            ?.toString()
            .toLowerCase()
            .includes(clSearchTerm.toLowerCase()) ||
          el.insuranceLineName
            ?.toString()
            .toLowerCase()
            .includes(clSearchTerm.toLowerCase()) ||
          (el.bookingDate
            ? moment(el.bookingDate).format("DD.MM.YYYY")
            : ""
          ).includes(clSearchTerm.toLowerCase()) ||
          (el.premiumFrom
            ? moment(el.premiumFrom).format("DD.MM.YYYY")
            : ""
          ).includes(clSearchTerm.toLowerCase()) ||
          (el.premiumTo
            ? moment(el.premiumTo).format("DD.MM.YYYY")
            : ""
          ).includes(clSearchTerm.toLowerCase()) ||
          formatNumberForSearch(el.premiumAmount).includes(
            clSearchTerm.toLowerCase()
          ) ||
          formatNumberForSearch(el.premiumDiscount).includes(
            clSearchTerm.toLowerCase()
          ) ||
          formatNumberForSearch(el.premiumDiscountPercent).includes(
            clSearchTerm.toLowerCase()
          ) ||
          formatNumberForSearch(el.totalPremiumAmount).includes(
            clSearchTerm.toLowerCase()
          ) ||
          formatNumberForSearch(el.paidPremiumAmount).includes(
            clSearchTerm.toLowerCase()
          ) ||
          // formatNumberForSearch(el.paidPremiumDiscountAmount).includes(
          //   clSearchTerm.toLowerCase()
          // ) ||
          formatNumberForSearch(el.premiumDebt).includes(
            clSearchTerm.toLowerCase()
          );
        // formatNumberForSearch(el.premiumDiscountDebt).includes(
        //   clSearchTerm.toLowerCase()
        // ) ||
        // formatNumberForSearch(el.suggestedPaymentAmount).includes(
        //   clSearchTerm.toLowerCase()
        // );
      }

      matchCount += isMatch ? 1 : 0;
      retVal[el.paymentPlanEntryId] = {
        ...retVal[el.paymentPlanEntryId],
        highlighted: isMatch,
      };
    });
    setRemindersCreateDialogStoreValues({
      clUnsettledPaymentPlanMetadataMap: retVal,
      clHightlightCount: matchCount,
    });
  }, [clSearchTerm, clUnsettledPaymentPlans]);

  const grid = useMemo(() => {
    return clUnsettledPaymentPlans.filter((el) => !el.isDeleted).length > 0 ? (
      <GrecoReactGrid
        rows={clUnsettledPaymentPlans}
        columns={createReminderColumnConfig(window.innerWidth / 1920)}
        idColumn={"paymentPlanEntryId"}
        rowsMetadataMap={clUnsettledPaymentPlanMetadataMap}
        handleChanges={handleChanges}
        cellTypeHandlers={cellTypeHandlers}
        keyColumn="paymentPlanEntryId"
        stickyColumns={{
          left: 2,
          right: 2,
          top: 1,
          bottom: 1,
        }}
        context={{
          taxonomy: taxonomy,
          t: t,
          remindersStore: remindersCreateDialogStore,
          theme: theme,
          sort: createReminderSort,
          setSort: (sort: any) => {
            setRemindersCreateDialogStoreValue("createReminderSort", sort);
          },
        }}
        sort={createReminderSort}
      />
    ) : null;
  }, [
    clUnsettledPaymentPlans,
    clUnsettledPaymentPlanMetadataMap,
    cellTypeHandlers,
    remindersCreateDialogStore,
  ]);

  useEffect(() => {
    setRemindersCreateDialogStoreValue(
      "clReminderNumber",
      clReminderNumberLocal
    );
  }, [clReminderNumberLocal]);

  useEffect(() => {
    setRemindersCreateDialogStoreValue("clComment", clCommentLocal);
  }, [clCommentLocal]);

  return (
    <>
      <div>
        <div style={{ display: "flex" }}>
          <div style={{ flex: 1 }}>{t("pct.payer.label")}: </div>
          <div style={{ flex: 4 }}>
            <SearchClients />
          </div>
        </div>
        <VSpace height={10} />
        <div style={{ display: "flex" }}>
          <div style={{ flex: 1 }}>{t("pct.reminderNumber.label")}: </div>
          <div style={{ flex: 4 }}>
            <Input
              value={clReminderNumberLocal ?? ""}
              style={{ width: "90%" }}
              placeholder=""
              autoFocus={false}
              aria-label="inline"
              onChange={(e, data) => {
                setClReminderNumberLocal(data.value);
              }}
            />
          </div>
        </div>
        <VSpace height={10} />
        <div style={{ display: "flex" }}>
          <div style={{ flex: 1 }}>{t("pct.comment.label")}: </div>
          <div style={{ flex: 4 }}>
            <Input
              value={clCommentLocal ?? ""}
              style={{ width: "90%" }}
              placeholder=""
              onChange={(e, data) => {
                setClCommentLocal(data.value);

                //setRemindersCreateDialogStoreValue("clComment", data.value);
              }}
              aria-label="inline"
              onCopy={(e) => e.stopPropagation()}
              onCut={(e) => e.stopPropagation()}
              onPaste={(e) => e.stopPropagation()}
            />
          </div>
        </div>
      </div>
      <VSpace height={10} />
      <div style={{ height: "calc(100vh - 370px)", overflow: "auto" }}>
        <div
          style={{
            width: "calc(100vw - 670px)",
            height: "calc(100vh - 370px)",
            overflow: "scroll",
          }}
        >
          <StyledWrap>{grid}</StyledWrap>
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Search
          placeholder="Search"
          value={clSearchTerm}
          onChange={(value) =>
            setRemindersCreateDialogStoreValue("clSearchTerm", value)
          }
        />
        <div style={{ float: "right", paddingTop: "24px" }}>
          <div style={{ float: "right" }}>
            {t("pct.selectedAmount.label")}:{" "}
            <span style={{ fontWeight: "bold" }}>
              {formatNumber(clTotal[0], 2, 2)}
            </span>
            &nbsp; ({t("pct.numberOfRows.label", { count: clTotal[1] })})
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateReminderEntriesGrid;

const StyledWrap = styled.div`
  .shadow-top-right-corner {
    z-index: 200 !important;
  }
`;
