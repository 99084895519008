import moment from "moment";
import {
  BankStatement,
  BankStatementEntry,
  BankStatementEntryInfo,
  BankStatementEntryMetadataMap,
  BankStatementInfo,
  BankStatementMetadataMap,
} from "utils/types";
import {
  deleteMissingProperties,
  formatNumberForSearch,
  getShortInsurerName,
  handleAxiosError,
  prepareNotificationObject,
} from "utils/utils";
import * as API from "../api/api";
import { StoreState } from "./BankStatementsStore";
import i18next from "i18next";
import { toast } from "components/FluentToast";
import { setNotificationMessage } from "./AppStoreActions";

export const setStoreValue =
  (name: keyof StoreState, value) =>
  ({ setState }) => {
    setState({
      [name]: value,
    });
  };

export const setStoreValues =
  (values) =>
  ({ setState }) => {
    setState({
      ...values,
    });
  };

export const hydrateBankStatements = ({
  bankStatements,
  taxonomy,
  grecoCompanies,
  t,
  setState,
  getState,
}) => {
  return bankStatements.map((statement) => {
    return {
      ...statement,
      currencyName: t(
        taxonomy?.Currency?.byId[statement["accountCurrencyCode"]].code
      ),
      companyName: grecoCompanies?.find(
        (gc) => gc.companyRegisterNumber === statement.companyRegisterNumber
      )?.companyShort,
      companyRegisterNumber: grecoCompanies?.find(
        (gc) => gc.companyRegisterNumber === statement.companyRegisterNumber
      )?.companyRegisterNumber,
    };
  });
};

export const loadBankStatements =
  (payload, onSuccess?) =>
  async ({ setState, getState, dispatch }) => {
    try {
      const { updatedGrecoCompanies, grecoCompanies, taxonomy, t } = payload;
      const searchDateFilterField = getState().searchDateFilterField;
      if (getState().bankStatementsLoadStatus === "loading") return;
      setState({
        bankStatementsLoadStatus: "loading",
      });

      const response = await API.bankStatementQuery({
        From: getState().fromDateFilter
          ? moment(getState().fromDateFilter).format("YYYY-MM-DD")
          : null,
        To: getState().toDateFilter
          ? moment(getState().toDateFilter).format("YYYY-MM-DD")
          : null,
        grecoCompanies: updatedGrecoCompanies
          .filter((el) => el.selected)
          .map((el) => el.companyRegisterNumber),
        DateFilterField: getState().searchDateFilterField,
      });
      let bankStatements = response.data.filter((el) => el.isDeleted === false);

      bankStatements = hydrateBankStatements({
        bankStatements,
        grecoCompanies,
        taxonomy,
        setState,
        getState,
        t,
      });

      let bankStatementEntries = [];
      let bankStatementMetadataMap: BankStatementMetadataMap = {};
      let bankStatementEntryMetadataMap: BankStatementEntryMetadataMap = {};

      bankStatements.forEach((statement) => {
        bankStatementMetadataMap[statement.bankStatementId] = {
          selected: false,
          inEditMode: false,
          changes: [],
          deleted: statement.isDeleted,
          editable:
            !statement.isDeleted && !statement.bankStatementDocumentPath,
          visible: true,
          oldRow: null,
        };
        statement.bankStatementEntries
          .filter((el) => el.isDeleted === false)
          .forEach((entry) => {
            bankStatementEntries.push(entry);
            bankStatementEntryMetadataMap[entry.bankStatementEntryId] = {
              selected: false,
              inEditMode: false,
              changes: [],
              deleted: entry.isDeleted,
              editable:
                !entry.isDeleted &&
                !statement.bankStatementDocumentPath &&
                !statement.isDeleted &&
                !entry.isCleared,
              visible: false,
              oldRow: null,
              resolved: entry.clientId !== null,
              cleared: entry.isCleared,
            };
          });
        delete statement.bankStatementEntries;
      });

      setState({
        bankStatements,
        bankStatementEntries,
        bankStatementMetadataMap,
        bankStatementEntryMetadataMap,
        bankStatementsLoadStatus: "success",
      });
      // onSuccess && onSuccess(response.headers["usertype"]);
    } catch (error: any) {
      if (error.code !== "ERR_CANCELED") {
        handleAxiosError(error, (message) => {
          dispatch(
            setNotificationMessage(
              prepareNotificationObject("loadBankStatements", message)
            )
          );
        });
        setState({
          bankStatementsLoadStatus: "error",
        });
      }
    }
  };

export const filterBankStatements =
  (payload) =>
  ({ setState, getState, dispatch }) => {
    const { searchTerm } = payload;
    let bankStatements = getState().bankStatements as BankStatement[];
    let bankStatementEntries = getState()
      .bankStatementEntries as BankStatementEntry[];
    let bankStatementMetadataMap = {
      ...(getState().bankStatementMetadataMap as BankStatementMetadataMap),
    };
    let bankStatementEntryMetadataMap = {
      ...(getState()
        .bankStatementEntryMetadataMap as BankStatementEntryMetadataMap),
    };
    bankStatements.forEach((el: any) => {
      let isMatch = true;
      if (el.bankStatementId < 0) {
        bankStatementMetadataMap[el.bankStatementId].visible = true;
      } else {
        if (searchTerm && searchTerm !== "") {
          isMatch =
            (isMatch &&
              (el.bankStatementComment ?? "")
                .toString()
                .toLowerCase()
                .includes(searchTerm.toLowerCase())) ||
            (el.accountOwnerName ?? "")
              .toString()
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            (el.companyName ?? "")
              .toString()
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            (el.currencyName ?? "")
              .toString()
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            formatNumberForSearch(el.accountOPBD).includes(
              searchTerm.toLowerCase()
            ) ||
            formatNumberForSearch(el.accountCLBD).includes(
              searchTerm.toLowerCase()
            ) ||
            (el.statementCreatedAt
              ? moment(el.statementCreatedAt).format("DD.MM.YYYY")
              : ""
            ).includes(searchTerm.toLowerCase()) ||
            (el.periodFrom
              ? moment(el.periodFrom).format("DD.MM.YYYY")
              : ""
            ).includes(searchTerm.toLowerCase()) ||
            (el.periodTo
              ? moment(el.periodTo).format("DD.MM.YYYY")
              : ""
            ).includes(searchTerm.toLowerCase());
        }
      }
      bankStatementMetadataMap[el.bankStatementId].visible = isMatch;
      bankStatementEntries
        .filter((entry) => entry.bankStatementId === el.bankStatementId)
        .forEach((entry) => {
          bankStatementEntryMetadataMap[entry.bankStatementEntryId].visible =
            isMatch;
        });

      if (!isMatch) {
        bankStatementMetadataMap[el.bankStatementId].selected = false;
      }
    });

    setState({
      bankStatementMetadataMap: bankStatementMetadataMap,
      bankStatementEntryMetadataMap: bankStatementEntryMetadataMap,
    });
  };

export const addNewEmptyBankStatement =
  (payload) =>
  ({ setState, getState, dispatch }) => {
    const { taxonomy, grecoCompanies } = payload;

    let bankStatements = getState().bankStatements as BankStatement[];
    const bankStatementMetadataMap = getState()
      .bankStatementMetadataMap as BankStatementMetadataMap;
    const statementId = -Math.random();

    bankStatements = [
      {
        ...new BankStatement(),
        bankStatementId: statementId,
        statementCreatedAt: moment().utc().toISOString(),
        bankStatementEntries: [],
        accountCurrencyCode: taxonomy.Currency.byCode["Currency.EUR"].id,
        companyRegisterNumber: grecoCompanies
          ? grecoCompanies[0].companyRegisterNumber
          : null,
        paidAmount: 0,
        receivedAmount: 0,
      },
      ...bankStatements,
    ];
    bankStatementMetadataMap[statementId] = {
      selected: true,
      inEditMode: true,
      changes: [],
      deleted: false,
      editable: true,
      visible: true,
      oldRow: null,
    };
    setState({
      bankStatements: bankStatements,
      bankStatementMetadataMap: bankStatementMetadataMap,
    });
  };

export const addNewEmptyBankStatementEntry =
  (bankStatementId) =>
  ({ setState, getState, dispatch }) => {
    let bankStatementEntries = getState()
      .bankStatementEntries as BankStatementEntry[];
    const bankStatementEntryId = -Math.random();
    const bankStatementEntryMetadataMap = getState()
      .bankStatementEntryMetadataMap as BankStatementEntryMetadataMap;
    bankStatementEntryMetadataMap[bankStatementEntryId] = {
      selected: false,
      inEditMode: true,
      changes: [],
      deleted: false,
      editable: true,
      visible: true,
      oldRow: null,
      resolved: false,
      cleared: false,
    };
    bankStatementEntries = [
      {
        ...new BankStatementEntry(),
        bankStatementId: bankStatementId,
        bankStatementEntryId: bankStatementEntryId,
        bookingDate: moment()
          .utc()
          .set({
            hour: 12,
            minute: 0,
            second: 0,
          })
          .toISOString(),
        valueDate: moment().utc().toISOString(),
      },
      ...bankStatementEntries,
    ];

    setState({
      bankStatementEntries: bankStatementEntries,
      bankStatementEntryMetadataMap: bankStatementEntryMetadataMap,
    });
  };

export const cancelEditBankStatement =
  (rowId) =>
  ({ setState, getState, dispatch }) => {
    let bankStatements = getState().bankStatements as BankStatement[];
    const bankStatementInfo = getState().bankStatementMetadataMap[
      rowId
    ] as BankStatementInfo;
    if (rowId < 0) {
      bankStatements = bankStatements.filter(
        (el) => el.bankStatementId !== rowId
      );
    } else {
      const oldBankStatement = bankStatementInfo.oldRow;
      bankStatements = bankStatements.map((el) => {
        if (el.bankStatementId === rowId) {
          return {
            ...el,
            ...oldBankStatement,
            bankStatementId: rowId,
          };
        }
        return el;
      });
    }

    setState({
      bankStatements: bankStatements,
      bankStatementMetadataMap: {
        ...getState().bankStatementMetadataMap,
        [rowId]: {
          ...bankStatementInfo,
          inEditMode: false,
          changes: [],
        },
      },
    });
  };

export const cancelEditBankStatementEntry =
  (rowId) =>
  ({ setState, getState, dispatch }) => {
    let bankStatementEntries = getState()
      .bankStatementEntries as BankStatementEntry[];
    const bankStatementEntryInfo = getState().bankStatementEntryMetadataMap[
      rowId
    ] as BankStatementEntryInfo;
    if (rowId < 0) {
      bankStatementEntries = bankStatementEntries.filter(
        (el) => el.bankStatementEntryId !== rowId
      );
    } else {
      const bankStatementEntry = bankStatementEntryInfo.oldRow;
      bankStatementEntries = bankStatementEntries.map((el) => {
        if (el.bankStatementEntryId === rowId) {
          return {
            ...deleteMissingProperties(el, bankStatementEntry),
            ...bankStatementEntry,
            bankStatementEntryId: rowId,
          };
        }
        return el;
      });
    }

    setState({
      bankStatementEntries: bankStatementEntries,
      bankStatementEntryMetadataMap: {
        ...getState().bankStatementEntryMetadataMap,
        [rowId]: {
          ...bankStatementEntryInfo,
          inEditMode: false,
          visible: rowId < 0 ? false : bankStatementEntryInfo.visible,
          changes: [],
        },
      },
    });
  };

export const getInsurersByCountry =
  (payload, onSuccess?, onError?) =>
  async ({ setState, getState, dispatch }) => {
    if (getState().getInsurersByCountryStatus === "loading") return;
    try {
      setState({
        getInsurersByCountryStatus: "loading",
      });
      const res = await API.getInsurersByCountry(payload);
      setState({
        getInsurersByCountryStatus: "success",
      });
      onSuccess && onSuccess(res.data);
    } catch (error) {
      handleAxiosError(error, (message) => {
        dispatch(
          setNotificationMessage(
            prepareNotificationObject("getInsurersByCountry", message)
          )
        );
      });
      setState({
        getInsurersByCountryStatus: "error",
      });
      onError && onError(error);
    }
  };

export const importBankStatement =
  (payload, onSuccess?, onError?) =>
  async ({ setState, getState, dispatch }) => {
    if (getState().importBankStatementStatus === "loading") return;
    try {
      setState({
        importBankStatementStatus: "loading",
      });
      const res = await API.importBankStatement(payload);
      setState({
        importBankStatementStatus: "success",
      });
      onSuccess && onSuccess(res.data);
    } catch (error) {
      handleAxiosError(error, (message) => {
        dispatch(
          setNotificationMessage(
            prepareNotificationObject("importBankStatement", message)
          )
        );
      });
      setState({
        importBankStatementStatus: "error",
      });
      onError && onError(error);
    }
  };

export const deleteBankStatement =
  (payload, onSuccess?, onError?) =>
  async ({ setState, getState, dispatch }) => {
    if (getState().deleteBankStatementStatus === "loading") return;
    try {
      setState({
        deleteBankStatementStatus: "loading",
      });
      const res = await API.deleteBankStatement(payload);
      setState({
        deleteBankStatementStatus: "success",
      });
      onSuccess && onSuccess(res.data);
    } catch (error) {
      handleAxiosError(error, (message) => {
        dispatch(
          setNotificationMessage(
            prepareNotificationObject("deleteBankStatement", message)
          )
        );
      });
      setState({
        deleteBankStatementStatus: "error",
      });
      onError && onError(error);
    }
  };

export const clearBankStatementEntry =
  (payload, onSuccess?, onError?) =>
  async ({ setState, getState, dispatch }) => {
    const { bankStatementEntry, suggestedPayments, paymentPlanRequest } =
      payload;
    setState({
      clearBankStatementEntryStatus: "loading",
    });
    try {
      const clearanceRequest = {
        bankStatementId: bankStatementEntry.bankStatementId,
        bankStatementEntryId: bankStatementEntry.bankStatementEntryId,
        clientId: bankStatementEntry.clientId,
        bankStatementEntry,
        suggestedPayments,
        paymentPlanRequest,
      };
      await API.commitBankStatementEntryClearance(clearanceRequest);
      const res = await API.getBankStatementEntry({
        bankStatementId: bankStatementEntry.bankStatementId,
        bankStatementEntryId: bankStatementEntry.bankStatementEntryId,
      });
      const updatedBankStatementEntry = res.data as BankStatementEntry;

      const bankStatementEntryMetadataMap = getState()
        .bankStatementEntryMetadataMap as BankStatementEntryMetadataMap;
      bankStatementEntryMetadataMap[bankStatementEntry.bankStatementEntryId] = {
        ...bankStatementEntryMetadataMap[
          bankStatementEntry.bankStatementEntryId
        ],
        inEditMode: false,
        changes: [],
        cleared: true,
      };
      setState({
        bankStatementEntries: [
          ...(getState().bankStatementEntries as BankStatementEntry[]).map(
            (el) =>
              el.bankStatementEntryId ===
              updatedBankStatementEntry.bankStatementEntryId
                ? updatedBankStatementEntry
                : el
          ),
        ],
        bankStatementEntryMetadataMap: bankStatementEntryMetadataMap,
        clearBankStatementEntryStatus: "success",
        clearanceRequest,
      });

      onSuccess && onSuccess(res.data);
    } catch (error) {
      handleAxiosError(error, (message) => {
        dispatch(
          setNotificationMessage(
            prepareNotificationObject("clearBankStatementEntry", message)
          )
        );
      });
      setState({
        clearBankStatementEntryStatus: "error",
      });
      onError && onError(error);
    }
  };

export const populateBankStatementEntryInfoData =
  (payload, onSuccess?, onError?) =>
  async ({ setState, getState, dispatch }) => {
    const { bankStatementEntryId } = payload;
    const bankStatementEntry = getState().bankStatementEntries.find(
      (el) => el.bankStatementEntryId === bankStatementEntryId
    ) as BankStatementEntry;
    if (bankStatementEntry) {
      setState({
        infoBankStatementEntryStatus: "loading",
      });
      const res = await API.populateBankStatementEntryInfo({
        bankStatementEntryId: bankStatementEntryId,
      });
      let settledPayments = res.data.map((el) => {
        return {
          ...el,

          clientName:
            (el.clientName
              ? el.clientName
              : " " + el.clientLastName + " " + el.clientFirstName) +
            " (" +
            el.clientInternalNumber +
            ")",
          insurerNameShort:
            getShortInsurerName(el.insurerId + "") === ""
              ? el.insurerName
              : getShortInsurerName(el.insurerId + "") +
                " (" +
                el.insurerInternalNumber +
                ")",
        };
      });

      const infoSettledPaymentsMetadataMap = {};
      settledPayments.forEach((el) => {
        infoSettledPaymentsMetadataMap[el.paymentId] = {
          visible: true,
          editable: false,
          changes: [],
        };
      });

      setState({
        infoSettledPayments: settledPayments,
        infoSettledPaymentsMetadataMap: infoSettledPaymentsMetadataMap,
        infoBankStatementEntryStatus: "success",
      });
    }
  };

export const populateClearanceData =
  (payload, onSuccess?, onError?) =>
  async ({ setState, getState, dispatch }) => {
    const {
      bankStatementEntryId,
      taxonomy,
      fromDate,
      toDate,
      searchTerm,
      serviceSegmentCodes,
      insurerIds,
      clientIds,
      policyNumbers,
      policySubnumbers,
      paymentReferenceNumbers,
      isBrokerPremiumCollection,
      t,
    } = payload;
    const bankStatementEntry = getState().bankStatementEntries.find(
      (el) => el.bankStatementEntryId === bankStatementEntryId
    ) as BankStatementEntry;
    if (bankStatementEntry) {
      setState({
        clearBankStatementEntryStatus: "loading",
      });
      try {
        const paymentPlanRequest = {
          bankStatementId: bankStatementEntry.bankStatementId,
          bankStatementEntryId: bankStatementEntry.bankStatementEntryId,
          clientId: bankStatementEntry.clientId,
          ServiceSegmentCodes: serviceSegmentCodes,
          From: fromDate ? moment(fromDate).format("YYYY-MM-DD") : null,
          To: toDate ? moment(toDate).format("YYYY-MM-DD") : null,
          PremiumPaymentMeanCodeId:
            taxonomy.PremiumPaymentMean.byCode[
              "PremiumPaymentMean.PREMIUMCOLLECTION"
            ].id,
          InsurerIds: insurerIds,
          ClientIds: clientIds,
          CurrencyCode: bankStatementEntry.accountCurrencyCode,
          MaxResults: 5000,
          PolicyNumbers: policyNumbers,
          PolicySubnumbers: policySubnumbers,
          PaymentReferenceNumbers: paymentReferenceNumbers,
          IsBrokerPremiumCollection: isBrokerPremiumCollection,
        };
        const res = await API.suggestBankStatementEntryClearance(
          paymentPlanRequest
        );

        let unsettledPaymentPlans = res.data.map((el) => {
          return {
            ...el,
            insuranceLineName: t(
              taxonomy?.InsuranceLine?.byId[el["insuranceLineCode"]].code
            ),
            clientName:
              (el.clientName
                ? el.clientName
                : " " + el.clientLastName + " " + el.clientFirstName) +
              " (" +
              el.clientInternalNumber +
              ")",
            insurerNameShort:
              getShortInsurerName(el.insurerId + "") === ""
                ? el.insurerName
                : getShortInsurerName(el.insurerId + "") +
                  " (" +
                  el.insurerInternalNumber +
                  ")",
          };
        });
        const clUnsettledPaymentPlanMetadataMap = {};
        //  getState()
        //   .clUnsettledPaymentPlanMetadataMap as UnsettledPaymentPlanMetadataMap;

        unsettledPaymentPlans.forEach((el) => {
          // filter list by search term
          let itemVisible = true;
          if (searchTerm) {
            if (
              !(
                el.policyName
                  .toLowerCase()
                  .includes(searchTerm.toLowerCase()) ||
                el.insurerName
                  .toLowerCase()
                  .includes(searchTerm.toLowerCase()) ||
                el.policyNumber
                  .toLowerCase()
                  .includes(searchTerm.toLowerCase()) ||
                el.policySubNumber
                  .toLowerCase()
                  .includes(searchTerm.toLowerCase())
              )
            )
              itemVisible = false;
          }
          clUnsettledPaymentPlanMetadataMap[el.paymentPlanEntryId] = {
            visible: itemVisible,
            editable: false,
            changes: [],
          };
        });
        let total = unsettledPaymentPlans.reduce(
          (acc, el) => acc + (el.suggestedPaymentAmount ?? 0),
          0
        );
        let totalRows = unsettledPaymentPlans.reduce(
          (acc, el) => acc + (el.suggestedPaymentAmount > 0 ? 1 : 0),
          0
        );

        //totalRow.premiumDebt = bankStatementEntry.amount;
        // if (unsettledPaymentPlans.length > 0) {
        //   unsettledPaymentPlans = [...unsettledPaymentPlans, totalRow];
        // }

        setState({
          paymentPlanRequest: paymentPlanRequest,
          clUnsettledPaymentPlans: unsettledPaymentPlans,
          clUnsettledPaymentPlanMetadataMap: clUnsettledPaymentPlanMetadataMap,
          clTotal: total,
          clTotalRows: totalRows,
          clearBankStatementEntryStatus: "success",
        });
      } catch (error) {
        handleAxiosError(error, (message) => {
          dispatch(
            setNotificationMessage(
              prepareNotificationObject("clearBankStatementEntry", message)
            )
          );
        });
        setState({
          clearBankStatementEntryStatus: "error",
        });
        onError && onError(error);
      }
    }
  };

export const deleteBankStatementEntry =
  (payload, onSuccess?, onError?) =>
  async ({ setState, getState, dispatch }) => {
    if (getState().deleteBankStatementEntryStatus === "loading") return;
    try {
      setState({
        deleteBankStatementEntryStatus: "loading",
      });
      const res = await API.deleteBankStatementEntry(payload);
      const bankStatementEntryMetadataMap = {
        ...(getState()
          .bankStatementEntryMetadataMap as BankStatementEntryMetadataMap),
      };

      bankStatementEntryMetadataMap[payload.bankStatementEntryId].visible =
        false;
      bankStatementEntryMetadataMap[payload.bankStatementEntryId].deleted =
        true;
      setState({
        bankStatementEntryMetadataMap: bankStatementEntryMetadataMap,
        deleteBankStatementEntryStatus: "success",
      });
      onSuccess && onSuccess(res.data);
    } catch (error) {
      handleAxiosError(error, (message) => {
        dispatch(
          setNotificationMessage(
            prepareNotificationObject("deleteBankStatementEntry", message)
          )
        );
      });
      setState({
        deleteBankStatementEntryStatus: "error",
      });
      onError && onError(error);
    }
  };

export const saveBankStatement =
  (rowId: any, onSuccess?, onError?) =>
  async ({ setState, getState, dispatch }) => {
    const bankStatement = getState().bankStatements.find(
      (el) => el.bankStatementId === rowId
    ) as BankStatement;
    const bankStatementMetadataMap = getState()
      .bankStatementMetadataMap as BankStatementMetadataMap;
    if (rowId < 0) {
      if (getState().bankStatementSaveStatus === "loading") return;
      try {
        setState({
          bankStatementSaveStatus: "loading",
        });
        const res = await API.createBankStatement(bankStatement);
        const newBankStatement = res.data;

        bankStatementMetadataMap[newBankStatement.bankStatementId] = {
          ...bankStatementMetadataMap[rowId],
          inEditMode: false,
          oldRow: null,
          changes: [],
        };
        // let oldRow = bankStatementMetadataMap[rowId].oldRow;
        // if (oldRow) {
        //   oldRow.bankStatementId = newBankStatement.bankStatementId;
        //   bankStatementMetadataMap[newBankStatement.bankStatementId] = {
        //     ...bankStatementMetadataMap[newBankStatement.bankStatementId],
        //     oldRow: null,
        //   };
        // }

        delete bankStatementMetadataMap[rowId];

        setState({
          bankStatements: [
            ...getState().bankStatements.map((el) =>
              el.bankStatementId === rowId ? newBankStatement : el
            ),
          ],
          bankStatementMetadataMap: bankStatementMetadataMap,
          bankStatementSaveStatus: "success",
        });

        onSuccess && onSuccess(newBankStatement);
        // toast.success(i18next.t("greco.success"));
      } catch (err) {
        handleAxiosError(err, (message) => {
          dispatch(
            setNotificationMessage(
              prepareNotificationObject("saveBankStatement", message)
            )
          );
        });
        setState({
          bankStatementSaveStatus: "error",
        });
        onError && onError(err);
      }
    } else {
      if (getState().bankStatementSaveStatus === "loading") return;
      try {
        setState({
          bankStatementSaveStatus: "loading",
        });
        const res = await API.updateBankStatement({
          bankStatement: {
            ...bankStatement,
            periodFrom: bankStatement.periodFrom
              ? moment(bankStatement.periodFrom).format("YYYY-MM-DD")
              : null,
            periodTo: bankStatement.periodTo
              ? moment(bankStatement.periodTo).format("YYYY-MM-DD")
              : null,
          },

          bankStatementId: bankStatement.bankStatementId,
        });

        const updatedBankStatement = res.data;

        bankStatementMetadataMap[updatedBankStatement.bankStatementId] = {
          ...bankStatementMetadataMap[rowId],
          inEditMode: false,
          changes: [],
        };

        setState({
          bankStatements: [
            ...getState().bankStatements.map((el) =>
              el.bankStatementId === updatedBankStatement.bankStatementId
                ? updatedBankStatement
                : el
            ),
          ],
          bankStatementMetadataMap: bankStatementMetadataMap,
          bankStatementSaveStatus: "success",
        });

        // toast.success(i18next.t("greco.success"));
        onSuccess && onSuccess(updatedBankStatement);
      } catch (err) {
        handleAxiosError(err, (message) => {
          dispatch(
            setNotificationMessage(
              prepareNotificationObject("saveBankStatement", message)
            )
          );
        });
        setState({
          bankStatementSaveStatus: "error",
        });
        onError && onError(err);
      }
    }
  };

export const saveBankStatementEntry =
  (rowId: any, onSuccess?, onError?) =>
  async ({ setState, getState, dispatch }) => {
    const bankStatementEntry = getState().bankStatementEntries.find(
      (el) => el.bankStatementEntryId === rowId
    ) as BankStatementEntry;

    if (rowId < 0) {
      if (getState().bankStatementEntrySaveStatus === "loading") return;
      try {
        setState({
          bankStatementEntrySaveStatus: "loading",
        });
        let entry = { ...bankStatementEntry }; //, ...bankStatementEntry.clientInfo };
        if (bankStatementEntry.clientInfo) {
          entry.clientId = bankStatementEntry.clientInfo.id;
        }
        delete entry.bankStatementEntryId;
        delete entry.clientInfo;
        entry.clientCode = entry.clientCode ? entry.clientCode + "" : "";
        const res = await API.createBankStatementEntry(
          bankStatementEntry.bankStatementId,
          entry
        );
        let newBankStatementEntry = {
          ...res.data,
        };
        if (bankStatementEntry.clientInfo) {
          newBankStatementEntry = {
            ...newBankStatementEntry,
            clientInfo: { ...bankStatementEntry.clientInfo },
          };
        }
        const bankStatementEntryMetadataMap = {
          ...(getState()
            .bankStatementEntryMetadataMap as BankStatementEntryMetadataMap),
        };
        bankStatementEntryMetadataMap[
          newBankStatementEntry.bankStatementEntryId
        ] = {
          ...bankStatementEntryMetadataMap[rowId],
          oldRow: null,

          inEditMode: false,
          changes: [],
        };
        delete bankStatementEntryMetadataMap[rowId];
        setState({
          bankStatementEntries: [
            ...getState().bankStatementEntries.map((el) =>
              el.bankStatementEntryId === rowId ? newBankStatementEntry : el
            ),
          ],
          bankStatementEntryMetadataMap: bankStatementEntryMetadataMap,
          bankStatementEntrySaveStatus: "success",
        });

        onSuccess && onSuccess(newBankStatementEntry);
        toast.success(i18next.t("greco.success"));
      } catch (err) {
        handleAxiosError(err, (message) => {
          dispatch(
            setNotificationMessage(
              prepareNotificationObject("saveBankStatementEntry", message)
            )
          );
        });
        setState({
          bankStatementEntrySaveStatus: "error",
        });
        onError && onError(err);
      }
    } else {
      if (getState().bankStatementEntrySaveStatus === "loading") return;
      try {
        setState({
          bankStatementEntrySaveStatus: "loading",
        });
        const bankStatementEntryMetadataMap = getState()
          .bankStatementEntryMetadataMap as BankStatementEntryMetadataMap;

        const bankStatementEntryEdited =
          bankStatementEntryMetadataMap[rowId].inEditMode;
        const changes = bankStatementEntryMetadataMap[rowId].changes;
        const bankStetementEntryContainsClientInfo =
          "clientInfo" in bankStatementEntry;
        const bankStatementEntryContainsChangesToClientInfo =
          "changes" in bankStatementEntryMetadataMap[rowId] &&
          changes.find((el) => el.type === "client") !== undefined;

        let entry = null;
        if (
          bankStatementEntryEdited &&
          bankStetementEntryContainsClientInfo &&
          bankStatementEntryContainsChangesToClientInfo
        ) {
          const bankStatementId = bankStatementEntry.bankStatementId;
          const bankStatementEntryId = bankStatementEntry.bankStatementEntryId;
          const clientId = bankStatementEntry.clientInfo.id;

          const res = await API.resolvementManual({
            bankStatementId,
            bankStatementEntryId,
            clientId,
          });
          entry = { ...res.data }; //, ...bankStatementEntry.clientInfo };
        } else {
          entry = { ...bankStatementEntry }; //, ...bankStatementEntry.clientInfo };
          delete entry.clientInfo;
        }
        entry.clientCode = entry.clientCode + "";
        const bankStatementDocumentPath = getState().bankStatements.find(
          (el) => el.bankStatementId === bankStatementEntry.bankStatementId
        ).bankStatementDocumentPath;
        let updatedBankStatementEntry = null;

        if (!bankStatementDocumentPath) {
          const res = await API.updateBankStatementEntry({
            bankStatementEntry: entry,
            bankStatementId: bankStatementEntry.bankStatementId,
            bankStatementEntryId: bankStatementEntry.bankStatementEntryId,
          });
          updatedBankStatementEntry = {
            ...res.data,
            clientInfo: res.data.clientInfo
              ? {
                  id: res.data.clientInfo.id,
                  name: res.data.clientInfo.name,
                  internalNumber: res.data.clientInfo.internalNumber,
                  isCompany: res.data.clientInfo.isCompany,
                }
              : null,
          };
        } else {
          updatedBankStatementEntry = {
            ...entry,
            clientInfo: entry.clientInfo
              ? {
                  id: entry.clientInfo.id,
                  name: entry.clientInfo.name,
                  internalNumber: entry.clientInfo.internalNumber,
                  isCompany: entry.clientInfo.isCompany,
                }
              : null,
          };
        }

        bankStatementEntryMetadataMap[
          updatedBankStatementEntry.bankStatementEntryId
        ] = {
          ...bankStatementEntryMetadataMap[rowId],
          inEditMode: false,
          changes: [],
        };

        setState({
          bankStatementEntries: [
            ...getState().bankStatementEntries.map((el) =>
              el.bankStatementEntryId ===
              updatedBankStatementEntry.bankStatementEntryId
                ? updatedBankStatementEntry
                : el
            ),
          ],
          bankStatementEntryMetadataMap: bankStatementEntryMetadataMap,
          bankStatementEntrySaveStatus: "success",
        });

        //toast.success(i18next.t("greco.success"));
        onSuccess && onSuccess(updatedBankStatementEntry);
      } catch (err) {
        handleAxiosError(err, (message) => {
          dispatch(
            setNotificationMessage(
              prepareNotificationObject("saveBankStatementEntry", message)
            )
          );
        });
        setState({
          bankStatementEntrySaveStatus: "error",
        });
        onError && onError(err);
      }
    }
  };

export const resolvementSuggest =
  ({ bankStatementId, bankStatementEntryId }, onSuccess?, onError?) =>
  async ({ setState, getState, dispatch }) => {
    if (getState().resolvementLoadStatus === "loading") return;
    try {
      setState({
        resolvementLoadStatus: "loading",
      });
      const res = await API.resolvementSuggest({
        bankStatementId,
        bankStatementEntryId,
      });
      const retVal = res.data;
      setState({
        resolvementLoadStatus: "success",
      });
      if (retVal.clientInfo === null && retVal.clientId === null) {
        toast.error(i18next.t("pct.noresolvementavailable.label"));
      }

      onSuccess && onSuccess(retVal);
    } catch (error) {
      handleAxiosError(error, (message) => {
        dispatch(
          setNotificationMessage(
            prepareNotificationObject("resolvementSuggest", message)
          )
        );
      });
      setState({
        resolvementLoadStatus: "error",
      });
      onError && onError(error);
    }
  };

export const resolvementSuggestForStatement =
  ({ bankStatementId }, onSuccess?, onError?) =>
  async ({ setState, getState, dispatch }) => {
    if (getState().resolvementLoadStatus === "loading") return;
    try {
      setState({
        resolvementLoadStatus: "loading",
      });
      const res = await API.resolvementSuggestForStatement({
        bankStatementId,
      });
      const retVal = res.data;
      setState({
        resolvementLoadStatus: "success",
      });
      onSuccess && onSuccess(retVal);
    } catch (error) {
      handleAxiosError(error, (message) => {
        dispatch(
          setNotificationMessage(
            prepareNotificationObject("resolvementSuggestForStatement", message)
          )
        );
      });
      setState({
        resolvementLoadStatus: "error",
      });
      onError && onError(error);
    }
  };
