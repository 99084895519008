import React from "react";
import { headerConfig } from "config/headerConfig";
import {
  createActionsHook,
  createHook,
  createStateHook,
  createStore,
  defaults,
} from "react-sweet-state";
import { GrecoCompany, LoadStatus, Notification } from "types/types";
import {
  clearAllNotifications,
  clearFormNotifications,
  filterDuplicates,
  loadGrecoCompanies,
  // loadAppInfoData,
  loadTaxonomy,
  loadUADTaxonomy,
  removeNotification,
  setAppStoreValue,
  setNotificationMessage,
} from "./AppStoreActions";
defaults.devtools = true;

export type StoreState = {
  appState: any;
  taxonomy: any;
  taxonomyResponse: any;
  userRole: string;
  taxonomyUad: any;
  taxonomyLoadStatus: LoadStatus;
  taxonomyUadLoadStatus: LoadStatus;
  notifications: Notification[];
  headerTitle: string;
  grecoCompanies: GrecoCompany[];
  updatedGrecoCompanies: GrecoCompany[];

  // tooltipsStatus: boolean;
};

// This is the value of the store on initialisation
const initialState: StoreState = {
  appState: {
    headerConfig: headerConfig,
    panelIsOpen: false,
    user: {},
    error: "",
    loading: false,
    search: "",
    darkMode: JSON.parse(localStorage.getItem("darkMode")!),
  },
  userRole: null,
  taxonomy: null,
  taxonomyResponse: null,
  taxonomyUad: null,
  taxonomyLoadStatus: "none",
  taxonomyUadLoadStatus: "none",
  notifications: [],
  headerTitle: "Premium collection tool",
  grecoCompanies: null,
  updatedGrecoCompanies: null,
  // tooltipsStatus: true,
};

// All the actions that mutate the store
const actions = {
  // loadAppInfoData: () => loadAppInfoData,
  loadUADTaxonomy: () => loadUADTaxonomy,
  loadTaxonomy: () => loadTaxonomy,
  setAppStoreValue: setAppStoreValue,
  setNotificationMessage: setNotificationMessage,
  removeNotification: removeNotification,
  filterDuplicates: filterDuplicates,
  clearAllNotifications: clearAllNotifications,
  clearFormNotifications: clearFormNotifications,
  loadGrecoCompanies: () => loadGrecoCompanies,
};

const AppStore = createStore({ initialState, actions, name: "AppStore" });
export const useAppStore = createHook(AppStore);
export const useAppStoreState = createStateHook(AppStore);
export const useAppStoreActions = createActionsHook(AppStore);
