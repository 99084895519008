import { Column, ColumnOptions as GrecoColumnOptions } from "@greco/components";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ExpandedColumn } from "../../types/columns";
import { Setting } from "../../types/types";
import {
  usePaymentPlanPageStore,
  usePaymentPlanPageStoreState,
} from "store/PaymentPlanPageStore";
import { columns } from "./columns";
import { useAppStore, useAppStoreState } from "store/AppStore";
import { clientSearch, insurerSearch } from "api/api";
import { getShortInsurerName, getTaxonomyItemId } from "utils/utils";

const reorderColumn = (
  columns: ExpandedColumn[],
  index1: number,
  index2: number
) => {
  const clone = [...columns];
  clone.splice(index2, 0, clone.splice(index1, 1)[0]);
  return clone;
};

export const ColumnOptions = ({
  isOpen,
  closePanel,
}: {
  isOpen: boolean;
  closePanel: () => void;
}) => {
  const appStore = useAppStore();
  const [{ taxonomyResponse }, { setAppStoreValue }] = appStore;
  const { t } = useTranslation();

  const [
    {
      tempColumns,
      settingsLoadStatus,
      selectedView,
      selectedViewColumns,
      columnOptionsSettings,
    },
    {
      setPaymentPlanStoreValue,
      setPaymentPlanStoreValues,
      deleteSetting,
      updateSetting,
      createSetting,
    },
  ] = usePaymentPlanPageStore();
  const { taxonomyUad } = useAppStoreState();

  const applicationCodeId = taxonomyUad?.ApplicationCode?.find(
    (el) => el.code === "Application.PCT"
  ).id;

  const appSettingCodeId = taxonomyUad?.ApplicationSetting?.find(
    (el) => el.code === "AppSetting.ColumnOptions"
  ).id;
  const areSettingsLoading = settingsLoadStatus === "loading";
  const areSettingsLoaded = settingsLoadStatus !== "loading";

  const isViewLoading = areSettingsLoading;

  const transformedColumns = useMemo(() => {
    const transformedColumns = tempColumns?.map((c) => {
      if (
        c.key === "select" ||
        c.key === "options" ||
        c.key === "SelectedInsurer"
      ) {
        return {
          ...c,
          label: t(c.labelKey),
          isPresentInColumnOptions: false,
        };
      }
      return {
        ...c,
        label: t(c.labelKey),
      };
    });
    const fixedColumn = transformedColumns?.filter((column) => column.isFixed);

    const unfixedTransformedColumns = transformedColumns?.filter(
      (column) => !column.isFixed
    );

    return fixedColumn?.concat(unfixedTransformedColumns);
  }, [tempColumns, t]);

  if (!isOpen) return null;

  function updateClientTaxonomies(values: number[]) {
    clientSearch({
      search: "",
      top: values.length,
      skip: 0,
      partnerType: [900000000003140, 900000000003141],
      filter: `partnerID eq '${values.join("' or partnerID eq '")}'`,
    }).then((res: any) => {
      if (res.isAxiosError) {
        //setClientsFilter([]);
        return;
      }
      const options: { document: any }[] = res.data.results;

      let clientTaxonomy = taxonomyResponse["client"];
      const newEntries = options.map((o: { document: any }) => ({
        code: o.document.partnerName || "",
        id: Number(o.document.partnerID),
      }));

      // Add new entries to clientTaxonomy, ignore if they already exist
      const newClientTaxonomy = newEntries.filter(
        (e) => !clientTaxonomy.find((c) => c.id === e.id)
      );
      // Append new entries to clientTaxonomy
      clientTaxonomy = [...clientTaxonomy, ...newClientTaxonomy];

      setAppStoreValue("taxonomyResponse", {
        ...taxonomyResponse,
        client: clientTaxonomy,
      });
    });
  }

  function updateInsurerTaxonomies(values: number[]) {
    insurerSearch({
      search: "",
      top: values.length,
      skip: 0,
      partnerType: [900000000003142],
      filter: `partnerID eq '${values.join("' or partnerID eq '")}'`,
    }).then((res: any) => {
      if (res.isAxiosError) {
        //setInsurersFilter([]);
        return;
      }
      const options: { document: any }[] = res.data.results;

      let insurerTaxonomy = taxonomyResponse["insurer"];
      const newEntries = options.map((o: { document: any }) => {
        let name = o.document.partnerName;
        const shortName = getShortInsurerName(o.document.partnerID + "");
        if (shortName !== "") name = shortName!;
        return {
          code: name || "",
          id: Number(o.document.partnerID),
        };
      });

      // Add new entries to insurerTaxonomy, ignore if they already exist
      const newInsurerTaxonomy = newEntries.filter(
        (e) => !insurerTaxonomy.find((c) => c.id === e.id)
      );
      // Append new entries to insurerTaxonomy
      insurerTaxonomy = [...insurerTaxonomy, ...newInsurerTaxonomy];

      setAppStoreValue("taxonomyResponse", {
        ...taxonomyResponse,
        insurer: insurerTaxonomy,
      });
    });
  }

  return (
    <div>
      <GrecoColumnOptions
        t={t}
        columns={transformedColumns as Column[]}
        isReady={areSettingsLoaded}
        isViewLoading={isViewLoading}
        closePanel={closePanel}
        onChangeView={(id) => {
          setPaymentPlanStoreValue("selectedViewId", id);
        }}
        onClickApply={() => {
          const visibleColumns = tempColumns.map((c) =>
            c.key === "options" ? { ...c, isVisible: true } : c
          );

          let values: any = {
            cols: visibleColumns,
          };
          const clientColumn = (visibleColumns as any[]).find(
            (c) => c.key === "client"
          );
          if (
            clientColumn.filter &&
            clientColumn.filter.value &&
            clientColumn.filter.value.length > 0
          ) {
            values = {
              ...values,
              searchClientsSelectedItem: null,
            };
          }

          const insurerColumn = (visibleColumns as any[]).find(
            (c) => c.key === "insurerName"
          );
          if (
            insurerColumn.filter &&
            insurerColumn.filter.value &&
            insurerColumn.filter.value.length > 0
          ) {
            values = {
              ...values,
              searchInsurersSelectedItem: null,
            };
          }

          setPaymentPlanStoreValues(values);
          closePanel();
        }}
        onClickClearAllFilters={() => {
          const visibleColumns = tempColumns.map((c) =>
            c.key === "options" ? { ...c, isVisible: true } : c
          );
          setPaymentPlanStoreValue(
            "tempColumns",
            visibleColumns.map((c) => ({
              ...c,
              filter: columns.find((dc) => dc.key === c.key)?.filter,
            }))
          );
        }}
        onClickDeleteView={() => {
          deleteSetting(selectedView.userAppSettingId);
        }}
        onClickReset={() => {
          setPaymentPlanStoreValue("tempColumns", selectedViewColumns);
        }}
        onClickSaveView={() => {
          const visibleColumns = tempColumns.map((c) =>
            c.key === "options" ? { ...c, isVisible: true } : c
          );
          updateSetting({
            ...selectedView,
            userAppSettingValue: JSON.stringify(visibleColumns),
          });
        }}
        onCreateView={(data) => {
          const visibleColumns = tempColumns.map((c) =>
            c.key === "options" ? { ...c, isVisible: true } : c
          );
          const newSetting = {
            applicationCodeId: applicationCodeId,
            appSettingCodeId: appSettingCodeId,
            userAppSettingValue: JSON.stringify(visibleColumns),
            ...data,
          } as Setting;
          createSetting(newSetting);
          closePanel();
        }}
        onUpdateView={(data) => {
          updateSetting({
            ...selectedView,
            ...data,
          });
          closePanel();
        }}
        onDeselectAll={() => {
          const visibleColumns = tempColumns.map((c) =>
            c.key === "options" ? { ...c, isVisible: true } : c
          );

          setPaymentPlanStoreValue(
            "tempColumns",
            visibleColumns.map((c) => ({
              ...c,
              isVisible: c.isFixed ? true : false,
            }))
          );
        }}
        onSelectAll={() => {
          setPaymentPlanStoreValue(
            "tempColumns",
            tempColumns.map((c) => ({
              ...c,
              isVisible: true,
            }))
          );
        }}
        onReorderColumn={(src, dest) => {
          setPaymentPlanStoreValue(
            "tempColumns",
            reorderColumn(tempColumns, src, dest)
          );
        }}
        onToggleColumnVisibility={(columnKey) => {
          setPaymentPlanStoreValue(
            "tempColumns",
            tempColumns.map((c) => {
              if (c.key === columnKey) {
                return {
                  ...c,
                  isVisible: !c.isVisible,
                };
              }
              return c;
            })
          );
        }}
        selectedView={selectedView}
        setFilter={(columnKey, filter) => {
          const visibleColumns = tempColumns.map((c) =>
            c.key === "options" ? { ...c, isVisible: true } : c
          );
          if ((filter as any).taxonomyKey === "client") {
            updateClientTaxonomies((filter as any).value);
          }
          if ((filter as any).taxonomyKey === "insurer") {
            updateInsurerTaxonomies((filter as any).value);
          }
          setPaymentPlanStoreValue(
            "tempColumns",
            visibleColumns.map((c) => {
              if (c.key === columnKey) {
                return {
                  ...c,
                  filter,
                };
              }
              return c;
            })
          );
        }}
        taxonomy={taxonomyResponse as any}
        views={columnOptionsSettings}
      />
    </div>
  );
};
