import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  Spinner,
  Textarea,
} from "@fluentui/react-components";
import { DeleteDismissFilled } from "@fluentui/react-icons";
import { toast } from "components/FluentToast";
import { VSpace } from "components/Spacer";
import React from "react";
import { useTranslation } from "react-i18next";
import { useBankStatementsStore } from "store/BankStatementsStore";

export const DeleteBankStatementEntryDialog = () => {
  const [
    {
      deleteBankStatementEntryDialog,
      deleteBankStatementEntryStatus,
      bankStatementEntries,
    },
    {
      setBankStatementsStoreValue,
      setBankStatementsStoreValues,
      deleteBankStatementEntry,
    },
  ] = useBankStatementsStore();

  const isLoading = deleteBankStatementEntryStatus === "loading";
  const [comment, setComment] = React.useState("");
  const { t } = useTranslation();

  return (
    <Dialog open={deleteBankStatementEntryDialog.isOpen}>
      <DialogSurface>
        <DialogBody>
          <DialogTitle>{t("pct.deleteBankStatementEntry.label")}</DialogTitle>
          <DialogContent>
            {t("pct.deleteBankStatementEntryConfirm.label", {
              paymentReferenceNumber: bankStatementEntries.find((el) => {
                return (
                  el.bankStatementEntryId ===
                  deleteBankStatementEntryDialog.bankStatementEntryId
                );
              })?.paymentReferenceNumber,
            })}
            <VSpace height={20} />
            <div>
              <label>{t("pct.comment.label")}</label>
              <VSpace height={5} />

              <Textarea
                style={{ width: "100%" }}
                rows={6}
                value={comment}
                disabled={isLoading}
                onChange={(e) => setComment(e.target.value)}
              />
            </div>
            <VSpace height={20} />
          </DialogContent>
          <DialogActions>
            <Button
              appearance="secondary"
              disabled={isLoading}
              onClick={() => {
                setBankStatementsStoreValue("deleteBankStatementEntryDialog", {
                  isOpen: false,
                  bankStatementEntry: null,
                  bankStatement: null,
                });
              }}
            >
              {t("pct.close.label")}
            </Button>
            <Button
              appearance="primary"
              disabled={isLoading}
              icon={
                isLoading ? (
                  <Spinner size="tiny" />
                ) : (
                  <DeleteDismissFilled fontSize={16} />
                )
              }
              onClick={() => {
                deleteBankStatementEntry(
                  {
                    bankStatementEntryId:
                      deleteBankStatementEntryDialog.bankStatementEntryId,
                    bankStatementId:
                      deleteBankStatementEntryDialog.bankStatementId,
                    comment: comment,
                    rowVersion: bankStatementEntries.find((el) => {
                      return (
                        el.bankStatementEntryId ===
                        deleteBankStatementEntryDialog.bankStatementEntryId
                      );
                    })?.rowVersion,
                  },
                  (row) => {
                    setBankStatementsStoreValues({
                      deleteBankStatementEntryDialog: {
                        isOpen: false,
                        bankStatementEntryId: null,
                        bankStatementId: null,
                      },
                      bankStatementEntries: bankStatementEntries.map((el) => {
                        if (
                          el.bankStatementEntryId === row.bankStatementEntryId
                        ) {
                          return {
                            selected: false,
                            edited: false,
                            changes: [],
                            ...row,
                          };
                        } else {
                          return el;
                        }
                      }),
                    });
                    toast.success(t("greco.success"));
                    //loadBankStatements({ reset: true, delay: true });
                  }
                );
              }}
            >
              {t("pct.delete.label")}
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};
