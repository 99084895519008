export interface ColumnConfig {
  columnId: string;
  width: number;
  reorderable: boolean;
  resizable: boolean;
  sortable: boolean;
  label: string;
  cellType: string;
  additionalInfo?: {
    taxonomy?: string;
    list?: string;
    clientAccountIdField?: string;
    clientCodeField?: string;
    clientIdField?: string;
    clientNameField?: string;
    linkField?: string;
    rowIdField?: string;
    gosLink?: {
      idField: string;
      linkType: "policy" | "client";
    };
    redColorFn?: (item: any) => boolean;
  };
  excel?: any;
  align?: string;
}

export const paymentColumnConfig: ColumnConfig[] = [
  {
    columnId: "ordinalNumber",
    width: 50,
    reorderable: false,
    resizable: true,
    label: "",
    cellType: "ordinalnumber",
    sortable: false,
  },
  {
    columnId: "select",
    width: 40,
    reorderable: false,
    resizable: false,
    label: "",
    cellType: "select",
    sortable: false,
    additionalInfo: {
      rowIdField: "paymentId",
    },
  },
  {
    columnId: "lastModified",
    width: 40,
    reorderable: true,
    resizable: false,
    sortable: true,
    label: "",
    cellType: "lastmodified",
  },
  {
    columnId: "declared",
    width: 40,
    reorderable: true,
    resizable: false,
    sortable: true,
    label: "",
    cellType: "declared",
  },

  {
    columnId: "isDiscountPayment",
    width: 70,
    reorderable: true,
    resizable: true,
    sortable: true,
    label: "pct.isDiscountPayment.label",
    cellType: "customcheckmark",
  },

  {
    columnId: "insurerNameShort",
    width: 135,
    reorderable: true,
    resizable: true,
    label: "pct.insurer.label",
    sortable: true,
    cellType: "customtext",
    additionalInfo: {
      gosLink: {
        idField: "insurerId",
        linkType: "client",
      },
    },
  },
  {
    columnId: "clientName",
    width: 250,
    reorderable: true,
    resizable: true,
    label: "pct.client.label",
    sortable: true,
    cellType: "customtext",
    additionalInfo: {
      gosLink: {
        idField: "clientId",
        linkType: "client",
      },
    },
  },
  {
    columnId: "policyName",
    width: 250,
    reorderable: true,
    resizable: true,
    label: "pct.policyName.label",
    sortable: true,
    cellType: "customtext",
    additionalInfo: {
      gosLink: {
        idField: "policyId",
        linkType: "policy",
      },
    },
  },
  {
    columnId: "policyNumber",
    width: 150,
    reorderable: true,
    resizable: true,
    label: "pct.policyNumber.label",
    sortable: true,
    cellType: "customtext",
    additionalInfo: undefined,
  },

  {
    columnId: "policySubNumber",
    width: 150,
    reorderable: true,
    resizable: true,
    label: "pct.policySubNumber.label",
    sortable: true,
    cellType: "customtext",
    additionalInfo: undefined,
  },

  {
    columnId: "reminderNumber",
    width: 180,
    reorderable: true,
    resizable: true,
    label: "pct.reminderNumber.label",
    sortable: true,
    cellType: "customtext",
    additionalInfo: undefined,
  },
  {
    columnId: "declaredAt",
    width: 120,
    reorderable: true,
    resizable: true,
    label: "pct.declaredAt.label",
    sortable: true,
    cellType: "datepicker",
    additionalInfo: undefined,
  },
  {
    columnId: "paymentDate",
    width: 120,
    reorderable: true,
    resizable: true,
    label: "pct.paymentDate.label",
    sortable: true,
    cellType: "datepicker",
    additionalInfo: undefined,
  },
  {
    columnId: "createdAt",
    width: 120,
    reorderable: true,
    resizable: true,
    label: "pct.clearedAt.label",
    sortable: true,
    cellType: "datepicker",
    additionalInfo: undefined,
  },
  {
    columnId: "bookingDate",
    width: 120,
    reorderable: true,
    resizable: true,
    label: "pct.bookingDate.label",
    sortable: true,
    cellType: "datepicker",
    additionalInfo: undefined,
  },
  {
    columnId: "bookedPremiumAmount",
    width: 120,
    reorderable: true,
    resizable: true,
    label: "pct.bookedPremiumAmount.label",
    sortable: true,
    cellType: "customnumber",
    additionalInfo: undefined,
  },
  // {
  //   columnId: "premiumFrom",
  //   width: 120,
  //   reorderable: true,
  //   resizable: true,
  //   label: "pct.premiumFrom.label",
  //   sortable: false,
  //   cellType: "datepicker",
  //   additionalInfo: undefined,
  // },
  // {
  //   columnId: "premiumTo",
  //   width: 120,
  //   reorderable: true,
  //   resizable: true,
  //   label: "pct.premiumTo.label",
  //   sortable: false,
  //   cellType: "datepicker",
  //   additionalInfo: undefined,
  // },
  // {
  //   columnId: "policyPaymentFrequencyCodeId",
  //   width: 150,
  //   reorderable: true,
  //   resizable: true,
  //   label: "pct.policyPaymentFrequencyCodeId.label",
  //   sortable: false, cellType: "taxonomy",
  //   additionalInfo: {
  //     taxonomy: "PaymentFrequency",
  //   },
  // },

  {
    columnId: "commissionAmount",
    width: 120,
    reorderable: true,
    resizable: true,
    label: "pct.commissionAmount.label",
    sortable: true,
    cellType: "customnumber",
    additionalInfo: undefined,
  },
  // {
  //   columnId: "premiumDiscountByGrECo",
  //   width: 200,
  //   reorderable: true,
  //   resizable: true,
  //   label: "pct.premiumDiscountByGrECo.label",
  //   sortable: false,
  //   cellType: "customnumber",
  //   additionalInfo: undefined,
  // },

  // {
  //   columnId: "declaredAt",
  //   width: 120,
  //   reorderable: true,
  //   resizable: true,
  //   label: "pct.declaredAt.label",
  //   sortable: false,
  //   cellType: "datepicker",
  //   additionalInfo: undefined,
  // },

  // {
  //   columnId: "declaredByUserId",
  //   width: 150,
  //   reorderable: true,
  //   resizable: true,
  //   label: "pct.declaredByUserId.label",
  //   sortable: false,
  //   cellType: "customtext",
  //   additionalInfo: undefined,
  // },

  // {
  //   columnId: "declaredByUserName",
  //   width: 150,
  //   reorderable: true,
  //   resizable: true,
  //   label: "pct.declaredByUserName.label",
  //   sortable: false,
  //   cellType: "customtext",
  //   additionalInfo: undefined,
  // },
  // {
  //   columnId: "declaredByUserEmail",
  //   width: 150,
  //   reorderable: true,
  //   resizable: true,
  //   label: "pct.declaredByUserEmail.label",
  //   sortable: false,
  //   cellType: "customtext",
  //   additionalInfo: undefined,
  // },

  // {
  //   columnId: "bookedPremiumAmount",
  //   width: 200,
  //   reorderable: true,
  //   resizable: true,
  //   label: "pct.bookedPremiumAmount.label",
  //   sortable: false,
  //   cellType: "customnumber",
  //   additionalInfo: undefined,
  // },

  {
    columnId: "currencyCode",
    width: 70,
    reorderable: true,
    resizable: true,
    sortable: true,
    label: "pct.currencyCode.label",
    cellType: "taxonomy",
    additionalInfo: {
      taxonomy: "Currency",
    },
  },

  {
    columnId: "insuranceLineCode",
    width: 250,
    reorderable: true,
    resizable: true,
    label: "pct.insuranceLineCode.label",
    sortable: true,
    cellType: "taxonomy",
    additionalInfo: {
      taxonomy: "InsuranceLine",
    },
  },

  {
    columnId: "paymentComment",
    width: 650,
    reorderable: true,
    resizable: true,
    label: "pct.paymentComment.label",
    sortable: true,
    cellType: "customtext",
    additionalInfo: undefined,
  },
  {
    columnId: "premiumAmount",
    width: 120,
    reorderable: true,
    resizable: true,
    label: "pct.premiumAmount.label",
    sortable: true,
    cellType: "customnumber",
    additionalInfo: undefined,
  },
  {
    columnId: "declaredPremiumAmount",
    width: 120,
    reorderable: true,
    resizable: true,
    label: "pct.declaredPremiumAmount.label",
    sortable: true,
    cellType: "customnumber",
    additionalInfo: {
      redColorFn: (item) => {
        if (
          item["declaredPremiumAmount"] !== null &&
          item["premiumAmount"] !== item["declaredPremiumAmount"]
        )
          return true;
        return false;
      },
    },
  },
  {
    columnId: "paymentoptions",
    width: 40,
    reorderable: true,
    resizable: true,
    label: "pct.paymentoptions.label",
    sortable: false,
    cellType: "paymentoptions",
    additionalInfo: {
      rowIdField: "paymentId",
    },
  },
];
