import { Button, Label } from "@fluentui/react-components";
import {
  Flex,
  FlexRight,
  HorizontalSpacer,
  NavigationBar,
} from "components/styled";
import React, { useEffect, useState } from "react";
import { usePaymentPlanPageStore } from "store/PaymentPlanPageStore";
import SearchClients from "./SearchClients";
import { useAppStore } from "store/AppStore";
import { useTranslation } from "react-i18next";
import SearchInsurers from "./SearchInsurers";
import { DocumentSyncRegular } from "@fluentui/react-icons";
import moment from "moment";
import { formatNumber } from "utils/number";
import { ColumnOptionsButton } from "@greco/components";
import { ColumnOptions } from "./ColumnOptions";
import { ResultsScore } from "components/ResultsScore";
import ClearClientSaldoButton from "./ClearClientSaldoButton";
import SearchIsBrokerPremiumCollection from "./SearchIsBrokerPremiumCollection";

const PaymentPlanPageNavigationSecondLine = () => {
  const [
    {
      count,
      areAnyColumnsFiltered,
      searchClientsSelectedItem,
      paymentPlansLoadStatus,
      gosLastSyncTime,
    },
    { getClientSaldo, synchronizeGos },
  ] = usePaymentPlanPageStore();
  const [isColumnOptionsOpen, setIsColumnOptionsOpen] = useState(false);

  // useEffect(() => {
  //   if (!searchClientsSelectedItem) return;
  //   getClientSaldo({
  //     clientId: searchClientsSelectedItem?.value?.id,
  //   });
  // }, [searchClientsSelectedItem]);
  const { t } = useTranslation();

  return (
    <NavigationBar style={{ zIndex: 333 }}>
      <Label>{t("pct.client.label")}:</Label>
      <HorizontalSpacer width={10} />
      <SearchClients />
      <HorizontalSpacer width={10} />
      {/* <ClearClientSaldoButton /> */}
      <SearchInsurers /> <HorizontalSpacer width={10} />
      <SearchIsBrokerPremiumCollection
        name="searchIsBrokerPremiumCollection"
        required={true}
        disabled={paymentPlansLoadStatus === "loading"}
        isLoading={paymentPlansLoadStatus === "loading"}
        visible={true}
      />
      <FlexRight>
        <Flex>
          <ColumnOptionsButton
            t={t}
            isActive={areAnyColumnsFiltered}
            onClick={() => {
              setIsColumnOptionsOpen(true);
            }}
          />
          <ColumnOptions
            isOpen={isColumnOptionsOpen}
            closePanel={() => {
              setIsColumnOptionsOpen(false);
            }}
          />
          <ResultsScore score={count} />
          <HorizontalSpacer width={10} />

          <Button
            appearance="primary"
            title={t("pct.syncGosTitle.label", [
              gosLastSyncTime
                ? moment(gosLastSyncTime).isSame(moment(), "day")
                  ? moment(gosLastSyncTime).format("HH:mm")
                  : moment(gosLastSyncTime).format("DD.MM.YYYY HH:mm")
                : "never",
            ])}
            icon={<DocumentSyncRegular fontSize={24} />}
            onClick={() => {
              synchronizeGos();
            }}
          >
            {t("pct.syncGos.label")}
          </Button>
        </Flex>
      </FlexRight>
    </NavigationBar>
  );
};

export default PaymentPlanPageNavigationSecondLine;
