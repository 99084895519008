import { Button, Tooltip } from "@fluentui/react-components";
import {
  Cell,
  CellStyle,
  CellTemplate,
  Compatible,
  Uncertain,
  UncertainCompatible,
  getCellProperty,
  keyCodes,
} from "@silevis/reactgrid";
import * as React from "react";
import {
  DismissFilled,
  FlashAutoRegular,
  ArrowSortRegular,
  ArrowSortUpRegular,
  ArrowSortDownRegular,
} from "@fluentui/react-icons";

export interface ClearedAmountAllCell extends Cell {
  type: "clearedamountall";
  value: number;
  format?: Intl.NumberFormat;
  nanToZero?: boolean;
  hideZero?: boolean;
  key?: string;

  sort?: any;
  sortFn?: any;
  sortable: boolean;
  t: any;
  onSelectAllFn: () => void;
  onClearAllFn: () => void;
}

export class ClearedAmountAllCellTemplate
  implements CellTemplate<ClearedAmountAllCell>
{
  getCompatibleCell(
    uncertainCell: Uncertain<ClearedAmountAllCell>
  ): Compatible<ClearedAmountAllCell> {
    let value: number;

    let t: any;
    t = getCellProperty(uncertainCell, "t", "function");

    let onSelectAllFn: any;
    onSelectAllFn = getCellProperty(uncertainCell, "onSelectAllFn", "function");

    let onClearAllFn: any;
    onClearAllFn = getCellProperty(uncertainCell, "onClearAllFn", "function");
    let key: any;
    try {
      key = getCellProperty(uncertainCell, "key", "string");
    } catch {
      key = undefined;
    }
    let sortable: any;
    try {
      sortable = getCellProperty(uncertainCell, "sortable", "boolean");
    } catch {
      sortable = undefined;
    }
    let sort: any;
    try {
      sort = getCellProperty(uncertainCell, "sort", "object");
    } catch {
      sort = undefined;
    }

    let sortFn: any;
    try {
      sortFn = getCellProperty(uncertainCell, "sortFn", "function");
    } catch {
      sortFn = undefined;
    }
    try {
      value = getCellProperty(uncertainCell, "value", "number");
    } catch (error) {
      value = NaN;
    }
    const numberFormat =
      uncertainCell.format || new Intl.NumberFormat(window.navigator.language);
    const displayValue =
      uncertainCell.nanToZero && Number.isNaN(value) ? 0 : value;
    const text =
      Number.isNaN(displayValue) ||
      (uncertainCell.hideZero && displayValue === 0)
        ? ""
        : numberFormat.format(displayValue);
    return {
      ...uncertainCell,
      key,

      sort,
      sortFn,
      sortable,
      onSelectAllFn,
      onClearAllFn,
      value: displayValue,
      text,
      t,
    };
  }

  handleKeyDown(
    cell: Compatible<ClearedAmountAllCell>,
    keyCode: number,
    ctrl: boolean,
    shift: boolean,
    alt: boolean
  ): { cell: Compatible<ClearedAmountAllCell>; enableEditMode: boolean } {
    //if (isNumpadNumericKey(keyCode)) keyCode -= 48;
    const char = String.fromCharCode(keyCode);
    const value = Number(char);

    return {
      cell: this.getCompatibleCell({ ...cell, value }),
      enableEditMode: false,
    };
    return { cell, enableEditMode: false };
  }

  update(
    cell: Compatible<ClearedAmountAllCell>,
    cellToMerge: UncertainCompatible<ClearedAmountAllCell>
  ): Compatible<ClearedAmountAllCell> {
    return this.getCompatibleCell({ ...cell, value: cellToMerge.value });
  }

  getClassName(cell: Compatible<ClearedAmountAllCell>): string {
    return cell.className ? cell.className : "";
  }

  render(
    cell: Compatible<ClearedAmountAllCell>,
    isInEditMode: boolean,
    onCellChanged: (
      cell: Compatible<ClearedAmountAllCell>,
      commit: boolean
    ) => void
  ): React.ReactNode {
    return (
      <div style={{ display: "flex", width: "100%" }}>
        <Tooltip content={cell.t("pct.selectall.label")} relationship="label">
          <Button
            appearance="subtle"
            size="small"
            icon={<FlashAutoRegular />}
            onClick={(e) => {
              cell.onSelectAllFn();
            }}
          ></Button>
        </Tooltip>
        <Tooltip content={cell.t("pct.clearall.label")} relationship="label">
          <Button
            appearance="subtle"
            size="small"
            icon={<DismissFilled />}
            onClick={(e) => {
              cell.onClearAllFn();
            }}
          ></Button>
        </Tooltip>

        {cell.sortable ? (
          <Button
            appearance="subtle"
            size="small"
            icon={
              cell.sort?.key === cell.key ? (
                cell.sort?.dir === "asc" ? (
                  <ArrowSortDownRegular />
                ) : cell.sort?.dir === "" ? (
                  <ArrowSortRegular />
                ) : (
                  <ArrowSortUpRegular />
                )
              ) : (
                <ArrowSortRegular />
              )
            }
            onClick={(e) => {
              cell.sortFn(
                cell.key,
                cell.sort?.key === cell.key && cell.sort?.dir !== ""
                  ? cell.sort.dir === "asc"
                    ? "desc"
                    : ""
                  : "asc"
              );
            }}
          ></Button>
        ) : null}
        <div style={{ flexGrow: 1, textAlign: "right" }}>
          <Tooltip
            content={cell.t("pct.toBeClearedAmount.label")}
            relationship="label"
          >
            <span>{cell.t("pct.toBeClearedAmount.label")}</span>
          </Tooltip>
        </div>
      </div>
    );
  }
  getStyle = (cell: Compatible<ClearedAmountAllCell>): CellStyle => ({
    background: "rgba(128, 128, 128, 0.1)",
  });
}
