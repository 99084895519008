import {
  Button,
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
} from "@fluentui/react-components";
import { HorizontalSpacer } from "components/styled";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { useAppStore } from "store/AppStore";
import { useRemindersCreateDialogStoreActions } from "store/RemindersCreateDialogStore";
import { useRemindersStoreState } from "store/RemindersStore";

const CreateReminderButton = () => {
  const { t } = useTranslation();

  const [{ updatedGrecoCompanies }] = useAppStore();

  const [{ taxonomy }] = useAppStore();
  const { searchClientsSelectedItem } = useRemindersStoreState();
  const { setRemindersCreateDialogStoreValues } =
    useRemindersCreateDialogStoreActions();
  // group clientSaldo?.clientSaldoAmountAndCurrency list entities by companyNumber into companiesInfo map
  const companiesInfo = updatedGrecoCompanies?.filter((el) => el.selected);

  return (
    <>
      {searchClientsSelectedItem && Object.keys(companiesInfo).length > 0 ? (
        Object.keys(companiesInfo).length === 1 ? (
          <Button
            onClick={() => {
              setRemindersCreateDialogStoreValues({
                createReminderDialog: {
                  isOpen: true,
                  client: searchClientsSelectedItem,
                  insuranceLineCode: null,
                },
                createReminderSort: null,
                clSearchPayersSelectedItem: searchClientsSelectedItem,
                clGrecoCompanyNumbers: [companiesInfo[0].companyNumber],
                clInsurersSelectedItem: [],
                clPoliciesSelectedItem: [],
                clSubpoliciesSelectedItem: [],
                clServiceSegmentsSelectedItem: [],
                clCurrencyCodesSelectedItem: {
                  value: taxonomy.Currency.byCode["Currency.EUR"].id,
                  label: t(taxonomy.Currency.byCode["Currency.EUR"].code),
                },
                clFromDateFilter: moment().startOf("day").toDate(),
                clToDateFilter: moment().endOf("day").add(15, "days").toDate(),
                clComment: "",
                clReminderNumber: "",
                clIsBrokerPremiumCollection: true,
              });
              setTimeout(() => {
                document.querySelectorAll("input").forEach(function (element) {
                  element.blur();
                });
              }, 50);
            }}
          >
            {t("pct.createReminder.label")}
          </Button>
        ) : (
          <>
            <Menu>
              <MenuTrigger disableButtonEnhancement>
                <Button>
                  {t("pct.createReminder.label")}{" "}
                  {Object.keys(companiesInfo).length > 1
                    ? " (" + Object.keys(companiesInfo).length + ")"
                    : null}
                </Button>
              </MenuTrigger>

              <MenuPopover>
                <MenuList>
                  {/* iterate through companiesInfo keys */}
                  {companiesInfo.map((item) => {
                    return (
                      <MenuItem
                        key={item.companyNumber}
                        onClick={() => {
                          setRemindersCreateDialogStoreValues({
                            createReminderDialog: {
                              isOpen: true,
                              client: searchClientsSelectedItem,
                              insuranceLineCode: null,
                            },
                            createReminderSort: null,
                            clSearchPayersSelectedItem:
                              searchClientsSelectedItem,
                            clGrecoCompanyNumbers: [item.companyNumber],
                            clInsurersSelectedItem: [],
                            clPoliciesSelectedItem: [],
                            clSubpoliciesSelectedItem: [],
                            clServiceSegmentsSelectedItem: [],
                            clCurrencyCodesSelectedItem: {
                              value:
                                taxonomy.Currency.byCode["Currency.EUR"].id,
                              label: t(
                                taxonomy.Currency.byCode["Currency.EUR"].code
                              ),
                            },
                            clFromDateFilter: moment().startOf("day").toDate(),
                            clToDateFilter: moment()
                              .endOf("day")
                              .add(15, "days")
                              .toDate(),
                            clComment: "",
                            clReminderNumber: "",
                            clIsBrokerPremiumCollection: true,
                          });
                          setTimeout(() => {
                            document
                              .querySelectorAll("input")
                              .forEach(function (element) {
                                element.blur();
                              });
                          }, 50);
                        }}
                      >
                        {t(taxonomy?.Company.byId[item.companyNumber].code)}
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </MenuPopover>
            </Menu>

            <HorizontalSpacer width={10} />
          </>
        )
      ) : null}
    </>
  );
};
export default CreateReminderButton;
