import {
  createActionsHook,
  createHook,
  createStateHook,
  createStore,
} from "react-sweet-state";
import { LoadStatus, Notification } from "types/types";
import {
  Reminder,
  ReminderEntry,
  ReminderEntryMetadataMap,
  ReminderMetadataMap,
} from "utils/types";
import {
  addNewEmptyReminder,
  addNewEmptyReminderEntry,
  cancelEditReminder,
  cancelEditReminderEntry,
  deleteReminder,
  filterReminders,
  loadReminders,
  resolvementSuggest,
  resolvementSuggestForStatement,
  saveReminder,
  saveReminderEntry,
  setStoreValue,
  setStoreValues,
  deleteReminderEntry,
  getReminder,
} from "./RemindersStoreActions";
import moment from "moment";
import { setNotificationMessage } from "./AppStoreActions";

export type StoreState = {
  notifications: Notification[];

  reminders: Reminder[];
  reminderEntries: ReminderEntry[];
  reminderEntriesSelectAll: boolean;

  // Map that contains additional bank statement info like filtered entries, selected entries, readonly entries, etc.
  reminderMetadataMap: ReminderMetadataMap;
  // Map that contains additional info for bank statement entries for each bank statement like filtered entries, selected entries, readonly entries, etc.
  reminderEntryMetadataMap: ReminderEntryMetadataMap;
  clUnsettledPaymentPlanSelectAll: boolean;
  searchClientsSelectedItem: any;
  remindersLoadStatus: LoadStatus;
  importReminderStatus: LoadStatus;
  deleteReminderStatus: LoadStatus;
  deleteReminderEntryStatus: LoadStatus;
  reminderSaveStatus: LoadStatus;
  reminderEntrySaveStatus: LoadStatus;
  resolvementLoadStatus: LoadStatus;
  searchTerm: string;
  searchPolicyNumbers: string;
  searchPolicySubNumbers: string;
  fromDateFilter: Date;
  toDateFilter: Date;

  remindersSort: any;
  reminderEntriesSort: any;

  importReminderDialog: {
    isOpen: boolean;
  };
  deleteReminderDialog: {
    isOpen: boolean;
    reminderId: any;
    clientId: any;
  };
  deleteReminderEntryDialog: {
    isOpen: boolean;
    reminderId: any;
    reminderEntryId: any;
  };
};

// This is the value of the store on initialisation
const initialState: StoreState = {
  notifications: [],

  reminders: [],
  reminderEntries: [],
  reminderMetadataMap: {},
  reminderEntryMetadataMap: {},
  reminderEntriesSelectAll: true,
  remindersLoadStatus: "none",
  clUnsettledPaymentPlanSelectAll: true,
  searchClientsSelectedItem: null,
  searchPolicyNumbers: null,
  searchPolicySubNumbers: null,
  importReminderDialog: {
    isOpen: false,
  },
  importReminderStatus: "none",
  deleteReminderDialog: {
    isOpen: false,
    reminderId: null,
    clientId: null,
  },
  deleteReminderStatus: "none",
  deleteReminderEntryStatus: "none",
  deleteReminderEntryDialog: {
    isOpen: false,
    reminderId: null,
    reminderEntryId: null,
  },
  reminderSaveStatus: "none",
  reminderEntrySaveStatus: "none",
  resolvementLoadStatus: "none",

  searchTerm: "",
  fromDateFilter: moment()
    .subtract(2, "months")
    .add(1, "days")
    .startOf("day")
    .toDate(),
  toDateFilter: moment().endOf("day").add(1, "days").toDate(),

  remindersSort: null,
  reminderEntriesSort: null,
};

// All the actions that mutate the store
const actions = {
  setRemindersStoreValue: setStoreValue,
  setRemindersStoreValues: setStoreValues,
  loadReminders: loadReminders,
  filterReminders: filterReminders,
  deleteReminder: deleteReminder,
  deleteReminderEntry: deleteReminderEntry,
  addNewEmptyReminder: addNewEmptyReminder,
  addNewEmptyReminderEntry: addNewEmptyReminderEntry,
  cancelEditReminder: cancelEditReminder,
  cancelEditReminderEntry: cancelEditReminderEntry,
  saveReminder: saveReminder,
  saveReminderEntry: saveReminderEntry,
  resolvementSuggest: resolvementSuggest,
  resolvementSuggestForStatement: resolvementSuggestForStatement,

  getReminder: getReminder,
  setNotificationMessage: setNotificationMessage,
};

const RemindersStore = createStore({
  initialState,
  actions,
  name: "RemindersStore",
});

export const useRemindersStore = createHook(RemindersStore);
export const useRemindersStoreState = createStateHook(RemindersStore) as any;
export const useRemindersStoreActions = createActionsHook(RemindersStore);

export const useSearchClientsSelectedItem = createHook(RemindersStore, {
  selector: (state) => ({
    searchClientsSelectedItem: state.searchClientsSelectedItem,
  }),
});

export const useReminderEntries = createHook(RemindersStore, {
  selector: (state) => ({ reminderEntries: state.reminderEntries }),
});

export const useSearchTerm = createHook(RemindersStore, {
  selector: (state) => ({ searchTerm: state.searchTerm }),
});
