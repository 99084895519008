import {
  Cell,
  CellTemplate,
  Compatible,
  Uncertain,
  UncertainCompatible,
  getCellProperty,
  keyCodes,
} from "@silevis/reactgrid";
import * as React from "react";
import {
  Checkmark20Regular,
  Dismiss20Regular,
  LineHorizontal1Regular,
} from "@fluentui/react-icons";
import { DirectionalHint, TooltipHost } from "@fluentui/react";
import { VSpace } from "components/Spacer";

// NOTE: all modules imported below may be imported from '@silevis/reactgrid'

export interface CustomCheckmarkCell extends Cell {
  type: "customcheckmark";
  checked?: boolean;
  editable?: boolean;
  tooltip?: string;
  selected?: boolean;
  highlighted?: boolean;
  checkedText?: string;
  uncheckedText?: string;
  onChecked: (checked: boolean) => void;
}

export class CustomCheckmarkCellTemplate
  implements CellTemplate<CustomCheckmarkCell>
{
  getCompatibleCell(
    uncertainCell: Uncertain<CustomCheckmarkCell>
  ): Compatible<CustomCheckmarkCell> {
    const checkedText = getCellProperty(uncertainCell, "checkedText", "string");
    let checked = false;

    if (checkedText !== "null") {
      checked = getCellProperty(uncertainCell, "checked", "boolean");
    } else {
    }

    let tooltip: string | undefined;
    try {
      tooltip = getCellProperty(uncertainCell, "tooltip", "string");
    } catch {
      tooltip = null;
    }

    let editable: boolean | undefined;
    try {
      editable = getCellProperty(uncertainCell, "editable", "boolean");
    } catch {
      editable = true;
    }
    let selected: boolean | undefined;
    try {
      selected = getCellProperty(uncertainCell, "selected", "boolean");
    } catch {
      selected = true;
    }
    let highlighted: boolean | undefined;
    try {
      highlighted = getCellProperty(uncertainCell, "highlighted", "boolean");
    } catch {
      highlighted = false;
    }
    const text = checked
      ? uncertainCell.checkedText
        ? uncertainCell.checkedText
        : "1"
      : uncertainCell.uncheckedText
      ? uncertainCell.uncheckedText
      : "";
    return {
      ...uncertainCell,
      checked: !!checked,
      value: checked ? 1 : NaN,
      onChecked: uncertainCell.onChecked,
      tooltip,
      checkedText,
      editable,
      selected,
      highlighted,
      text,
    };
  }

  handleKeyDown(
    cell: Compatible<CustomCheckmarkCell>,
    keyCode: number,
    ctrl: boolean,
    shift: boolean,
    alt: boolean
  ): { cell: Compatible<CustomCheckmarkCell>; enableEditMode: boolean } {
    if (!shift && (keyCode === keyCodes.SPACE || keyCode === keyCodes.ENTER))
      return {
        cell: this.getCompatibleCell(this.toggleCustomCheckmarkCell(cell)),
        enableEditMode: false,
      };
    return { cell, enableEditMode: false };
  }

  private toggleCustomCheckmarkCell(
    cell: Compatible<CustomCheckmarkCell>
  ): Compatible<CustomCheckmarkCell> {
    return this.getCompatibleCell({ ...cell, checked: !cell.checked });
  }

  update(
    cell: Compatible<CustomCheckmarkCell>,
    cellToMerge: UncertainCompatible<CustomCheckmarkCell>
  ): Compatible<CustomCheckmarkCell> {
    const checked =
      cellToMerge.type === "customcheckmark"
        ? cellToMerge.checked
        : !!cellToMerge.value;
    return this.getCompatibleCell({ ...cell, checked });
  }

  getClassName(cell: Compatible<CustomCheckmarkCell>): string {
    return (
      (cell.className ? cell.className : "") +
      (cell.selected ? " rg-selected" : "") +
      (cell.highlighted ? " highlighted-selected-row" : "")
    );
  }

  render(
    cell: Compatible<CustomCheckmarkCell>,
    isInEditMode: boolean,
    onCellChanged: (
      cell: Compatible<CustomCheckmarkCell>,
      commit: boolean
    ) => void
  ): React.ReactNode {
    const content = (
      <span style={{ textAlign: "center", paddingTop: "5px" }}>
        {cell.checkedText === "null" ? (
          <LineHorizontal1Regular />
        ) : cell.checked ? (
          <Checkmark20Regular />
        ) : (
          <Dismiss20Regular />
        )}
      </span>
    );
    if (cell.tooltip !== null) {
      return (
        <TooltipHost
          content={cell.tooltip}
          directionalHint={DirectionalHint.topCenter}
          hidden={false}
        >
          <VSpace height={5} />
          {content}
        </TooltipHost>
      );
    } else {
      return content;
    }
  }
}
