import { CheckboxCell, CheckboxCellTemplate } from "@silevis/reactgrid";
import { ICellTypeHandler } from "components/grecoreactgrid/ICellTypeHandler";
import { SelectCellTemplate } from "../customcells/SelectCellTemplate";

class SelectCellTypeHandler implements ICellTypeHandler {
  cellType = "select";
  isCustom = true;
  instance = new SelectCellTemplate();
  determineNewValue = (change: any) => {
    return (change.newCell as CheckboxCell).checked;
  };
  getEntryRow = (
    entry: any,
    columnConfig: any,
    rowsMetadataMap: any,
    keyColumn: string,
    context: {
      [key: string]: any;
    }
  ) => {
    const [
      { clUnsettledPaymentPlanMetadataMap },
      { setRemindersCreateDialogStoreValue },
    ] = context["remindersStore"];
    return {
      type: "select",
      selected: rowsMetadataMap[entry[keyColumn]]?.selected ?? false,
      highlighted: rowsMetadataMap[entry[keyColumn]]?.highlighted ?? false,

      checked:
        rowsMetadataMap[entry[columnConfig.additionalInfo.rowIdField]].selected,
      onChecked: (checked: boolean) => {
        setRemindersCreateDialogStoreValue(
          "clUnsettledPaymentPlanMetadataMap",
          {
            ...clUnsettledPaymentPlanMetadataMap,
            [entry[columnConfig.additionalInfo.rowIdField]]: {
              ...clUnsettledPaymentPlanMetadataMap[
                entry[columnConfig.additionalInfo.rowIdField]
              ],
              selected: checked,
            },
          }
        );
      },
    };
  };
}

export default SelectCellTypeHandler;
