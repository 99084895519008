import { NumberCellTemplate, NumberCell } from "@silevis/reactgrid";
import { ICellTypeHandler } from "../ICellTypeHandler";

class NumberCellTypeHandler implements ICellTypeHandler {
  cellType = "number";
  isCustom = false;
  instance = new NumberCellTemplate();
  determineNewValue = (change: any) => {
    return (change.newCell as NumberCell).value;
  };
  getEntryRow = (
    entry: any,
    columnConfig: any,
    rowsMetadataMap: any,
    keyColumn: string,
    context: {
      [key: string]: any;
    }
  ) => {
    return {
      type: "number",
      value: entry[columnConfig.columnId],
    };
  };
}

export default NumberCellTypeHandler;
