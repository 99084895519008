import { DirectionalHint, TooltipHost } from "@fluentui/react";
import { Button, Tooltip } from "@fluentui/react-components";
import {
  CheckmarkCircleRegular,
  CheckmarkCircleFilled,
  QuestionRegular,
  EditRegular,
} from "@fluentui/react-icons";
import { HSpace } from "@greco/components/dist/components/utils";
import {
  Cell,
  CellTemplate,
  Compatible,
  Uncertain,
  getCellProperty,
} from "@silevis/reactgrid";
import { HorizontalSpacer } from "components/styled";
import * as React from "react";

export interface BankStatementEntryStatusCell extends Cell {
  type: "bankstatemententrystatus";
  link?: string;
  deleteFn?: any;
  saveFn?: any;
  cancelFn?: any;
  resolvementSuggestFn?: any;
  clearFn?: any;
  rowId?: any;
  clientId?: any;
  edited?: boolean;
  isDisabled?: boolean;
  isResolved?: boolean;
  isCleared?: boolean;
  selected?: boolean;
  highlighted?: boolean;
  t: any;
}

export class BankStatementEntryStatusCellTemplate
  implements CellTemplate<BankStatementEntryStatusCell>
{
  getCompatibleCell(
    uncertainCell: Uncertain<BankStatementEntryStatusCell>
  ): Compatible<BankStatementEntryStatusCell> {
    let t: any;
    t = getCellProperty(uncertainCell, "t", "function");

    let rowId: any;

    try {
      rowId = getCellProperty(uncertainCell, "rowId", "number");
    } catch {
      rowId = undefined;
    }

    let isDisabled = true;
    try {
      isDisabled = getCellProperty(uncertainCell, "isDisabled", "boolean");
    } catch {
      isDisabled = false;
    }

    let isCleared = true;
    try {
      isCleared = getCellProperty(uncertainCell, "isCleared", "boolean");
    } catch {
      isCleared = false;
    }

    let isResolved = true;
    try {
      isResolved = getCellProperty(uncertainCell, "isResolved", "boolean");
    } catch {
      isResolved = false;
    }

    let edited = false;
    try {
      edited = getCellProperty(uncertainCell, "edited", "boolean");
    } catch {
      edited = false;
    }

    let selected = false;
    try {
      selected = getCellProperty(uncertainCell, "selected", "boolean");
    } catch {
      selected = false;
    }
    let highlighted: boolean | undefined;
    try {
      highlighted = getCellProperty(uncertainCell, "highlighted", "boolean");
    } catch {
      highlighted = false;
    }
    // let inputValue: string | undefined;
    // try {
    //   inputValue = getCellProperty(uncertainCell, "inputValue", "string");
    // } catch {
    //   inputValue = undefined;
    // }

    const text = "";
    return {
      ...uncertainCell,
      text,
      rowId,

      value: NaN,
      isDisabled,
      isCleared,
      isResolved,
      edited,
      selected,
      highlighted,
      t, // isOpen,
      // inputValue,
    };
  }

  getClassName(
    cell: Compatible<BankStatementEntryStatusCell>,
    isInEditMode: boolean
  ): string {
    return (
      (cell.className ? cell.className : "") +
      (cell.selected ? " rg-selected" : "") +
      (cell.highlighted ? " highlighted-selected-row" : "")
    );
  }

  render(
    cell: Compatible<BankStatementEntryStatusCell>,
    isInEditMode: boolean,
    onCellChanged: (
      cell: Compatible<BankStatementEntryStatusCell>,
      commit: boolean
    ) => void
  ): React.ReactNode {
    return <OptionsCell cell={cell} />;
  }
}

interface DIProps {
  cell: Record<string, any>;
}

const OptionsCell: React.FC<DIProps> = ({ cell }) => {
  return (
    <div style={{ width: "100%", textAlign: "center" }}>
      {cell.edited ? (
        <TooltipHost
          content={cell.t("pct.edited.label")}
          directionalHint={DirectionalHint.rightCenter}
          hidden={false}
        >
          <EditRegular />
        </TooltipHost>
      ) : cell.isCleared ? (
        <TooltipHost
          content={cell.t("pct.cleared.label")}
          directionalHint={DirectionalHint.rightCenter}
          hidden={false}
        >
          <CheckmarkCircleFilled />
        </TooltipHost>
      ) : cell.isResolved ? (
        <TooltipHost
          content={cell.t("pct.resolved.label")}
          directionalHint={DirectionalHint.rightCenter}
          hidden={false}
        >
          <CheckmarkCircleRegular />
        </TooltipHost>
      ) : (
        <TooltipHost
          content={cell.t("pct.unresolved.label")}
          directionalHint={DirectionalHint.rightCenter}
          hidden={false}
        >
          <QuestionRegular />
        </TooltipHost>
      )}
    </div>
  );
};
