import Axios, { AxiosError } from "axios";
import moment from "moment";
import { Setting } from "types/types";
import {
  getShortInsurerName,
  handleAxiosError,
  prepareNotificationObject,
} from "utils/utils";
import * as API from "../api/api";
import { StoreState } from "./PaymentPlanPageStore";
import { toast } from "components/FluentToast";
import i18next from "i18next";
import { setNotificationMessage } from "./AppStoreActions";

export const setStoreValue =
  (name: keyof StoreState, value) =>
  ({ setState }) => {
    setState({
      [name]: value,
    });
  };

export const setStoreValues =
  (values) =>
  ({ setState }) => {
    setState({
      ...values,
    });
  };

export const loadPaymentPlans =
  (payload, onSuccess?) =>
  async ({ setState, getState, dispatch }) => {
    const {
      reset = false,
      delay = false,
      skip = 0,
      top = 200,
      updatedGrecoCompanies,
    } = payload;
    const cancelSource = Axios.CancelToken.source();
    // if (cancelSource && reset) {
    //   cancelSource.cancel();
    // }

    try {
      if (getState().paymentPlansLoadStatus === "loading") return;
      setState({
        paymentPlansLoadStatus: "loading",
      });
      const colsMapped = getState().cols.map((c) => {
        if (
          updatedGrecoCompanies !== null &&
          updatedGrecoCompanies?.length > 0 &&
          c.key === "grecoCompanyNumber"
        ) {
          return {
            ...c,
            filter: {
              taxonomyKey: "Company",
              type: "taxonomy",
              value: updatedGrecoCompanies
                .filter((c) => c.selected)
                .map((c) => c.companyNumber),
            },
          };
        }
        if (
          getState().searchClientsSelectedItem !== null &&
          c.key === "clientInternalNumber"
        ) {
          return {
            ...c,
            filter: {
              format: "integer",
              includeThousandSeparators: false,
              number1:
                getState().searchClientsSelectedItem.value.internalNumber + "",
              number2: null,
              operator: "eq",
              type: "number",
            },
          };
        }

        if (
          getState().searchInsurersSelectedItem !== null &&
          c.key === "insurerInternalNumber"
        ) {
          return {
            ...c,
            filter: {
              format: "integer",
              includeThousandSeparators: false,
              number1: getState().searchInsurersSelectedItem.value + "",
              number2: null,
              operator: "eq",
              type: "number",
            },
          };
        }

        if (
          getState().fromBookingDateFilter !== null &&
          getState().toBookingDateFilter === null &&
          c.key === "bookingDate"
        ) {
          return {
            ...c,
            filter: {
              date1: moment(getState().fromBookingDateFilter).format(
                "YYYY-MM-DD"
              ),
              date2: null,
              operator: "isAfter",
              type: "date",
            },
          };
        }

        if (
          getState().fromBookingDateFilter === null &&
          getState().toBookingDateFilter !== null &&
          c.key === "bookingDate"
        ) {
          return {
            ...c,
            filter: {
              date1: moment(getState().toBookingDateFilter).format(
                "YYYY-MM-DD"
              ),
              date2: null,
              operator: "isBefore",
              type: "date",
            },
          };
        }
        if (
          getState().fromBookingDateFilter !== null &&
          getState().toBookingDateFilter !== null &&
          c.key === "bookingDate"
        ) {
          return {
            ...c,
            filter: {
              date1: moment(getState().fromBookingDateFilter).format(
                "YYYY-MM-DD"
              ),
              date2: moment(getState().toBookingDateFilter).format(
                "YYYY-MM-DD"
              ),
              operator: "isBetween",
              type: "date",
            },
          };
        }
        if (c.key === "client") {
          return {
            ...c,
            key: "clientId",
            // filter: {
            //   ...c.filter,
            // },
          };
        }
        if (c.key === "insurerName") {
          return {
            ...c,
            key: "insurerId",
            // filter: {
            //   ...c.filter,
            // },
          };
        }
        return { ...c };
      });

      let isBrokerPremiumCollection = null;
      if (getState().searchIsBrokerPremiumCollectionItem !== "all") {
        isBrokerPremiumCollection =
          getState().searchIsBrokerPremiumCollectionItem;
      }

      const response = await API.paymentPlanEntry({
        columns: colsMapped,
        skip,
        top,
        sort: getState().sort,
        search: getState().searchTerm,
        cancelToken: cancelSource.token,
        isBrokerPremiumCollection: isBrokerPremiumCollection,
      });
      let newRows = response.data.value.map((row) => {
        return {
          ...row,
          insurerName:
            (getShortInsurerName(row.insurerId + "") === ""
              ? row.insurerName
              : getShortInsurerName(row.insurerId + "")) +
            " (" +
            row.insurerInternalNumber +
            ")",
        };
      });
      if (reset === false) {
        newRows = [
          ...getState().rowItems,
          ...response.data.value.map((row) => {
            return {
              ...row,
              insurerName:
                (getShortInsurerName(row.insurerId + "") === ""
                  ? row.insurerName
                  : getShortInsurerName(row.insurerId + "")) +
                " (" +
                row.insurerInternalNumber +
                ")",
            };
          }),
        ];
      }
      const res = await API.gosLastSyncTime();

      setState({
        gosLastSyncTime: res.data.lastSyncTime,
        rowItems: hydrateRows(newRows),
        count: response.data["@odata.count"],
        paymentPlansLoadStatus: "success",
      });
      onSuccess && onSuccess(response.headers["usertype"]);
    } catch (error: any) {
      if (error.code !== "ERR_CANCELED") {
        handleAxiosError(error, (message) => {
          dispatch(
            setNotificationMessage(
              prepareNotificationObject("loadPaymentPlans", message)
            )
          );
        });
        setState({
          paymentPlansLoadStatus: "error",
        });
      }
    }
  };

export const loadPaymentPlansForReport =
  (payload) =>
  async ({ setState, getState, dispatch }) => {
    const { onSuccess, onError, updatedGrecoCompanies } = payload;
    const cancelSource = Axios.CancelToken.source();

    try {
      if (getState().paymentPlansLoadStatus === "loading") return;
      setState({
        paymentPlansLoadStatus: "loading",
      });
      const colsMapped = getState().cols.map((c) => {
        if (
          updatedGrecoCompanies !== null &&
          updatedGrecoCompanies?.length > 0 &&
          c.key === "grecoCompanyNumber"
        ) {
          return {
            ...c,
            filter: {
              taxonomyKey: "Company",
              type: "taxonomy",
              value: updatedGrecoCompanies
                .filter((c) => c.selected)
                .map((c) => c.companyNumber),
            },
          };
        }
        if (
          getState().searchClientsSelectedItem !== null &&
          c.key === "clientInternalNumber"
        ) {
          return {
            ...c,
            filter: {
              format: "integer",
              includeThousandSeparators: false,
              number1:
                getState().searchClientsSelectedItem.value.internalNumber + "",
              number2: null,
              operator: "eq",
              type: "number",
            },
          };
        }

        if (
          getState().searchInsurersSelectedItem !== null &&
          c.key === "insurerInternalNumber"
        ) {
          return {
            ...c,
            filter: {
              format: "integer",
              includeThousandSeparators: false,
              number1: getState().searchInsurersSelectedItem.value + "",
              number2: null,
              operator: "eq",
              type: "number",
            },
          };
        }

        if (
          getState().fromBookingDateFilter !== null &&
          getState().toBookingDateFilter === null &&
          c.key === "bookingDate"
        ) {
          return {
            ...c,
            filter: {
              date1: moment(getState().fromBookingDateFilter).format(
                "YYYY-MM-DD"
              ),
              date2: null,
              operator: "isAfter",
              type: "date",
            },
          };
        }

        if (
          getState().fromBookingDateFilter === null &&
          getState().toBookingDateFilter !== null &&
          c.key === "bookingDate"
        ) {
          return {
            ...c,
            filter: {
              date1: moment(getState().toBookingDateFilter).format(
                "YYYY-MM-DD"
              ),
              date2: null,
              operator: "isBefore",
              type: "date",
            },
          };
        }
        if (
          getState().fromBookingDateFilter !== null &&
          getState().toBookingDateFilter !== null &&
          c.key === "bookingDate"
        ) {
          return {
            ...c,
            filter: {
              date1: moment(getState().fromBookingDateFilter).format(
                "YYYY-MM-DD"
              ),
              date2: moment(getState().toBookingDateFilter).format(
                "YYYY-MM-DD"
              ),
              operator: "isBetween",
              type: "date",
            },
          };
        }
        if (c.key === "client") {
          return {
            ...c,
            key: "clientId",
            // filter: {
            //   ...c.filter,
            // },
          };
        }
        if (c.key === "insurerName") {
          return {
            ...c,
            key: "insurerId",
            // filter: {
            //   ...c.filter,
            // },
          };
        }
        return { ...c };
      });

      let skip = 0;
      const limit = 5000;

      let newRows: any[] = [];
      let rowCount = 0;

      do {
        const res = await API.paymentPlanEntry({
          columns: colsMapped,
          skip: skip,
          top: limit,
          sort: getState().sort,
          search: getState().searchTerm,
          cancelToken: cancelSource.token,
        });
        rowCount = res.data["@odata.count"];
        newRows = newRows.concat(res.data.value);
        skip += limit;
      } while (rowCount > newRows.length);

      setState({
        paymentPlansLoadStatus: "success",
      });

      onSuccess && onSuccess(hydrateRows(newRows));
    } catch (error: any) {
      onError && onError(error);
      if (error.code !== "ERR_CANCELED") {
        handleAxiosError(error, (message) => {
          dispatch(
            setNotificationMessage(
              prepareNotificationObject("loadPaymentPlansForReport", message)
            )
          );
        });
        setState({
          paymentPlansLoadStatus: "error",
        });
      }
    }
  };

export const loadSettings =
  (applicationCodeId) =>
  async ({ setState, getState, dispatch }) => {
    if (getState().settingsLoadStatus === "loading") return;
    try {
      setState({
        settingsLoadStatus: "loading",
      });
      const res = await API.getAppSettings(applicationCodeId);
      setState({
        settings: res.data,
        settingsLoadStatus: "success",
      });
      const defaultView = res.data.find((s) => s.isDefault);
      if (defaultView) {
        setState({
          selectedViewId: defaultView.userAppSettingId,
        });
      }
    } catch (err) {
      handleAxiosError(err, (message) => {
        dispatch(
          setNotificationMessage(
            prepareNotificationObject("loadSettings", message)
          )
        );
      });
      setState({
        settingsLoadStatus: "error",
      });
    }
  };

export const searchClients =
  (payload) =>
  async ({ setState, getState, dispatch }) => {
    const { onSuccess, onError, searchTerm } = payload;
    const cancelSource = Axios.CancelToken.source();
    try {
      if (getState().clientsLoadStatus === "loading") return;
      setState({
        searchClientsLoadStatus: "loading",
      });
      const response = await API.searchClients(searchTerm);
      setState({
        searchClientsList: response.data,
        searchClientsLoadStatus: "success",
      });
      onSuccess && onSuccess(response.data);
    } catch (error: any) {
      onError && onError(error);
      if (error.code !== "ERR_CANCELED") {
        handleAxiosError(error, (message) => {
          dispatch(
            setNotificationMessage(
              prepareNotificationObject("searchClients", message)
            )
          );
        });
        setState({
          searchClientsLoadStatus: "error",
        });
      }
    }
  };

export const searchInsurers =
  (payload) =>
  async ({ setState, getState, dispatch }) => {
    const { onSuccess, onError, searchTerm } = payload;
    const cancelSource = Axios.CancelToken.source();
    try {
      if (getState().insurersLoadStatus === "loading") return;
      setState({
        searchInsurersLoadStatus: "loading",
      });
      const response = await API.searchInsurers(searchTerm);
      setState({
        searchInsurersList: response.data,
        searchInsurersLoadStatus: "success",
      });
      onSuccess && onSuccess(response.data);
      return response.data;
    } catch (error: any) {
      onError && onError(error);
      if (error.code !== "ERR_CANCELED") {
        handleAxiosError(error, (message) => {
          dispatch(
            setNotificationMessage(
              prepareNotificationObject("searchInsurers", message)
            )
          );
        });
        setState({
          searchInsurersLoadStatus: "error",
        });
      }
    }
  };

export const synchronizeGos =
  () =>
  async ({ setState, getState, dispatch }) => {
    try {
      if (getState().paymentPlansLoadStatus === "loading") return;
      setState({
        paymentPlansLoadStatus: "loading",
      });
      await API.synchronizeGos();
      setState({
        paymentPlansLoadStatus: "success",
        timestamp: new Date(),
      });
      toast.success(i18next.t("pct.syncGosSuccessfullyInitiated.label"));
    } catch (error: any) {
      if (error.response.status === 409) {
        toast.info(i18next.t("Sync GOS is already in progress"));
      } else if (error.code !== "ERR_CANCELED") {
        handleAxiosError(error, (message) => {
          dispatch(
            setNotificationMessage(
              prepareNotificationObject("synchronizeGos", message)
            )
          );
        });
      }
      setState({
        paymentPlansLoadStatus: "error",
        timestamp: new Date(),
      });
    }
  };

export const populateClearanceData =
  (payload, onSuccess?, onError?) =>
  async ({ setState, getState, dispatch }) => {
    const {
      clientId,
      clientIds,
      taxonomy,
      fromDate,
      toDate,
      searchTerm,
      serviceSegmentCodes,
      insurerIds,
      currencyCode,
      policyNumbers,
      policySubnumbers,
      isBrokerPremiumCollection,
      paymentReferenceNumbers,
      grecoCompanyNumbers,
    } = payload;

    setState({
      clearClientSaldoStatus: "loading",
    });

    const saldo = await API.getClientSaldo({
      clientId: clientId,
    });

    const clearanceRequest = {
      clientId: clientId,
      clientIds: clientIds,
      serviceSegmentCodes: serviceSegmentCodes,
      From: fromDate ? moment(fromDate).format("YYYY-MM-DD") : null,
      To: toDate ? moment(toDate).format("YYYY-MM-DD") : null,
      PremiumPaymentMeanCodeId:
        taxonomy.PremiumPaymentMean.byCode[
          "PremiumPaymentMean.PREMIUMCOLLECTION"
        ].id,
      insurerIds: insurerIds,
      currencyCode: currencyCode,
      policyNumbers: policyNumbers,
      policySubnumbers: policySubnumbers,
      PaymentReferenceNumbers: paymentReferenceNumbers,
      isBrokerPremiumCollection: isBrokerPremiumCollection,
      GrecoCompanyNumbers: grecoCompanyNumbers,
      MaxResults: 5000,
    };
    const res = await API.suggestClientSaldoClearance(clearanceRequest);

    let unsettledPaymentPlans = res.data;
    // filter list by search term
    if (searchTerm) {
      unsettledPaymentPlans = unsettledPaymentPlans.filter((el) => {
        return (
          el.policyName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          el.insurerName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          el.policyNumber.toLowerCase().includes(searchTerm.toLowerCase()) ||
          el.policySubNumber.toLowerCase().includes(searchTerm.toLowerCase())
        );
      });
    }
    // add default metadata to each row to clUnsettledPaymentPlanMetadataMap
    const metadataMap = {};
    unsettledPaymentPlans.forEach((el) => {
      metadataMap[el.paymentPlanEntryId] = {
        visible: true,
        selected: true,
        edited: false,
        editable: false,
        changes: [],
      };
    });
    let total = unsettledPaymentPlans.reduce(
      (acc, el) => acc + (el.suggestedPaymentAmount ?? 0),
      0
    );
    let totalRows = unsettledPaymentPlans.reduce(
      (acc, el) => acc + (el.suggestedPaymentAmount > 0 ? 1 : 0),
      0
    );

    //totalRow.premiumDebt = bankStatementEntry.amount;
    // if (unsettledPaymentPlans.length > 0) {
    //   unsettledPaymentPlans = [...unsettledPaymentPlans, totalRow];
    // }
    setState({
      clUnsettledPaymentPlans: unsettledPaymentPlans,
      clUnsettledPaymentPlanMetadataMap: metadataMap,
      clientSaldo: saldo.data,
      clientSaldoAmount: saldo?.data?.clientSaldoAmountAndCurrency?.find(
        (el) =>
          el.currencyCode === currencyCode &&
          el.companyNumber === Number(grecoCompanyNumbers[0])
      ).clientSaldoAmount,
      clTotal: total,
      clTotalRows: totalRows,
      clearanceRequest: clearanceRequest,
      clearClientSaldoStatus: "success",
    });
  };

export const getClientSaldo =
  (payload, onSuccess?, onError?) =>
  async ({ setState, getState, dispatch }) => {
    const { clientId } = payload;
    setState({
      getClientSaldoStatus: "loading",
    });
    try {
      const res = await API.getClientSaldo({
        clientId: clientId,
      });

      setState({
        clientSaldo: res.data,

        getClientSaldoStatus: "success",
      });
      onSuccess && onSuccess(res.data);
    } catch (error) {
      handleAxiosError(error, (message) => {
        dispatch(
          setNotificationMessage(
            prepareNotificationObject("getClientSaldo", message)
          )
        );
      });
      setState({
        getClientSaldoStatus: "error",
      });
      onError && onError(error);
    }
  };

export const clearClientSaldo =
  (payload, onSuccess?, onError?) =>
  async ({ setState, getState, dispatch }) => {
    const { clientId, clearanceRequest, suggestedPayments } = payload;
    setState({
      clearClientSaldoStatus: "loading",
    });
    try {
      const res = await API.commitClientSaldoClearance({
        clientId,
        clearanceRequest,
        suggestedPayments,
      });

      setState({
        clearClientSaldoStatus: "success",
      });

      onSuccess && onSuccess(res.data);
    } catch (error) {
      handleAxiosError(error, (message) => {
        dispatch(
          setNotificationMessage(
            prepareNotificationObject("clearClientSaldo", message)
          )
        );
      });
      setState({
        clearClientSaldoStatus: "error",
      });
      onError && onError(error);
    }
  };

export const createSetting =
  (newSetting: Setting) =>
  async ({ setState, getState, dispatch }) => {
    if (getState().settingsLoadStatus === "loading") return;
    try {
      setState({
        settingsLoadStatus: "loading",
      });
      const res = await API.addAppSetting(newSetting);
      const allSettings = res.data;
      setState({
        settings: allSettings,
        settingsLoadStatus: "success",
      });
      const newSettingId = allSettings.find(
        (s) => s.userAppSettingName === newSetting.userAppSettingName
      )?.userAppSettingId;
      if (newSettingId) {
        setState({
          selectedViewId: newSettingId,
        });
      }
      // toast.success(i18next.t("greco.success"));
    } catch (err) {
      handleAxiosError(err, (message) => {
        dispatch(
          setNotificationMessage(
            prepareNotificationObject("createSetting", message)
          )
        );
      });
      setState({
        settingsLoadStatus: "error",
      });
    }
  };

export const deleteSetting =
  (id: number) =>
  async ({ setState, getState, dispatch }) => {
    if (getState().settingsLoadStatus === "loading") return;
    try {
      setState({
        settingsLoadStatus: "loading",
      });
      const res = await API.deleteAppSetting(id);
      const allSettings = res.data;
      setState({
        settings: allSettings,
        settingsLoadStatus: "success",
      });
      // toast.success(i18next.t("greco.success"));
    } catch (err) {
      handleAxiosError(err, (message) => {
        dispatch(
          setNotificationMessage(
            prepareNotificationObject("deleteSetting", message)
          )
        );
      });
      setState({
        settingsLoadStatus: "error",
      });
    }
  };

export const updateSetting =
  (setting: Setting) =>
  async ({ setState, getState, dispatch }) => {
    if (getState().settingsLoadStatus === "loading") return;
    try {
      setState({
        settingsLoadStatus: "loading",
      });
      const res = await API.updateAppSetting(setting);
      const allSettings = res.data;
      setState({
        settings: allSettings,
        settingsLoadStatus: "success",
      });
      // toast.success(i18next.t("greco.success"));
    } catch (err) {
      handleAxiosError(err, (message) => {
        dispatch(
          setNotificationMessage(
            prepareNotificationObject("updateSetting", message)
          )
        );
      });
      setState({
        settingsLoadStatus: "error",
      });
    }
  };

const hydrateRows = (newRows: any) => {
  const columns = newRows.map((row) => ({
    ...row,
    client:
      row.clientName === null
        ? " " +
          row.clientLastName +
          " " +
          row.clientFirstName +
          " (" +
          row.clientInternalNumber +
          ")"
        : (row.clientName
            ? row.clientName
            : " " + row.clientLastName + " " + row.clientFirstName) +
          " (" +
          row.clientInternalNumber +
          ")",
    clientNumber:
      row.clientCompanyRegisterNumber === null
        ? row.clientPersonIdentificationNumber
        : row.clientCompanyRegisterNumber,
  }));
  return columns;
};
