import { DirectionalHint } from "@fluentui/react";
import { Input, Tooltip } from "@fluentui/react-components";
import {
  Cell,
  CellTemplate,
  Compatible,
  Uncertain,
  UncertainCompatible,
  getCellProperty,
  getCharFromKeyCode,
  inNumericKey,
  isAllowedOnNumberTypingKey,
  isAlphaNumericKey,
  isNavigationKey,
  isNumpadNumericKey,
  keyCodes,
} from "@silevis/reactgrid";
import { TooltipHost } from "office-ui-fabric-react";
import * as React from "react";
import { parseFloatBasedOnLocale } from "utils/utils";

export interface CustomNumberCell extends Cell {
  type: "customnumber";
  value: number;
  editable?: boolean;
  selected?: boolean;
  highlighted?: boolean;
  format?: Intl.NumberFormat;
  nanToZero?: boolean;
  hideZero?: boolean;
  redColor?: boolean;
}

export class CustomNumberCellTemplate
  implements CellTemplate<CustomNumberCell>
{
  getCompatibleCell(
    uncertainCell: Uncertain<CustomNumberCell>
  ): Compatible<CustomNumberCell> {
    let editable: boolean | undefined;
    try {
      editable = getCellProperty(uncertainCell, "editable", "boolean");
    } catch {
      editable = true;
    }
    let redColor: boolean | undefined;
    try {
      redColor = getCellProperty(uncertainCell, "redColor", "boolean");
    } catch {
      redColor = true;
    }

    let selected: boolean | undefined;
    try {
      selected = getCellProperty(uncertainCell, "selected", "boolean");
    } catch {
      selected = true;
    }
    let highlighted: boolean | undefined;
    try {
      highlighted = getCellProperty(uncertainCell, "highlighted", "boolean");
    } catch {
      highlighted = false;
    }
    let value: number;
    try {
      value = getCellProperty(uncertainCell, "value", "number");
    } catch (error) {
      value = NaN;
    }
    const numberFormat =
      uncertainCell.format || new Intl.NumberFormat(window.navigator.language);
    const displayValue =
      uncertainCell.nanToZero && Number.isNaN(value) ? 0 : value;
    const text =
      Number.isNaN(displayValue) ||
      (uncertainCell.hideZero && displayValue === 0)
        ? ""
        : numberFormat.format(displayValue);
    return {
      ...uncertainCell,
      value: displayValue,
      editable,
      selected,
      highlighted,
      text,
      redColor,
    };
  }

  handleKeyDown(
    cell: Compatible<CustomNumberCell>,
    keyCode: number,
    ctrl: boolean,
    shift: boolean,
    alt: boolean
  ): { cell: Compatible<CustomNumberCell>; enableEditMode: boolean } {
    if (isNumpadNumericKey(keyCode)) keyCode -= 48;
    const char = String.fromCharCode(keyCode);
    if (
      !ctrl &&
      !alt &&
      !shift &&
      (inNumericKey(keyCode) || isAllowedOnNumberTypingKey(keyCode)) &&
      cell.editable
    ) {
      const value = Number(char);
      if (Number.isNaN(value) && isAllowedOnNumberTypingKey(keyCode))
        return {
          cell: { ...this.getCompatibleCell({ ...cell, value }), text: char },
          enableEditMode: true,
        };
      return {
        cell: this.getCompatibleCell({ ...cell, value }),
        enableEditMode: true,
      };
    }
    return {
      cell,
      enableEditMode:
        keyCode === keyCodes.POINTER || keyCode === keyCodes.ENTER,
    };
  }

  update(
    cell: Compatible<CustomNumberCell>,
    cellToMerge: UncertainCompatible<CustomNumberCell>
  ): Compatible<CustomNumberCell> {
    return this.getCompatibleCell({ ...cell, value: cellToMerge.value });
  }

  private getTextFromCharCode = (cellText: string): string => {
    switch (cellText.charCodeAt(0)) {
      case keyCodes.DASH:
      case keyCodes.FIREFOX_DASH:
      case keyCodes.SUBTRACT:
        return "-";
      case keyCodes.COMMA:
        return ",";
      case keyCodes.PERIOD:
      case keyCodes.DECIMAL:
        return ".";
      default:
        return cellText;
    }
  };

  getClassName(
    cell: Compatible<CustomNumberCell>,
    isInEditMode: boolean
  ): string {
    return (
      (cell.className ? cell.className : "") +
      (cell.selected ? " rg-selected" : "") +
      (cell.highlighted ? " highlighted-selected-row" : "") +
      (cell.redColor ? "  rg-redtext" : "")
    );
  }

  render(
    cell: Compatible<CustomNumberCell>,
    isInEditMode: boolean,
    onCellChanged: (cell: Compatible<CustomNumberCell>, commit: boolean) => void
  ): React.ReactNode {
    const locale = cell.format
      ? cell.format.resolvedOptions().locale
      : window.navigator.languages[0];
    const format = new Intl.NumberFormat(locale, {
      useGrouping: false,
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });
    const groupingFormat = new Intl.NumberFormat(locale, {
      useGrouping: true,
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });
    if (!isInEditMode) {
      return (
        <Tooltip
          content={
            Number.isNaN(cell.text) || cell.text === ""
              ? ""
              : groupingFormat.format(parseFloatBasedOnLocale(cell.text))
          }
          relationship="label"
        >
          <span>
            {Number.isNaN(cell.text) || cell.text === ""
              ? ""
              : groupingFormat.format(parseFloatBasedOnLocale(cell.text))}
          </span>
        </Tooltip>
      );
    }
    return (
      <Input
        style={{ width: "100%", fontSize: "1em" }}
        size="small"
        //disabled={!cell.editable}
        ref={(input) => {
          if (input) {
            input.focus();
            input.setSelectionRange(input.value.length, input.value.length);
            input.classList.add("cell-input");
          }
        }}
        defaultValue={
          Number.isNaN(cell.value)
            ? this.getTextFromCharCode(cell.text)
            : cell.value + ""
        }
        onChange={(e) => {
          if (cell.editable) {
            onCellChanged(
              this.getCompatibleCell({
                ...cell,
                value: parseFloat(e.currentTarget.value.replace(/,/g, ".")),
              }),
              false
            );
          }
        }}
        onBlur={(e) => {
          if (cell.editable) {
            onCellChanged(
              this.getCompatibleCell({
                ...cell,
                value: parseFloat(e.currentTarget.value.replace(/,/g, ".")),
              }),
              true
            );
          }
        }}
        onKeyDown={(e) => {
          if (
            inNumericKey(e.keyCode) ||
            isNavigationKey(e.keyCode) ||
            isAllowedOnNumberTypingKey(e.keyCode)
          )
            e.stopPropagation();
          if (
            (!inNumericKey(e.keyCode) &&
              !isNavigationKey(e.keyCode) &&
              !isAllowedOnNumberTypingKey(e.keyCode)) ||
            e.shiftKey
          )
            e.preventDefault();
        }}
        onPointerDown={(e) => e.stopPropagation()}
        onCopy={(e) => e.stopPropagation()}
        onCut={(e) => e.stopPropagation()}
        onPaste={(e) => e.stopPropagation()}
      />
    );
  }
}
