import { Input, Label } from "@fluentui/react-components";
import {
  Flex,
  FlexRight,
  HorizontalSpacer,
  NavigationBar,
} from "components/styled";
import React, { useEffect } from "react";
import SearchInsurers from "./SearchInsurers";
import PaymentsMenubar from "./PaymentsMenubar";
import SearchClients from "./SearchClients";
import SearchServiceSegments from "./SearchServiceSegments";
import SearchCurrency from "./SearchCurrency";
import { usePaymentPageStore } from "store/PaymentPageStore";
import { useTranslation } from "react-i18next";
import TextInputField from "components/controls9/TextInputField";
import useDebouncedState from "components/useDebouncedState";
import SearchIsDiscountPaid from "./SearchIsDiscountPaid";
import { ResultsScore } from "components/ResultsScore";

const PaymentPageNavigationSecondLine = () => {
  const [
    { paymentsLoadStatus, paymentsMetadataMap },
    { setPaymentStoreValue },
  ] = usePaymentPageStore();
  const { t } = useTranslation();
  const loading = paymentsLoadStatus === "loading";

  const [
    searchPolicyNumberTerm,
    setSearchPolicyNumberTerm,
    debouncedSearchPolicyNumberTerm,
  ] = useDebouncedState("", 500);

  useEffect(() => {
    // Use debouncedSearchTerm here for API calls or other side effects
    setPaymentStoreValue("searchPolicyNumber", debouncedSearchPolicyNumberTerm);
  }, [debouncedSearchPolicyNumberTerm]);

  const handleChange = (value) => {
    setSearchPolicyNumberTerm(value);
  };
  const count = Object.values(paymentsMetadataMap).reduce(
    (acc: number, obj: any) => {
      return obj.visible === true ? acc + 1 : acc;
    },
    0
  );
  return (
    <NavigationBar style={{ zIndex: 333 }}>
      <Label>{t("pct.insurer.label")}:</Label>
      <HorizontalSpacer width={10} />
      <SearchInsurers />
      <HorizontalSpacer width={10} />
      <SearchClients /> <HorizontalSpacer width={10} />
      <Input
        key={"policyNumber"}
        name={"policyNumber"}
        id={"input_policyNumber"}
        autoComplete="off"
        value={searchPolicyNumberTerm?.toString() || ""}
        onChange={(e, newValue) => {
          handleChange(newValue.value);
        }}
        placeholder={t("pct.policyNumber.label")}
        onFocus={(e) => {
          e.target.select();
        }}
        onCopy={(e) => e.stopPropagation()}
        onCut={(e) => e.stopPropagation()}
        onPaste={(e) => e.stopPropagation()}
      />
      <HorizontalSpacer width={10} />
      <SearchServiceSegments
        isLoading={loading}
        name="serviceSegmentCode"
        label={t("pct.serviceSegment.label")}
        taxonomyKey="ServiceSegment"
        required={true}
        visible={true}
        placeholder={t("pct.serviceSegmentPlaceholder.label")}
      />{" "}
      <HorizontalSpacer width={10} />
      <SearchCurrency
        isLoading={loading}
        name="currency"
        label={t("pct.currency.label")}
        taxonomyKey="Currency"
        required={false}
        visible={true}
        placeholder={t("pct.currency.label")}
      />
      <HorizontalSpacer width={10} />
      <SearchIsDiscountPaid
        name="isDiscountPaid"
        isLoading={loading}
        visible={true}
      />{" "}
      <FlexRight>
        <Flex>
          <ResultsScore score={count} />
        </Flex>
      </FlexRight>
    </NavigationBar>
  );
};

export default PaymentPageNavigationSecondLine;
