import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { getUserData } from "./api/UserDataLoader";
import { getAppSettings } from "api/api";
import PhraseInContextEditorPostProcessor from "i18next-phrase-in-context-editor-post-processor";
import i18nextXHRBackend from "i18next-xhr-backend";

import queryString from "query-string";
const defaultLanguage = "en-GB";

const phraseProjectId =
  process.env.REACT_APP_PHRASE_ID || "6bcadb0a360c1e31644ffe97cd739340";

export const applicationCodeId = process.env.REACT_APP_APPLICATION_PCT;
export const appSettingUserSettingsCodeId = process.env.REACT_APP_USER_SETTINGS;

// creating a language detection plugin
// http://i18next.com/docs/ownplugin/#languagedetector
const languageDetector = {
  type: "languageDetector" as any, // Exact type is 'backend' | 'logger' | 'languageDetector' | 'postProcessor' | 'i18nFormat' | '3rdParty'
  async: true, // flags below detection to be async
  detect: (callback: Function) =>
    getAppSettings(Number(applicationCodeId)!)
      .then((userSettings) => {
        const userSettingsForViewOptions: any[] = userSettings.data.filter(
          (item: any) =>
            `${item.appSettingCodeId}` === appSettingUserSettingsCodeId
        );
        if (userSettingsForViewOptions.length) {
          callback(
            userSettingsForViewOptions.find(
              (userSetting: any) => userSetting.isDefault
            )?.userAppSettingValue || defaultLanguage
          );
        } else {
          getUserData()
            .then((userData) => {
              return callback(userData.preferredLanguage || defaultLanguage);
            })
            .catch(() => callback(defaultLanguage));
        }
      })
      .catch(() =>
        getUserData()
          .then((userData) =>
            callback(userData.preferredLanguage || defaultLanguage)
          )
          .catch(() => callback(defaultLanguage))
      ),
  init: () => {},
  cacheUserLanguage: () => {},
};
export const inContextEditorPostProcessor =
  new PhraseInContextEditorPostProcessor({
    phraseEnabled: !!queryString.parse(window.location.search).phrase_context,
    // @ts-ignore
    projectId: phraseProjectId,
    useOldICE: true,
    autoLowercase: false,
  });
i18n
  .use(i18nextXHRBackend)
  .use(languageDetector)
  .use(initReactI18next)
  .use(inContextEditorPostProcessor)
  .init({
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: `${process.env.REACT_APP_PHRASE_BASE_URL}/{{lng}}.json`,
    },
    fallbackLng: (code) => {
      if (!code || code !== "lt-LT") return ["en-GB"];

      return code;
    },
    saveMissing: true,
    missingKeyHandler: (lngs, ns, key, fallbackValue) => {
      // Check if the key is available in 'en-GB', and if so, return its value

      if (lngs[1] === "en-GB") {
        return i18n.t(key, { lng: "en-GB" });
      }

      // If not found in 'en-GB', return the fallback value
      // console.warn(`Missing translation key: ${key}`);
      return fallbackValue;
    },
    supportedLngs: [
      "en-GB",
      "de-AT",
      "bg-BG",
      "cs-CZ",
      "et-EE",
      "hr-HR",
      "hu-HU",
      "lt-LT",
      "pl-PL",
      "ro-RO",
      "ru-RU",
      "sr-Latn-RS",
      "sk-SK",
      "sl-SI",
      "tr-TR",
      "uk-UA",
    ],
    postProcess: ["phraseInContextEditor"],
  });

export default i18n;
(window as any).i18n = i18n;
