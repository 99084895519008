import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  Spinner,
  Textarea,
} from "@fluentui/react-components";
import { ArrowSplitFilled } from "@fluentui/react-icons";
import { VSpace } from "components/Spacer";
import React from "react";
import { useTranslation } from "react-i18next";
import { useAppStore } from "store/AppStore";
import { useBankStatementsStore } from "store/BankStatementsStore";
import { formatNumber } from "utils/number";
import { toast } from "components/FluentToast";

export const ClearToSaldoBankStatementEntryDialog = () => {
  const [
    {
      clearToSaldoBankStatementEntryDialog,
      clearToSaldoBankStatementEntryStatus,
      clearBankStatementEntryStatus,
      bankStatementEntries,
    },
    {
      setBankStatementsStoreValue,
      setBankStatementsStoreValues,
      clearBankStatementEntry,
    },
  ] = useBankStatementsStore();
  const [{ taxonomy }] = useAppStore();

  const isLoading =
    clearToSaldoBankStatementEntryStatus === "loading" ||
    clearBankStatementEntryStatus === "loading";
  const { t } = useTranslation();
  const bankStatementEntry = bankStatementEntries.find((el) => {
    return (
      el.bankStatementEntryId ===
      clearToSaldoBankStatementEntryDialog.bankStatementEntryId
    );
  });
  return (
    <Dialog open={clearToSaldoBankStatementEntryDialog.isOpen}>
      <DialogSurface style={{ maxWidth: "96%", transform: "none" }}>
        <DialogBody>
          <DialogTitle>
            {t("pct.clearToSaldoBankStatementEntry.label")}
          </DialogTitle>
          <DialogContent>
            {t("pct.clearToSaldoBankStatementEntryConfirm.label", [
              formatNumber(bankStatementEntry.amount, 2, 2),
              t(
                taxonomy.Currency.byId[bankStatementEntry.accountCurrencyCode]
                  .code
              ),
              bankStatementEntry.clientInfo.name,
            ])}
            <VSpace height={20} />
          </DialogContent>
          <DialogActions>
            <Button
              appearance="secondary"
              disabled={isLoading}
              onClick={() => {
                setBankStatementsStoreValue(
                  "clearToSaldoBankStatementEntryDialog",
                  {
                    isOpen: false,
                    bankStatementEntry: null,
                    bankStatement: null,
                  }
                );
              }}
            >
              {t("pct.close.label")}
            </Button>
            <Button
              appearance="primary"
              disabled={isLoading}
              icon={
                isLoading ? (
                  <Spinner size="tiny" />
                ) : (
                  <ArrowSplitFilled fontSize={16} />
                )
              }
              onClick={() => {
                clearBankStatementEntry(
                  {
                    bankStatementEntry,
                    suggestedPayments: [],
                    paymentPlanRequest: {},
                  },
                  () => {
                    setBankStatementsStoreValues({
                      clearToSaldoBankStatementEntryDialog: {
                        isOpen: false,
                        bankStatementEntryId: null,
                        bankStatementId: null,
                      },
                      clearanceData: {},
                      clUnsettledPaymentPlans: [],
                      clearBankStatementEntry: null,
                    });
                    toast.success(t("greco.success"));
                  }
                );
              }}
            >
              {t("pct.clearToSaldo.label")}
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};
