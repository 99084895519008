import { ColumnConfig } from "types/reminders";

export const reminderEntriesExcelColumnConfig: ColumnConfig[] = [
  {
    columnId: "policyName",
    width: 250,
    reorderable: true,
    resizable: true,
    sortable: false,
    label: "pct.policyName.label",
    cellType: "customtext",
    additionalInfo: {
      gosLink: {
        idField: "policyId",
        linkType: "policy",
      },
    },
  },

  {
    columnId: "insurerName",
    width: 250,
    reorderable: true,
    resizable: true,
    sortable: false,
    label: "pct.insurerName.label",
    cellType: "customtext",
    additionalInfo: {
      gosLink: {
        idField: "insurerId",
        linkType: "client",
      },
    },
  },
  {
    columnId: "policyNumber",
    width: 250,
    reorderable: true,
    resizable: true,
    sortable: false,
    label: "pct.policyNumber.label",
    cellType: "customtext",
    additionalInfo: undefined,
  },

  {
    columnId: "policySubNumber",
    width: 150,
    reorderable: true,
    resizable: true,
    sortable: false,
    label: "pct.policySubNumber.label",
    cellType: "customtext",
    additionalInfo: undefined,
  },
  {
    columnId: "insuranceLineCode",
    width: 250,
    reorderable: true,
    resizable: true,
    sortable: false,
    label: "pct.insuranceLineCode.label",
    cellType: "taxonomy",
    additionalInfo: {
      taxonomy: "InsuranceLine",
    },
  },
  {
    columnId: "bookingDate",
    width: 120,
    reorderable: true,
    resizable: true,
    sortable: false,
    label: "pct.bookingDate.label",
    cellType: "datepicker",
    additionalInfo: undefined,
  },
  {
    columnId: "premiumFrom",
    width: 120,
    reorderable: true,
    resizable: true,
    sortable: false,
    label: "pct.premiumFrom.label",
    cellType: "datepicker",
    additionalInfo: undefined,
  },
  {
    columnId: "premiumTo",
    width: 120,
    reorderable: true,
    resizable: true,
    sortable: false,
    label: "pct.premiumTo.label",
    cellType: "datepicker",
    additionalInfo: undefined,
  },
  {
    columnId: "paidBookedAmount",
    width: 150,
    reorderable: true,
    resizable: true,
    sortable: false,
    label: "pct.paidBookedAmount.label",
    cellType: "customnumber",
    additionalInfo: {
      rowIdField: "reminderEntryId",
    },
  },
  {
    columnId: "bookedAmount",
    width: 150,
    reorderable: true,
    resizable: true,
    sortable: false,
    label: "pct.bookedAmount.label",
    cellType: "customnumber",
    additionalInfo: {
      rowIdField: "bookedAmount",
    },
  },
  {
    columnId: "paidAmount",
    width: 150,
    reorderable: true,
    resizable: true,
    sortable: false,
    label: "pct.paidAmount.label",
    cellType: "customnumber",
    additionalInfo: {
      rowIdField: "paidAmount",
    },
  },
  {
    columnId: "premiumAmount",
    width: 150,
    reorderable: true,
    resizable: true,
    sortable: false,
    label: "pct.premiumAmount.label",
    cellType: "customnumber",
    additionalInfo: {
      rowIdField: "premiumAmount",
    },
  },

  {
    columnId: "paidDiscountAmount",
    width: 150,
    reorderable: true,
    resizable: true,
    sortable: false,
    label: "pct.paidDiscountAmount.label",
    cellType: "customnumber",
    additionalInfo: {
      rowIdField: "reminderEntryId",
    },
  },
  {
    columnId: "premiumDiscountAmount",
    width: 150,
    reorderable: true,
    resizable: true,
    sortable: false,
    label: "pct.premiumDiscountAmount.label",
    cellType: "customnumber",
    additionalInfo: {
      rowIdField: "reminderEntryId",
    },
  },
];
