import { TextCell } from "@silevis/reactgrid";
import { toast } from "components/FluentToast";
import { ICellTypeHandler } from "components/grecoreactgrid/ICellTypeHandler";
import { DeclaredAmountAllCellTemplate } from "../customcells/DeclaredAmountAllCellTemplate";

class DeclaredAmountAllCellTypeHandler implements ICellTypeHandler {
  cellType = "declaredamountall";
  isCustom = true;
  instance = new DeclaredAmountAllCellTemplate();
  determineNewValue = (change: any) => {
    return (change.newCell as TextCell).text;
  };
  getEntryRow = (
    entry: any,
    columnConfig: any,
    rowsMetadataMap: any,
    keyColumn: string,
    context: {
      [key: string]: any;
    }
  ) => {
    const { sort, setSort, t, paymentDeclarationDialogStore } = context;

    const [{ declaredPayments }, { setPaymentDeclarationStoreValue }] =
      paymentDeclarationDialogStore;

    return {
      type: "declaredamountall",
      value: "paymentsDeclaredAmountAll",
      selected: rowsMetadataMap[entry[keyColumn]]?.selected ?? false,
      highlighted: rowsMetadataMap[entry[keyColumn]]?.highlighted ?? false,
      sortFn: (key, dir) => {
        setSort({
          key: columnConfig.columnId,
          dir: dir,
        });
      },
      key: columnConfig.columnId,
      sort: sort,
      sortable: columnConfig.sortable,
      onSelectAllFn: () => {
        const totalAmount = declaredPayments.reduce(
          (accumulator, current) => accumulator + current.premiumAmount,
          0
        );
        // Iterate through all the plans and set the suggested payment amount to the premium debt values up to the total amount
        let total = 0;
        const updatedPlans = declaredPayments.map((plan) => {
          const premiumAmount = plan.premiumAmount;

          const declaredAmount =
            premiumAmount > totalAmount - total
              ? totalAmount - total
              : premiumAmount;
          total += declaredAmount;
          return { ...plan, declaredAmount };
        });
        setPaymentDeclarationStoreValue("declaredPayments", updatedPlans);
      },
      onClearAllFn: () => {
        setPaymentDeclarationStoreValue(
          "declaredPayments",
          declaredPayments.map((plan) => {
            return { ...plan, declaredAmount: 0 };
          })
        );
      },
      t,
    };
  };
}

export default DeclaredAmountAllCellTypeHandler;
