import React from "react";
import MainToolbar from "components/MainToolbar";
import { Flex, FlexRight, NavigationBar } from "components/styled";
import BankStatementsMenubar from "./BankStatementsGrid/BankStatementsMenubar";
import { VSpace } from "components/Spacer";
import { RefreshButton } from "./BankStatementsGrid/RefreshButton";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
} from "@fluentui/react-components";
import { AddRegular, ArrowImportRegular } from "@fluentui/react-icons";
import { useTranslation } from "react-i18next";
import {
  useBankStatementsStore,
  useBankStatementsStoreActions,
} from "store/BankStatementsStore";
import { useAppStoreState } from "store/AppStore";
import { ResultsScore } from "components/ResultsScore";

const Navigation = () => {
  const { t } = useTranslation();

  const [
    { bankStatementMetadataMap },
    { addNewEmptyBankStatement, setBankStatementsStoreValue },
  ] = useBankStatementsStore();

  const { grecoCompanies, taxonomy } = useAppStoreState();

  return (
    <>
      <NavigationBar style={{ zIndex: 333 }}>
        <MainToolbar pageName={"bankStatementPage"} />
        <RefreshButton />

        <BankStatementsMenubar />
        <VSpace height={5} />
      </NavigationBar>
      <NavigationBar style={{ zIndex: 333 }}>
        <Menu>
          <MenuTrigger disableButtonEnhancement>
            <MenuButton appearance="subtle" icon={{ children: <AddRegular /> }}>
              {t("pct.addBankStatement.label")}
            </MenuButton>
          </MenuTrigger>

          <MenuPopover>
            <MenuList>
              <MenuItem
                icon={{ children: <ArrowImportRegular /> }}
                onClick={(e) => {
                  setBankStatementsStoreValue("importBankStatementDialog", {
                    isOpen: true,
                  });
                }}
              >
                {t("pct.importFromXml.label")}
              </MenuItem>
              <MenuItem
                icon={{ children: <AddRegular /> }}
                onClick={(e) => {
                  e.stopPropagation();
                  addNewEmptyBankStatement({ taxonomy, grecoCompanies });
                }}
              >
                {t("pct.enterManually.label")}
              </MenuItem>
            </MenuList>
          </MenuPopover>
        </Menu>
        <FlexRight>
          <Flex>
            <ResultsScore
              score={
                [
                  ...new Map(Object.entries(bankStatementMetadataMap)).values(),
                ].filter((e: any) => {
                  return e.visible === true;
                }).length
              }
            />
          </Flex>
        </FlexRight>
      </NavigationBar>
    </>
  );
};

export default Navigation;
