import {
  DatePicker,
  DirectionalHint,
  TooltipHost,
  defaultDatePickerStrings,
  useTheme,
} from "@fluentui/react";
import { FluentProvider, Tooltip } from "@fluentui/react-components";
import {
  Cell,
  CellTemplate,
  Compatible,
  Uncertain,
  UncertainCompatible,
  getCellProperty,
  getCharFromKeyCode,
  isAlphaNumericKey,
  isNavigationKey,
  keyCodes,
} from "@silevis/reactgrid";
import moment from "moment";
import * as React from "react";

const datePickerStyles = (theme) => ({
  root: {
    ".ms-TextField-fieldGroup": {
      borderTop: "none !important",
      borderRight: "none !important",
      borderLeft: "none !important",
      background: "transparent !important",
      height: "auto !important",
    },
    ".ms-TextField-fieldGroup::after": {
      borderTop: "none !important",
      borderRight: "none !important",
      borderLeft: "none !important",
    },
    ".ms-TextField-suffix": {
      //Cancel Icon
      marginRight: "30px",
      background: "none",
      cursor: "pointer",
    },
    ".msDatePickerDisabled": {
      color: "#005aa1",
    },
    ".ms-TextField-fieldGroup input": {
      background: "transparent !important",
    },
    ".ms-TextField-fieldGroup i": {
      padding: "0px !important",
    },
  },
  container: {
    ".ms-DatePicker-goToday": {
      right: "14px !important",
    },
  },
  icon: { right: "0" },
});

export type OptionType = {
  label: string;
  value: string;
};

export interface DatePickerCell extends Cell {
  type: "datepicker";
  selectedValue?: Date;
  isDisabled?: boolean;
  format: string;
  editable?: boolean;
  selected?: boolean;
  highlighted?: boolean;
  t: any;
}
const onFormatDate = (date?: Date): string => {
  // if (includeTime) {
  //   return moment(date).format("DD.MM.YYYY HH:mm");
  // }
  return !date
    ? ""
    : ((date.getDate() + "").length === 1 ? "0" : "") +
        date.getDate() +
        "." +
        ((date.getMonth() + 1 + "").length === 1 ? "0" : "") +
        (date.getMonth() + 1) +
        "." +
        date.getFullYear();
};
export class DatePickerCellTemplate implements CellTemplate<DatePickerCell> {
  getCompatibleCell(
    uncertainCell: Uncertain<DatePickerCell>
  ): Compatible<DatePickerCell> {
    let selectedValue: Date | undefined;
    let t: any;
    t = getCellProperty(uncertainCell, "t", "function");
    try {
      selectedValue = getCellProperty(uncertainCell, "selectedValue", "object");
    } catch {
      selectedValue = undefined;
    }
    const format = getCellProperty(uncertainCell, "format", "string");
    const value = NaN; //selectedValue ? selectedValue : NaN;
    let isDisabled = true;
    try {
      isDisabled = getCellProperty(uncertainCell, "isDisabled", "boolean");
    } catch {
      isDisabled = false;
    }
    let editable: boolean | undefined;

    try {
      editable = getCellProperty(uncertainCell, "editable", "boolean");
    } catch {
      editable = true;
    }
    let selected: boolean | undefined;

    try {
      selected = getCellProperty(uncertainCell, "selected", "boolean");
    } catch {
      selected = true;
    }
    let highlighted: boolean | undefined;
    try {
      highlighted = getCellProperty(uncertainCell, "highlighted", "boolean");
    } catch {
      highlighted = false;
    }
    // let inputValue: string | undefined;
    // try {
    //   inputValue = getCellProperty(uncertainCell, "inputValue", "string");
    // } catch {
    //   inputValue = undefined;
    // }

    const text = selectedValue + "" || "";
    return {
      ...uncertainCell,
      selectedValue,
      text,
      value,
      format,
      isDisabled,
      selected,
      highlighted,
      t,
      // isOpen,
      // inputValue,
    };
  }

  update(
    cell: Compatible<DatePickerCell>,
    cellToMerge: UncertainCompatible<DatePickerCell>
  ): Compatible<DatePickerCell> {
    return this.getCompatibleCell({
      ...cell,
      selectedValue: cellToMerge.selectedValue,
      editable: cellToMerge.editable,

      // inputValue: cellToMerge.inputValue,
    });
  }

  getClassName(
    cell: Compatible<DatePickerCell>,
    isInEditMode: boolean
  ): string {
    return (
      (cell.className ? cell.className : "") +
      (cell.selected ? " rg-selected" : "") +
      (cell.highlighted ? " highlighted-selected-row" : "")
    );
  }

  handleKeyDown(
    cell: Compatible<DatePickerCell>,
    keyCode: number,
    ctrl: boolean,
    shift: boolean,
    alt: boolean
  ): { cell: Compatible<DatePickerCell>; enableEditMode: boolean } {
    if ((keyCode === keyCodes.SPACE || keyCode === keyCodes.ENTER) && !shift) {
      return {
        cell: this.getCompatibleCell({ ...cell }),
        enableEditMode: false,
      };
    }
    const char = getCharFromKeyCode(keyCode, shift);
    if (!ctrl && !alt && isAlphaNumericKey(keyCode))
      return {
        cell: this.getCompatibleCell({
          ...cell,
          // inputValue: shift ? char : char.toLowerCase(),
          format: cell.format,
        }),
        enableEditMode: true,
      };
    return {
      cell,
      enableEditMode:
        keyCode === keyCodes.POINTER || keyCode === keyCodes.ENTER,
    };
  }

  render(
    cell: Compatible<DatePickerCell>,
    isInEditMode: boolean,
    onCellChanged: (cell: Compatible<DatePickerCell>, commit: boolean) => void
  ): React.ReactNode {
    const onCellChangedWithCurrentTime = (
      cell: Compatible<DatePickerCell>,
      commit: boolean
    ) => {
      let cellCopy = { ...cell };
      if (
        cell.selectedValue !== null &&
        cell.selectedValue !== undefined &&
        cell.selectedValue.toString().length > 0
      ) {
        let selectedDate = moment(cell.selectedValue);
        selectedDate.set({
          hour: 12,
          minute: 0,
          second: 0,
        });
        cellCopy = {
          ...cellCopy,
          selectedValue: selectedDate.toDate(),
        };
      }
      onCellChanged(cellCopy, commit);
    };

    if (!isInEditMode) {
      return (
        <Tooltip
          content={
            cell.text && cell.text !== "undefined"
              ? moment(cell.text).format(cell.format)
              : ""
          }
          relationship="label"
        >
          <span>
            {cell.text && cell.text !== "undefined"
              ? moment(cell.text).format(cell.format)
              : ""}
          </span>
        </Tooltip>
      );
    }
    return (
      <DateCell
        onCellChanged={(cell) =>
          onCellChangedWithCurrentTime(this.getCompatibleCell(cell), true)
        }
        cell={cell}
      />
    );
  }
}

interface DIProps {
  onCellChanged: (...args: any[]) => void;
  cell: Record<string, any>;
}

const DateCell: React.FC<DIProps> = ({ onCellChanged, cell }) => {
  const datePickerRef = React.useRef<any>(null);
  const theme = useTheme();

  React.useEffect(() => {
    // if (cell.isOpen && selectRef.current) {
    //   selectRef.current.focus();
    //   setInputValue(cell.inputValue);
    // }
  }, [cell.inputValue]);

  const [fieldValue, setFieldValue] = React.useState<Date | undefined>(
    cell.selectedValue
  );

  // const [fieldValue, setFieldValue] = useState(defaultValues[name]);

  // useEffect(() => {
  //   setValue(name, fieldValue);
  // }, [fieldValue]);

  const onParseDateFromString = React.useCallback(
    (newValue: string, onChange: (...event: any[]) => void): Date => {
      const previousValue = fieldValue || new Date();
      const newValueParts = (newValue || "").trim().split(".");
      if (newValueParts.length === 1 && newValueParts[0].length === 0) {
        onChange({
          ...cell,
          selectedValue: null,
        });
        return null;
      }
      try {
        const day =
          newValueParts.length > 0 && newValueParts[0].length > 0
            ? Math.max(1, Math.min(31, parseInt(newValueParts[0], 10)))
            : previousValue.getDate();
        const month =
          newValueParts.length > 1
            ? Math.max(1, Math.min(12, parseInt(newValueParts[1], 10)))
            : previousValue.getMonth() + 1;
        const year =
          newValueParts.length > 2
            ? Math.max(0, parseInt(newValueParts[2], 10))
            : previousValue.getFullYear();
        const retVal = new Date(year, month - 1, day);
        if (retVal && !isNaN(retVal.getTime())) {
          onChange({
            ...cell,
            selectedValue: retVal,
          });
          return retVal;
        } else {
          onChange({
            ...cell,
            selectedValue: null,
          });
        }
      } catch (e) {
        onChange({
          ...cell,
          selectedValue: null,
        });
      }
      return null;
    },
    [fieldValue]
  );
  const placeholder = cell.placeholder || "dd.mm.yyyy";
  return (
    <div
      style={{ width: "95%", margin: "auto" }}
      onPointerDown={(e) => {
        if (!cell.editable) return;
        onCellChanged({ ...cell });
      }}
    >
      <FluentProvider theme={cell.theme}>
        <DatePicker
          className="cell-input"
          disabled={!cell.editable}
          styles={datePickerStyles(theme)}
          onError={(e) => {
            return;
          }}
          onErrorCapture={(e) => {
            return;
          }}
          onPointerDown={(e) => {
            e.stopPropagation();
            if (!cell.editable) return;
            onCellChanged({ ...cell });
          }}
          allowTextInput
          componentRef={datePickerRef}
          value={cell.selectedValue}
          onSelectDate={(date: Date | null) => {
            if (!cell.editable) return;
            onCellChanged({
              ...cell,
              selectedValue: date,
            });
          }}
          parseDateFromString={(dateStr) => {
            if (!cell.editable) return;
            return onParseDateFromString(dateStr, onCellChanged);
          }}
          onChange={(e: any) => {
            if (!cell.editable) return;
            onCellChanged({
              ...cell,
              selectedValue: e.data?.value,
            });
          }}
          formatDate={onFormatDate}
          // DatePicker uses English strings by default. For localized apps, you must override this prop.
          strings={defaultDatePickerStrings}
          placeholder={placeholder}
          disableAutoFocus={true}
          // minDate={minDate}
          // maxDate={maxDate}
          onBlur={(e) => {
            if (!cell.editable) return;
            const inputEl: any =
              e.currentTarget.childNodes[0].childNodes[0].childNodes[0]
                .childNodes[0].childNodes[0];
            setTimeout(() => {
              inputEl.blur();
              onParseDateFromString(inputEl.value, onCellChanged);
            }, 300);
          }}
          onKeyDown={(e) => {
            if (isAlphaNumericKey(e.keyCode) || isNavigationKey(e.keyCode))
              e.stopPropagation();
          }}
        />
      </FluentProvider>
    </div>
  );
};
