import Axios, { AxiosError } from "axios";
import moment from "moment";
import { Setting } from "types/types";
import {
  formatNumberForSearch,
  getShortInsurerName,
  handleAxiosError,
  prepareNotificationObject,
} from "utils/utils";
import * as API from "../api/api";
import { setNotificationMessage } from "./AppStoreActions";
import { StoreState } from "./PaymentDeclarationDialogStore";

export const setStoreValue =
  (name: keyof StoreState, value) =>
  ({ setState }) => {
    setState({
      [name]: value,
    });
  };

export const setStoreValues =
  (values) =>
  ({ setState }) => {
    setState({
      ...values,
    });
  };

export const declarePayments =
  (requestBody, onSuccess?, onError?) =>
  async ({ setState, getState, dispatch }) => {
    try {
      if (getState().generateExcelStatus === "loading") return;
      setState({
        declarePaymentsStatus: "loading",
      });

      const response = await API.declarePayments(requestBody);

      setState({
        declarePaymentsStatus: "success",
      });

      onSuccess && onSuccess(response.data);

      // onSuccess && onSuccess(response.headers["usertype"]);
    } catch (error: any) {
      if (error.code !== "ERR_CANCELED") {
        handleAxiosError(error, (message) => {
          dispatch(
            setNotificationMessage(
              prepareNotificationObject("declarePayments", message)
            )
          );
        });
        setState({
          declarePaymentsStatus: "error",
        });
      }
    }
  };

export const generateExcel =
  (requestBody, onSuccess?, onError?) =>
  async ({ setState, getState, dispatch }) => {
    try {
      if (getState().generateExcelStatus === "loading") return;
      setState({
        generateExcelStatus: "loading",
      });

      const response = await API.generateExcel(requestBody);

      setState({
        excelReportResponse: response.data,
        generateExcelStatus: "success",
      });
      onSuccess && onSuccess(response.data);
    } catch (error: any) {
      if (error.code !== "ERR_CANCELED") {
        handleAxiosError(error, (message) => {
          dispatch(
            setNotificationMessage(
              prepareNotificationObject("generateExcel", message)
            )
          );
        });
        setState({
          generateExcelStatus: "error",
        });
      }
    }
  };
