import React from "react";
import { Button, Toolbar } from "@fluentui/react-components";
import { SearchRegular } from "@fluentui/react-icons";
import { useAppStore } from "store/AppStore";
import { useTranslation } from "react-i18next";
import { DatePicker, IDatePicker } from "@fluentui/react-datepicker-compat";
import { Search } from "./Search";
import { useRemindersStore } from "store/RemindersStore";
import { defaultDatePickerStrings } from "@fluentui/react";
import moment from "moment";
import { isInvalidDate } from "utils/utils";
import { useRemindersCreateDialogStore } from "store/RemindersCreateDialogStore";

const onFormatDate = (date?: Date): string => {
  return !date
    ? ""
    : date.getDate() + "." + (date.getMonth() + 1) + "." + date.getFullYear();
};

const RemindersMenubar = (props) => {
  const [
    { remindersLoadStatus, searchTerm, fromDateFilter, toDateFilter },
    { setRemindersStoreValue },
  ] = useRemindersStore();

  const [{ populateCreateReminderDataStatus }, {}] =
    useRemindersCreateDialogStore();

  const [{ updatedGrecoCompanies }] = useAppStore();

  const loading =
    remindersLoadStatus === "loading" ||
    populateCreateReminderDataStatus === "loading";
  const { t } = useTranslation();

  const fromDatePickerRef = React.useRef<IDatePicker>(null);
  const toDatePickerRef = React.useRef<IDatePicker>(null);

  const onParseFromDateFromString = React.useCallback(
    (newValue: string): Date => {
      const previousValue = fromDateFilter || new Date();
      const newValueParts = (newValue || "").trim().split(".");
      const day =
        newValueParts.length > 0
          ? Math.max(1, Math.min(31, parseInt(newValueParts[0], 10)))
          : previousValue.getDate();
      const month =
        newValueParts.length > 1
          ? Math.max(1, Math.min(12, parseInt(newValueParts[1], 10))) - 1
          : previousValue.getMonth();
      let year =
        newValueParts.length > 2
          ? parseInt(newValueParts[2], 10)
          : previousValue.getFullYear();
      // if (year < 100) {
      //   year +=
      //     previousValue.getFullYear() - (previousValue.getFullYear() % 100);
      // }
      return new Date(year, month, day);
    },
    [fromDateFilter]
  );

  const onParseFromDateToString = React.useCallback(
    (newValue: string): Date => {
      const previousValue = toDateFilter || new Date();
      const newValueParts = (newValue || "").trim().split(".");
      const day =
        newValueParts.length > 0
          ? Math.max(1, Math.min(31, parseInt(newValueParts[0], 10)))
          : previousValue.getDate();
      const month =
        newValueParts.length > 1
          ? Math.max(1, Math.min(12, parseInt(newValueParts[1], 10))) - 1
          : previousValue.getMonth();
      let year =
        newValueParts.length > 2
          ? parseInt(newValueParts[2], 10)
          : previousValue.getFullYear();
      // if (year < 100) {
      //   year +=
      //     previousValue.getFullYear() - (previousValue.getFullYear() % 100);
      // }
      return new Date(year, month, day);
    },
    [toDateFilter]
  );

  return (
    <>
      <Toolbar
        {...props}
        aria-label="Small"
        size="small"
        style={{
          display: "flex",
          gap: "8px",
        }}
      >
        <Search
          disabled={loading}
          placeholder="Search"
          value={searchTerm}
          onChange={(value) => setRemindersStoreValue("searchTerm", value)}
        />
        <DatePicker
          onError={(e) => {
            return;
          }}
          onErrorCapture={(e) => {
            return;
          }}
          componentRef={fromDatePickerRef}
          allowTextInput
          value={fromDateFilter}
          onSelectDate={(date: Date | undefined) => {
            setRemindersStoreValue("fromDateFilter", date);
          }}
          parseDateFromString={(dateStr) => onParseFromDateFromString(dateStr)}
          onChange={(e: any) => {
            //console.log(e.target.value);
          }}
          formatDate={onFormatDate}
          // DatePicker uses English strings by default. For localized apps, you must override this prop.
          strings={defaultDatePickerStrings}
          // disabled={isLoading}
          placeholder="Select from date..."
          disableAutoFocus={true}
          // minDate={minDate}
          // maxDate={maxDate}
          onBlur={(e) => {
            const inputEl: any =
              e.currentTarget.childNodes[0].childNodes[0].childNodes[0]
                .childNodes[0];
            setTimeout(() => {
              inputEl.blur();
              const val = onParseFromDateFromString(inputEl.value);
              if (
                !isInvalidDate(val) &&
                moment(val.getTime()).format("YYYY-MM-DD") !==
                  moment(fromDateFilter?.getTime()).format("YYYY-MM-DD")
              )
                setRemindersStoreValue(
                  "fromDateFilter",
                  isNaN(val.getTime()) ? null : val
                );
            }, 300);
          }}
          disabled={loading}
        />

        <DatePicker
          onError={(e) => {
            return;
          }}
          onErrorCapture={(e) => {
            return;
          }}
          componentRef={toDatePickerRef}
          allowTextInput
          value={toDateFilter}
          onSelectDate={(date) => {
            setRemindersStoreValue("toDateFilter", date);
          }}
          parseDateFromString={(dateStr) => onParseFromDateToString(dateStr)}
          onChange={(e) => {
            //console.log("onChange", e);
          }}
          formatDate={onFormatDate}
          // DatePicker uses English strings by default. For localized apps, you must override this prop.
          strings={defaultDatePickerStrings}
          // disabled={isLoading}
          placeholder="Select to date..."
          disableAutoFocus={true}
          // minDate={minDate}
          // maxDate={maxDate}
          onBlur={(e) => {
            const inputEl: any =
              e.currentTarget.childNodes[0].childNodes[0].childNodes[0]
                .childNodes[0];
            setTimeout(() => {
              inputEl.blur();
              const val = onParseFromDateFromString(inputEl.value);
              if (
                !isInvalidDate(val) &&
                moment(val.getTime()).format("YYYY-MM-DD") !==
                  moment(toDateFilter?.getTime()).format("YYYY-MM-DD")
              )
                setRemindersStoreValue(
                  "toDateFilter",
                  isNaN(val.getTime()) ? null : val
                );
            }, 300);
          }}
          disabled={loading}
        />

        {/* <Button
          disabled={loading}
          appearance="secondary"
          icon={<SearchRegular />}
          onClick={(e) => {
            loadReminders({ reset: true, delay: true, updatedGrecoCompanies });
          }}
        >
          {t("pct.apply.label")}
        </Button> */}
      </Toolbar>
    </>
  );
};

export default RemindersMenubar;
