import { IDatePickerStyles, defaultDatePickerStrings } from "@fluentui/react";
import { Toolbar } from "@fluentui/react-components";
import { DatePicker, IDatePicker } from "@fluentui/react-datepicker-compat";
import moment from "moment";
import React from "react";

import { usePaymentPlanPageStore } from "store/PaymentPlanPageStore";
import { isInvalidDate } from "utils/utils";

const onFormatDate = (date?: Date): string => {
  return !date
    ? ""
    : date.getDate() + "." + (date.getMonth() + 1) + "." + date.getFullYear();
};

const PaymentPlanMenubar = (props) => {
  const [
    { fromBookingDateFilter, toBookingDateFilter, paymentPlansLoadStatus },
    { setPaymentPlanStoreValue },
  ] = usePaymentPlanPageStore();
  const loading = paymentPlansLoadStatus === "loading";

  const fromDatePickerRef = React.useRef<IDatePicker>(null);
  const toDatePickerRef = React.useRef<IDatePicker>(null);

  const onParseFromDateFromString = React.useCallback(
    (newValue: string): Date => {
      const previousValue = fromBookingDateFilter || new Date();
      const newValueParts = (newValue || "").trim().split(".");
      const day =
        newValueParts.length > 0
          ? Math.max(1, Math.min(31, parseInt(newValueParts[0], 10)))
          : previousValue.getDate();
      const month =
        newValueParts.length > 1
          ? Math.max(1, Math.min(12, parseInt(newValueParts[1], 10))) - 1
          : previousValue.getMonth();
      let year =
        newValueParts.length > 2
          ? parseInt(newValueParts[2], 10)
          : previousValue.getFullYear();

      return new Date(year, month, day);
    },
    [fromBookingDateFilter]
  );

  const onParseToDateFromString = React.useCallback(
    (newValue: string): Date => {
      const previousValue = toBookingDateFilter || new Date();
      const newValueParts = (newValue || "").trim().split(".");
      const day =
        newValueParts.length > 0
          ? Math.max(1, Math.min(31, parseInt(newValueParts[0], 10)))
          : previousValue.getDate();
      const month =
        newValueParts.length > 1
          ? Math.max(1, Math.min(12, parseInt(newValueParts[1], 10))) - 1
          : previousValue.getMonth();
      let year =
        newValueParts.length > 2
          ? parseInt(newValueParts[2], 10)
          : previousValue.getFullYear();

      return new Date(year, month, day);
    },
    [toBookingDateFilter]
  );

  return (
    <>
      <Toolbar {...props} aria-label="Small" size="small" style={{}}>
        <DatePicker
          className="narrow-datepicker"
          onError={(e) => {
            return;
          }}
          onErrorCapture={(e) => {
            return;
          }}
          componentRef={fromDatePickerRef}
          allowTextInput
          value={fromBookingDateFilter}
          onSelectDate={(date: Date | undefined) => {
            setPaymentPlanStoreValue("fromBookingDateFilter", date);
          }}
          parseDateFromString={(dateStr) => onParseFromDateFromString(dateStr)}
          strings={defaultDatePickerStrings}
          disableAutoFocus={true}
          formatDate={onFormatDate}
          onBlur={(e) => {
            const inputEl: any =
              e.currentTarget.childNodes[0].childNodes[0].childNodes[0]
                .childNodes[0];
            setTimeout(() => {
              inputEl.blur();
              const val = onParseFromDateFromString(inputEl.value);
              if (
                !isInvalidDate(val) &&
                moment(val.getTime()).format("YYYY-MM-DD") !==
                  moment(fromBookingDateFilter?.getTime()).format("YYYY-MM-DD")
              )
                setPaymentPlanStoreValue(
                  "fromBookingDateFilter",
                  isNaN(val.getTime()) ? null : val
                );
            }, 300);
          }}
          placeholder="Select from date..."
          disabled={loading}
        />
        <DatePicker
          className="narrow-datepicker"
          onError={(e) => {
            return;
          }}
          onErrorCapture={(e) => {
            return;
          }}
          componentRef={toDatePickerRef}
          allowTextInput
          value={toBookingDateFilter}
          onSelectDate={(date) => {
            setPaymentPlanStoreValue("toBookingDateFilter", date);
          }}
          parseDateFromString={onParseToDateFromString}
          strings={defaultDatePickerStrings}
          disableAutoFocus={true}
          formatDate={onFormatDate}
          onBlur={(e) => {
            const inputEl: any =
              e.currentTarget.childNodes[0].childNodes[0].childNodes[0]
                .childNodes[0];
            setTimeout(() => {
              inputEl.blur();
              const val = onParseFromDateFromString(inputEl.value);
              if (
                !isInvalidDate(val) &&
                moment(val.getTime()).format("YYYY-MM-DD") !==
                  moment(toBookingDateFilter?.getTime()).format("YYYY-MM-DD")
              )
                setPaymentPlanStoreValue(
                  "toBookingDateFilter",
                  isNaN(val.getTime()) ? null : moment(val).toDate()
                );
            }, 300);
          }}
          placeholder="Select to date..."
          disabled={loading}
        />
      </Toolbar>
    </>
  );
};

export default PaymentPlanMenubar;
