import { TextCell } from "@silevis/reactgrid";
import { ICellTypeHandler } from "components/grecoreactgrid/ICellTypeHandler";
import { DeclaredCellTemplate } from "../customcells/DeclaredCellTemplate";

class DeclaredCellTypeHandler implements ICellTypeHandler {
  cellType = "declared";
  isCustom = true;
  instance = new DeclaredCellTemplate();
  determineNewValue = (change: any) => (change.newCell as TextCell).text;
  getEntryRow = (
    entry: any,
    columnConfig: any,
    rowsMetadataMap: any,
    keyColumn: string,
    context: {
      [key: string]: any;
    }
  ) => {
    const { t } = context;
    return {
      type: "declared", //,
      declaredAt: entry["declaredAt"],
      declaredByUserEmail: entry["declaredByUserEmail"],
      declaredByUserId: entry["declaredByUserId"],
      declaredByUserName: entry["declaredByUserName"],
      selected: rowsMetadataMap[entry[keyColumn]]?.selected ?? false,
      highlighted: rowsMetadataMap[entry[keyColumn]]?.highlighted ?? false,
      isDeclaredViaWebApi: entry["isDeclaredViaWebApi"],
      t,
    };
  };
}

export default DeclaredCellTypeHandler;
