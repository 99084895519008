import styled from "@emotion/styled";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import MainToolbar from "components/MainToolbar";
import { Flex, FlexRight, HorizontalSpacer } from "components/styled";
import React from "react";
import { useClientBalancePageStore } from "store/ClientBalancePageStore";
import { ExportExcelButton } from "./navigationbuttons/ExportExcelButton";
import { RefreshButton } from "./navigationbuttons/RefreshButton";
import { Search } from "./navigationbuttons/Search";

import CompaniesMenu from "components/CompaniesMenu";
import { Checkbox } from "@fluentui/react-components";
import { useTranslation } from "react-i18next";
import { ClientBalancesMetadataMap } from "utils/types";

export const NavigationBar = styled.div`
  height: 44px;
  background: ${(p) => p.theme.palette.white};
  display: flex;
  align-items: center;
  padding: 0 10px;
  border-bottom: 1px solid ${(p) => p.theme.palette.neutralLight};
  position: sticky;
  top: 0;
  z-index: 2;
`;

type Props = {
  refExcelExport: ExcelExport;
};

const ClientBalancePageNavigationFirstLine = ({ refExcelExport }: Props) => {
  const [
    { searchTerm, showZeroBalanceClients, clientBalancesMetadataMap },
    { setClientBalancePageStoreValue, setClientBalancePageStoreValues },
  ] = useClientBalancePageStore();
  const { t } = useTranslation();

  return (
    <NavigationBar style={{ zIndex: 334 }}>
      <MainToolbar pageName={"clientBalancePage"} />
      <RefreshButton />
      <Search
        placeholder="Search"
        value={searchTerm}
        onChange={(value) =>
          setClientBalancePageStoreValue("searchTerm", value)
        }
      />
      <Checkbox
        size="medium"
        checked={showZeroBalanceClients}
        onChange={(e) => {
          const newMap: ClientBalancesMetadataMap = {};
          for (const [key, value] of Object.entries(
            clientBalancesMetadataMap
          )) {
            newMap[key] = { ...value, selected: false };
          }
          setClientBalancePageStoreValues({
            showZeroBalanceClients: !showZeroBalanceClients,
            clientBalancesMetadataMap: newMap,
            clientBalancesSelectAll: false,
          });
        }}
      />
      {t("pct.showZeroBalanceClients.label")}
      <FlexRight>
        <Flex>
          <CompaniesMenu />
        </Flex>
      </FlexRight>
    </NavigationBar>
  );
};

export default ClientBalancePageNavigationFirstLine;
