import moment from "moment";
import {
  createActionsHook,
  createHook,
  createStateHook,
  createStore,
} from "react-sweet-state";
import { LoadStatus } from "types/types";
import { UnsettledPaymentPlanMetadataMap } from "utils/types";
import {
  createReminder,
  getInsurersByCountry,
  populateCreateReminderData,
  setStoreCreateDialogValue,
  setStoreCreateDialogValues,
} from "./RemindersStoreActions";

export type StoreState = {
  suggestedReminder: any;

  createReminderStatus: LoadStatus;
  populateCreateReminderDataStatus: LoadStatus;
  getInsurersByCountryStatus: LoadStatus;
  createReminderSort: any;
  createReminderDialog: {
    isOpen: boolean;
    reminderId: any;
  };

  clUnsettledPaymentPlans: any[];
  clUnsettledPaymentPlanMetadataMap: UnsettledPaymentPlanMetadataMap;
  clTotal: [number, number];
  clReminder: any;
  clInsurersSelectedItem: any[];
  clInsurersOptions: any[];
  clPoliciesSelectedItem: any[];
  clSubpoliciesSelectedItem: any[];
  clServiceSegmentsSelectedItem: any[];
  clGrecoCompanyNumbers: any[];
  clCurrencyCodesSelectedItem: any;
  clIsBrokerPremiumCollection: boolean;
  clIncludeIfAlreadyInReminder: boolean;
  clReminderNumber: string;
  clComment: string;
  clSearchPayersSelectedItem: any;
  clFromDateFilter: Date;
  clToDateFilter: Date;
  clHightlightCount: number;

  clInsurersCountryCode: any;

  clSearchTerm: string;
  clServiceSegmentCode: number;
};

// This is the value of the store on initialisation
const initialState: StoreState = {
  suggestedReminder: null,
  clUnsettledPaymentPlans: [],
  clUnsettledPaymentPlanMetadataMap: {},
  clTotal: [0, 0],
  clReminder: null,
  clInsurersSelectedItem: [],
  clInsurersOptions: [],
  clPoliciesSelectedItem: [],
  clSubpoliciesSelectedItem: [],
  clServiceSegmentsSelectedItem: [],
  clCurrencyCodesSelectedItem: null,
  clIsBrokerPremiumCollection: true,
  clIncludeIfAlreadyInReminder: false,
  clReminderNumber: "",
  clComment: "",
  clSearchPayersSelectedItem: null,
  clFromDateFilter: moment().startOf("day").toDate(),
  clToDateFilter: moment().endOf("day").add(15, "days").toDate(),
  clSearchTerm: "",
  clServiceSegmentCode: null,
  clGrecoCompanyNumbers: [],
  clHightlightCount: 0,

  clInsurersCountryCode: null,
  populateCreateReminderDataStatus: "none",
  createReminderStatus: "none",
  getInsurersByCountryStatus: "none",
  createReminderDialog: {
    isOpen: false,
    reminderId: null,
  },
  createReminderSort: null,
};

// All the actions that mutate the store
const actions = {
  setRemindersCreateDialogStoreValue: setStoreCreateDialogValue,
  setRemindersCreateDialogStoreValues: setStoreCreateDialogValues,

  populateCreateReminderData: populateCreateReminderData,
  createReminder: createReminder,
  getInsurersByCountry: getInsurersByCountry,
};

const RemindersCreateDialogStore = createStore({
  initialState,
  actions,
  name: "RemindersCreateDialogStore",
});

export const useRemindersCreateDialogStore = createHook(
  RemindersCreateDialogStore
);
export const useRemindersCreateDialogStoreState = createStateHook(
  RemindersCreateDialogStore
);
export const useRemindersCreateDialogStoreActions = createActionsHook(
  RemindersCreateDialogStore
);

export const useClReminderNumber = createHook(RemindersCreateDialogStore, {
  selector: (state) => {
    return {
      clReminderNumber: state.clReminderNumber,
    };
  },
});

export const useClComment = createHook(RemindersCreateDialogStore, {
  selector: (state) => ({ clComment: state.clComment }),
});

export const useClSearchTerm = createHook(RemindersCreateDialogStore, {
  selector: (state) => ({ clSearchTerm: state.clSearchTerm }),
});

export const useClUnsettledPaymentPlans = createHook(
  RemindersCreateDialogStore,
  {
    selector: (state) => ({
      clUnsettledPaymentPlans: state.clUnsettledPaymentPlans,
    }),
  }
);

export const useClUnsettledPaymentPlanMetadataMap = createHook(
  RemindersCreateDialogStore,
  {
    selector: (state) => ({
      clUnsettledPaymentPlanMetadataMap:
        state.clUnsettledPaymentPlanMetadataMap,
    }),
  }
);

export const useClGrecoCompanyNumbers = createHook(RemindersCreateDialogStore, {
  selector: (state) => ({ clGrecoCompanyNumbers: state.clGrecoCompanyNumbers }),
});

export const useClSearchPayersSelectedItem = createHook(
  RemindersCreateDialogStore,
  {
    selector: (state) => ({
      clSearchPayersSelectedItem: state.clSearchPayersSelectedItem,
    }),
  }
);

export const useClFromDateFilter = createHook(RemindersCreateDialogStore, {
  selector: (state) => ({ clFromDateFilter: state.clFromDateFilter }),
});

export const useClToDateFilter = createHook(RemindersCreateDialogStore, {
  selector: (state) => ({ clToDateFilter: state.clToDateFilter }),
});

export const useClTotal = createHook(RemindersCreateDialogStore, {
  selector: (state) => ({ clTotal: state.clTotal }),
});

export const useClPoliciesSelectedItem = createHook(
  RemindersCreateDialogStore,
  {
    selector: (state) => ({
      clPoliciesSelectedItem: state.clPoliciesSelectedItem,
    }),
  }
);

export const useClSubpoliciesSelectedItem = createHook(
  RemindersCreateDialogStore,
  {
    selector: (state) => ({
      clSubpoliciesSelectedItem: state.clSubpoliciesSelectedItem,
    }),
  }
);

export const useClServiceSegmentsSelectedItem = createHook(
  RemindersCreateDialogStore,
  {
    selector: (state) => ({
      clServiceSegmentsSelectedItem: state.clServiceSegmentsSelectedItem,
    }),
  }
);

export const useClCurrencyCodesSelectedItem = createHook(
  RemindersCreateDialogStore,
  {
    selector: (state) => ({
      clCurrencyCodesSelectedItem: state.clCurrencyCodesSelectedItem,
    }),
  }
);

export const useClInsurersSelectedItem = createHook(
  RemindersCreateDialogStore,
  {
    selector: (state) => ({
      clInsurersSelectedItem: state.clInsurersSelectedItem,
    }),
  }
);

export const useClInsurersOptions = createHook(RemindersCreateDialogStore, {
  selector: (state) => ({
    clInsurersOptions: state.clInsurersOptions,
  }),
});

export const useClIsBrokerPremiumCollection = createHook(
  RemindersCreateDialogStore,
  {
    selector: (state) => ({
      clIsBrokerPremiumCollection: state.clIsBrokerPremiumCollection,
    }),
  }
);

export const useClIncludeIfAlreadyInReminder = createHook(
  RemindersCreateDialogStore,
  {
    selector: (state) => ({
      clIncludeIfAlreadyInReminder: state.clIncludeIfAlreadyInReminder,
    }),
  }
);
export const useSuggestedReminder = createHook(RemindersCreateDialogStore, {
  selector: (state) => ({ suggestedReminder: state.suggestedReminder }),
});
export const useCreateReminderDialog = createHook(RemindersCreateDialogStore, {
  selector: (state) => ({ createReminderDialog: state.createReminderDialog }),
});

export const useCreateReminderStatus = createHook(RemindersCreateDialogStore, {
  selector: (state) => ({ createReminderStatus: state.createReminderStatus }),
});

export const usePopulateCreateReminderDataStatus = createHook(
  RemindersCreateDialogStore,
  {
    selector: (state) => ({
      populateCreateReminderDataStatus: state.populateCreateReminderDataStatus,
    }),
  }
);

export const useCreateReminderSort = createHook(RemindersCreateDialogStore, {
  selector: (state) => ({ createReminderSort: state.createReminderSort }),
});

export const useInsurersCountryCode = createHook(RemindersCreateDialogStore, {
  selector: (state) => ({ insurersCountryCode: state.clInsurersCountryCode }),
});
