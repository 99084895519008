import React from "react";
import { useTranslation } from "react-i18next";

interface Props {
  value: boolean;
}

const YesNoText = ({ value }: Props) => {
  const { t } = useTranslation();

  return value ? t("greco.yes") : t("greco.no");
};

export default YesNoText;
