import { Setting } from "@greco/components/dist/types/types";
import {
  createStore,
  createHook,
  createActionsHook,
  createStateHook,
} from "react-sweet-state";
import { ExpandedColumn } from "types/columns";
import { LoadStatus, Notification } from "types/types";
import { columns } from "../pages/PaymentPlan/columns";

import {
  createSetting,
  deleteSetting,
  loadSettings,
  loadPaymentPlans,
  setStoreValue,
  updateSetting,
  loadPaymentPlansForReport,
  searchClients,
  searchInsurers,
  setStoreValues,
  getClientSaldo,
  synchronizeGos,
} from "./PaymentPlanPageStoreActions";
import moment from "moment";
import { setNotificationMessage } from "./AppStoreActions";

export type StoreState = {
  notifications: Notification[];

  settings: Setting[];
  settingsLoadStatus: LoadStatus;
  searchTerm: string;
  showInvalidated: boolean;
  rowItems: any[];
  xlsRowItems: any[];
  paymentPlansLoadStatus: LoadStatus;
  rowSelectionMap: { [key: number]: boolean };
  sort?: {
    key: string;
    dir: string;
  };
  skip: number;
  top?: number;
  cols: ExpandedColumn[];
  selectedViewColumns: ExpandedColumn[];
  selectedViewId: number;
  selectedView: any;
  tempColumns: ExpandedColumn[];
  count: number;
  areAnyColumnsFiltered: boolean;
  columnOptionsSettings: Setting[];

  searchClientsSelectedItem: any;

  searchInsurersSelectedItem: any;

  getClientSaldoStatus: LoadStatus;
  searchIsBrokerPremiumCollectionItem: "all" | "yes" | "no";
  gosLastSyncTime: string;
  timestamp: Date;
  fromBookingDateFilter: Date;
  toBookingDateFilter: Date;
  selectAllValues: { [key: string]: boolean };
};

// This is the value of the store on initialisation
const initialState: StoreState = {
  notifications: [],

  settings: [],
  settingsLoadStatus: "none",
  searchTerm: "",
  rowItems: [],
  xlsRowItems: [],
  paymentPlansLoadStatus: "none",
  rowSelectionMap: {},
  selectAllValues: {},
  gosLastSyncTime: null,
  sort: {
    key: "",
    dir: "",
  },
  showInvalidated: false,
  skip: 0,
  top: 200,
  cols: columns,
  selectedViewColumns: null,
  selectedViewId: null,
  selectedView: null,
  tempColumns: columns,
  count: 0,
  areAnyColumnsFiltered: false,
  columnOptionsSettings: [],

  searchClientsSelectedItem: null,
  searchInsurersSelectedItem: null,
  searchIsBrokerPremiumCollectionItem: "all",

  getClientSaldoStatus: "none",

  fromBookingDateFilter: moment()
    .subtract(3, "months")
    .add(15, "days")
    .startOf("day")
    .toDate(),
  toBookingDateFilter: moment().endOf("day").add(15, "days").toDate(),
  timestamp: new Date(),
};

// All the actions that mutate the store
const actions = {
  setPaymentPlanStoreValue: setStoreValue,
  setPaymentPlanStoreValues: setStoreValues,
  loadSettings: loadSettings,
  loadPaymentPlans: loadPaymentPlans,
  loadPaymentPlansForReport: loadPaymentPlansForReport,
  createSetting: createSetting,
  deleteSetting: deleteSetting,
  updateSetting: updateSetting,
  searchClients: searchClients,
  searchInsurers: searchInsurers,
  getClientSaldo: getClientSaldo,
  synchronizeGos: synchronizeGos,
  setNotificationMessage: setNotificationMessage,
};

const PaymentPlanPageStore = createStore({
  initialState,
  actions,
  name: "PaymentPlanPageStore",
});
export const usePaymentPlanPageStore = createHook(PaymentPlanPageStore);
export const usePaymentPlanPageStoreState =
  createStateHook(PaymentPlanPageStore);
export const usePaymentPlanPageStoreActions =
  createActionsHook(PaymentPlanPageStore);
