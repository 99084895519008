import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppStoreState } from "store/AppStore";
import { useClientBalancePageStore } from "store/ClientBalancePageStore";

import { VSpace } from "components/Spacer";
import GrecoReactGrid, {
  standardCellTypeHandlers,
} from "components/grecoreactgrid/GrecoReactGrid";
import DatePickerCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/DatePickerCellTypeHandler";
import { infoEntriesColumnConfig } from "./infoEntriesColumnConfig";
import SortableHeaderCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/SortableHeaderCellTypeHandler";
import TaxonomyCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/TaxonomyCellTypeHandler";
import CustomTextCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/CustomTextCellTypeHandler";
import CustomNumberCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/CustomNumberCellTypeHandler";
import { useTheme } from "@emotion/react";
import SumHeaderCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/SumHeaderCellTypeHandler";
import CustomCheckmarkCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/CustomCheckmarkCellTypeHandler";
import OrdinalNumberCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/OrdinalNumberCellTypeHandler";

const InfoEntriesGrid = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const { taxonomy } = useAppStoreState();
  const clientBalancePageStore = useClientBalancePageStore();
  const [
    {
      infoSettledPayments,
      infoSettledPaymentsMetadataMap,
      infoClientBalancesSort,
    },
    { setClientBalancePageStoreValue },
  ] = clientBalancePageStore;

  // useEffect(() => {
  //   if (!infoSettledPaymentPlans) {
  //     return;
  //   }
  //   const totalInfoAmount = infoSettledPaymentPlans.reduce(
  //     (acc, el, currentIndex, array) => {
  //       if (currentIndex !== array.length) {
  //         acc = acc + (el.suggestedPaymentAmount ?? 0);
  //       }
  //       return acc;
  //     },
  //     0
  //   );
  //   setBankStatementsStoreValues({
  //     clTotal: totalInfoAmount,
  //   });
  // }, [clUnsettledPaymentPlans]);

  const handleChanges = (
    updatedRows: any[],
    updatedRowsMetadataMap: { [key: number]: any }
  ) => {
    // setBankStatementsStoreValues({
    //   clUnsettledPaymentPlans: infoSettledPaymentPlans.map((entry) => {
    //     const updatedEntry = updatedRows.find(
    //       (row) => row.paymentPlanEntryId === entry.paymentPlanEntryId
    //     );
    //     if (updatedEntry) {
    //       return updatedEntry;
    //     } else {
    //       return entry;
    //     }
    //   }),
    //   clUnsettledPaymentPlanMetadataMap: updatedRowsMetadataMap,
    // });
  };

  const cellTypeHandlers = {
    ...standardCellTypeHandlers,
    taxonomy: new TaxonomyCellTypeHandler(),
    datepicker: new DatePickerCellTypeHandler(),
    sortableheader: new SortableHeaderCellTypeHandler(),
    sumheader: new SumHeaderCellTypeHandler(),
    customnumber: new CustomNumberCellTypeHandler(),
    customtext: new CustomTextCellTypeHandler(),
    customcheckmark: new CustomCheckmarkCellTypeHandler(),
    ordinalnumber: new OrdinalNumberCellTypeHandler(),
  };

  return (
    <div style={{ height: "350px", overflow: "auto" }}>
      <div
        style={{
          width: "calc(100% - 5px)",
          height: "calc(100% - 30px)",
          overflow: "scroll",
        }}
      >
        {infoSettledPayments.filter((el) => !el.isDeleted).length > 0 ? (
          <GrecoReactGrid
            rows={infoSettledPayments}
            columns={infoEntriesColumnConfig}
            idColumn={"paymentId"}
            rowsMetadataMap={infoSettledPaymentsMetadataMap}
            handleChanges={handleChanges}
            cellTypeHandlers={cellTypeHandlers}
            keyColumn="paymentId"
            stickyColumns={{
              left: 2,
              top: 1,
              bottom: 1,

              right: 2,
            }}
            context={{
              taxonomy: taxonomy,
              t: t,
              clientBalancePageStore: clientBalancePageStore,
              theme: theme,
              sort: infoClientBalancesSort,
              setSort: (sort: any) => {
                setClientBalancePageStoreValue("infoClientBalancesSort", sort);
              },
            }}
            sort={infoClientBalancesSort}
          />
        ) : null}
      </div>
      <VSpace height={25} />
    </div>
  );
};

export default InfoEntriesGrid;
