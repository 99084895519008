import { AsyncSelect } from "@greco/components";
import debounce from "debounce-promise";
import React from "react";
import { useTranslation } from "react-i18next";

import { searchClients } from "api/api";
import { usePaymentPageStore } from "store/PaymentPageStore";
import { useParams } from "react-router-dom";

const SearchClients = () => {
  const { t } = useTranslation();
  const { clientId } = useParams() as any;
  const [{ searchClientsSelectedItem }, { setPaymentStoreValue }] =
    usePaymentPageStore();
  const isDisabled = !!clientId;

  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
    }),
  };

  return (
    <>
      <AsyncSelect
        id="clientsSearch"
        loadOptions={debounce(async (input) => {
          const trimmedInput = input.trim();
          if (trimmedInput.length === 0) return [];
          const clients = await searchClients(trimmedInput);
          return clients.data.map((c) => {
            return {
              label:
                c.name + (c.internalNumber ? ` (${c.internalNumber})` : ""),
              value: c,
            };
          });
        }, 300)}
        style={{ zIndex: 9999 }}
        styles={customStyles}
        clearable
        placeholder={t("pct.searchClients.label")}
        noOptionsMessage={() => t("greco.noResults")}
        // styles={getSelectStyles(!!field.error, theme)}
        isClearable
        isDisabled={isDisabled}
        loadingMessage={() => t("greco.searching")}
        value={searchClientsSelectedItem}
        onChange={(value) => {
          setPaymentStoreValue("searchClientsSelectedItem", value);
        }}
      />
    </>
  );
};

export default SearchClients;
