import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
} from "@fluentui/react-components";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  useReminderEntries,
  useRemindersStoreActions,
  useSearchClientsSelectedItem,
} from "store/RemindersStore";

import CreateReminderButton from "./CreateReminderButton";
import CreateReminderMenubar from "./CreateReminderMenubar";
import {
  useCreateReminderDialog,
  useCreateReminderStatus,
  usePopulateCreateReminderDataStatus,
  useRemindersCreateDialogStoreActions,
} from "store/RemindersCreateDialogStore";

export const CreateReminderDialog = () => {
  const { setRemindersCreateDialogStoreValues } =
    useRemindersCreateDialogStoreActions();

  const [{ searchClientsSelectedItem }] = useSearchClientsSelectedItem();

  const [{ populateCreateReminderDataStatus }] =
    usePopulateCreateReminderDataStatus();

  const [{ createReminderDialog }] = useCreateReminderDialog();

  const [{ createReminderStatus }] = useCreateReminderStatus();

  const [{ reminderEntries }] = useReminderEntries();
  const { t } = useTranslation();

  const isLoading = useMemo(() => {
    return (
      createReminderStatus === "loading" ||
      populateCreateReminderDataStatus === "loading"
    );
  }, [createReminderStatus, populateCreateReminderDataStatus]);

  useEffect(() => {
    const reminder = reminderEntries.find((el) => {
      return el.reminderEntryId === createReminderDialog.reminderId;
    });
    setRemindersCreateDialogStoreValues({
      clReminder: reminder,
    });
  }, [reminderEntries, createReminderDialog.reminderId]);

  return (
    <Dialog open={createReminderDialog.isOpen}>
      <DialogSurface style={{ maxWidth: "96%", transform: "none" }}>
        <DialogBody>
          <DialogTitle>
            {t("pct.createReminder.label")} -{" "}
            {searchClientsSelectedItem.value.name} (
            {searchClientsSelectedItem.value.internalNumber})
          </DialogTitle>
          <DialogContent>
            <CreateReminderMenubar />
          </DialogContent>
          <DialogActions>
            <Button
              appearance="secondary"
              disabled={isLoading}
              onClick={() => {
                setRemindersCreateDialogStoreValues({
                  createReminderDialog: {
                    isOpen: false,
                    ReminderEntry: null,
                    reminder: null,
                  },
                  clInsurersCountryCode: null,
                  clUnsettledPaymentPlans: [],
                  clUnsettledPaymentPlanMetadataMap: {},
                  clTotal: [0, 0],
                  clReminder: null,
                  clInsurersSelectedItem: null,
                  clSearchTerm: "",
                  clServiceSegmentCode: null,
                  clComment: "",
                });
              }}
            >
              {t("pct.close.label")}
            </Button>
            <CreateReminderButton />
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};
