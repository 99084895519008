import { CheckboxCell, CheckboxCellTemplate } from "@silevis/reactgrid";
import { ICellTypeHandler } from "../ICellTypeHandler";

class CheckboxCellTypeHandler implements ICellTypeHandler {
  cellType = "checkbox";
  isCustom = false;
  instance = new CheckboxCellTemplate();
  determineNewValue = (change: any) => {
    return (change.newCell as CheckboxCell).checked;
  };
  getEntryRow = (
    entry: any,
    columnConfig: any,
    rowsMetadataMap: any,
    keyColumn: string,
    context: {
      [key: string]: any;
    }
  ) => {
    return {
      type: "checkbox",
      checked: entry[columnConfig.columnId],
    };
  };
}

export default CheckboxCellTypeHandler;
