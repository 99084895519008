import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useAppStoreState } from "store/AppStore";
import Select from "react-select";
import { useClientBalancePageStore } from "store/ClientBalancePageStore";

const SearchServiceSegments = () => {
  const { t } = useTranslation();
  const { clientId } = useParams() as any;
  const [
    { clServiceSegmentsSelectedItem, clearClientSaldoStatus },
    { setClientBalancePageStoreValue },
  ] = useClientBalancePageStore();
  const { taxonomy } = useAppStoreState();
  const taxonomyKey = "ServiceSegment";

  const options = useMemo(() => {
    if (!taxonomy || !taxonomy[taxonomyKey]) return [];
    const options = taxonomy[taxonomyKey]?.items
      .map((tax) => {
        return {
          value: tax.id,
          label: t(tax.code),
        };
      })
      .sort((a, b) => a.label.localeCompare(b.label));

    return options;
  }, [t, taxonomy, taxonomyKey]);
  const isDisabled = !!clientId || clearClientSaldoStatus === "loading";
  const { appState } = useAppStoreState();

  const customStyles = {
    menu: (baseStyles, state) => ({
      ...baseStyles,
      backgroundColor: appState.darkMode ? "#222222" : "white", // Or any desired color
      color: appState.darkMode ? "white" : "#222222", // Or any desired color
    }),
    control: (baseStyles, state) => ({
      ...baseStyles,
      // minHeight: "30px", // reduce the height
      // height: "30px",
      // padding: "0 6px",

      backgroundColor: appState.darkMode ? "#222222" : "white", // Or any desired color
      color: appState.darkMode ? "white" : "white", // Or any desired color
    }),
    valueContainer: (provided) => ({
      ...provided,
      // height: "30px",
      // padding: "0 6px",
      // alignItems: "center", // vertically center the text
    }),
    input: (baseStyles, state) => ({
      ...baseStyles,
      color: appState.darkMode ? "white" : "#222222",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: appState.darkMode ? "white" : "#222222",
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? data.color
          : isFocused
          ? appState.darkMode
            ? "#000000"
            : "#eeeeee"
          : undefined,
        color: isDisabled
          ? "#ccc"
          : isSelected
          ? appState.darkMode
            ? "white"
            : "#222222"
          : appState.darkMode
          ? "white"
          : "#222222",
        cursor: isDisabled ? "not-allowed" : "default",

        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled
            ? isSelected
              ? "yellow"
              : appState.darkMode
              ? "white"
              : "white"
            : undefined,
        },
      };
    },
  };

  return (
    <div>
      <Select
        isMulti
        options={options}
        id="serviceSegmentsSearch"
        styles={customStyles}
        menuPlacement={"auto"}
        placeholder={t("pct.searchServiceSegments.label")}
        noOptionsMessage={() => t("greco.noResults")}
        isClearable
        isDisabled={isDisabled}
        loadingMessage={() => t("greco.searching")}
        value={clServiceSegmentsSelectedItem}
        onChange={(value) => {
          setClientBalancePageStoreValue(
            "clServiceSegmentsSelectedItem",
            value
          );
        }}
      />
    </div>
  );
};

export default SearchServiceSegments;
