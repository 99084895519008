import { Button } from "@fluentui/react-components";
import { ArrowDownloadRegular } from "@fluentui/react-icons";
import {
  Cell,
  CellTemplate,
  Compatible,
  Uncertain,
  getCellProperty,
} from "@silevis/reactgrid";
import * as React from "react";

export interface DownloadCell extends Cell {
  type: "download";
  link?: string;
  isDisabled?: boolean;
  selected?: boolean;
  highlighted?: boolean;
}

export class DownloadCellTemplate implements CellTemplate<DownloadCell> {
  getCompatibleCell(
    uncertainCell: Uncertain<DownloadCell>
  ): Compatible<DownloadCell> {
    let link: string | undefined;
    try {
      link = getCellProperty(uncertainCell, "link", "string");
    } catch {
      link = undefined;
    }

    let isDisabled = true;
    try {
      isDisabled = getCellProperty(uncertainCell, "isDisabled", "boolean");
    } catch {
      isDisabled = false;
    }
    let selected = false;
    try {
      selected = getCellProperty(uncertainCell, "selected", "boolean");
    } catch {
      selected = false;
    }
    let highlighted: boolean | undefined;
    try {
      highlighted = getCellProperty(uncertainCell, "highlighted", "boolean");
    } catch {
      highlighted = false;
    }
    // let inputValue: string | undefined;
    // try {
    //   inputValue = getCellProperty(uncertainCell, "inputValue", "string");
    // } catch {
    //   inputValue = undefined;
    // }

    const text = link;
    return {
      ...uncertainCell,
      link,
      text,
      value: NaN,
      isDisabled,
      selected,
      highlighted,
      // isOpen,
      // inputValue,
    };
  }

  getClassName(cell: Compatible<DownloadCell>, isInEditMode: boolean): string {
    return `${
      (cell.className ? cell.className : "") +
      (cell.selected ? " rg-selected" : "") +
      (cell.highlighted ? " highlighted-selected-row" : "")
    }`;
  }

  render(
    cell: Compatible<DownloadCell>,
    isInEditMode: boolean,
    onCellChanged: (cell: Compatible<DownloadCell>, commit: boolean) => void
  ): React.ReactNode {
    return <DownloadCell cell={cell} />;
  }
}

interface DIProps {
  cell: Record<string, any>;
}

const DownloadCell: React.FC<DIProps> = ({ cell }) => {
  return (
    <div style={{ width: "100%", textAlign: "center" }}>
      {cell.link ? (
        <Button
          appearance="subtle"
          size="small"
          icon={<ArrowDownloadRegular />}
          onClick={(e) => {
            window.open(cell.link, "_blank");
          }}
        ></Button>
      ) : null}
    </div>
  );
};
