import {
  Cell,
  CellTemplate,
  Compatible,
  Uncertain,
  UncertainCompatible,
  getCellProperty,
  keyCodes,
} from "@silevis/reactgrid";
import * as React from "react";
import { Checkmark20Regular, Dismiss20Regular } from "@fluentui/react-icons";
import { formatNumber } from "utils/number";

// NOTE: all modules imported below may be imported from '@silevis/reactgrid'

export interface InfoNumberCell extends Cell {
  type: "infonumber";
  editable?: boolean;
  selected?: boolean;
  highlighted?: boolean;
  value: number;
  checkedText?: string;
  uncheckedText?: string;
  onChecked: (checked: boolean) => void;
}

export class InfoNumberCellTemplate implements CellTemplate<InfoNumberCell> {
  getCompatibleCell(
    uncertainCell: Uncertain<InfoNumberCell>
  ): Compatible<InfoNumberCell> {
    let editable: boolean | undefined;
    try {
      editable = getCellProperty(uncertainCell, "editable", "boolean");
    } catch {
      editable = true;
    }
    let selected: boolean | undefined;
    try {
      selected = getCellProperty(uncertainCell, "selected", "boolean");
    } catch {
      selected = true;
    }
    let highlighted: boolean | undefined;
    try {
      highlighted = getCellProperty(uncertainCell, "highlighted", "boolean");
    } catch {
      highlighted = false;
    }
    let value: number | undefined;
    try {
      value = getCellProperty(uncertainCell, "value", "number");
    } catch {
      value = null;
    }

    const text = value + "";
    return {
      ...uncertainCell,
      value: value,
      onChecked: uncertainCell.onChecked,
      editable,
      selected,
      highlighted,
      text,
    };
  }

  getClassName(cell: Compatible<InfoNumberCell>): string {
    return (
      (cell.className ? cell.className : "") +
      (cell.selected ? " rg-selected" : "") +
      (cell.highlighted ? " highlighted-selected-row" : "")
    );
  }

  render(
    cell: Compatible<InfoNumberCell>,
    isInEditMode: boolean,
    onCellChanged: (cell: Compatible<InfoNumberCell>, commit: boolean) => void
  ): React.ReactNode {
    return cell.value ? (
      <span style={{ textAlign: "right", width: "100%" }}>
        {formatNumber(cell.value, 2, 2)}
      </span>
    ) : null;
  }
}
