import React from "react";

import { usePaymentPageStore } from "store/PaymentPageStore";
import { Button } from "@fluentui/react-components";
import { ArrowClockwiseFilled } from "@fluentui/react-icons";
import { useAppStore } from "store/AppStore";

export const RefreshButton = () => {
  const [{ paymentsLoadStatus }, { loadPayments }] = usePaymentPageStore();
  const loading = paymentsLoadStatus === "loading";
  const [{ updatedGrecoCompanies }] = useAppStore();

  return (
    <Button
      disabled={loading}
      appearance="subtle"
      icon={<ArrowClockwiseFilled fontSize={24} />}
      onClick={() => {
        loadPayments(updatedGrecoCompanies);
      }}
    ></Button>
  );
};
