import { TextCell, TextCellTemplate } from "@silevis/reactgrid";
import { ICellTypeHandler } from "../ICellTypeHandler";
import { SortableHeaderCellTemplate } from "../customcells/SortableHeaderCellTemplate";

class SortableHeaderCellTypeHandler implements ICellTypeHandler {
  cellType = "sortableheader";
  isCustom = true;
  instance = new SortableHeaderCellTemplate();
  determineNewValue = (change: any) => {
    return (change.newCell as TextCell).text;
  };
  getEntryRow = (
    entry: any,
    columnConfig: any,
    rowsMetadataMap: any,
    keyColumn: string,
    context: {
      [key: string]: any;
    }
  ) => {
    const { sort, setSort, t } = context;

    return {
      type: "sortableheader",
      text: t(columnConfig.label),
      tooltip: t(columnConfig.label.replace(".label", ".tooltip")),
      selectable: false,
      key: columnConfig.columnId,
      sortFn: (key, dir) => {
        setSort({
          key: columnConfig.columnId,
          dir: dir,
        });
      },
      sort: sort,
      sortable: columnConfig.sortable,
      selected: rowsMetadataMap[entry[keyColumn]]?.selected ?? false,
      highlighted: rowsMetadataMap[entry[keyColumn]]?.highlighted ?? false,
    };
  };
}

export default SortableHeaderCellTypeHandler;
