import React from "react";
import { useTranslation } from "react-i18next";

import { usePaymentPageStore } from "store/PaymentPageStore";
import { Button } from "@fluentui/react-components";
import { ArrowClockwiseFilled } from "@fluentui/react-icons";
import { useAppStore } from "store/AppStore";
import { useClientBalancePageStore } from "store/ClientBalancePageStore";

export const RefreshButton = () => {
  const [{ clientBalancesLoadStatus }, { loadClientBalances }] =
    useClientBalancePageStore();
  const loading = clientBalancesLoadStatus === "loading";
  const [{ taxonomy, updatedGrecoCompanies, grecoCompanies }] = useAppStore();
  const { t } = useTranslation();

  return (
    <Button
      disabled={loading}
      appearance="subtle"
      icon={<ArrowClockwiseFilled fontSize={24} />}
      onClick={() => {
        loadClientBalances({
          grecoCompanyNumbers: updatedGrecoCompanies,
          t,
          grecoCompanies,
          taxonomy,
        });
      }}
    ></Button>
  );
};
