import { Button, Tooltip } from "@fluentui/react-components";
import {
  ContractDownLeftFilled,
  ContractDownLeftRegular,
  DeleteDismissRegular,
  DocumentTableRegular,
} from "@fluentui/react-icons";
import {
  Cell,
  CellTemplate,
  Compatible,
  Uncertain,
  getCellProperty,
} from "@silevis/reactgrid";
import * as React from "react";

export interface ReminderOptionsCell extends Cell {
  type: "reminderoptions";
  link?: string;
  link2?: string;
  deleteFn?: any;
  saveFn?: any;
  cancelFn?: any;
  addEntryFn?: any;
  exportToExcelFn?: any;
  resolvementSuggestFn?: any;
  rowId?: any;
  edited?: boolean;
  selected?: boolean;
  highlighted?: boolean;
  isDisabled?: boolean;
  t: any;
}

export class ReminderOptionsCellTemplate
  implements CellTemplate<ReminderOptionsCell>
{
  getCompatibleCell(
    uncertainCell: Uncertain<ReminderOptionsCell>
  ): Compatible<ReminderOptionsCell> {
    let t: any;
    t = getCellProperty(uncertainCell, "t", "function");

    let link: string | undefined;
    try {
      link = getCellProperty(uncertainCell, "link", "string");
    } catch {
      link = undefined;
    }

    let link2: string | undefined;
    try {
      link2 = getCellProperty(uncertainCell, "link2", "string");
    } catch {
      link2 = undefined;
    }

    let saveFn: any;

    try {
      saveFn = getCellProperty(uncertainCell, "saveFn", "function");
    } catch {
      saveFn = undefined;
    }

    let exportToExcelFn: any;

    try {
      exportToExcelFn = getCellProperty(
        uncertainCell,
        "exportToExcelFn",
        "function"
      );
    } catch {
      exportToExcelFn = undefined;
    }

    let addEntryFn: any;

    try {
      addEntryFn = getCellProperty(uncertainCell, "addEntryFn", "function");
    } catch {
      addEntryFn = undefined;
    }

    let cancelFn: any;

    try {
      cancelFn = getCellProperty(uncertainCell, "cancelFn", "function");
    } catch {
      cancelFn = undefined;
    }

    let resolvementSuggestFn: any;
    try {
      resolvementSuggestFn = getCellProperty(
        uncertainCell,
        "resolvementSuggestFn",
        "function"
      );
    } catch {
      resolvementSuggestFn = undefined;
    }

    let deleteFn: any;

    try {
      deleteFn = getCellProperty(uncertainCell, "deleteFn", "function");
    } catch {
      deleteFn = undefined;
    }

    let rowId: any;

    try {
      rowId = getCellProperty(uncertainCell, "rowId", "number");
    } catch {
      rowId = undefined;
    }

    let isDisabled = true;
    try {
      isDisabled = getCellProperty(uncertainCell, "isDisabled", "boolean");
    } catch {
      isDisabled = false;
    }

    let edited = false;
    try {
      edited = getCellProperty(uncertainCell, "edited", "boolean");
    } catch {
      edited = false;
    }

    let selected = false;
    try {
      selected = getCellProperty(uncertainCell, "selected", "boolean");
    } catch {
      selected = false;
    }
    let highlighted: boolean | undefined;
    try {
      highlighted = getCellProperty(uncertainCell, "highlighted", "boolean");
    } catch {
      highlighted = false;
    }
    // let inputValue: string | undefined;
    // try {
    //   inputValue = getCellProperty(uncertainCell, "inputValue", "string");
    // } catch {
    //   inputValue = undefined;
    // }

    const text = link;
    return {
      ...uncertainCell,
      link,
      link2,
      text,
      rowId,
      deleteFn,
      cancelFn,
      resolvementSuggestFn,
      saveFn,
      exportToExcelFn,
      addEntryFn,
      value: NaN,
      isDisabled,
      edited,
      selected,
      highlighted,
      t, // isOpen,
      // inputValue,
    };
  }

  getClassName(
    cell: Compatible<ReminderOptionsCell>,
    isInEditMode: boolean
  ): string {
    return `${
      (cell.className ? cell.className : "") +
      (cell.selected ? " rg-selected" : "") +
      (cell.highlighted ? " highlighted-selected-row" : "")
    }`;
  }

  render(
    cell: Compatible<ReminderOptionsCell>,
    isInEditMode: boolean,
    onCellChanged: (
      cell: Compatible<ReminderOptionsCell>,
      commit: boolean
    ) => void
  ): React.ReactNode {
    return <OptionsCell cell={cell} />;
  }
}

interface DIProps {
  cell: Record<string, any>;
}

const OptionsCell: React.FC<DIProps> = ({ cell }) => {
  return (
    <div style={{ width: "100%", textAlign: "center" }}>
      <>
        {cell.link ? (
          <Tooltip
            content={cell.t("pct.reminderDocumentPath.label")}
            relationship="label"
          >
            <Button
              appearance="subtle"
              size="small"
              icon={<ContractDownLeftFilled />}
              onClick={(e) => {
                window.open(cell.link, "_blank");
              }}
            ></Button>
          </Tooltip>
        ) : null}
        {cell.link2 ? (
          <Tooltip
            content={cell.t("pct.additionalReminderDocumentPath.label")}
            relationship="label"
          >
            <Button
              appearance="subtle"
              size="small"
              icon={<ContractDownLeftRegular />}
              onClick={(e) => {
                window.open(cell.link2, "_blank");
              }}
            ></Button>
          </Tooltip>
        ) : null}

        {cell.deleteFn ? (
          <Tooltip
            content={cell.t("pct.deleteReminder.label")}
            relationship="label"
          >
            <Button
              appearance="subtle"
              size="small"
              icon={<DeleteDismissRegular />}
              onClick={(e) => {
                cell.deleteFn(cell.rowId);
              }}
            ></Button>
          </Tooltip>
        ) : null}

        {cell.selected ? (
          <Tooltip content={cell.t("greco.exportExcel")} relationship="label">
            <Button
              appearance="subtle"
              size="small"
              icon={<DocumentTableRegular />}
              onClick={(e) => {
                cell.exportToExcelFn();
              }}
            ></Button>
          </Tooltip>
        ) : null}
      </>
    </div>
  );
};
