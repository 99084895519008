import { ThemeProvider } from "@greco/components";
import LoadingOverlay from "components/LoadingOverlay";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SplitPane, { Pane } from "split-pane-react";
import "split-pane-react/esm/themes/default.css";
import { useAppStore } from "store/AppStore";
import {
  useRemindersStore,
  useRemindersStoreState,
} from "store/RemindersStore";
import ReminderEntryGrid from "./ReminderEntriesGrid/ReminderEntryGrid";
import ReminderGrid from "./RemindersGrid/ReminderGrid";
import Navigation from "./Navigation";
import i18n from "i18n";

const Reminders = () => {
  const [sizes, setSizes] = useState([100, "30%", "auto"]);
  const [{ appState }, { setAppStoreValue }] = useAppStore();
  const { t } = useTranslation();
  const [{ searchClientsSelectedItem }, { setRemindersStoreValues }] =
    useRemindersStore();
  const {
    remindersLoadStatus,
    createReminderStatus,
    importReminderStatus,
    deleteReminderStatus,
    deleteReminderEntryStatus,
    reminderSaveStatus,
    reminderEntrySaveStatus,
    resolvementLoadStatus,
  } = useRemindersStoreState();

  const isLoading =
    createReminderStatus === "loading" ||
    remindersLoadStatus === "loading" ||
    importReminderStatus === "loading" ||
    deleteReminderStatus === "loading" ||
    deleteReminderEntryStatus === "loading" ||
    reminderSaveStatus === "loading" ||
    reminderEntrySaveStatus === "loading" ||
    resolvementLoadStatus === "loading";

  useEffect(() => {
    setAppStoreValue(
      "headerTitle",
      t("pct.premiumCollectionTool.label") + " - " + t("pct.reminders.label")
    );
  }, [i18n.language]);

  useEffect(() => {
    if (!searchClientsSelectedItem) {
      setRemindersStoreValues({
        suggestedReminder: null,
        reminders: [],
        reminderEntries: [],
        reminderMetadataMap: {},
        reminderEntryMetadataMap: {},
      });
    }
  }, [searchClientsSelectedItem]);

  return (
    <>
      <ThemeProvider isDarkMode={appState.darkMode}>
        <Navigation />
      </ThemeProvider>

      <SplitPane
        split="horizontal"
        sizes={sizes}
        onChange={setSizes}
        resizerSize={5}
        sashRender={() => <></>}
        className="split-pane"
        style={{
          height: "calc(100% - 140px)",
        }}
      >
        <Pane minSize={"7%"} maxSize="93%">
          <ReminderGrid />
        </Pane>
        <Pane minSize={"7%"} maxSize="93%">
          <ReminderEntryGrid />
        </Pane>
      </SplitPane>

      {isLoading ? <LoadingOverlay /> : null}
    </>
  );
};

export default Reminders;
