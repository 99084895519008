import React, { useEffect } from "react";
import { SearchBox, ISearchBoxStyles } from "@fluentui/react";
import useDebouncedState from "../../../components/useDebouncedState";
import { useClientBalancePageStore } from "store/ClientBalancePageStore";
import { useTranslation } from "react-i18next";

type Props = {
  onChange: (value: string) => void;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
} & {
  [key: string]: any;
};

const searchBoxStyles: Partial<ISearchBoxStyles> = {
  root: {
    height: 30,
    width: 300,
    marginTop: 18,
    border: "0px none",
    selectors: {
      "&::after": {
        borderWidth: 1,
      },
    },
  },
};

export const Search = (props: Props) => {
  const [searchTerm, setSearchTerm, debouncedSearchTerm] = useDebouncedState(
    props.value,
    500
  );
  const { t } = useTranslation();

  const [{ clSearchTerm, clHightlightCount }, {}] = useClientBalancePageStore();

  useEffect(() => {
    // Use debouncedSearchTerm here for API calls or other side effects
    onChange(debouncedSearchTerm);
  }, [debouncedSearchTerm]);

  const handleChange = (value) => {
    setSearchTerm(value);
  };

  const { onChange, placeholder, disabled } = props;
  return (
    <div style={{ display: "flex" }}>
      <SearchBox
        disabled={disabled}
        styles={searchBoxStyles}
        placeholder={placeholder}
        value={searchTerm}
        onChange={(_, value) => handleChange(value || "")}
      />
      {clSearchTerm && clSearchTerm.length > 0 ? (
        <div style={{ paddingLeft: "10px", paddingTop: "24px" }}>
          ({t("pct.numberOfRows.label", { count: clHightlightCount })})
        </div>
      ) : null}
    </div>
  );
};
