import { ICellTypeHandler } from "components/grecoreactgrid/ICellTypeHandler";
import { BankStatementEntryStatusCellTemplate } from "../customcells/BankStatementEntryStatusCellTemplate";

class BankStatementEntryStatusCellTypeHandler implements ICellTypeHandler {
  cellType = "bankstatemententrystatus";
  isCustom = true;
  instance = new BankStatementEntryStatusCellTemplate();
  determineNewValue = (change: any) => null;
  getEntryRow = (
    entry: any,
    columnConfig: any,
    rowsMetadataMap: any,
    keyColumn: string,
    context: {
      [key: string]: any;
    }
  ) => {
    const { t } = context;
    return {
      type: "bankstatemententrystatus", //,
      rowId: columnConfig.additionalInfo?.rowIdField
        ? entry[columnConfig.additionalInfo.rowIdField]
        : "",

      edited: rowsMetadataMap[entry["bankStatementEntryId"]]["inEditMode"],
      selected: rowsMetadataMap[entry["bankStatementEntryId"]]["selected"],
      isCleared: entry["isCleared"],
      isResolved: entry["clientId"] !== null,
      t,
    };
  };
}

export default BankStatementEntryStatusCellTypeHandler;
