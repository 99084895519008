import { Avatar, Input } from "@fluentui/react-components";
import {
  Cell,
  CellTemplate,
  Compatible,
  Uncertain,
  UncertainCompatible,
  getCellProperty,
  getCharFromKeyCode,
  isAlphaNumericKey,
  isNavigationKey,
  keyCodes,
} from "@silevis/reactgrid";
import * as React from "react";
import { getShortInsurerName, gosLinkTypeToPath } from "utils/utils";

export interface CustomInsurerIconCell extends Cell {
  type: "custominsurericon";
  text: string;
  editable?: boolean;
  placeholder?: string;
  validator?: (text: string) => boolean;
  renderer?: (text: string) => React.ReactNode;
  errorMessage?: string;
  selected?: boolean;
  highlighted?: boolean;
  idField?: string;
}
export class CustomInsurerIconCellTemplate
  implements CellTemplate<CustomInsurerIconCell>
{
  getCompatibleCell(
    uncertainCell: Uncertain<CustomInsurerIconCell>
  ): Compatible<CustomInsurerIconCell> {
    const text = getCellProperty(uncertainCell, "text", "string");
    let placeholder: string | undefined;
    try {
      placeholder = getCellProperty(uncertainCell, "placeholder", "string");
    } catch {
      placeholder = "";
    }
    let editable: boolean | undefined;
    try {
      editable = getCellProperty(uncertainCell, "editable", "boolean");
    } catch {
      editable = true;
    }

    let idField: string | undefined;
    try {
      idField = getCellProperty(uncertainCell, "idField", "string");
    } catch {
      idField = "";
    }

    let selected: boolean | undefined;
    try {
      selected = getCellProperty(uncertainCell, "selected", "boolean");
    } catch {
      selected = true;
    }
    let highlighted: boolean | undefined;
    try {
      highlighted = getCellProperty(uncertainCell, "highlighted", "boolean");
    } catch {
      highlighted = false;
    }
    const value = parseFloat(text); // TODO more advanced parsing for all text based cells
    return {
      ...uncertainCell,
      text,
      value,
      editable,
      selected,
      highlighted,
      placeholder,
      idField,
    };
  }
  update(
    cell: Compatible<CustomInsurerIconCell>,
    cellToMerge: UncertainCompatible<CustomInsurerIconCell>
  ): Compatible<CustomInsurerIconCell> {
    return this.getCompatibleCell({
      ...cell,
      text: cellToMerge.text,
      placeholder: cellToMerge.placeholder,
      editable: cellToMerge.editable,
    });
  }
  handleKeyDown(
    cell: Compatible<CustomInsurerIconCell>,
    keyCode: number,
    ctrl: boolean,
    shift: boolean,
    alt: boolean
  ): { cell: Compatible<CustomInsurerIconCell>; enableEditMode: boolean } {
    const char = getCharFromKeyCode(keyCode, shift);
    if (
      !ctrl &&
      !alt &&
      isAlphaNumericKey(keyCode) &&
      !(shift && keyCode === keyCodes.SPACE) &&
      cell.editable
    )
      return {
        cell: this.getCompatibleCell({
          ...cell,
          text: shift ? char : char.toLowerCase(),
        }),
        enableEditMode: true,
      };
    return {
      cell,
      enableEditMode:
        keyCode === keyCodes.POINTER || keyCode === keyCodes.ENTER,
    };
  }
  getClassName(
    cell: Compatible<CustomInsurerIconCell>,
    isInEditMode: boolean
  ): string {
    const isValid = cell.validator ? cell.validator(cell.text) : true;
    const className =
      (cell.className ? cell.className : "") +
      (cell.selected ? " rg-selected" : "") +
      (cell.highlighted ? " highlighted-selected-row" : "");
    return `${isValid ? "valid" : "rg-invalid"} ${
      cell.placeholder && cell.text === "" ? "placeholder" : ""
    } ${className}`;
  }
  render(
    cell: Compatible<CustomInsurerIconCell>,
    isInEditMode: boolean,
    onCellChanged: (
      cell: Compatible<CustomInsurerIconCell>,
      commit: boolean
    ) => void
  ): React.ReactNode {
    if (!isInEditMode) {
      const isValid = cell.validator ? cell.validator(cell.text) : true;
      const cellText = cell.text || cell.placeholder || "";
      const textToDisplay =
        !isValid && cell.errorMessage ? cell.errorMessage : cellText;

      const gosLink = gosLinkTypeToPath("client", cell.idField);

      const abbreviatedName = getShortInsurerName(cell.idField);

      const displayedName = abbreviatedName ? abbreviatedName : textToDisplay;

      return (
        <span
          className="gos-link"
          onClick={(e) => {
            window.open(gosLink, "_blank");
          }}
        >
          {cell.renderer
            ? cell.renderer(displayedName === null ? "" : displayedName)
            : displayedName === null
            ? ""
            : displayedName}
        </span>
      );
    }
    return (
      <Input
        style={{ width: "100%", fontSize: "1em" }}
        size="small"
        //disabled={}
        ref={(input) => {
          if (input) {
            input.focus();
            input.setSelectionRange(input.value.length, input.value.length);
            input.classList.add("cell-input");
          }
        }}
        defaultValue={cell.text}
        onChange={(e) => {
          if (cell.editable) {
            onCellChanged(
              this.getCompatibleCell({ ...cell, text: e.currentTarget.value }),
              false
            );
          }
        }}
        onBlur={(e) => {
          if (cell.editable) {
            onCellChanged(
              this.getCompatibleCell({ ...cell, text: e.currentTarget.value }),
              (e as any).view?.event?.keyCode !== keyCodes.ESCAPE
            );
          }
        }}
        onCopy={(e) => e.stopPropagation()}
        onCut={(e) => e.stopPropagation()}
        onPaste={(e) => e.stopPropagation()}
        onPointerDown={(e) => e.stopPropagation()}
        placeholder={cell.placeholder}
        onKeyDown={(e) => {
          if (isAlphaNumericKey(e.keyCode) || isNavigationKey(e.keyCode))
            e.stopPropagation();
        }}
      />
    );
  }
}
