import { TextCell } from "@silevis/reactgrid";
import { ICellTypeHandler } from "components/grecoreactgrid/ICellTypeHandler";
import { ReminderStatusCellTemplate } from "../customcells/ReminderStatusCellTemplate";

class ReminderStatusCellTypeHandler implements ICellTypeHandler {
  cellType = "reminderstatus";
  isCustom = true;
  instance = new ReminderStatusCellTemplate();
  determineNewValue = (change: any) => (change.newCell as TextCell).text;
  getEntryRow = (
    entry: any,
    columnConfig: any,
    rowsMetadataMap: any,
    keyColumn: string,
    context: {
      [key: string]: any;
    }
  ) => {
    const { t } = context;
    const remindersStore = context["remindersStore"];
    const [storeState] = remindersStore;
    return {
      type: "reminderstatus", //,
      rowId: columnConfig.additionalInfo?.rowIdField
        ? entry[columnConfig.additionalInfo.rowIdField]
        : "",
      numEntries: storeState["reminderEntries"].filter((e) => {
        return !e.isDeleted && e.reminderId === entry["reminderId"];
      }).length,
      numResolved: storeState["reminderEntries"].filter((e) => {
        return (
          !e.isDeleted &&
          e.clientId !== null &&
          e.reminderId === entry["reminderId"]
        );
      }).length,
      numCleared: storeState["reminderEntries"].filter((e) => {
        return (
          !e.isDeleted && e.isCleared && e.reminderId === entry["reminderId"]
        );
      }).length,
      edited: rowsMetadataMap[entry["reminderId"]]["inEditMode"],
      selected: rowsMetadataMap[entry["reminderId"]]["selected"],
      t,
    };
  };
}

export default ReminderStatusCellTypeHandler;
