import { TextCell } from "@silevis/reactgrid";
import { ICellTypeHandler } from "components/grecoreactgrid/ICellTypeHandler";
import { PaymentOptionsCellTemplate } from "../customcells/PaymentOptionsCellTemplate";

class PaymentOptionsCellTypeHandler implements ICellTypeHandler {
  cellType = "paymentoptions";
  isCustom = true;
  instance = new PaymentOptionsCellTemplate();
  determineNewValue = (change: any) => (change.newCell as TextCell).text;
  getEntryRow = (
    entry: any,
    columnConfig: any,
    rowsMetadataMap: any,
    keyColumn: string,
    context: {
      [key: string]: any;
    }
  ) => {
    const [
      { payments, fromDateFilter, toDateFilter },
      { setPaymentStoreValues },
    ] = context["paymentPageStore"];
    const { t } = context;

    return {
      type: "paymentoptions", //,
      rowId: columnConfig.additionalInfo?.rowIdField
        ? entry[columnConfig.additionalInfo.rowIdField]
        : "",
      invalidateFn: () => {
        setPaymentStoreValues({
          invalidatePaymentsDialog: {
            isOpen: true,
            paymentIds: Object.entries(rowsMetadataMap)
              .filter((e: any) => {
                return e[1].selected;
              })
              .map((e: any) => {
                return Number(e[0]);
              }),
          },
        });
      },
      selected: rowsMetadataMap[entry["paymentId"]]["selected"],
      selectedCount: Object.values(rowsMetadataMap).filter((e: any) => {
        return e.selected;
      }).length,

      t,
    };
  };
}

export default PaymentOptionsCellTypeHandler;
