import { TextCell, TextCellTemplate } from "@silevis/reactgrid";
import {
  CustomTextCell,
  CustomTextCellTemplate,
} from "../customcells/CustomTextCellTemplate";
import { ICellTypeHandler } from "components/grecoreactgrid/ICellTypeHandler";
import { gosLinkTypeToPath } from "utils/utils";

class CustomTextCellTypeHandler implements ICellTypeHandler {
  cellType = "customtext";
  isCustom = true;
  instance = new CustomTextCellTemplate();
  determineNewValue = (change: any) => {
    return (change.newCell as CustomTextCell).text;
  };
  getEntryRow = (
    entry: any,
    columnConfig: any,
    rowsMetadataMap: any,
    keyColumn: string,
    context: {
      [key: string]: any;
    }
  ) => {
    let gosLink = null;
    if (columnConfig.additionalInfo && columnConfig.additionalInfo.gosLink) {
      const linkType = columnConfig.additionalInfo.gosLink.linkType;
      let personType: string = "corporate";
      if (linkType === "client") {
        if (entry[columnConfig.columnId]) {
          if ((entry[columnConfig.columnId] as string).startsWith(" ")) {
            personType = "private";
          }
          if (
            entry["clientInfo"]?.["isCompany"] &&
            entry["clientInfo"]?.["isCompany"] === false
          ) {
            personType = "private";
          }
        }
      }
      const idField = columnConfig.additionalInfo.gosLink.idField;
      gosLink = gosLinkTypeToPath(linkType, entry[idField], personType);
    }
    return {
      type: "customtext",
      text: entry[columnConfig.columnId]
        ? entry[columnConfig.columnId].trim()
        : "",
      editable: rowsMetadataMap[entry[keyColumn]]?.editable ?? true,
      selected: rowsMetadataMap[entry[keyColumn]]?.selected ?? false,
      highlighted: rowsMetadataMap[entry[keyColumn]]?.highlighted ?? false,
      gosLink: gosLink,
    };
  };
}

export default CustomTextCellTypeHandler;
