import { Flex, FlexRight, NavigationBar } from "components/styled";
import React from "react";
import { useClientBalancePageStore } from "store/ClientBalancePageStore";
import { ResultsScore } from "components/ResultsScore";

const ClientBalancePageNavigationSecondLine = () => {
  const [{ clientBalancesMetadataMap }, {}] = useClientBalancePageStore();

  const count = Object.values(clientBalancesMetadataMap).reduce(
    (acc: number, obj: any) => {
      return obj.visible === true ? acc + 1 : acc;
    },
    0
  );
  return (
    <NavigationBar style={{ zIndex: 333 }}>
      <FlexRight>
        <Flex>
          <ResultsScore score={count} />
        </Flex>
      </FlexRight>
    </NavigationBar>
  );
};

export default ClientBalancePageNavigationSecondLine;
