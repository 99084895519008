import {
  Button,
  Menu,
  MenuItemCheckbox,
  MenuList,
  MenuPopover,
  MenuProps,
  MenuTrigger,
} from "@fluentui/react-components";
import React from "react";

import { useAppStore, useAppStoreState } from "store/AppStore";
import { BuildingRegular } from "@fluentui/react-icons";
import { useTranslation } from "react-i18next";

const CompaniesMenu = () => {
  const [{ grecoCompanies }, { setAppStoreValue }] = useAppStore();
  const { t } = useTranslation();

  const checkedValues: string[] = grecoCompanies
    ?.map((company: any) => {
      return company.selected ? company.companyRegisterNumber : null;
    })
    .filter((x) => x !== null);

  const onChange: MenuProps["onCheckedValueChange"] = (
    e,
    { name, checkedItems }
  ) => {
    if (checkedItems.length === 0) return;
    setAppStoreValue(
      "grecoCompanies",
      grecoCompanies?.map((company: any) => {
        return {
          ...company,
          selected: checkedItems.includes(company.companyRegisterNumber),
        };
      })
    );
  };

  const selectedCompanies = grecoCompanies?.filter(
    (company: any) => company.selected
  );

  return (
    <Menu
      hasIcons
      hasCheckmarks
      checkedValues={{ grecoCompanies: checkedValues }}
      onCheckedValueChange={onChange}
      onOpenChange={(e, { open }) => {
        if (!open) {
          setAppStoreValue("updatedGrecoCompanies", grecoCompanies);
        }
      }}
    >
      <MenuTrigger disableButtonEnhancement>
        <Button>
          {selectedCompanies?.length === 1 ? (
            <>{selectedCompanies[0].companyShort}</>
          ) : (
            <>
              {t("pct.grecoCompanies.label")} (
              {grecoCompanies
                ? grecoCompanies?.reduce((acc: number, company: any) => {
                    return company.selected ? acc + 1 : acc;
                  }, 0)
                : 0}
              )
            </>
          )}
        </Button>
      </MenuTrigger>
      <MenuPopover>
        <MenuList>
          {grecoCompanies?.map((company: any) => (
            <MenuItemCheckbox
              key={company.companyRegisterNumber}
              icon={<BuildingRegular />}
              name="grecoCompanies"
              value={company.companyRegisterNumber}
            >
              {company.companyShort}
            </MenuItemCheckbox>
          ))}
        </MenuList>
      </MenuPopover>
    </Menu>
  );
};

export default CompaniesMenu;
