import { DateCell, DateCellTemplate } from "@silevis/reactgrid";
import { ICellTypeHandler } from "../ICellTypeHandler";
import moment from "moment";

class DateCellTypeHandler implements ICellTypeHandler {
  cellType = "date";
  isCustom = false;
  instance = new DateCellTemplate();
  determineNewValue = (change: any) => {
    return (change.newCell as DateCell).date;
  };
  getEntryRow = (
    entry: any,
    columnConfig: any,
    rowsMetadataMap: any,
    keyColumn: string,
    context: {
      [key: string]: any;
    }
  ) => {
    return {
      type: "date",
      date: moment(entry[columnConfig.columnId]).toDate(),
      format: new Intl.DateTimeFormat("de-DE"),
    };
  };
}

export default DateCellTypeHandler;
