import { AsyncSelect } from "@greco/components";
import { searchInsurers } from "api/api";
import debounce from "debounce-promise";
import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { usePaymentPlanPageStore } from "store/PaymentPlanPageStore";
import { getShortInsurerName } from "utils/utils";

const SearchInsurers = () => {
  const { t } = useTranslation();
  const { clientId } = useParams() as any;

  const [
    { searchInsurersSelectedItem, paymentPlansLoadStatus },
    { setPaymentPlanStoreValue },
  ] = usePaymentPlanPageStore();
  const loading = paymentPlansLoadStatus === "loading";

  const isDisabled = !!clientId || loading;
  return (
    <>
      <AsyncSelect
        id="insurersSearch"
        loadOptions={debounce(async (input) => {
          const trimmedInput = input.trim();
          if (trimmedInput.length === 0) return [];
          const dataList = await searchInsurers(trimmedInput);
          let retVal = [];
          dataList.data.forEach((insurer) => {
            let name = insurer.name;
            const shortName = getShortInsurerName(insurer.id + "");
            if (shortName !== "") {
              name = shortName;
              retVal.unshift({
                value: insurer.internalNumber,
                label:
                  name +
                  (insurer.internalNumber
                    ? ` (${insurer.internalNumber})`
                    : ""),
              });
            } else {
              retVal.push({
                value: insurer.internalNumber,
                label:
                  name +
                  (insurer.internalNumber
                    ? ` (${insurer.internalNumber})`
                    : ""),
              });
            }
          });
          return retVal;
        }, 300)}
        clearable
        placeholder={t("pct.searchInsurers.label")}
        noOptionsMessage={() => t("greco.noResults")}
        isClearable
        isDisabled={isDisabled}
        loadingMessage={() => t("greco.searching")}
        value={searchInsurersSelectedItem}
        onChange={(value) => {
          setPaymentPlanStoreValue("searchInsurersSelectedItem", value);
        }}
      />
    </>
  );
};

export default SearchInsurers;
