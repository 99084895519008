import { useState, useEffect, useRef, useCallback } from "react";

function useDebouncedState(initialValue, delay) {
  const [state, setState] = useState(initialValue);
  const [debouncedState, setDebouncedState] = useState(initialValue);

  const timerRef = useRef(null);

  useEffect(() => {
    return () => {
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, []);

  useEffect(() => {
    timerRef.current = setTimeout(() => {
      setDebouncedState(state);
    }, delay);

    return () => {
      clearTimeout(timerRef.current);
    };
  }, [state, delay]);

  return [state, setState, debouncedState];
}

export default useDebouncedState;
