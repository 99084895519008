import { Button } from "@fluentui/react-components";
import { DocumentTableRegular } from "@fluentui/react-icons";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import React from "react";
import { useTranslation } from "react-i18next";
import { usePaymentPageStore } from "store/PaymentPageStore";

type Props = {
  refExcelExport: ExcelExport;
};

export const ExportExcelButton = ({ refExcelExport }: Props) => {
  const { t } = useTranslation();
  const [{ paymentsLoadStatus }] = usePaymentPageStore();

  const save = (component) => {
    if (!component) return;

    const options = component.workbookOptions();
    const data = component.props.data;
    const rows = options.sheets[0].rows;
    if (rows.length === 0) return;
    if (data.length === 0) return;
    for (let index = 1; index < rows.length; index++) {
      const item = data[index - 1];
      const row = rows[index];
      if (row.type === "data" && item["Status"] === "2") {
        row.cells.forEach((cell) => {
          cell.background = "#fed9cc";
        });
      }
    }
    component.save(options);
  };

  return (
    <Button
      appearance="subtle"
      icon={<DocumentTableRegular fontSize={24} />}
      disabled={paymentsLoadStatus === "loading"}
      onClick={() => {
        save(refExcelExport);
      }}
    >
      {t("greco.exportExcel")}
    </Button>
  );
};
