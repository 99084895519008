export interface ColumnConfig {
  columnId: string;
  width: number;
  reorderable: boolean;
  resizable: boolean;
  sortable: boolean;
  label: string;
  cellType: string;
  additionalInfo?: {
    taxonomy?: string;
    list?: string;
    clientAccountIdField?: string;
    clientCodeField?: string;
    clientIdField?: string;
    clientNameField?: string;
    linkField?: string;
    rowIdField?: string;
  };
  excel?: any;
  align?: string;
  summable?: boolean;
}

export const infoEntriesColumnConfig: ColumnConfig[] = [
  {
    columnId: "ordinalNumber",
    width: 50,
    reorderable: false,
    resizable: true,
    label: "",
    cellType: "ordinalnumber",
    sortable: false,
  },
  {
    columnId: "premiumAmount",
    width: 120,
    reorderable: true,
    resizable: true,
    label: "pct.premiumAmount.label",
    sortable: true,
    cellType: "customnumber",
    additionalInfo: undefined,
    summable: false,
  },
  {
    columnId: "currentBalance",
    width: 120,
    reorderable: true,
    resizable: true,
    label: "pct.currentBalance.label",
    sortable: true,
    cellType: "customnumber",
    additionalInfo: undefined,
  },
  // {
  //   columnId: "clientName",
  //   width: 250,
  //   reorderable: true,
  //   resizable: true,
  //   sortable: false,
  //   label: "pct.clientName.label",
  //   cellType: "customtext",
  //   additionalInfo: undefined,
  // },

  {
    columnId: "createdAt",
    width: 120,
    reorderable: true,
    resizable: true,
    label: "pct.createdAt.label",
    sortable: true,
    cellType: "datepicker",
    additionalInfo: undefined,
  },
  {
    columnId: "paymentDate",
    width: 120,
    reorderable: true,
    resizable: true,
    label: "pct.paymentDate.label",
    sortable: true,
    cellType: "datepicker",
    additionalInfo: undefined,
  },
  {
    columnId: "isBankStatementEntryClearancePayment",
    width: 200,
    reorderable: true,
    resizable: true,
    label: "pct.isBankStatementEntryClearancePayment.label",
    sortable: true,
    cellType: "customcheckmark",
    additionalInfo: undefined,
  },
  {
    columnId: "bankStatementStatementCreatedAt",
    width: 200,
    reorderable: true,
    resizable: true,
    label: "pct.StatementCreatedAt.label",
    sortable: true,
    cellType: "datepicker",
    additionalInfo: undefined,
  },

  {
    columnId: "bankStatementEntryPaymentReferenceNumber",
    width: 350,
    reorderable: true,
    resizable: true,
    label: "pct.paymentReferenceNumber.label",
    sortable: true,
    cellType: "customtext",
    additionalInfo: undefined,
  },
  {
    columnId: "bankStatementEntryClientAccountId",
    width: 350,
    reorderable: true,
    resizable: true,
    label: "pct.clientAccountId.label",
    sortable: true,
    cellType: "customtext",
    additionalInfo: undefined,
  },

  // {
  //   columnId: "currencyCode",
  //   width: 150,
  //   reorderable: true,
  //   resizable: true,
  //   sortable: false,
  //   label: "pct.currencyCode.label",
  //   cellType: "taxonomy",
  //   additionalInfo: {
  //     taxonomy: "Currency",
  //   },
  // },

  {
    columnId: "paymentComment",
    width: 650,
    reorderable: true,
    resizable: true,
    label: "pct.paymentComment.label",
    sortable: true,
    cellType: "customtext",
    additionalInfo: undefined,
  },

  // {
  //   columnId: "policyPaymentFrequencyCodeId",
  //   width: 150,
  //   reorderable: true,
  //   resizable: true,
  //   label: "pct.policyPaymentFrequencyCodeId.label",
  //   sortable: false, cellType: "taxonomy",
  //   additionalInfo: {
  //     taxonomy: "PaymentFrequency",
  //   },
  // },
];
