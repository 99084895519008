import Select from "react-select";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useAppStoreState } from "store/AppStore";
import {
  useBankStatementsStore,
  useClInsurersOptions,
} from "store/BankStatementsStore";
import { getShortInsurerName } from "utils/utils";

const SearchInsurers = () => {
  const { t } = useTranslation();
  const { clientId } = useParams() as any;
  const [
    {
      clInsurersCountryCode,
      clInsurersSelectedItem,
      clearBankStatementEntryStatus,
      getInsurersByCountryStatus,
    },
    { getInsurersByCountry, setBankStatementsStoreValue },
  ] = useBankStatementsStore();

  const isDisabled =
    !!clientId ||
    clearBankStatementEntryStatus === "loading" ||
    getInsurersByCountryStatus === "loading";
  const [{ clInsurersOptions }] = useClInsurersOptions();

  const { appState } = useAppStoreState();

  const customStyles = {
    menu: (baseStyles, state) => ({
      ...baseStyles,
      backgroundColor: appState.darkMode ? "#222222" : "white", // Or any desired color
      color: appState.darkMode ? "white" : "#222222", // Or any desired color
    }),
    control: (baseStyles, state) => ({
      ...baseStyles,
      // minHeight: "30px", // reduce the height
      // height: "30px",
      // padding: "0 6px",

      backgroundColor: appState.darkMode ? "#222222" : "white", // Or any desired color
      color: appState.darkMode ? "white" : "white", // Or any desired color
    }),
    valueContainer: (provided) => ({
      ...provided,
      // height: "30px",
      // padding: "0 6px",
      // alignItems: "center", // vertically center the text
    }),
    input: (baseStyles, state) => ({
      ...baseStyles,
      color: appState.darkMode ? "white" : "#222222",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: appState.darkMode ? "white" : "#222222",
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? data.color
          : isFocused
          ? appState.darkMode
            ? "#000000"
            : "#eeeeee"
          : undefined,
        color: isDisabled
          ? "#ccc"
          : isSelected
          ? appState.darkMode
            ? "white"
            : "#222222"
          : appState.darkMode
          ? "white"
          : "#222222",
        cursor: isDisabled ? "not-allowed" : "default",

        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled
            ? isSelected
              ? "yellow"
              : appState.darkMode
              ? "white"
              : "white"
            : undefined,
        },
      };
    },
  };

  useEffect(() => {
    if (!clInsurersCountryCode) return;
    getInsurersByCountry(clInsurersCountryCode?.value, (data) => {
      if (data) {
        let dataList = data.results.filter((insurer) => {
          return insurer.document.isDeleted === false;
        });

        let retVal = [];
        dataList.forEach((insurer) => {
          let name = insurer.document.partnerName;
          const shortName = getShortInsurerName(
            insurer.document.partnerID + ""
          );
          if (shortName !== "") {
            name = shortName;
            retVal.unshift({
              value: insurer.document.partnerID,
              label: name + " (" + insurer.document.partnerNumber + ")",
            });
          } else {
            retVal.push({
              value: insurer.document.partnerID,
              label: name + " (" + insurer.document.partnerNumber + ")",
            });
          }
        });
        setBankStatementsStoreValue("clInsurersOptions", retVal);
      }
    });
  }, [clInsurersCountryCode]);

  return (
    <div>
      <Select
        isMulti
        id="insurersSearch"
        inputId="insurersSearchInput"
        styles={customStyles}
        menuPlacement={"auto"}
        placeholder={t("pct.searchInsurers.label")}
        noOptionsMessage={() => t("greco.noResults")}
        isClearable
        isDisabled={isDisabled}
        loadingMessage={() => t("greco.searching")}
        value={clInsurersSelectedItem}
        onChange={(value) => {
          setBankStatementsStoreValue("clInsurersSelectedItem", value);
        }}
        options={clInsurersOptions}
      />
    </div>
  );
};

export default SearchInsurers;
