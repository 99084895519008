import React, { Suspense, useEffect, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Logo from "../src/assets/GrECo_Logo_small.jpg";
import "./App.css";
// --- API
import { ErrorMessage, GrecoSpinner } from "@greco/components";
import { getUserPhotoSmall, logout } from "./api/GraphService";
// --- Greco Components

// --- Routes
import { routes } from "./config/routes";
// --- Application context

import { GrecoErrorPage } from "@greco/components";
import { getUserData } from "./api/UserDataLoader";
import AppHeader from "./components/Header/AppHeader";
// --- Theme
import { loadTheme } from "@fluentui/react";
import {
  FluentProvider,
  webDarkTheme,
  webLightTheme,
} from "@fluentui/react-components";

import { ToastContainer } from "react-toastify";
// --- Insights
import {
  AppInsightsContext,
  AppInsightsErrorBoundary,
} from "@microsoft/applicationinsights-react-js";
import { GlobalStyles } from "components/GlobalStyles";
import i18next from "i18next";
import { useAppStore } from "store/AppStore";
import { darkStyledTheme, styledTheme } from "theme";
import useNotifications from "useNotifications";
import { reactPlugin } from "./insights";

import { ThemeProvider as StyledThemeProvider } from "styled-components";

interface IState {
  headerConfig: any;
  panelIsOpen: boolean;
  user: any;
  error: string;
  loading: boolean;
  search: string;
  darkMode: boolean;
}

export const AppComponent = () => {
  const [
    { appState, taxonomyUad, taxonomy, headerTitle, grecoCompanies },
    { setAppStoreValue, loadUADTaxonomy, loadTaxonomy, loadGrecoCompanies },
  ] = useAppStore();

  useNotifications();

  const [webTheme, setWebTheme] = useState(webLightTheme);
  const getAllUserData = () => {
    setAppStoreValue("appState", { ...appState, loading: true });
    Promise.all([getUserData(), getUserPhotoSmall()])
      .then((values: any) => {
        return { ...values[0], photoSmall: values[1] };
      })
      .then((results: any) => {
        setAppStoreValue("appState", {
          ...appState,
          user: results,
          loading: false,
        });
      })
      .catch((err) => {
        setAppStoreValue("appState", {
          ...appState,
          loading: false,
        });
        errorHandler(err);
      });
  };

  const closeErrorMessage = (): void => {
    setAppStoreValue("appState", { ...appState, error: "" });
  };

  const panelStatus = (status: boolean): void => {
    setAppStoreValue("appState", { ...appState, panelIsOpen: status });
  };

  const searchOnChange = (value: string): void => {
    setAppStoreValue("appState", { ...appState, search: value });
  };

  const searchOnSearch = (value: string): void => {
    alert(value);
  };

  const errorHandler = (value: string): void => {
    setAppStoreValue("appState", { ...appState, error: value });
  };

  useEffect(() => {
    getAllUserData();
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      const targetElements = document.querySelectorAll(".reactgrid");

      const elementsArray = Array.from(targetElements);

      const isClickedOutside = elementsArray.every((element) => {
        return !element.contains(event.target);
      });

      if (isClickedOutside) {
        // Create a new 'keydown' event for the Escape key
        const keydownEvent = new KeyboardEvent("keydown", {
          key: "Escape", // Specify the key value
          code: "Escape", // Specify the physical key code
          keyCode: 27, // Specify the keyCode (deprecated but included for completeness)
          which: 27, // Deprecated but included for older browsers
          bubbles: true, // Ensure the event bubbles up through the DOM
          cancelable: true, // Ensure the event can be canceled
        });

        // Select all elements with the class 'cell-input'
        const inputs = document.querySelectorAll(".cell-input");

        // Iterate over the NodeList and dispatch the event on each element
        inputs.forEach((input) => {
          input.dispatchEvent(keydownEvent);
        });
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    document.addEventListener("keydown", function (event) {
      if (event.key === "Escape" || event.keyCode === 27) {
        console.log("ESC key pressed.");
        // Your logic here
      }
    });

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []); // Prazan niz zavisnosti znači da će se efekat pokrenuti samo jednom prilikom montiranja

  useEffect(() => {
    if (appState.darkMode) {
      setWebTheme(webDarkTheme);
    } else {
      setWebTheme(webLightTheme);
    }
  }, [appState.darkMode]);

  useEffect(() => {
    if (grecoCompanies !== null) return;
    loadGrecoCompanies();
  }, [loadGrecoCompanies, grecoCompanies]);

  useEffect(() => {
    if (taxonomyUad !== null) return;
    loadUADTaxonomy();
  }, [loadUADTaxonomy, taxonomyUad]);

  useEffect(() => {
    if (taxonomy !== null) return;
    loadTaxonomy();
  }, [loadTaxonomy, taxonomy]);

  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <Suspense fallback={<GrecoSpinner />}>
        <StyledThemeProvider
          theme={appState.darkMode ? darkStyledTheme : styledTheme}
        >
          <AppInsightsErrorBoundary
            onError={() => (
              <GrecoErrorPage
                title={(i18next.t("gos.error.title"), "Error")}
                message={i18next.t(
                  "gos.error.description",
                  "Uh, oh, something went wrong. Refresh is needed."
                )}
                dismissButton={null}
              />
            )}
            appInsights={reactPlugin}
          >
            <div>
              <FluentProvider theme={webTheme}>
                {/* {appState.loading && <GrecoSpinner />} */}
                {appState.error && (
                  <ErrorMessage
                    message={appState.error}
                    closeError={closeErrorMessage}
                  />
                )}
                <BrowserRouter>
                  <AppHeader
                    taxonomyUad={taxonomyUad}
                    user={appState.user}
                    headerConfig={{
                      ...appState.headerConfig,
                      application_name: headerTitle,
                    }}
                    logo={Logo}
                    logout={logout}
                    panelStatus={panelStatus}
                    searchOnChange={searchOnChange}
                    searchOnSearch={searchOnSearch}
                    darkMode={(dark: boolean) => {
                      loadTheme(dark ? (darkStyledTheme as any) : styledTheme);
                      setAppStoreValue("appState", {
                        ...appState,
                        darkMode: dark,
                      });
                      setWebTheme(dark ? webDarkTheme : webLightTheme);
                    }}
                    tooltipStatusChange={(tooltipsStatus) => {
                      const checked = Boolean(
                        tooltipsStatus.target.ariaChecked === "true"
                          ? false
                          : true
                      );
                      localStorage.setItem("tooltipsStatus", String(checked));
                      setAppStoreValue("appState", {
                        ...appState,
                        headerConfig: {
                          ...appState.headerConfig,
                          tooltipsStatus: checked,
                        },
                      });
                    }}
                  />

                  <div
                    className={
                      appState.panelIsOpen
                        ? "app-wrapper app-wrapper--panelIsOpen"
                        : "app-wrapper"
                    }
                    style={{
                      backgroundColor: webTheme.colorNeutralBackground2,
                      height: "100%",
                    }}
                  >
                    <Routes>
                      {Object.keys(routes).map((key) => {
                        const route = routes[key];
                        return (
                          <Route
                            key={key}
                            path={route.path}
                            element={<route.component />}
                            // exact
                          />
                        );
                      })}
                      <Route
                        path="*"
                        element={
                          <Navigate to={routes.bankStatements.path} replace />
                        }
                      />
                    </Routes>
                  </div>
                  <ToastContainer icon={false} />
                  <GlobalStyles />
                </BrowserRouter>
              </FluentProvider>
            </div>
          </AppInsightsErrorBoundary>
        </StyledThemeProvider>
      </Suspense>
    </AppInsightsContext.Provider>
  );
};

const App = () => <AppComponent />;

export default App;
