import React from "react";

import {
  useExcludeDeclaredPayments,
  usePaymentPageStore,
  usePaymentPageStoreActions,
} from "store/PaymentPageStore";
import { Button, Tooltip, useId } from "@fluentui/react-components";
import { CheckboxCheckedFilled } from "@fluentui/react-icons";
import { useAppStore } from "store/AppStore";
import { logWithTimestamp } from "utils/utils";
import { log } from "console";
import {
  DefaultButton,
  IButtonStyles,
  IIconProps,
  TooltipHost,
} from "@fluentui/react";
import { useTranslation } from "react-i18next";

const iconOutline: IIconProps = { iconName: "ToDoLogoOutline" };
const iconInverse: IIconProps = { iconName: "ToDoLogoInverse" };

const buttonStyles: Partial<IButtonStyles> = {
  root: {
    minWidth: 32,
    border: "none",
    padding: 0,
  },
};

export const ExcludeDeclaredPaymentsButton = () => {
  const [{ paymentsLoadStatus }, { setPaymentStoreValue }] =
    usePaymentPageStore();
  const loading = paymentsLoadStatus === "loading";

  const [{ excludeDeclaredPayments }] = useExcludeDeclaredPayments();
  const { t } = useTranslation();

  return (
    <TooltipHost
      content={
        excludeDeclaredPayments
          ? t("pct.excludeDeclaredPayments.label")
          : t("pct.includeDeclaredPayments.label")
      }
      id={useId("tooltip")}
      calloutProps={{ gapSpace: 0 }}
      styles={{ root: { display: "inline-block" } }}
      setAriaDescribedBy={false}
    >
      {" "}
      <DefaultButton
        styles={buttonStyles}
        disabled={loading}
        toggle
        checked={excludeDeclaredPayments}
        iconProps={excludeDeclaredPayments ? iconOutline : iconInverse}
        onClick={(e) => {
          setPaymentStoreValue(
            "excludeDeclaredPayments",
            !excludeDeclaredPayments
          );
        }}
      ></DefaultButton>
    </TooltipHost>
  );
};
