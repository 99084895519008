import {
  Cell,
  CellChange,
  CellTemplates,
  Column,
  Highlight,
  Id,
  ReactGrid,
  Row,
} from "@silevis/reactgrid";
import React, { useEffect, useMemo, useRef } from "react";
import { ICellTypeHandler } from "./ICellTypeHandler";
import CheckboxCellTypeHandler from "./standardcelltypehandlers/CheckboxCellTypeHandler";
import TextCellTypeHandler from "./standardcelltypehandlers/TextCellTypeHandler";
import NumberCellTypeHandler from "./standardcelltypehandlers/NumberCellTypeHandler";
import DateCellTypeHandler from "./standardcelltypehandlers/DateCellTypeHandler";
import { useTheme } from "@fluentui/react";
import { useAppStore } from "store/AppStore";
import "./styles.css";
import { sortFunctionMap } from "./sortFunctionsMap";

const formatNumber = (value: number) => {
  const locale = window.navigator.languages[0];

  const groupingFormat = new Intl.NumberFormat(locale, {
    useGrouping: true,
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });
  return Number.isNaN(value) ? "" : groupingFormat.format(value);
};

interface Props {
  idColumn: string;
  rows: any[];
  rowsMetadataMap: { [key: number]: any };
  columns: any[];
  cellTypeHandlers: { [key: string]: ICellTypeHandler };
  context: { [key: string]: any };
  handleChanges: (
    updatedRows: any[],
    updatedRowsMetadataMap: { [key: number]: any }
  ) => void;
  keyColumn: string;
  hasSumHeaderRow?: boolean;
  hasSelectColumn?: boolean;
  stickyColumns?: {
    left?: number;
    right?: number;
    top?: number;
    bottom?: number;
  };
  sort?: any;
  horizontalStickyBreakpoint?: number;
}

export const standardCellTypeHandlers = {
  text: new TextCellTypeHandler(),
  number: new NumberCellTypeHandler(),
  date: new DateCellTypeHandler(),
  checkbox: new CheckboxCellTypeHandler(),
};

const GrecoReactGrid = (props: Props) => {
  const {
    rows,
    idColumn,
    columns,
    rowsMetadataMap,
    cellTypeHandlers,
    handleChanges,
    stickyColumns,
    context,
    keyColumn,
    hasSumHeaderRow = true,
    hasSelectColumn = true,
    sort,
    horizontalStickyBreakpoint = 50,
  } = props;

  const [{ appState }, {}] = useAppStore();

  const [highlightedRowId, setHighlightedRowId] = React.useState<number>(-1);
  const columnSums = useMemo(() => {
    if (hasSumHeaderRow === false) {
      return {};
    }

    const retVal: { [key: string]: any } = {};
    for (let column of columns) {
      if (
        column.cellType === "customnumber" ||
        column.cellType === "clearedamount"
      ) {
        retVal[column.columnId] = rows
          .filter((row) =>
            hasSelectColumn &&
            rowsMetadataMap[row[keyColumn]]?.selected === false
              ? false
              : true
          )
          .reduce((acc, row) => {
            return (
              acc +
              (rowsMetadataMap[row[keyColumn]]?.visible
                ? isNaN(row[column.columnId])
                  ? 0
                  : row[column.columnId]
                : 0)
            );
          }, 0);
      } else if (column.cellType === "declaredamount") {
        retVal[column.columnId] = rows.reduce((acc, row) => {
          return (
            acc +
            (rowsMetadataMap[row[keyColumn]]?.visible
              ? isNaN(row[column.columnId])
                ? 0
                : row[column.columnId]
              : 0)
          );
        }, 0);
      } else if (column.cellType === "amounts") {
        const fieldsMap = {
          booked_paid_amount: ["paidBookedAmount", "bookedAmount"],
          total_paid_amount: ["paidAmount", "premiumAmount"],
          discount_paid_amount: ["paidDiscountAmount", "premiumDiscountAmount"],
        };
        // the same as with customnumber and clearedamount but the accumulator is a tuplet, not a number
        // the cell value is a string with the format "amount1 / amount2" which needs to be parsed and each amount added to the corresponding accumulator
        const accumulator = rows
          .filter((row) =>
            hasSelectColumn &&
            rowsMetadataMap[row[keyColumn]]?.selected === false
              ? false
              : true
          )
          .reduce(
            (acc, row) => {
              const amounts = [
                row[fieldsMap[column.columnId][0]],
                row[fieldsMap[column.columnId][1]],
              ];
              return [
                acc[0] +
                  (rowsMetadataMap[row[keyColumn]]?.visible ? amounts[0] : 0),
                acc[1] +
                  (rowsMetadataMap[row[keyColumn]]?.visible ? amounts[1] : 0),
              ];
            },
            [0, 0]
          );
        retVal[column.columnId] =
          formatNumber(accumulator[0]) + " / " + formatNumber(accumulator[1]);
      }
    }
    return retVal;
  }, [
    rows,
    columns,
    rowsMetadataMap,
    keyColumn,
    hasSumHeaderRow,
    hasSelectColumn,
  ]);
  const theme = useTheme();
  useEffect(() => {
    let styleSheet = document.getElementById(
      "dynamicStyles"
    ) as HTMLStyleElement | null;
    if (!styleSheet) {
      styleSheet = document.createElement("style");
      styleSheet.id = "dynamicStyles";
      document.head.appendChild(styleSheet);
    }

    if (styleSheet.sheet) {
      const sheet = styleSheet.sheet;
      const className = ".rg-celleditor";
      const newStyle = "background-color: " + theme.palette.white + ";";

      const existingRule = Array.from(sheet.cssRules).find(
        (rule) =>
          rule instanceof CSSStyleRule && rule.selectorText === className
      ) as CSSStyleRule | undefined;

      if (existingRule) {
        existingRule.style.cssText = newStyle;
      } else {
        sheet.insertRule(`${className} { ${newStyle} }`, sheet.cssRules.length);
      }
    }
  }, [theme]);

  const [columnsPrepared, setColumnsPrepared] = React.useState<Column[]>(
    columns.map((column) => {
      return {
        columnId: column.columnId,
        width: column.width,
        reorderable: column.reorderable,
        resizable: column.resizable,
      };
    })
  );

  const customCellTemplates: CellTemplates = useMemo(() => {
    let retVal: CellTemplates = {};
    for (let handler in cellTypeHandlers) {
      if (cellTypeHandlers[handler].isCustom) {
        retVal[handler] = cellTypeHandlers[handler].instance;
      }
    }
    return retVal;
  }, []);

  const constructHeaderRow = (
    columnConfigs: any[],
    context: { [name: string]: any }
  ): Row<any> => {
    return {
      rowId: "header",
      cells: columnConfigs.map((columnConfig, index) => {
        if (columnConfig.cellType === "clearedamount") {
          return cellTypeHandlers["clearedamountall"].getEntryRow(
            {},
            columnConfig,
            rowsMetadataMap,
            keyColumn,
            context
          );
        } else if (columnConfig.cellType === "declaredamount") {
          return cellTypeHandlers["declaredamountall"].getEntryRow(
            {},
            columnConfig,
            rowsMetadataMap,
            keyColumn,
            context
          );
        } else if (columnConfig.cellType === "select") {
          return cellTypeHandlers["selectall"].getEntryRow(
            {},
            columnConfig,
            rowsMetadataMap,
            keyColumn,
            context
          );
        } else {
          return cellTypeHandlers["sortableheader"].getEntryRow(
            {},
            columnConfig,
            rowsMetadataMap,
            keyColumn,
            context
          );
          //   type: "sortableheader",
          //   text: context["t"](columnConfig.label),
          //   selectable: false,
          // };
        }
      }),
    };
  };

  const constructSumHeaderRow = (
    columnConfigs: any[],
    context: { [name: string]: any }
  ): Row<any> => {
    return {
      rowId: "sumheader",
      cells: columnConfigs.map((columnConfig, index) => {
        return cellTypeHandlers["sumheader"].getEntryRow(
          {},
          columnConfig,
          rowsMetadataMap,
          keyColumn,
          { ...context, columnSums }
        );
      }),
    };
  };

  function sortArray(array, sort) {
    const { key, dir } = sort;

    // for each element in columns array find the one with the columnId === key and extract the cellType and index
    let index = 0;
    for (let i = 0; i < columns.length; i++) {
      if (columns[i].columnId === key) {
        var columnType = columns[i].cellType;
        index = i;
        break;
      }
    }

    // const columnType = columns.find(
    //   (column) => column.columnId === key
    // ).cellType;

    const sortFunc = sortFunctionMap[columnType];
    if (!sortFunc) {
      throw new Error(`No sort function defined for type: ${columnType}`);
    }

    let sortedElements = array.sort((a, b) => {
      const result = sortFunc(a.cells[index], b.cells[index]);
      return dir === "asc" ? result : -result;
    });

    index = -1;
    for (let i = 0; i < columns.length; i++) {
      if (columns[i].cellType === "ordinalnumber") {
        index = i;
        break;
      }
    }

    if (index > -1) {
      for (let i = 0; i < sortedElements.length; i++) {
        sortedElements[i].cells[index].value = i + 1;
      }
    }
    return sortedElements;
  }

  const rowCells = useMemo(() => {
    const sumHeaderRow = constructSumHeaderRow(columns, context);
    const headerRow = constructHeaderRow(columns, context);
    let rowCells = rows.filter((row) => {
      return rowsMetadataMap[row[idColumn]]
        ? rowsMetadataMap[row[idColumn]].visible
        : false;
    });
    // console.log("1 " + rowCells.length);
    rowCells = rowCells.map((entry, index) => {
      const updatedContext = { ...context, index };
      return {
        rowId: index,
        cells: columns.map((columnConfig) => {
          // console.log(columnConfig.cellType);

          return cellTypeHandlers[columnConfig.cellType].getEntryRow(
            entry,
            columnConfig,
            rowsMetadataMap,
            keyColumn,
            updatedContext
          );
        }),
      };
    });
    if (sort && sort.dir !== "") {
      rowCells = sortArray(rowCells, sort);
    }
    //console.log("2 " + rowCells.length);
    if (rowCells.length === 0 || !hasSumHeaderRow) {
      return [headerRow, ...rowCells];
    }
    return [headerRow, ...rowCells, sumHeaderRow];
  }, [rows, rowsMetadataMap, columns, cellTypeHandlers]);

  const handleColumnResize = (ci: Id, width: number) => {
    setColumnsPrepared((prevColumns) => {
      const columnIndex = prevColumns.findIndex((el) => el.columnId === ci);
      const resizedColumn = prevColumns[columnIndex];
      const updatedColumn = { ...resizedColumn, width };
      prevColumns[columnIndex] = updatedColumn;
      return [...prevColumns];
    });
  };

  const highlights = () => {
    const retVal: Highlight[] = [];
    for (let key in rowsMetadataMap) {
      const meta = rowsMetadataMap[key];
      if (meta.changes.length > 0) {
        for (let change of meta.changes) {
          retVal.push({
            columnId: change.columnId,
            rowId: change.rowId,
            borderColor: "#aaaa00",
          });
        }
      }
    }
    return retVal;
  };

  const applyChangesToRows = (
    changes: CellChange<Cell>[],
    rows: any[],
    rowsMetadataMap: { [key: number]: any }
  ): [any[], { [key: number]: any }] => {
    let updatedRows = [
      ...rows.filter((row) => {
        return rowsMetadataMap[row[idColumn]].visible;
      }),
    ];
    let updatedRowsMetadataMap = { ...rowsMetadataMap };
    changes.forEach((change) => {
      const rowIndex = change.rowId;
      const fieldName = change.columnId;
      const newValue =
        cellTypeHandlers[change.newCell.type].determineNewValue(change);
      if (
        change.columnId !== "selected" &&
        change.columnId !== "suggestedPaymentAmount" &&
        change.columnId !== "declaredAmount" &&
        (change.columnId !== "clientId" ||
          (change.columnId === "clientId" && (change as any).cleared === true))
      ) {
        if (!rowsMetadataMap[updatedRows[rowIndex][idColumn]]["oldRow"]) {
          updatedRowsMetadataMap[updatedRows[rowIndex][idColumn]]["oldRow"] = {
            ...updatedRows[rowIndex],
          };
        }
        updatedRowsMetadataMap[updatedRows[rowIndex][idColumn]]["inEditMode"] =
          true;
        updatedRowsMetadataMap[updatedRows[rowIndex][idColumn]]["changes"] =
          updatedRowsMetadataMap[updatedRows[rowIndex][idColumn]]["changes"] ||
          [];
        updatedRowsMetadataMap[updatedRows[rowIndex][idColumn]]["changes"].push(
          change
        );
      }
      if (
        change.columnId === "suggestedPaymentAmount" ||
        change.columnId === "declaredAmount" ||
        (change.columnId === "clientId" && (change as any).cleared === true) ||
        change.columnId === "clientInfo" ||
        updatedRowsMetadataMap[updatedRows[rowIndex][idColumn]]["editable"]
      ) {
        updatedRows[rowIndex][fieldName] = newValue;
      } else {
        updatedRowsMetadataMap[updatedRows[rowIndex][idColumn]]["changes"] = [];
        updatedRowsMetadataMap[updatedRows[rowIndex][idColumn]]["inEditMode"] =
          false;
      }
      // }
      //   }
    });
    return [updatedRows, updatedRowsMetadataMap];
  };

  const onCellsChanged = (changes: CellChange<Cell>[]) => {
    const [rowsUpdated, rowsMetadataMapUpdated] = applyChangesToRows(
      changes,
      rows,
      rowsMetadataMap
    );
    handleChanges(rowsUpdated, rowsMetadataMapUpdated);
  };

  useEffect(() => {
    setTimeout(() => {
      let cols = Array.from(
        document.getElementsByClassName(
          "rg-pane"
        ) as HTMLCollectionOf<HTMLElement>
      );
      for (let i = 0; i < cols.length; i++) {
        if (appState.darkMode) {
          cols[i].style.backgroundColor = "#222222";
          cols[i].style.color = "#ffffff";
        } else {
          cols[i].style.backgroundColor = "#f8f8f8";
          cols[i].style.color = "#000000";
        }
      }
    }, 20);
  }, [appState.darkMode, rows, rowsMetadataMap]);

  function mouseEnterEventListener(event) {
    //console.log(event.target);
    if (event.target.dataset.cellRowidx) {
      const element = document.querySelector(".ms-DatePicker.is-open");
      if (!element) {
        setHighlightedRowId(event.target.dataset.cellRowidx);
      }
    }
    // console.log(event.target);
  }

  function mouseLeaveEventListener(event) {
    const element = document.querySelector(".ms-DatePicker.is-open");
    if (!element) {
      setHighlightedRowId(-1);
    }
  }

  useEffect(() => {
    const gridDiv = document.getElementById(idColumn);
    if (!gridDiv) return;
    const rowCells = gridDiv.querySelectorAll(
      `.rg-cell[data-cell-rowidx="${highlightedRowId}"]`
    );
    // Add the 'highlighted-row' class
    rowCells.forEach((cell) => {
      cell.classList.add("highlighted-row");
    });

    // Cleanup function to remove the class
    return () => {
      rowCells.forEach((cell) => {
        cell.classList.remove("highlighted-row");
      });
    };
  }, [highlightedRowId, idColumn]);

  useEffect(() => {
    setTimeout(() => {
      const gridDiv = document.getElementById(idColumn);
      if (!gridDiv) return;

      gridDiv.removeEventListener("mouseover", mouseEnterEventListener);
      gridDiv.addEventListener("mouseover", mouseEnterEventListener);

      gridDiv.removeEventListener("mouseleave", mouseLeaveEventListener);
      gridDiv.addEventListener("mouseleave", mouseLeaveEventListener);

      return () => {
        // document.removeEventListener("mouseover", mouseEnterEventListener);
      };
    }, 20);
  }, [appState.darkMode, rows, rowsMetadataMap]);

  if (rowCells.length === 1) {
    return <></>;
  }
  return (
    <div id={idColumn}>
      <ReactGrid
        rows={rowCells}
        columns={columnsPrepared}
        onCellsChanged={onCellsChanged}
        onColumnResized={handleColumnResize}
        onFocusLocationChanged={(location) => {}}
        onFocusLocationChanging={(props) => {
          return true;
        }}
        stickyLeftColumns={stickyColumns?.left}
        stickyRightColumns={stickyColumns?.right}
        stickyTopRows={stickyColumns?.top}
        stickyBottomRows={stickyColumns?.bottom}
        //enableRowSelection
        customCellTemplates={customCellTemplates}
        highlights={highlights()}
        horizontalStickyBreakpoint={horizontalStickyBreakpoint}
      />
    </div>
  );
};

export default GrecoReactGrid;
