export const formatNumber = (n: number, minPrecision = 2, maxPrecision = 2) => {
  return n.toLocaleString("de-DE", {
    minimumFractionDigits: minPrecision,
    maximumFractionDigits: maxPrecision,
  });
};

export const formatIntegerNumber = (n: number) => {
  return n.toLocaleString("hu-HU", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
};
