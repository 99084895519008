import {
  Cell,
  CellTemplate,
  Compatible,
  Uncertain,
  UncertainCompatible,
  getCellProperty,
  keyCodes,
} from "@silevis/reactgrid";
import * as React from "react";
import { Checkmark20Regular, Dismiss20Regular } from "@fluentui/react-icons";

// NOTE: all modules imported below may be imported from '@silevis/reactgrid'

export interface OrdinalNumberCell extends Cell {
  type: "ordinalnumber";
  editable?: boolean;
  selected?: boolean;
  highlighted?: boolean;
  value: number;
  checkedText?: string;
  uncheckedText?: string;
  onChecked: (checked: boolean) => void;
}

export class OrdinalNumberCellTemplate
  implements CellTemplate<OrdinalNumberCell>
{
  getCompatibleCell(
    uncertainCell: Uncertain<OrdinalNumberCell>
  ): Compatible<OrdinalNumberCell> {
    let editable: boolean | undefined;
    try {
      editable = getCellProperty(uncertainCell, "editable", "boolean");
    } catch {
      editable = true;
    }
    let selected: boolean | undefined;
    try {
      selected = getCellProperty(uncertainCell, "selected", "boolean");
    } catch {
      selected = true;
    }
    let highlighted: boolean | undefined;
    try {
      highlighted = getCellProperty(uncertainCell, "highlighted", "boolean");
    } catch {
      highlighted = false;
    }
    let value: number | undefined;
    try {
      value = getCellProperty(uncertainCell, "value", "number");
    } catch {
      value = null;
    }

    const text = value + "";
    return {
      ...uncertainCell,
      value: value,
      onChecked: uncertainCell.onChecked,
      editable,
      selected,
      highlighted,
      text,
    };
  }

  getClassName(cell: Compatible<OrdinalNumberCell>): string {
    return (
      (cell.className ? cell.className : "") +
      (cell.selected ? " rg-selected" : "") +
      (cell.highlighted ? " highlighted-selected-row" : "")
    );
  }

  render(
    cell: Compatible<OrdinalNumberCell>,
    isInEditMode: boolean,
    onCellChanged: (
      cell: Compatible<OrdinalNumberCell>,
      commit: boolean
    ) => void
  ): React.ReactNode {
    return (
      <span style={{ textAlign: "center", paddingTop: "5px", width: "100%" }}>
        {cell.value}
      </span>
    );
  }
}
