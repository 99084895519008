import * as React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { GrecoHeader } from "@greco/components";
import { inContextEditorPostProcessor } from "../../i18n";
import {
  ActionButton,
  mergeStyleSets,
  IDropdownOption,
} from "office-ui-fabric-react";
import i18n from "i18n";
import { addAppSetting, getAppSettings, updateAppSetting } from "api/api";
import { useAppStore } from "store/AppStore";
import { ErrorHandlerMessage } from "utils/errorHandler";
import { ISettingsToggle } from "@greco/components/dist/components/GrecoHeader/GrecoPanel/Settings/Settings";
import { useLocation, useNavigate } from "react-router-dom";
import { MSGraphAuth } from "api/GraphService";
import { useTheme } from "@fluentui/react";

interface IAppHeaderProps extends WithTranslation {
  headerConfig?: any;
  user?: any;
  panelStatus?: Function;
  tooltipStatusChange?: any;
  logo?: any;
  logout?: any;
  searchOnChange?: any;
  searchOnSearch?: any;
  search?: string;
  darkMode?: any;
  taxonomyUad?: any;
}

export const errorHandlerClasses = mergeStyleSets({
  contentContainer: {
    whiteSpace: "initial",
    margin: "10px 0",
    display: "flex",
    flexDirection: "column",
  },
  copyTo: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  copyToIcon: { height: 17 },
  notificationMessage: {
    borderBottom: "2px solid #faf9f8",
    cursor: "pointer",
  },
});

const languageList: { [key: string]: string } = {
  // "Language.BG_BG": "bg-BG",
  // "Language.CS_CZ": "cs-CZ",
  // "Language.DE_AT": "de-AT",
  "Language.EN_GB": "en-GB",
  // "Language.ET_EE": "et-EE",
  // "Language.HR_HR": "hr-HR",
  // "Language.HU_HU": "hu-HU",
  // "Language.KA_GE": "ka-GE",
  // "Language.KK_KZ": "kk-KZ",
  "Language.LT_LT": "lt-LT",
  // "Language.PL_PL": "pl-PL",
  // "Language.RO_RO": "ro-RO",
  // "Language.RU_RU": "ru-RU",
  // "Language.SK_SK": "sk-SK",
  // "Language.SL_SI": "sl-SI",
  // "Language.SQ_AL": "aq-AL",
  // "Language.SR_RS": "sr-Latn-RS",
  // "Language.TR_TR": "tr-TR",
  // "Language.UK_UA": "uk-UA",
  // "Language.UZ_UZ": "uz-UZ",
};

interface SupportedLanguage {
  isGrECoLanguage: boolean;
  code: string;
  isDeleted: boolean;
  id: number;
}

export interface ViewOptions {
  appSettingCodeId: number;
  userAppSettingValue: any; // IColumnCRM[]
  userAppSettingId: number;
  userAppSettingName: string;
  userAppSettingDescription: string;
  applicationCodeId: number;
  isDefault: boolean;
}

const AppHeader = (props: IAppHeaderProps): JSX.Element => {
  const {
    headerConfig,
    user,
    panelStatus,
    tooltipStatusChange,
    logo,
    logout,
    searchOnChange,
    searchOnSearch,
    search,
    darkMode,
    taxonomyUad,
    t,
  } = props;
  const location = useLocation();
  const navigate = useNavigate();

  const [shouldShowPhrase, setShouldShowPhrase] = React.useState(false);
  const [appSettings, setAppSettings] = React.useState([]);

  const [phraseModalOpen, setPhraseModalOpen] = React.useState(
    inContextEditorPostProcessor.phraseEnabled
  );
  const theme = useTheme();

  const applicationCodeId = taxonomyUad?.ApplicationCode?.find(
    (el) => el.code === "Application.PCT"
  ).id;

  const appSettingCodeId = taxonomyUad?.ApplicationSetting?.find(
    (el) => el.code === "AppSetting.UserSettings"
  ).id;

  const phraseToggle: ISettingsToggle = {
    title: "Phrase",
    toggleProps: {
      styles: {
        root: {
          ".ms-Toggle-background": {
            background: phraseModalOpen
              ? theme.palette.themePrimary
              : theme.palette.white,
          },
        },
      },
      defaultChecked:
        !!new URLSearchParams(location.search).get("phrase_context") ||
        inContextEditorPostProcessor.phraseEnabled,
      onChange: (_event, checked) => {
        setPhraseModalOpen(checked);

        if (checked) {
          inContextEditorPostProcessor.phraseEnabled = true;
        } else {
          inContextEditorPostProcessor.phraseEnabled = false;
          const queryParams = new URLSearchParams(location.search);
          if (queryParams.get("phrase_context")) {
            queryParams.delete("phrase_context");
            navigate({
              pathname: location.pathname,
              search: queryParams.toString(),
            });
          }
          // Reload is needed because we have to remove
          // Phrase scripts from the client
          window.location.reload();
        }
      },
    },
  };

  const enableInContextEditor = async () => {
    const account = await MSGraphAuth.accountObj;

    if (!(account && account.idTokenClaims)) return false;

    const roles: string[] = (account.idTokenClaims as { roles: string[] })
      .roles;
    return !!(roles && roles.includes("PHRASE"));
  };

  React.useEffect(() => {
    (async () => {
      const shouldShowPhrase = await enableInContextEditor();

      setShouldShowPhrase(shouldShowPhrase);
    })();
  }, []);

  React.useEffect(() => {
    if (applicationCodeId) {
      getAppSettings(Number(applicationCodeId)).then((res) => {
        setAppSettings(res.data);
      });
    }
  }, [applicationCodeId]);

  const [{ notifications }, { clearAllNotifications }] = useAppStore();

  React.useEffect(() => {
    const onChangeLanguage = (key: string) => {
      if (!key) return;

      const createData: any = {
        applicationCodeId: applicationCodeId,
        appSettingCodeId: appSettingCodeId,
        userAppSettingName: "Language",
        userAppSettingValue: key,
        isDefault: true,
      };
      try {
        getAppSettings(applicationCodeId).then((userSettings: any) => {
          const userSettingsForViewOptions: any = userSettings.data
            .filter((item: any) => item.appSettingCodeId === appSettingCodeId)
            ?.find((userSetting: any) => userSetting.isDefault);

          if (userSettingsForViewOptions) {
            const editData = {
              applicationCodeId: userSettingsForViewOptions.applicationCodeId,
              appSettingCodeId: userSettingsForViewOptions.appSettingCodeId,
              userAppSettingId: userSettingsForViewOptions.userAppSettingId,
              userAppSettingName: userSettingsForViewOptions.userAppSettingName,
              userAppSettingDescription:
                userSettingsForViewOptions.userAppSettingDescription,
              userAppSettingValue: key,
              isDefault: userSettingsForViewOptions.isDefault,
            };
            updateAppSetting(editData);
          } else addAppSetting(createData);

          i18n.changeLanguage(key as string);
        });
      } catch (err) {
        i18n.changeLanguage(key as string);

        // @ts-ignore
        appInsights.trackException(err);
      }
    };
    if (!window.appHeaderInstance) window.appHeaderInstance = {};
    window.appHeaderInstance.onChangeLanguage = onChangeLanguage;
  }, [appSettingCodeId, applicationCodeId]);

  return (
    <GrecoHeader
      headerConfig={headerConfig}
      user={user}
      panelStatus={panelStatus}
      tooltipStatusChange={tooltipStatusChange}
      tooltipsStatus={headerConfig.tooltipsStatus}
      logo={logo}
      logout={logout}
      searchOnChange={searchOnChange}
      searchOnSearch={searchOnSearch}
      search={search}
      darkMode={darkMode}
      languageOptions={
        taxonomyUad
          ? (taxonomyUad.SupportedLanguage as SupportedLanguage[])
              .filter(
                (language: SupportedLanguage) =>
                  language.isGrECoLanguage &&
                  (language.code === "Language.EN_GB" ||
                    language.code === "Language.LT_LT")
              )
              .map<IDropdownOption>((language: SupportedLanguage) => ({
                key: languageList[language.code],
                text: t(language.code),
              }))
          : []
      }
      defaultLanguage={i18n.language}
      headerStrings={{
        notificationsPanelStrings: {
          panelTitle: t("header.Notifications"),
          noNotificationsTitle: t("header.NoNotificationsTitle"),
          noNotificationsSubtitle: t("header.NoNotificationsSubtitle"),
        },
        helpPanelStrings: {
          panelTitle: t("header.Help"),
        },
        settingsPanelStrings: {
          panelTitle: t("header.Settings"),
          darkModeToggleLabel: t("header.DarkMode"),
          allwaysShowTooltipsToggleLabel: t("header.AlwaysShowTooltips"),
          selectLanguageLabel: t("header.SelectLanguage"),
        },
        userSettingsPanelStrings: {
          panelTitle: t("header.UserSettings"),
          myOfficeProfileLinkText: t("header.MyOfficeProfile"),
          myAccountLinkText: t("header.UserSettings"),
          signOutLinkText: t("header.SignOut"),
        },
        searchPlaceholder: t("header.Search"),
      }}
      additionalSettingsToggles={shouldShowPhrase ? [phraseToggle] : undefined}
      onChangeLanguage={async (__event: any, languageOption?: any) => {
        if (!languageOption) return;

        const createData: any = {
          applicationCodeId: applicationCodeId,
          appSettingCodeId: appSettingCodeId,
          userAppSettingName: "Language",
          userAppSettingValue: languageOption.key,
          isDefault: true,
        };
        try {
          const userSettings: any = await getAppSettings(applicationCodeId);
          const userSettingsForViewOptions: any = userSettings.data
            .filter((item: any) => item.appSettingCodeId === appSettingCodeId)
            ?.find((userSetting: any) => userSetting.isDefault);

          if (userSettingsForViewOptions) {
            const editData = {
              applicationCodeId: userSettingsForViewOptions.applicationCodeId,
              appSettingCodeId: userSettingsForViewOptions.appSettingCodeId,
              userAppSettingId: userSettingsForViewOptions.userAppSettingId,
              userAppSettingName: userSettingsForViewOptions.userAppSettingName,
              userAppSettingDescription:
                userSettingsForViewOptions.userAppSettingDescription,
              userAppSettingValue: languageOption.key,
              isDefault: userSettingsForViewOptions.isDefault,
            };
            await updateAppSetting(editData);
          } else await addAppSetting(createData);

          i18n.changeLanguage(languageOption.key as string);
        } catch (err) {
          i18n.changeLanguage(languageOption.key as string);

          // @ts-ignore
          appInsights.trackException(err);
        }
      }}
      showErrorAndWarningMessages={() => {
        return (
          <>
            {notifications?.map((item) => {
              return (
                <div
                  key={item.notify?.label}
                  onClick={(e) => item.handleNotificationClick(e)}
                  className={errorHandlerClasses.notificationMessage}
                >
                  <ErrorHandlerMessage
                    notify={item.notify}
                    errors={null}
                    fieldName={null}
                  />
                </div>
              );
            })}
            <ActionButton
              iconProps={{ iconName: "RingerRemove" }}
              allowDisabledFocus
              styles={{
                root: { float: "right", margin: "10px 10px 0 0" },
              }}
              onClick={() => clearAllNotifications()}
            >
              {t("greco.clear")}
            </ActionButton>
          </>
        );
      }}
      messagesCount={notifications.length}
    />
  );
};

export default withTranslation()(AppHeader);
