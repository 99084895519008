import {
  Cell,
  CellTemplate,
  Compatible,
  Uncertain,
  UncertainCompatible,
  getCellProperty,
  getCharFromKeyCode,
  isAlphaNumericKey,
  isNavigationKey,
  keyCodes,
} from "@silevis/reactgrid";
import { searchClients } from "api/api";
import debounce from "debounce-promise";
import * as React from "react";
import AsyncSelect from "react-select/async";
import { components } from "react-select";
import { useTheme } from "office-ui-fabric-react";
import { useAppStore } from "store/AppStore";
import { HomePersonRegular, ShieldGlobeRegular } from "@fluentui/react-icons";
import { DirectionalHint, TooltipHost } from "@fluentui/react";
import { Tooltip } from "@fluentui/react-components";

export type OptionType = {
  label: string;
  value: string;
};

const customStyles = (appState) => {
  return {
    menu: (baseStyles, state) => ({
      ...baseStyles,
      backgroundColor: appState.darkMode ? "#222222" : "white", // Or any desired color
      color: appState.darkMode ? "white" : "#222222", // Or any desired color
    }),
    control: (baseStyles, state) => ({
      ...baseStyles,
      minHeight: "22px",
      height: "22px",
      boxShadow: state.isFocused ? null : null,

      backgroundColor: appState.darkMode ? "#222222" : "white", // Or any desired color
      color: appState.darkMode ? "white" : "white", // Or any desired color
    }),
    valueContainer: (provided) => ({
      ...provided,
      height: "22px",
      padding: "0 6px",
    }),
    input: (baseStyles, state) => ({
      ...baseStyles,
      margin: "0px",
      color: appState.darkMode ? "white" : "#222222",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: appState.darkMode ? "white" : "#222222",
    }),
    indicatorSeparator: (state) => ({
      display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "22px",
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? data.color
          : isFocused
          ? appState.darkMode
            ? "#000000"
            : "#eeeeee"
          : undefined,
        color: isDisabled
          ? "#ccc"
          : isSelected
          ? appState.darkMode
            ? "white"
            : "#222222"
          : appState.darkMode
          ? "white"
          : "#222222",
        cursor: isDisabled ? "not-allowed" : "default",

        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled
            ? isSelected
              ? "yellow"
              : appState.darkMode
              ? "white"
              : "white"
            : undefined,
        },
      };
    },
  };
};

export interface ClientCell extends Cell {
  type: "client";
  text: string;
  selectedValue?: any;
  editable?: boolean;
  isDisabled?: boolean;
  isGrecoPayment?: boolean;
  isInsurerPayment?: boolean;
  t: any;
  selected?: boolean;
  highlighted?: boolean;
  gosLink?: string;
}

export class ClientCellTemplate implements CellTemplate<ClientCell> {
  getCompatibleCell(
    uncertainCell: Uncertain<ClientCell>
  ): Compatible<ClientCell> {
    const text = getCellProperty(uncertainCell, "text", "string");
    let selectedValue: any | undefined;
    let t: any;
    t = getCellProperty(uncertainCell, "t", "function");
    try {
      selectedValue = getCellProperty(uncertainCell, "selectedValue", "object");
    } catch {
      selectedValue = undefined;
    }
    let editable: boolean | undefined;
    try {
      editable = getCellProperty(uncertainCell, "editable", "boolean");
    } catch {
      editable = true;
    }
    let selected: boolean | undefined;

    try {
      selected = getCellProperty(uncertainCell, "selected", "boolean");
    } catch {
      selected = true;
    }
    let gosLink: string | undefined;
    try {
      gosLink = getCellProperty(uncertainCell, "gosLink", "string");
    } catch {
      gosLink = "";
    }

    let highlighted: boolean | undefined;
    try {
      highlighted = getCellProperty(uncertainCell, "highlighted", "boolean");
    } catch {
      highlighted = false;
    }
    let isGrecoPayment: boolean | undefined;
    try {
      isGrecoPayment = getCellProperty(
        uncertainCell,
        "isGrecoPayment",
        "boolean"
      );
    } catch {
      isGrecoPayment = true;
    }

    let isInsurerPayment: boolean | undefined;
    try {
      isInsurerPayment = getCellProperty(
        uncertainCell,
        "isInsurerPayment",
        "boolean"
      );
    } catch {
      isInsurerPayment = true;
    }

    const value = selectedValue ? parseFloat(selectedValue.clientCode) : NaN;
    let isDisabled = true;
    try {
      isDisabled = getCellProperty(uncertainCell, "isDisabled", "boolean");
    } catch {
      isDisabled = false;
    }
    // let inputValue: string | undefined;
    // try {
    //   inputValue = getCellProperty(uncertainCell, "inputValue", "string");
    // } catch {
    //   inputValue = undefined;
    // }

    return {
      ...uncertainCell,
      selectedValue,
      text,
      value,
      isDisabled,
      editable: editable && !isGrecoPayment,
      isGrecoPayment,
      isInsurerPayment,
      selected,
      highlighted,
      gosLink,
      t,
      // isOpen,
      // inputValue,
    };
  }

  update(
    cell: Compatible<ClientCell>,
    cellToMerge: UncertainCompatible<ClientCell>
  ): Compatible<ClientCell> {
    return this.getCompatibleCell({
      ...cell,
      text: cellToMerge.text,
      selectedValue: cellToMerge.selectedValue,
      // inputValue: cellToMerge.inputValue,
    });
  }

  handleKeyDown(
    cell: Compatible<ClientCell>,
    keyCode: number,
    ctrl: boolean,
    shift: boolean,
    alt: boolean
  ): { cell: Compatible<ClientCell>; enableEditMode: boolean } {
    if (!cell.editable)
      return {
        cell: this.getCompatibleCell({
          ...cell,
        }),
        enableEditMode: false,
      };
    if ((keyCode === keyCodes.SPACE || keyCode === keyCodes.ENTER) && !shift) {
      return {
        cell: this.getCompatibleCell({ ...cell }),
        enableEditMode: false,
      };
    }
    const char = getCharFromKeyCode(keyCode, shift);
    if (
      !ctrl &&
      !alt &&
      isAlphaNumericKey(keyCode) &&
      !(shift && keyCode === keyCodes.SPACE) &&
      cell.editable
    )
      return {
        cell: this.getCompatibleCell({
          ...cell,
          text: shift ? char : char.toLowerCase(),

          // inputValue: shift ? char : char.toLowerCase(),
        }),
        enableEditMode: true,
      };
    return {
      cell,
      enableEditMode:
        keyCode === keyCodes.POINTER || keyCode === keyCodes.ENTER,
    };
  }

  getClassName(cell: Compatible<ClientCell>, isInEditMode: boolean): string {
    return `${
      (cell.className ? cell.className : "") +
      (cell.selected ? " rg-selected" : "") +
      (cell.highlighted ? " highlighted-selected-row" : "")
    }`;
  }

  render(
    cell: Compatible<ClientCell>,
    isInEditMode: boolean,
    onCellChanged: (cell: Compatible<ClientCell>, commit: boolean) => void
  ): React.ReactNode {
    if (isInEditMode && !cell.editable) {
      return (
        <span style={{ color: "#bdbdbd" }}>
          {cell.isGrecoPayment ? (
            <>
              <TooltipHost
                content={cell.t("pct.grecoCompany.label")}
                directionalHint={DirectionalHint.rightCenter}
                hidden={false}
              >
                <HomePersonRegular />
              </TooltipHost>{" "}
            </>
          ) : null}
          {cell.isInsurerPayment ? (
            <>
              <TooltipHost
                content={cell.t("pct.insurerCompany.label")}
                directionalHint={DirectionalHint.rightCenter}
                hidden={false}
              >
                <ShieldGlobeRegular />
              </TooltipHost>{" "}
            </>
          ) : null}
          {cell.text}
        </span>
      );
    }
    if (!isInEditMode) {
      if (!cell.selectedValue) {
        return (
          <>
            <span
              style={{ fontStyle: "italic", opacity: "50%", color: "#222222" }}
            >
              {cell.t("pct.clickToSelectClient.label")}
            </span>
          </>
        );
      }
      return (
        <span>
          {cell.isGrecoPayment ? (
            <>
              <TooltipHost
                content={cell.t("pct.grecoCompany.label")}
                directionalHint={DirectionalHint.rightCenter}
                hidden={false}
              >
                <HomePersonRegular />
              </TooltipHost>{" "}
            </>
          ) : null}
          {cell.isInsurerPayment ? (
            <>
              <TooltipHost
                content={cell.t("pct.insurerCompany.label")}
                directionalHint={DirectionalHint.rightCenter}
                hidden={false}
              >
                <ShieldGlobeRegular />
              </TooltipHost>{" "}
            </>
          ) : null}
          <Tooltip content={cell.text} relationship="label">
            <span>
              {cell.gosLink ? (
                <span
                  className="gos-link"
                  onClick={(e) => {
                    window.open(cell.gosLink, "_blank");
                  }}
                >
                  {cell.text}
                </span>
              ) : (
                <>{cell.text}</>
              )}
            </span>
          </Tooltip>
        </span>
      );
    }
    return (
      <div
        style={{ width: "100%" }}
        //onPointerDown={(e) => onCellChanged({ ...cell }, true)}
      >
        <AsyncField
          cell={cell}
          onCellChanged={onCellChanged}
          getCompatibleCell={this.getCompatibleCell}
        />
      </div>
    );
  }
}

const AsyncField = (props: any) => {
  const { cell, onCellChanged, getCompatibleCell } = props;

  const selectRef = React.useRef(null);
  const [{ appState }, {}] = useAppStore();

  React.useEffect(() => {
    if (selectRef.current) {
      selectRef.current.focus();
    }
  }, [selectRef]);

  const theme = useTheme();
  const CustomOption = (props) => {
    const { data, innerProps } = props;

    return (
      <components.Option
        {...props}
        innerProps={{
          ...innerProps,
          onPointerDown: (e) => {
            e.preventDefault();
            onCellChanged(
              {
                ...cell,
                selectedValue: {
                  ...cell.selectedValue,
                  id: data.value.id,
                  name: data.value.isCompany
                    ? data.value.name
                    : " " + data.value.name,
                  internalNumber: data.value.internalNumber,
                  isCompany: data.value.isCompany,
                },
                text: data.value.name + " (" + data.value.internalNumber + ")",
              },
              true
            );
          },
        }}
      >
        {data.label}
      </components.Option>
    );
  };

  return (
    <AsyncSelect
      ref={selectRef}
      components={{ Option: CustomOption }}
      styles={customStyles(appState)}
      loadOptions={debounce(async (input) => {
        const trimmedInput = input.trim();
        if (trimmedInput.length === 0) return [];
        const clients = await searchClients(trimmedInput);
        return clients.data.map((c) => {
          return {
            label: c.name + (c.internalNumber ? ` (${c.internalNumber})` : ""),
            value: c,
          };
        });
      }, 300)}
      placeholder={cell.t("pct.searchClients.label")}
      noOptionsMessage={() => cell.t("greco.noResults")}
      // styles={getSelectStyles(!!field.error, theme)}

      isDisabled={cell.isDisabled && !cell.editable}
      onChange={(newValue, data) => {
        onCellChanged(
          {
            ...cell,
            selectedValue: newValue.value,
            text: newValue.label,
          },
          false
        );
      }}
      // onBlur={(e) => {
      //   onCellChanged(
      //     getCompatibleCell({
      //       ...cell,
      //       text: e.currentTarget.value,
      //       selectedValue: e.currentTarget.value,
      //     }),
      //     (e as any).view?.event?.keyCode !== keyCodes.ESCAPE
      //   );
      // }}
      onKeyDown={(e) => {
        if (isAlphaNumericKey(e.keyCode) || isNavigationKey(e.keyCode))
          e.stopPropagation();
      }}
      value={
        cell.selectedValue instanceof Object
          ? cell.selectedValue.clientName
          : cell.selectedValue
      }
      loadingMessage={() => cell.t("greco.searching")}
      menuPlacement={"auto"}
      menuPosition="absolute"
    />
  );
};
