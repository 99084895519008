// import BankStatement from "pages/BankStatement/BankStatement";
import BankStatements from "pages/BankStatements/BankStatements";
import ClientBalance from "pages/ClientBalance/ClientBalance";
import PaymentPlan from "pages/PaymentPlan/PaymentPlan";
import Payment from "pages/Payments/Payment";
// import PaymentPlan from "pages/PaymentPlan/PaymentPlan";
// import Clearance from "pages/clearance/Clearance";
// import Dashboard from "pages/dashboard/Dashboard";
import Reminders from "pages/Reminders/Reminders";
// import Reports from "pages/report/Reports";
// import Resolvement from "pages/resolvement/Resolvement";
import { FunctionComponent } from "react";
import { matchPath, useLocation } from "react-router-dom";

const paymentPlanPath = "/paymentPlans";
const clientBalancePath = "/clientBalance";
const paymentPath = "/payments";
const bankStatementsPath = "/bankStatements";
const remindersPath = "/reminders";

type RouteDef = {
  key: RouteKey;
  path: string;
  component: FunctionComponent<any>;
  getPath: (...args: any[]) => string;
  matchPath: (loc: any) => any;
};

type Routes = {
  listPage: RouteDef;
  formPageNew: RouteDef;
};

export type RouteKey = keyof Routes;

export const routes = {
  bankStatements: {
    key: "bankStatements",
    path: bankStatementsPath,
    component: BankStatements,
    getPath: () => bankStatementsPath,
    matchPath: (l) =>
      matchPath(
        {
          path: bankStatementsPath,
          // exact: true
        },
        l.pathname
      ),
  },
  // dashboardPath: {
  //   key: "dashboardPage",
  //   path: dashboardPath,
  //   component: Dashboard,
  //   getPath: () => dashboardPath,
  //   matchPath: (l) =>
  //     matchPath(
  //       {
  //         path: dashboardPath,
  //         // exact: true
  //       },
  //       l.pathname
  //     ),
  // },
  // resolvementPage: {
  //   key: "resolvementPage",
  //   path: resolvementPath,
  //   component: Resolvement,
  //   getPath: () => resolvementPath,
  //   matchPath: (l) =>
  //     matchPath(
  //       {
  //         path: resolvementPath,
  //         // exact: true
  //       },
  //       l.pathname
  //     ),
  // },
  // clearancePage: {
  //   key: "clearancePage",
  //   path: clearancePath,
  //   component: Clearance,
  //   getPath: () => clearancePath,
  //   matchPath: (l) =>
  //     matchPath(
  //       {
  //         path: clearancePath,
  //         // exact: true
  //       },
  //       l.pathname
  //     ),
  // },
  // remindersPath: {
  //   key: "remindersPage",
  //   path: remindersPath,
  //   component: Reminders,
  //   getPath: () => remindersPath,
  //   matchPath: (l) =>
  //     matchPath(
  //       {
  //         path: remindersPath,
  //         // exact: true
  //       },
  //       l.pathname
  //     ),
  // },
  // reportsPage: {
  //   key: "reportsPage",
  //   path: reportsPath,
  //   component: Reports,
  //   getPath: () => reportsPath,
  //   matchPath: (l) =>
  //     matchPath(
  //       {
  //         path: reportsPath,
  //         // exact: true
  //       },
  //       l.pathname
  //     ),
  // },

  paymentPlanPage: {
    key: "paymentPlanPage",
    path: paymentPlanPath,
    component: PaymentPlan,
    getPath: () => paymentPlanPath,
    matchPath: (l) =>
      matchPath(
        {
          path: paymentPlanPath,
          // exact: true
        },
        l.pathname
      ),
  },
  clientBalancePage: {
    key: "clientBalancePage",
    path: clientBalancePath,
    component: ClientBalance,
    getPath: () => clientBalancePath,
    matchPath: (l) =>
      matchPath(
        {
          path: clientBalancePath,
          // exact: true
        },
        l.pathname
      ),
  },

  paymentPage: {
    key: "paymentPage",
    path: paymentPath,
    component: Payment,
    getPath: () => paymentPath,
    matchPath: (l) =>
      matchPath(
        {
          path: paymentPath,
          // exact: true
        },
        l.pathname
      ),
  },

  remindersPage: {
    key: "remindersPage",
    path: remindersPath,
    component: Reminders,
    getPath: () => remindersPath,
    matchPath: (l) =>
      matchPath(
        {
          path: remindersPath,
          // exact: true
        },
        l.pathname
      ),
  },

  // bankStatementPage: {
  //   key: "bankStatementPage",
  //   path: bankStatementPath,
  //   component: BankStatement,
  //   getPath: () => bankStatementPath,
  //   matchPath: (l) =>
  //     matchPath(
  //       {
  //         path: bankStatementPath,
  //         // exact: true
  //       },
  //       l.pathname
  //     ),
  // },
};

export const useCurrentRoute = () => {
  const location = useLocation();
  return Object.values(routes).reduce((match: any | null, r) => {
    const matchResult = r.matchPath(location);
    if (!!matchResult) {
      return r.key;
    }
    return match;
  }, null);
};
