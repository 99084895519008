import {
  GrecoCompanyCell,
  GrecoCompanyCellTemplate,
} from "components/grecoreactgrid/customcells/GrecoCompanyCellTemplate";
import { ICellTypeHandler } from "../ICellTypeHandler";

class GrecoCompanyCellTypeHandler implements ICellTypeHandler {
  cellType = "grecocompany";
  isCustom = true;
  instance = new GrecoCompanyCellTemplate();
  determineNewValue = (change: any) => {
    return (change.newCell as GrecoCompanyCell).selectedValue;
  };
  getEntryRow = (
    entry: any,
    columnConfig: any,
    rowsMetadataMap: any,
    keyColumn: string,
    context: {
      [key: string]: any;
    }
  ) => {
    const { t, grecoCompanies, theme } = context;
    const retVal = {
      type: "grecocompany",
      selectedValue: grecoCompanies?.find(
        (el) => el.companyRegisterNumber === entry[columnConfig.columnId] + ""
      )
        ? grecoCompanies?.find(
            (el) =>
              el.companyRegisterNumber === entry[columnConfig.columnId] + ""
          )?.companyRegisterNumber + ""
        : grecoCompanies[0]?.companyRegisterNumber + "",

      selectedValueText: grecoCompanies?.find(
        (el) => el.companyRegisterNumber === entry[columnConfig.columnId] + ""
      )
        ? grecoCompanies?.find(
            (el) =>
              el.companyRegisterNumber === entry[columnConfig.columnId] + ""
          )?.companyShort + ""
        : grecoCompanies[0]?.companyShort + "",
      options: grecoCompanies?.map((el) => {
        return { label: el.companyShort, value: el.companyRegisterNumber + "" };
      }),
      editable: rowsMetadataMap[entry[keyColumn]]?.editable ?? true,
      selected: rowsMetadataMap[entry[keyColumn]]?.selected ?? false,
      highlighted: rowsMetadataMap[entry[keyColumn]]?.highlighted ?? false,

      t,
      theme: theme,
    };
    return retVal;
  };
}

export default GrecoCompanyCellTypeHandler;
