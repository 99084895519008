import { CheckboxCell } from "@silevis/reactgrid";
import { ICellTypeHandler } from "components/grecoreactgrid/ICellTypeHandler";
import { SelectCellTemplate } from "../customcells/SelectCellTemplate";
import { toast } from "components/FluentToast";

class SelectCellTypeHandler implements ICellTypeHandler {
  cellType = "select";
  isCustom = true;
  instance = new SelectCellTemplate();
  determineNewValue = (change: any) => {
    return (change.newCell as CheckboxCell).checked;
  };
  getEntryRow = (
    entry: any,
    columnConfig: any,
    rowsMetadataMap: any,
    keyColumn: string,
    context: {
      [key: string]: any;
    }
  ) => {
    const [{ paymentsMetadataMap }, { setPaymentStoreValue }] =
      context["paymentPageStore"];
    const { t } = context;

    return {
      type: "select",
      selected: rowsMetadataMap[entry[keyColumn]]?.selected ?? false,
      highlighted: rowsMetadataMap[entry[keyColumn]]?.highlighted ?? false,

      checked:
        rowsMetadataMap[entry[columnConfig.additionalInfo.rowIdField]].selected,
      onChecked: (checked: boolean) => {
        console.time("myTimer");
        if (
          [...new Map(Object.entries(paymentsMetadataMap)).values()].reduce(
            (acc: boolean, curr: any) => acc || curr.inEditMode,
            false
          )
        ) {
          toast.error(
            t(
              "pct.please_save_or_cancel_your_changes_before_selecting_a_row.label"
            )
          );
        } else {
          setPaymentStoreValue("paymentsMetadataMap", {
            ...paymentsMetadataMap,
            [entry[columnConfig.additionalInfo.rowIdField]]: {
              ...paymentsMetadataMap[
                entry[columnConfig.additionalInfo.rowIdField]
              ],
              selected: checked,
            },
          });
        }
      },
    };
  };
}

export default SelectCellTypeHandler;
