import { TextCell, TextCellTemplate } from "@silevis/reactgrid";
import {
  CustomInsurerIconCell,
  CustomInsurerIconCellTemplate,
} from "../customcells/CustomInsurerIconCellTemplate";
import { ICellTypeHandler } from "components/grecoreactgrid/ICellTypeHandler";
import { gosLinkTypeToPath } from "utils/utils";

class CustomInsurerIconCellTypeHandler implements ICellTypeHandler {
  cellType = "custominsurericon";
  isCustom = true;
  instance = new CustomInsurerIconCellTemplate();
  determineNewValue = (change: any) => {
    return (change.newCell as CustomInsurerIconCell).text;
  };
  getEntryRow = (
    entry: any,
    columnConfig: any,
    rowsMetadataMap: any,
    keyColumn: string,
    context: {
      [key: string]: any;
    }
  ) => {
    let idField = null;
    if (columnConfig.additionalInfo && columnConfig.additionalInfo.gosLink) {
      //const linkType = columnConfig.additionalInfo.gosLink.linkType;
      idField = columnConfig.additionalInfo.gosLink.idField;
      // gosLink = gosLinkTypeToPath(linkType, entry[idField]);
    }
    return {
      type: "custominsurericon",
      text: entry[columnConfig.columnId] ? entry[columnConfig.columnId] : "",
      editable: rowsMetadataMap[entry[keyColumn]]?.editable ?? true,
      selected: rowsMetadataMap[entry[keyColumn]]?.selected ?? false,
      highlighted: rowsMetadataMap[entry[keyColumn]]?.highlighted ?? false,
      idField: entry[idField] + "",
    };
  };
}

export default CustomInsurerIconCellTypeHandler;
