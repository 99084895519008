import { TextCell } from "@silevis/reactgrid";
import { ICellTypeHandler } from "components/grecoreactgrid/ICellTypeHandler";
import { BankStatementStatusCellTemplate } from "../customcells/BankStatementStatusCellTemplate";

class BankStatementStatusCellTypeHandler implements ICellTypeHandler {
  cellType = "bankstatementstatus";
  isCustom = true;
  instance = new BankStatementStatusCellTemplate();
  determineNewValue = (change: any) => (change.newCell as TextCell).text;
  getEntryRow = (
    entry: any,
    columnConfig: any,
    rowsMetadataMap: any,
    keyColumn: string,
    context: {
      [key: string]: any;
    }
  ) => {
    const { t } = context;
    const bankStatementsStore = context["bankStatementsStore"];
    const [storeState] = bankStatementsStore;
    return {
      type: "bankstatementstatus", //,
      rowId: columnConfig.additionalInfo?.rowIdField
        ? entry[columnConfig.additionalInfo.rowIdField]
        : "",
      numEntries: storeState["bankStatementEntries"].filter((e) => {
        return !e.isDeleted && e.bankStatementId === entry["bankStatementId"];
      }).length,
      numResolved: storeState["bankStatementEntries"].filter((e) => {
        return (
          !e.isDeleted &&
          e.clientId !== null &&
          e.bankStatementId === entry["bankStatementId"]
        );
      }).length,
      numCleared: storeState["bankStatementEntries"].filter((e) => {
        return (
          !e.isDeleted &&
          e.isCleared &&
          e.bankStatementId === entry["bankStatementId"]
        );
      }).length,
      edited: rowsMetadataMap[entry["bankStatementId"]]["inEditMode"],
      selected: rowsMetadataMap[entry["bankStatementId"]]["selected"],
      t,
    };
  };
}

export default BankStatementStatusCellTypeHandler;
