import {
  DatePickerCell,
  DatePickerCellTemplate,
} from "components/grecoreactgrid/customcells/DatePickerCellTemplate";
import { ICellTypeHandler } from "../ICellTypeHandler";
import moment from "moment";

class DatePickerCellTypeHandler implements ICellTypeHandler {
  cellType = "datepicker";
  isCustom = true;
  instance = new DatePickerCellTemplate();
  determineNewValue = (change: any) => {
    return (change.newCell as DatePickerCell).selectedValue;
  };
  getEntryRow = (
    entry: any,
    columnConfig: any,
    rowsMetadataMap: any,
    keyColumn: string,
    context: {
      [key: string]: any;
    }
  ) => {
    const { t } = context;
    return {
      type: "datepicker",
      selectedValue: entry[columnConfig.columnId]
        ? moment(entry[columnConfig.columnId]).toDate()
        : null,
      format: "DD.MM.YYYY",
      editable: rowsMetadataMap[entry[keyColumn]]?.editable ?? true,
      selected: rowsMetadataMap[entry[keyColumn]]?.selected ?? false,
      highlighted: rowsMetadataMap[entry[keyColumn]]?.highlighted ?? false,

      t: t,
    };
  };
}

export default DatePickerCellTypeHandler;
