import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  Spinner,
} from "@fluentui/react-components";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useBankStatementsStore } from "store/BankStatementsStore";

import InfoEntriesGrid from "./InfoEntriesGrid";
import { VSpace } from "components/Spacer";
import { compareNumbersUpToTwoDecimals, currencyFormat } from "utils/utils";
import { useAppStore } from "store/AppStore";
import { formatNumber } from "utils/number";
import { DocumentTableRegular } from "@fluentui/react-icons";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { ExportExcel } from "./ExportExcel";

export const InfoBankStatementEntryDialog = () => {
  const [
    {
      infoBankStatementEntryDialog,
      infoBankStatementEntryStatus,
      bankStatementEntries,
      clBankStatementEntry,
      infoSettledPayments,
    },
    { setBankStatementsStoreValues },
  ] = useBankStatementsStore();

  const refExcelExport = useRef<ExcelExport>();

  const [clTotal, setClTotal] = React.useState(0);

  const [{ taxonomy }] = useAppStore();
  const isLoading = infoBankStatementEntryStatus === "loading";
  const { t } = useTranslation();

  const saveExcel = (component) => {
    if (!component) return;

    const options = component.workbookOptions();
    const data = component.props.data;
    const rows = options.sheets[0].rows;
    if (rows.length === 0) return;
    if (data.length === 0) return;
    for (let index = 1; index < rows.length; index++) {
      const item = data[index - 1];
      const row = rows[index];
      if (row.type === "data" && item["Status"] === "2") {
        row.cells.forEach((cell) => {
          cell.background = "#fed9cc";
        });
      }
    }
    component.save(options);
  };
  useEffect(() => {
    const bankStetementEntry = bankStatementEntries.find((el) => {
      return (
        el.bankStatementEntryId ===
        infoBankStatementEntryDialog.bankStatementEntryId
      );
    });
    setBankStatementsStoreValues({
      clBankStatementEntry: bankStetementEntry,
    });
  }, [bankStatementEntries, infoBankStatementEntryDialog.bankStatementEntryId]);

  useEffect(() => {
    if (!infoSettledPayments) {
      return;
    }
    const totalInfoAmount = infoSettledPayments.reduce(
      (acc, el, currentIndex, array) => {
        if (currentIndex !== array.length) {
          acc = acc + (el.premiumAmount ?? 0);
        }
        return acc;
      },
      0
    );
    setClTotal(totalInfoAmount);
  }, [infoSettledPayments]);

  return (
    <>
      <Dialog open={infoBankStatementEntryDialog.isOpen}>
        <DialogSurface style={{ maxWidth: "96%", transform: "none" }}>
          <DialogBody>
            <DialogTitle>{t("pct.infoBankStatementEntry.label")}</DialogTitle>
            <DialogContent>
              {infoBankStatementEntryStatus !== "loading" ? (
                <>
                  <InfoEntriesGrid />
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <div style={{ float: "right", textAlign: "right" }}>
                      {t("pct.clearedAmount.label")}:{" "}
                      <span style={{ fontWeight: "bold" }}>
                        {formatNumber(clTotal, 2, 2)}
                      </span>
                      /{formatNumber(clBankStatementEntry.amount, 2, 2)}
                      &nbsp;
                      {t(
                        taxonomy.Currency.byId[
                          clBankStatementEntry.accountCurrencyCode
                        ].code
                      )}
                      {compareNumbersUpToTwoDecimals(
                        clBankStatementEntry.amount,
                        clTotal
                      ) !== 0 ? (
                        <>
                          <br />
                          {t("pct.movedToClientSaldo.label")}:&nbsp;
                          <span style={{ fontWeight: "bold" }}>
                            {formatNumber(
                              clBankStatementEntry.amount - clTotal,
                              0,
                              2
                            )}
                            &nbsp;
                            {t(
                              taxonomy.Currency.byId[
                                clBankStatementEntry.accountCurrencyCode
                              ].code
                            )}
                          </span>
                        </>
                      ) : null}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <VSpace height={175} />
                  <Spinner size="large" />
                  <VSpace height={175} />
                </>
              )}
            </DialogContent>
            <DialogActions>
              {infoSettledPayments.filter((el) => !el.isDeleted).length > 0 &&
              !isLoading ? (
                <Button
                  appearance="primary"
                  disabled={isLoading}
                  icon={<DocumentTableRegular />}
                  onClick={() => {
                    saveExcel(refExcelExport.current);
                  }}
                >
                  {t("greco.exportExcel")}
                </Button>
              ) : null}
              <Button
                appearance="secondary"
                disabled={isLoading}
                onClick={() => {
                  setBankStatementsStoreValues({
                    infoBankStatementEntryDialog: {
                      isOpen: false,
                      bankStatementEntry: null,
                    },
                  });
                }}
              >
                {t("pct.close.label")}
              </Button>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
      <ExportExcel ref={refExcelExport} />
    </>
  );
};
