import { Setting } from "@greco/components/dist/types/types";
import {
  createStore,
  createHook,
  createActionsHook,
  createStateHook,
} from "react-sweet-state";
import { ExpandedColumn } from "types/columns";
import { LoadStatus, Notification } from "types/types";
import {
  createSetting,
  deleteSetting,
  loadSettings,
  loadPayments,
  setStoreValue,
  updateSetting,
  loadPaymentPlansForReport,
  searchClients,
  searchInsurers,
  setStoreValues,
  invalidatePayment,
  filterPayments,
} from "./PaymentPageStoreActions";
import { PaymentPlanEntryMetadataMap, PaymentsMetadataMap } from "utils/types";
import moment from "moment";
import { setNotificationMessage } from "./AppStoreActions";

export type StoreState = {
  notifications: Notification[];

  settings: Setting[];
  settingsLoadStatus: LoadStatus;
  searchTerm: string;
  showInvalidated: boolean;
  payments: any[];
  paymentsMetadataMap: PaymentsMetadataMap;
  paymentsSelectAll: boolean;

  paymentsSort: any;
  paymentsLoadStatus: LoadStatus;
  invalidatePaymentsStatus: LoadStatus;
  invalidatePaymentsDialog: {
    isOpen: boolean;
    paymentIds: number[];
  };

  count: number;
  areAnyColumnsFiltered: boolean;
  columnOptionsSettings: Setting[];
  searchClientsSelectedItem: any;
  searchInsurersSelectedItem: any;
  searchServiceSegmentItem: any;
  searchCurrencyItem: any;
  searchPolicyNumber: any;
  searchDateFilterField:
    | "paymentDate"
    | "createdAt"
    | "bookingDate"
    | "declaredAt";
  searchIsDiscountPaidItem: "all" | "yes" | "no";
  fromDateFilter: Date;
  toDateFilter: Date;
  excludeDeclaredPayments: boolean;
};
// This is the value of the store on initialisation
const initialState: StoreState = {
  notifications: [],

  settings: [],
  settingsLoadStatus: "none",
  searchTerm: "",
  payments: [],
  paymentsMetadataMap: {},
  paymentsSelectAll: false,
  paymentsLoadStatus: "none",
  invalidatePaymentsStatus: "none",

  invalidatePaymentsDialog: {
    isOpen: false,
    paymentIds: [],
  },
  showInvalidated: false,

  paymentsSort: null,

  count: 0,
  areAnyColumnsFiltered: false,
  columnOptionsSettings: [],
  searchClientsSelectedItem: null,
  searchInsurersSelectedItem: null,
  searchServiceSegmentItem: null,
  searchCurrencyItem: "378",
  searchPolicyNumber: "",
  searchIsDiscountPaidItem: "all",
  searchDateFilterField: "paymentDate",
  fromDateFilter: moment(new Date())
    .subtract(1, "month")

    .startOf("day")
    .toDate(),
  toDateFilter: moment(new Date()).startOf("day").toDate(),

  excludeDeclaredPayments: true,
};

// All the actions that mutate the store
const actions = {
  setPaymentStoreValue: setStoreValue,
  setPaymentStoreValues: setStoreValues,
  loadSettings: loadSettings,
  loadPayments: loadPayments,
  filterPayments: filterPayments,
  loadPaymentPlansForReport: loadPaymentPlansForReport,
  createSetting: createSetting,
  deleteSetting: deleteSetting,
  updateSetting: updateSetting,
  searchClients: searchClients,
  searchInsurers: searchInsurers,
  invalidatePayment: invalidatePayment,

  setNotificationMessage: setNotificationMessage,
};

const PaymentPageStore = createStore({
  initialState,
  actions,
  name: "PaymentPageStore",
});
export const usePaymentPageStore = createHook(PaymentPageStore);
export const usePaymentPageStoreState = createStateHook(PaymentPageStore);
export const usePaymentPageStoreActions = createActionsHook(PaymentPageStore);

export const useExcludeDeclaredPayments = createHook(PaymentPageStore, {
  selector: (state) => ({
    excludeDeclaredPayments: state.excludeDeclaredPayments,
  }),
});
