import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppStoreState } from "store/AppStore";
import { useBankStatementsStore } from "store/BankStatementsStore";

import { VSpace } from "components/Spacer";
import GrecoReactGrid, {
  standardCellTypeHandlers,
} from "components/grecoreactgrid/GrecoReactGrid";
import DatePickerCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/DatePickerCellTypeHandler";
import ClearedAmountCellTypeHandler from "./customcelltypehandlers/ClearedAmountCellTypeHandler";
import { clearanceEntriesColumnConfig } from "./clearanceEntriesColumnConfig";
import SortableHeaderCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/SortableHeaderCellTypeHandler";
import TaxonomyCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/TaxonomyCellTypeHandler";
import CustomTextCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/CustomTextCellTypeHandler";
import CustomNumberCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/CustomNumberCellTypeHandler";
import ClearedAmountAllCellTypeHandler from "./customcelltypehandlers/ClearedAmountAllCellTypeHandler";
import { useTheme } from "@emotion/react";
import SumHeaderCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/SumHeaderCellTypeHandler";
import OrdinalNumberCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/OrdinalNumberCellTypeHandler";
import { Search } from "./Search";
import {
  compareNumbersUpToTwoDecimals,
  formatNumberForSearch,
} from "utils/utils";
import { formatNumber } from "utils/number";
import moment from "moment";
import styled from "styled-components";

const ClearanceEntriesGrid = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const { taxonomy } = useAppStoreState();
  const bankStatementsStore = useBankStatementsStore();
  const [
    {
      clUnsettledPaymentPlans,
      clUnsettledPaymentPlanMetadataMap,
      clearBankStatementEntrySort,
      clTotal,
      clTotalRows,
      clSearchTerm,
      clBankStatementEntry,
    },
    { setBankStatementsStoreValues, setBankStatementsStoreValue },
  ] = bankStatementsStore;

  useEffect(() => {
    if (!clUnsettledPaymentPlans) {
      return;
    }
    const totalClearedAmount = clUnsettledPaymentPlans.reduce(
      (acc, el, currentIndex, array) => {
        if (currentIndex !== array.length) {
          acc =
            acc +
            (Number.isNaN(el.suggestedPaymentAmount)
              ? 0
              : el.suggestedPaymentAmount ?? 0);
        }
        return acc;
      },
      0
    );
    const totalClearedRows = clUnsettledPaymentPlans.reduce(
      (acc, el, currentIndex, array) => {
        if (currentIndex !== array.length) {
          acc =
            acc +
            (Number.isNaN(el.suggestedPaymentAmount)
              ? 0
              : el.suggestedPaymentAmount > 0
              ? 1
              : 0);
        }
        return acc;
      },
      0
    );
    setBankStatementsStoreValues({
      clTotal: totalClearedAmount,
      clTotalRows: totalClearedRows,
    });
  }, [clUnsettledPaymentPlans]);

  const handleChanges = (
    updatedRows: any[],
    updatedRowsMetadataMap: { [key: number]: any }
  ) => {
    setBankStatementsStoreValues({
      clUnsettledPaymentPlans: clUnsettledPaymentPlans.map((entry) => {
        const updatedEntry = updatedRows.find(
          (row) => row.paymentPlanEntryId === entry.paymentPlanEntryId
        );
        if (updatedEntry) {
          return updatedEntry;
        } else {
          return entry;
        }
      }),
      clUnsettledPaymentPlanMetadataMap: updatedRowsMetadataMap,
    });
  };

  const cellTypeHandlers = {
    ...standardCellTypeHandlers,
    taxonomy: new TaxonomyCellTypeHandler(),
    datepicker: new DatePickerCellTypeHandler(),
    clearedamount: new ClearedAmountCellTypeHandler(),
    sortableheader: new SortableHeaderCellTypeHandler(),
    sumheader: new SumHeaderCellTypeHandler(),
    customnumber: new CustomNumberCellTypeHandler(),
    customtext: new CustomTextCellTypeHandler(),
    clearedamountall: new ClearedAmountAllCellTypeHandler(),
    ordinalnumber: new OrdinalNumberCellTypeHandler(),
  };

  useEffect(() => {
    const retVal = {};
    let matchCount = 0;
    for (const key in clUnsettledPaymentPlanMetadataMap) {
      retVal[key] = {
        ...clUnsettledPaymentPlanMetadataMap[key],
        highlighted: false,
      };
    }
    clUnsettledPaymentPlans.forEach((el) => {
      let isMatch = false;
      if (clSearchTerm && clSearchTerm !== "") {
        isMatch =
          // el.insurerName.toLowerCase().includes(clSearchTerm.toLowerCase()) ||
          el.policyName
            ?.toString()
            .toLowerCase()
            .includes(clSearchTerm.toLowerCase()) ||
          el.clientName
            ?.toString()
            .toLowerCase()
            .includes(clSearchTerm.toLowerCase()) ||
          el.insurerName
            ?.toString()
            .toLowerCase()
            .includes(clSearchTerm.toLowerCase()) ||
          el.policyNumber
            ?.toString()
            .toLowerCase()
            .includes(clSearchTerm.toLowerCase()) ||
          el.policySubNumber
            ?.toString()
            .toLowerCase()
            .includes(clSearchTerm.toLowerCase()) ||
          el.insuranceLineName
            ?.toString()
            .toLowerCase()
            .includes(clSearchTerm.toLowerCase()) ||
          (el.bookingDate
            ? moment(el.bookingDate).format("DD.MM.YYYY")
            : ""
          ).includes(clSearchTerm.toLowerCase()) ||
          (el.premiumFrom
            ? moment(el.premiumFrom).format("DD.MM.YYYY")
            : ""
          ).includes(clSearchTerm.toLowerCase()) ||
          (el.premiumTo
            ? moment(el.premiumTo).format("DD.MM.YYYY")
            : ""
          ).includes(clSearchTerm.toLowerCase()) ||
          formatNumberForSearch(el.premiumAmount).includes(
            clSearchTerm.toLowerCase()
          ) ||
          formatNumberForSearch(el.premiumDiscount).includes(
            clSearchTerm.toLowerCase()
          ) ||
          formatNumberForSearch(el.premiumDiscountPercent).includes(
            clSearchTerm.toLowerCase()
          ) ||
          formatNumberForSearch(el.totalPremiumAmount).includes(
            clSearchTerm.toLowerCase()
          ) ||
          formatNumberForSearch(el.paidPremiumAmount).includes(
            clSearchTerm.toLowerCase()
          ) ||
          formatNumberForSearch(el.paidPremiumDiscountAmount).includes(
            clSearchTerm.toLowerCase()
          ) ||
          formatNumberForSearch(el.premiumDebt).includes(
            clSearchTerm.toLowerCase()
          ) ||
          formatNumberForSearch(el.premiumDiscountDebt).includes(
            clSearchTerm.toLowerCase()
          ) ||
          formatNumberForSearch(el.suggestedPaymentAmount).includes(
            clSearchTerm.toLowerCase()
          );
      }

      matchCount += isMatch ? 1 : 0;
      retVal[el.paymentPlanEntryId] = {
        ...retVal[el.paymentPlanEntryId],
        highlighted: isMatch,
      };
    });
    setBankStatementsStoreValues({
      clUnsettledPaymentPlanMetadataMap: retVal,
      clHightlightCount: matchCount,
    });
  }, [clSearchTerm, clUnsettledPaymentPlans]);

  return (
    <div style={{ height: "calc(100vh - 190px)", overflow: "auto" }}>
      {/* <BankStatementEntriesMenubar />*/}
      <div
        style={{
          width: "calc(100vw - 670px)",
          height: "calc(100vh - 240px)",
          overflow: "scroll",
        }}
      >
        {clUnsettledPaymentPlans.filter((el) => !el.isDeleted).length > 0 ? (
          <StyledWrap>
            <GrecoReactGrid
              rows={clUnsettledPaymentPlans}
              columns={clearanceEntriesColumnConfig(window.innerWidth / 1920)}
              idColumn={"paymentPlanEntryId"}
              rowsMetadataMap={clUnsettledPaymentPlanMetadataMap}
              handleChanges={handleChanges}
              cellTypeHandlers={cellTypeHandlers}
              keyColumn="paymentPlanEntryId"
              stickyColumns={{
                left: 1,
                right: 3,
                top: 1,
                bottom: 1,
              }}
              context={{
                taxonomy: taxonomy,
                t: t,
                bankStatementsStore: bankStatementsStore,
                theme: theme,
                sort: clearBankStatementEntrySort,

                setSort: (sort: any) => {
                  setBankStatementsStoreValue(
                    "clearBankStatementEntrySort",
                    sort
                  );
                },
              }}
              sort={clearBankStatementEntrySort}
            />
          </StyledWrap>
        ) : null}
      </div>
      {clBankStatementEntry !== null ? (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Search
            placeholder="Search"
            value={clSearchTerm}
            onChange={(value) =>
              setBankStatementsStoreValue("clSearchTerm", value)
            }
          />
          <div id="stesic" style={{ marginLeft: "auto", paddingTop: "24px" }}>
            {t("pct.toBeClearedAmount.label")}:{" "}
            <span
              style={{ fontWeight: "bold" }}
              className={
                (clBankStatementEntry?.amount >= 0 &&
                  compareNumbersUpToTwoDecimals(
                    clTotal,
                    clBankStatementEntry?.amount
                  ) > 0) ||
                (clBankStatementEntry?.amount < 0 && clTotal !== 0)
                  ? "redcolor"
                  : ""
              }
            >
              {formatNumber(clTotal, 2, 2)}
            </span>
            /{formatNumber(clBankStatementEntry?.amount, 2, 2)}
            &nbsp;
            {t(
              taxonomy.Currency.byId[clBankStatementEntry?.accountCurrencyCode]
                .code
            )}{" "}
            ({t("pct.numberOfRows.label", { count: clTotalRows })})
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ClearanceEntriesGrid;

const StyledWrap = styled.div`
  .shadow-top-right-corner {
    z-index: 200 !important;
  }
`;
