import { ICellTypeHandler } from "components/grecoreactgrid/ICellTypeHandler";
import {
  DeclaredAmountCell,
  DeclaredAmountCellTemplate,
} from "../customcells/DeclaredAmountCellTemplate";

class DeclaredAmountCellTypeHandler implements ICellTypeHandler {
  cellType = "declaredamount";
  isCustom = true;
  instance = new DeclaredAmountCellTemplate();
  determineNewValue = (change: any) =>
    (change.newCell as DeclaredAmountCell).value;
  getEntryRow = (
    entry: any,
    columnConfig: any,
    rowsMetadataMap: any,
    keyColumn: string,
    context: {
      [key: string]: any;
    }
  ) => {
    const { t } = context;
    const [{ declaredPayments }] = context["paymentDeclarationDialogStore"];
    return {
      type: "declaredamount",
      value: entry[columnConfig.columnId],
      selected: rowsMetadataMap[entry[keyColumn]]?.selected ?? false,
      highlighted: rowsMetadataMap[entry[keyColumn]]?.highlighted ?? false,
      premiumAmount: entry["premiumAmount"],
      getDeclareDataTotal: () => {
        let sum = 0;
        (declaredPayments as any[]).forEach((el) => {
          sum += el.declaredAmount;
        });
        return sum;
      },
      totalToBeDeclared: declaredPayments.reduce(
        (accumulator, current) => accumulator + current.premiumAmount,
        0
      ),
      t,
    };
  };
}

export default DeclaredAmountCellTypeHandler;
