import { Label } from "office-ui-fabric-react";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";
import Skeleton from "react-loading-skeleton";
import { useAppStoreState } from "store/AppStore";
import { useTheme } from "@fluentui/react";
import { Body1Strong, Select } from "@fluentui/react-components";
import { toInteger } from "lodash";
import { usePaymentPageStore } from "store/PaymentPageStore";

type Props = {
  name: string;
  label: string;
  required?: boolean;
  disabled?: boolean;
  taxonomyKey: string;
  value?: any;
  visible?: boolean;
  isLoading: boolean;
  placeholder?: string;
  onBlur?: (e: any) => void;
};

export const SearchServiceSegments = ({
  name,
  label,
  required = false,
  disabled = false,
  taxonomyKey,
  visible,
  isLoading,
  onBlur = null,
  placeholder,
}: Props) => {
  const { t } = useTranslation();
  const { taxonomy } = useAppStoreState();
  const [{ searchServiceSegmentItem }, { setPaymentStoreValue }] =
    usePaymentPageStore();
  const options = useMemo(() => {
    if (!taxonomy || !taxonomy[taxonomyKey]) return [];
    const options = taxonomy[taxonomyKey]?.items
      .map((tax) => {
        return {
          value: tax.id,
          label: t(tax.code),
        };
      })
      .sort((a, b) => a.label.localeCompare(b.label));

    return options;
  }, [t, taxonomy, taxonomyKey]);

  if (!visible) return null;
  return (
    <>
      <Select
        required={required}
        disabled={disabled || isLoading}
        id={name}
        name={"searchServiceSegments"}
        onBlur={(e) => {
          onBlur && onBlur(e);
        }}
        onChange={(e, data) => {
          setPaymentStoreValue("searchServiceSegmentItem", data.value);
        }}
        value={searchServiceSegmentItem || ""}
        title={t("pct.taxonomy.selectTitle.label")}
      >
        {required ? (
          <option key={"none"} value={""}>
            {placeholder}
          </option>
        ) : null}
        {options.map((tax) => (
          <option key={tax.value} value={tax.value}>
            {t(tax.label)}
          </option>
        ))}
      </Select>
    </>
  );
};

export default SearchServiceSegments;
