import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";

export const reactPlugin = new ReactPlugin();

// Telemetry setup
// For more details see https://github.com/microsoft/ApplicationInsights-JS/tree/master/extensions/applicationinsights-react-js
export const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: process.env
      .REACT_APP_APP_INSIGHTS_INSTRUMENTATION_KEY as string,
    enableAutoRouteTracking: true,
    extensions: [reactPlugin],
  },
});

appInsights.loadAppInsights();
appInsights.trackPageView();
