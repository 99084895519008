import { TextCell } from "@silevis/reactgrid";
import { toast } from "components/FluentToast";
import { ICellTypeHandler } from "components/grecoreactgrid/ICellTypeHandler";
import { SelectAllCellTemplate } from "../customcells/SelectAllCellTemplate";

class SelectAllCellTypeHandler implements ICellTypeHandler {
  cellType = "selectall";
  isCustom = true;
  instance = new SelectAllCellTemplate();
  determineNewValue = (change: any) => {
    return (change.newCell as TextCell).text;
  };
  getEntryRow = (
    entry: any,
    columnConfig: any,
    rowsMetadataMap: any,
    keyColumn: string,
    context: {
      [key: string]: any;
    }
  ) => {
    const [
      { paymentsSelectAll, paymentsMetadataMap },
      { setPaymentStoreValues },
    ] = context["paymentPageStore"];
    const { t } = context;

    return {
      type: "selectall",
      value: paymentsSelectAll,
      checked: paymentsSelectAll,
      onChecked: (checked: boolean) => {
        if (
          [...new Map(Object.entries(paymentsMetadataMap)).values()].reduce(
            (acc: boolean, curr: any) => acc || curr.inEditMode,
            false
          )
        ) {
          toast.error(
            t(
              "pct.please_save_or_cancel_your_changes_before_selecting_a_row.label"
            )
          );
        } else {
          let metadataMapCopy = { ...rowsMetadataMap };
          for (let item in metadataMapCopy) {
            metadataMapCopy[item].selected = checked;
          }

          setPaymentStoreValues({
            paymentsSelectAll: checked,
            paymentsMetadataMap: metadataMapCopy,
          });
        }
      },
    };
  };
}

export default SelectAllCellTypeHandler;
