import { ThemeProvider } from "@greco/components";
import LoadingOverlay from "components/LoadingOverlay";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SplitPane, { Pane } from "split-pane-react";
import "split-pane-react/esm/themes/default.css";
import { useAppStore } from "store/AppStore";
import { useBankStatementsStoreState } from "store/BankStatementsStore";
import BankStatementEntryGrid from "./BankStatementEntriesGrid/BankStatementEntryGrid";
import BankStatementGrid from "./BankStatementsGrid/BankStatementGrid";
import Navigation from "./Navigation";
import i18n from "i18n";
import useNotifications from "useNotifications";

const BankStatements = () => {
  const [sizes, setSizes] = useState([100, "30%", "auto"]);
  const [{ appState }, { setAppStoreValue }] = useAppStore();
  const { t } = useTranslation();

  const {
    bankStatementsLoadStatus,
    clearBankStatementEntryStatus,
    importBankStatementStatus,
    deleteBankStatementStatus,
    deleteBankStatementEntryStatus,
    bankStatementSaveStatus,
    bankStatementEntrySaveStatus,
    resolvementLoadStatus,
  } = useBankStatementsStoreState();

  const isLoading =
    clearBankStatementEntryStatus === "loading" ||
    bankStatementsLoadStatus === "loading" ||
    importBankStatementStatus === "loading" ||
    deleteBankStatementStatus === "loading" ||
    deleteBankStatementEntryStatus === "loading" ||
    bankStatementSaveStatus === "loading" ||
    bankStatementEntrySaveStatus === "loading" ||
    resolvementLoadStatus === "loading";

  useEffect(() => {
    setAppStoreValue(
      "headerTitle",
      t("pct.premiumCollectionTool.label") +
        " - " +
        t("pct.bankStatements.label")
    );
  }, [i18n.language]);

  return (
    <>
      <ThemeProvider isDarkMode={appState.darkMode}>
        <Navigation />
      </ThemeProvider>

      <SplitPane
        split="horizontal"
        sizes={sizes}
        onChange={setSizes}
        resizerSize={5}
        sashRender={() => <></>}
        className="split-pane"
        style={{
          height: "calc(100% - 90px)",
        }}
      >
        <Pane minSize={"7%"} maxSize="93%">
          <BankStatementGrid />
        </Pane>
        <Pane minSize={"7%"} maxSize="93%">
          <BankStatementEntryGrid />
        </Pane>
      </SplitPane>

      {isLoading ? <LoadingOverlay /> : null}
    </>
  );
};

export default BankStatements;
