import React from "react";

import { Flex, FlexRight, HorizontalSpacer } from "components/styled";
import { DatePicker, IDatePicker } from "@fluentui/react-datepicker-compat";
import { Search } from "./Search";
import { useBankStatementsStore } from "store/BankStatementsStore";
import { defaultDatePickerStrings } from "@fluentui/react";
import moment from "moment";
import { isInvalidDate } from "utils/utils";
import CompaniesMenu from "components/CompaniesMenu";
import SearchDateFilterField from "../SearchDateFilterField";

const onFormatDate = (date?: Date): string => {
  return !date
    ? ""
    : date.getDate() + "." + (date.getMonth() + 1) + "." + date.getFullYear();
};

const BankStatementsMenubar = (props) => {
  const [
    { searchTerm, fromDateFilter, toDateFilter, bankStatementsLoadStatus },
    { setBankStatementsStoreValue },
  ] = useBankStatementsStore();

  const fromDatePickerRef = React.useRef<IDatePicker>(null);
  const toDatePickerRef = React.useRef<IDatePicker>(null);

  const onParseFromDateFromString = React.useCallback(
    (newValue: string): Date => {
      const previousValue = fromDateFilter || new Date();
      const newValueParts = (newValue || "").trim().split(".");
      const day =
        newValueParts.length > 0
          ? Math.max(1, Math.min(31, parseInt(newValueParts[0], 10)))
          : previousValue.getDate();
      const month =
        newValueParts.length > 1
          ? Math.max(1, Math.min(12, parseInt(newValueParts[1], 10))) - 1
          : previousValue.getMonth();
      let year =
        newValueParts.length > 2
          ? parseInt(newValueParts[2], 10)
          : previousValue.getFullYear();
      return new Date(year, month, day);
    },
    [fromDateFilter]
  );

  const onParseToDateFromString = React.useCallback(
    (newValue: string): Date => {
      const previousValue = toDateFilter || new Date();
      const newValueParts = (newValue || "").trim().split(".");
      const day =
        newValueParts.length > 0
          ? Math.max(1, Math.min(31, parseInt(newValueParts[0], 10)))
          : previousValue.getDate();
      const month =
        newValueParts.length > 1
          ? Math.max(1, Math.min(12, parseInt(newValueParts[1], 10))) - 1
          : previousValue.getMonth();
      let year =
        newValueParts.length > 2
          ? parseInt(newValueParts[2], 10)
          : previousValue.getFullYear();

      return new Date(year, month, day);
    },
    [toDateFilter]
  );

  return (
    <>
      <Search
        placeholder="Search"
        value={searchTerm}
        onChange={(value) => setBankStatementsStoreValue("searchTerm", value)}
      />{" "}
      <HorizontalSpacer width={10} />
      <DatePicker
        onError={(e) => {
          return;
        }}
        onErrorCapture={(e) => {
          return;
        }}
        componentRef={fromDatePickerRef}
        allowTextInput
        value={fromDateFilter}
        onSelectDate={(date: Date | undefined) => {
          setBankStatementsStoreValue("fromDateFilter", date);
        }}
        parseDateFromString={(dateStr) => onParseFromDateFromString(dateStr)}
        strings={defaultDatePickerStrings}
        disableAutoFocus={true}
        formatDate={onFormatDate}
        onBlur={(e) => {
          const inputEl: any =
            e.currentTarget.childNodes[0].childNodes[0].childNodes[0]
              .childNodes[0];
          setTimeout(() => {
            inputEl.blur();
            const val = onParseFromDateFromString(inputEl.value);
            if (
              !isInvalidDate(val) &&
              moment(val.getTime()).format("YYYY-MM-DD") !==
                moment(fromDateFilter?.getTime()).format("YYYY-MM-DD")
            )
              setBankStatementsStoreValue(
                "fromDateFilter",
                isNaN(val.getTime()) ? null : val
              );
          }, 300);
        }}
        placeholder="Select from date..."
      />
      <HorizontalSpacer width={10} />
      <DatePicker
        onError={(e) => {
          return;
        }}
        onErrorCapture={(e) => {
          return;
        }}
        componentRef={toDatePickerRef}
        allowTextInput
        value={toDateFilter}
        onSelectDate={(date) => {
          setBankStatementsStoreValue("toDateFilter", date);
        }}
        parseDateFromString={onParseToDateFromString}
        strings={defaultDatePickerStrings}
        disableAutoFocus={true}
        formatDate={onFormatDate}
        onBlur={(e) => {
          const inputEl: any =
            e.currentTarget.childNodes[0].childNodes[0].childNodes[0]
              .childNodes[0];
          setTimeout(() => {
            inputEl.blur();
            const val = onParseFromDateFromString(inputEl.value);
            if (
              !isInvalidDate(val) &&
              moment(val.getTime()).format("YYYY-MM-DD") !==
                moment(toDateFilter?.getTime()).format("YYYY-MM-DD")
            )
              setBankStatementsStoreValue(
                "toDateFilter",
                isNaN(val.getTime()) ? null : moment(val).toDate()
              );
          }, 300);
        }}
        placeholder="Select to date..."
      />
      <HorizontalSpacer width={10} />
      <SearchDateFilterField
        name="searchDateFilterField"
        isLoading={bankStatementsLoadStatus === "loading"}
        visible={true}
      />{" "}
      <FlexRight>
        <Flex>
          <CompaniesMenu />
        </Flex>
      </FlexRight>
    </>
  );
};

export default BankStatementsMenubar;
