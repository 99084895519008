import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useAppStoreState } from "store/AppStore";
import { useBankStatementsStore } from "store/BankStatementsStore";
import CreatableSelect from "react-select/creatable";
import { useRemindersStore } from "store/RemindersStore";
import useDebouncedState from "components/useDebouncedState";
import { Input } from "@fluentui/react-components";

const SearchSubpolicies = () => {
  const { t } = useTranslation();
  const [{ searchPolicySubNumbers }, { setRemindersStoreValue }] =
    useRemindersStore();
  const [
    searchPolicySubNumbersTerm,
    setSearchPolicySubNumbersTerm,
    debouncedSearchPolicySubNumbersTerm,
  ] = useDebouncedState(null, 500);

  useEffect(() => {
    // Use debouncedSearchTerm here for API calls or other side effects
    setRemindersStoreValue(
      "searchPolicySubNumbers",
      debouncedSearchPolicySubNumbersTerm
    );
  }, [debouncedSearchPolicySubNumbersTerm]);

  const handleChange = (value) => {
    setSearchPolicySubNumbersTerm(value);
  };

  const { appState } = useAppStoreState();

  const customStyles = {
    menu: (baseStyles, state) => ({
      ...baseStyles,
      backgroundColor: appState.darkMode ? "#222222" : "white", // Or any desired color
      color: appState.darkMode ? "white" : "#222222", // Or any desired color
    }),
    control: (baseStyles, state) => ({
      ...baseStyles,
      minWidth: "300px",
      // minHeight: "30px", // reduce the height
      // height: "30px",
      // padding: "0 6px",

      backgroundColor: appState.darkMode ? "#222222" : "white", // Or any desired color
      color: appState.darkMode ? "white" : "white", // Or any desired color
    }),
    valueContainer: (provided) => ({
      ...provided,
      // height: "30px",
      // padding: "0 6px",
      // alignItems: "center", // vertically center the text
    }),
    input: (baseStyles, state) => ({
      ...baseStyles,
      color: appState.darkMode ? "white" : "#222222",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: appState.darkMode ? "white" : "#222222",
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? data.color
          : isFocused
          ? appState.darkMode
            ? "#000000"
            : "#eeeeee"
          : undefined,
        color: isDisabled
          ? "#ccc"
          : isSelected
          ? appState.darkMode
            ? "white"
            : "#222222"
          : appState.darkMode
          ? "white"
          : "#222222",
        cursor: isDisabled ? "not-allowed" : "default",

        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled
            ? isSelected
              ? "yellow"
              : appState.darkMode
              ? "white"
              : "white"
            : undefined,
        },
      };
    },
  };

  return (
    <div>
      <Input
        key={"policySubNumber"}
        name={"policySubNumber"}
        id={"input_policySubNumber"}
        autoComplete="off"
        value={searchPolicySubNumbersTerm?.toString() || ""}
        onChange={(e, newValue) => {
          handleChange(newValue.value);
        }}
        placeholder={t("pct.policySubNumber.label")}
        onFocus={(e) => {
          e.target.select();
        }}
        onCopy={(e) => e.stopPropagation()}
        onCut={(e) => e.stopPropagation()}
        onPaste={(e) => e.stopPropagation()}
      />
      {/* <CreatableSelect
        isMulti
        id="subpoliciesSearch"
        styles={customStyles}
        menuPlacement={"auto"}
        placeholder={t("pct.searchSubpolicies.label")}
        noOptionsMessage={() => t("greco.noResults")}
        isClearable
        isDisabled={false}
        loadingMessage={() => t("greco.searching")}
        value={searchPolicySubNumbersTerm}
        onChange={(value) => {
          handleChange(value);
        }}
      /> */}
    </div>
  );
};

export default SearchSubpolicies;
