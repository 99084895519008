import { TextCell, TextCellTemplate } from "@silevis/reactgrid";
import { ICellTypeHandler } from "../ICellTypeHandler";

class TextCellTypeHandler implements ICellTypeHandler {
  cellType = "text";
  isCustom = false;
  instance = new TextCellTemplate();
  determineNewValue = (change: any) => {
    return (change.newCell as TextCell).text;
  };
  getEntryRow = (
    entry: any,
    columnConfig: any,
    rowsMetadataMap: any,
    keyColumn: string,
    context: {
      [key: string]: any;
    }
  ) => {
    return {
      type: "text",
      text: entry[columnConfig.columnId] ? entry[columnConfig.columnId] : "",
    };
  };
}

export default TextCellTypeHandler;
