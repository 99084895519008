import styled from "@emotion/styled";
import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  Spinner,
  makeStyles,
  shorthands,
} from "@fluentui/react-components";
import { ArrowImportRegular, Dismiss24Filled } from "@fluentui/react-icons";
import { Buffer } from "buffer";
import clsx from "clsx";
import { toast } from "components/FluentToast";
import MyDefaultButton from "components/MyDefaultButton";
import { VSpace } from "components/Spacer";
import { DefaultButton, Icon } from "office-ui-fabric-react";
import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { useBankStatementsStore } from "store/BankStatementsStore";
import { Flex } from "../../../components/styled";
import { useAppStoreState } from "store/AppStore";

const useStyles = makeStyles({
  card: {
    ...shorthands.margin("auto"),
    width: "1024px",
    maxWidth: "100%",
    marginTop: "20px",
    marginBottom: "20px",
  },
  grid: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: "10px",
  },
  gridPremium: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridTemplateRows: "repeat(2, 1fr)",
    gridGap: "10px",
  },
});

export const FormSection = styled.section`
  display: flex;
  flex-wrap: wrap;
  background-color: rgba(201, 201, 201, 0.1);
`;

export const FormSectionItem5 = styled.div`
  width: 16%;
`;

export const FormSectionItem4 = styled.div`
  width: 20%;
  margin: 9px;
`;

export const FormSectionItem100 = styled.div`
  width: 100%;
  margin: 9px;
`;

export const FormSectionItem = styled.div`
  width: auto;
  margin: 9px;
`;

export const FormSectionItem42 = styled.div`
  width: 41%;
  margin: 9px;
`;

export const ImportBankStatementDialog = () => {
  const styles = useStyles();

  const [
    { importBankStatementDialog, importBankStatementStatus },
    {
      setBankStatementsStoreValue,
      setBankStatementsStoreValues,
      importBankStatement,
      loadBankStatements,
    },
  ] = useBankStatementsStore();
  const { taxonomy, grecoCompanies } = useAppStoreState();

  const isLoading = importBankStatementStatus === "loading";
  const [name, setName] = React.useState("");
  const { t } = useTranslation();
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { updatedGrecoCompanies } = useAppStoreState();

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = (event) => {
        // Do whatever you want with the file contents
        // const binaryStr: any = reader.result;
        const binaryStr: any = reader.result;
        const encode = Buffer.from(binaryStr).toString("base64"); // console.log(file.name);
        setFile({ base64str: encode, name: file.name });
        setName(file.name.replace(/\.[^/.]+$/, ""));
      };
      reader.readAsArrayBuffer(file);
    });
  }, []);
  const { getRootProps, getInputProps, isDragAccept, isDragReject, open } =
    useDropzone({
      noDragEventsBubbling: true,
      noClick: true,
      noKeyboard: true,
      accept: {
        "text/xml": [".xml"],
      },
      multiple: false,
      onDrop,
    });

  return (
    <Dialog open={importBankStatementDialog.isOpen}>
      <DialogSurface>
        <DialogBody>
          <DialogTitle>{t("pct.importBankStatement.label")}</DialogTitle>
          <DialogContent>
            <VSpace height={20} />
            <div>
              {/* <label>{t("pct.fileToUpload.label")}</label> */}
              <VSpace height={5} />
              <FormSection>
                <FormSectionItem100>
                  <div
                    className={clsx({
                      accept: isDragAccept,
                      reject: isDragReject,
                    })}
                    {...getRootProps()}
                  >
                    <input {...(getInputProps() as any)} />
                    {file ? (
                      <Flex direction="column" gap={10}>
                        <Flex gap={5} justifyContent="center">
                          <Icon
                            style={{ fontSize: "25px" }}
                            iconName="FileHTML"
                          />
                          <FileName title={file.name}>{file.name}</FileName>
                          <Button
                            appearance="subtle"
                            icon={<Dismiss24Filled />}
                            onClick={() => {
                              setFile(null);
                            }}
                            disabled={isLoading}
                          />
                        </Flex>
                        <MyDefaultButton
                          visible={true}
                          isLoading={isLoading}
                          text={t("pct.chooseDifferentFile")}
                          onClick={() => {
                            setMessage("");
                            open();
                          }}
                        />
                      </Flex>
                    ) : (
                      <Flex direction="column" gap={5}>
                        <UploadIcon iconName="CloudUpload" />
                        <TitleContainer>
                          <Title>{t("pct.dragXmlFileHere")}</Title>
                          <Title>{t("pct.or")}</Title>
                        </TitleContainer>
                        <DefaultButton
                          text={t("pct.browseFiles")}
                          onClick={open}
                        />
                      </Flex>
                    )}
                  </div>
                </FormSectionItem100>
              </FormSection>
            </div>
            <VSpace height={20} />
          </DialogContent>
          <DialogActions>
            <Button
              appearance="secondary"
              disabled={isLoading}
              onClick={() => {
                setBankStatementsStoreValue("importBankStatementDialog", {
                  isOpen: false,
                });
              }}
            >
              {t("pct.close.label")}
            </Button>
            <Button
              appearance="primary"
              disabled={isLoading || !file}
              icon={
                isLoading ? (
                  <Spinner size="tiny" />
                ) : (
                  <ArrowImportRegular fontSize={16} />
                )
              }
              onClick={() => {
                importBankStatement(
                  {
                    name: name,
                    base64String: file.base64str,
                  },
                  () => {
                    setBankStatementsStoreValues({
                      importBankStatementDialog: {
                        isOpen: false,
                      },
                    });
                    toast.success(t("greco.success"));
                    loadBankStatements({
                      taxonomy,
                      grecoCompanies,
                      t,
                      reset: true,
                      delay: true,
                      updatedGrecoCompanies,
                    });
                  }
                );
              }}
            >
              {t("pct.import.label")}
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};

// const Root = styled.div<any>`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   border: 2px dashed ${(p) => p.theme.palette.neutralTertiaryAlt};
//   height: 15rem;
//   padding: 10px;
//   width: 100%;
//   margin: auto;
//   font-size: 18px;
//   font-weight: 600;
//   flex-direction: column;
//   user-select: none;
//   &:focus {
//     outline: none;
//   }
//   &.accept {
//     background-color: ${(p) => p.theme.palette.themeLighterAlt};
//   }
//   &.reject {
//     background-color: ${(p) => p.theme.palette.redDark};
//   }
// `;

const TitleContainer = styled.div`
  text-align: center;
`;

const FileName = styled.div`
  font-size: 20px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const FileNameLink = styled.div`
  cursor: pointer;
  font-size: 20px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Title = styled.div`
  font-size: 20px;
`;

const UploadIcon = styled(Icon)`
  font-size: 35px;
`;
// color: ${(p) => p.theme.palette.neutralPrimary};

// const ExcelIcon = styled(Icon)`
//   font-size: 25px;
//   color: ${(p) => p.theme.palette.greenDark};
// `;
