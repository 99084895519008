import { DirectionalHint, TooltipHost } from "@fluentui/react";
import { Button, Tooltip } from "@fluentui/react-components";
import {
  CheckmarkCircleRegular,
  CheckmarkCircleFilled,
  EditRegular,
} from "@fluentui/react-icons";
import { HSpace } from "@greco/components/dist/components/utils";
import {
  Cell,
  CellTemplate,
  Compatible,
  Uncertain,
  getCellProperty,
} from "@silevis/reactgrid";
import { HorizontalSpacer } from "components/styled";
import * as React from "react";

export interface ReminderStatusCell extends Cell {
  type: "reminderstatus";
  link?: string;
  deleteFn?: any;
  saveFn?: any;
  cancelFn?: any;
  resolvementSuggestFn?: any;
  clearFn?: any;
  rowId?: any;
  clientId?: any;
  edited?: boolean;
  isDisabled?: boolean;
  numEntries?: boolean;
  numResolved?: boolean;
  numCleared?: boolean;
  selected?: boolean;
  highlighted?: boolean;
  t: any;
}

export class ReminderStatusCellTemplate
  implements CellTemplate<ReminderStatusCell>
{
  getCompatibleCell(
    uncertainCell: Uncertain<ReminderStatusCell>
  ): Compatible<ReminderStatusCell> {
    let t: any;
    t = getCellProperty(uncertainCell, "t", "function");

    let rowId: any;

    try {
      rowId = getCellProperty(uncertainCell, "rowId", "number");
    } catch {
      rowId = undefined;
    }

    let isDisabled = true;
    try {
      isDisabled = getCellProperty(uncertainCell, "isDisabled", "boolean");
    } catch {
      isDisabled = false;
    }

    let numEntries = true;
    try {
      numEntries = getCellProperty(uncertainCell, "numEntries", "number");
    } catch {
      numEntries = false;
    }

    let numCleared = true;
    try {
      numCleared = getCellProperty(uncertainCell, "numCleared", "number");
    } catch {
      numCleared = false;
    }

    let numResolved = true;
    try {
      numResolved = getCellProperty(uncertainCell, "numResolved", "number");
    } catch {
      numResolved = false;
    }

    let edited = false;
    try {
      edited = getCellProperty(uncertainCell, "edited", "boolean");
    } catch {
      edited = false;
    }
    let selected = false;
    try {
      selected = getCellProperty(uncertainCell, "selected", "boolean");
    } catch {
      selected = false;
    }
    // let inputValue: string | undefined;
    // try {
    //   inputValue = getCellProperty(uncertainCell, "inputValue", "string");
    // } catch {
    //   inputValue = undefined;
    // }

    const text = "";
    return {
      ...uncertainCell,
      text,
      rowId,

      value: NaN,
      isDisabled,
      numEntries,
      numResolved,
      numCleared,
      edited,
      selected,
      t, // isOpen,
      // inputValue,
    };
  }

  getClassName(
    cell: Compatible<ReminderStatusCell>,
    isInEditMode: boolean
  ): string {
    return `${cell.className ? cell.className : ""}`;
  }

  render(
    cell: Compatible<ReminderStatusCell>,
    isInEditMode: boolean,
    onCellChanged: (
      cell: Compatible<ReminderStatusCell>,
      commit: boolean
    ) => void
  ): React.ReactNode {
    return <OptionsCell cell={cell} />;
  }
}

interface DIProps {
  cell: Record<string, any>;
}

const OptionsCell: React.FC<DIProps> = ({ cell }) => {
  return (
    <div style={{ width: "100%", textAlign: "center" }}>
      <TooltipHost
        content={
          cell.numResolved + " / " + cell.numCleared + " / " + cell.numEntries
        }
        directionalHint={DirectionalHint.topCenter}
        hidden={false}
      >
        {cell.numResolved} / {cell.numCleared} / {cell.numEntries}
      </TooltipHost>
    </div>
  );
};
