import { TextCell } from "@silevis/reactgrid";
import { ICellTypeHandler } from "components/grecoreactgrid/ICellTypeHandler";
import { ClientBalanceOptionsCellTemplate } from "../customcells/ClientBalanceOptionsCellTemplate";
import moment from "moment";

class ClientBalanceOptionsCellTypeHandler implements ICellTypeHandler {
  cellType = "clientbalanceoptions";
  isCustom = true;
  instance = new ClientBalanceOptionsCellTemplate();
  determineNewValue = (change: any) => (change.newCell as TextCell).text;
  getEntryRow = (
    entry: any,
    columnConfig: any,
    rowsMetadataMap: any,
    keyColumn: string,
    context: {
      [key: string]: any;
    }
  ) => {
    const [
      { clientBalances, fromDateFilter, toDateFilter },
      {
        setClientBalancePageStoreValues,
        populateClearanceData,
        populateClientBalanceInfoData,
      },
    ] = context["clientBalancePageStore"];
    const { t, taxonomy } = context;

    return {
      type: "clientbalanceoptions", //,
      rowId: columnConfig.additionalInfo?.rowIdField
        ? entry[columnConfig.additionalInfo.rowIdField]
        : "",
      infoFn: (rowId) => {
        setClientBalancePageStoreValues({
          infoClearanceDialog: {
            isOpen: true,
            clientBalanceId: entry["clientBalanceId"],
          },
          infoClientBalancesSort: null,
        });
        populateClientBalanceInfoData({
          clientBalanceId: entry["clientBalanceId"],
          clientId: entry.clientId,
          companyNumber: entry.companyNumber,
          currencyCode: entry.currencyCode,
        });
      },
      clearFn: () => {
        let client = null;

        if (!entry.isGrECoClient) {
          client = {
            label:
              entry.clientInfo["name"] +
              " (" +
              entry.clientInfo["internalNumber"] +
              ")",
            value: entry.clientInfo["id"],
          };
        }

        setClientBalancePageStoreValues({
          clearClientSaldoDialog: {
            isOpen: true,
            clientName: entry.clientName,
          },
          //clientSaldoItems: items,
          clearClientBalancesSort: null,
          clientSaldo: entry,
          clientSaldoAmount: entry.clientBalanceAmount,
          clCurrencyCodesSelectedItem: {
            value: entry.currencyCode,
            label: t(taxonomy.Currency.byId[entry.currencyCode].code),
          },
          clCurrencyCodesItems: [
            {
              value: entry.currencyCode,
              label: t(taxonomy.Currency.byId[entry.currencyCode].code),
            },
          ],
          clSearchTerm: "",
          clFromDateFilter: moment()
            .subtract(3, "months")
            .add(15, "days")
            .startOf("day")
            .toDate(),
          clToDateFilter: moment().endOf("day").add(15, "days").toDate(),
          clGrecoCompanyNumbers: [entry.companyNumber],
          clInsurersSelectedItem: [],
          clServiceSegmentsSelectedItem: [],
          clPoliciesSelectedItem: [],
          clSubpoliciesSelectedItem: [],
          clClientsSelectedItem: client ? [client] : [],
          clIsBrokerPremiumCollection: true,
          clPaymentDate: null,
          clComment: "",
          clPaymentReferenceNumbersSelectedItem: [],
        });
        if (!entry.isGrECoClient) {
          populateClearanceData({
            clientId: entry.clientInfo.id,
            taxonomy: taxonomy,

            fromDate: moment()
              .subtract(3, "months")
              .add(15, "days")
              .startOf("day")
              .toDate(),
            toDate: moment().endOf("day").add(15, "days").toDate(),
            searchTerm: null,
            clientIds: [entry.clientInfo.id],
            currencyCode: entry.currencyCode,
            serviceSegmentCodes: [],
            policyNumbers: [],
            policySubnumbers: [],
            insurerIds: [],
            grecoCompanyNumbers: [entry.companyNumber],
            paymentReferenceNumbers: [],
            t,
          });
        }
      },
      selected: rowsMetadataMap[entry["clientBalanceId"]]["selected"],
      selectedCount: Object.values(rowsMetadataMap).filter((e: any) => {
        return e.selected;
      }).length,

      t,
    };
  };
}

export default ClientBalanceOptionsCellTypeHandler;
