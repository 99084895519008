import { ExcelExport } from "@progress/kendo-react-excel-export";
import React from "react";
import ClientBalancePageNavigationFirstLine from "./ClientBalancePageNavigationFirstLine";
import ClientBalancePageNavigationSecondLine from "./ClientBalancePageNavigationSecondLine";

type Props = {
  refExcelExport: ExcelExport;
};

const ListPageNavigation = ({ refExcelExport }: Props) => {
  return (
    <>
      <ClientBalancePageNavigationFirstLine refExcelExport={refExcelExport} />
      <ClientBalancePageNavigationSecondLine />
    </>
  );
};

export default ListPageNavigation;
