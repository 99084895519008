import { CommandBarButton } from "@fluentui/react";
import React from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

import { createPDFRows } from "../../../utils/pdf";

import { usePaymentPlanPageStore } from "store/PaymentPlanPageStore";
import { useAppStoreState } from "store/AppStore";
import { Button } from "@fluentui/react-components";
import { DocumentPdfRegular } from "@fluentui/react-icons";

export const ExportPDFButton = () => {
  const { t } = useTranslation();
  const [
    { cols, paymentPlansLoadStatus, searchClientsSelectedItem },
    { loadPaymentPlansForReport },
  ] = usePaymentPlanPageStore();
  const loading = paymentPlansLoadStatus === "loading";
  const { taxonomy, updatedGrecoCompanies } = useAppStoreState();

  return (
    <Button
      disabled={loading}
      appearance="subtle"
      icon={<DocumentPdfRegular fontSize={24} />}
      onClick={() => {
        loadPaymentPlansForReport({
          updatedGrecoCompanies,
          onSuccess: (res) => {
            createPDFRows({
              result: res,
              columns: cols.filter(
                (c) =>
                  c.isVisible === true &&
                  c.key !== "ordinalNumber" &&
                  c.key !== "select"
              ),
              taxonomy,
              t: t,
            }).open();
          },
          onError: () => {},
        });
      }}
    >
      {t("greco.exportPDF")}
    </Button>
  );
};
