import { TextCell } from "@silevis/reactgrid";
import { ICellTypeHandler } from "components/grecoreactgrid/ICellTypeHandler";
import { BankStatementPaidReceivedCellTemplate } from "../customcells/BankStatementPaidReceivedCellTemplate";

class BankStatementPaidReceivedCellTypeHandler implements ICellTypeHandler {
  cellType = "bankstatementpaidreceived";
  isCustom = true;
  instance = new BankStatementPaidReceivedCellTemplate();
  determineNewValue = (change: any) => (change.newCell as TextCell).text;
  getEntryRow = (
    entry: any,
    columnConfig: any,
    rowsMetadataMap: any,
    keyColumn: string,
    context: {
      [key: string]: any;
    }
  ) => {
    const { t } = context;
    const bankStatementsStore = context["bankStatementsStore"];
    const [storeState] = bankStatementsStore;
    return {
      type: "bankstatementpaidreceived", //,
      rowId: columnConfig.additionalInfo?.rowIdField
        ? entry[columnConfig.additionalInfo.rowIdField]
        : "",
      numPaid: storeState["bankStatementEntries"]
        .filter((e) => {
          return (
            e.amount < 0 &&
            !e.isDeleted &&
            e.bankStatementId === entry["bankStatementId"]
          );
        })
        .reduce((a, b) => a - b.amount, 0),
      numReceived: storeState["bankStatementEntries"]
        .filter((e) => {
          return (
            e.amount > 0 &&
            !e.isDeleted &&
            e.bankStatementId === entry["bankStatementId"]
          );
        })
        .reduce((a, b) => a + b.amount, 0),

      edited: rowsMetadataMap[entry["bankStatementId"]]["inEditMode"],
      selected: rowsMetadataMap[entry["bankStatementId"]]["selected"],
      t,
    };
  };
}

export default BankStatementPaidReceivedCellTypeHandler;
