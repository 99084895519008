import axios from "axios";
import { grecoServices } from "@greco/services";

import { MSGraphParams, AzureGraphParams } from "../config/MSGraphConfig";

const graph = require("@microsoft/microsoft-graph-client");

export const MSGraphAuth = new grecoServices.AuthProvider(MSGraphParams);

export const logout = () => {
  MSGraphAuth.logout();
};

async function getAuthenticatedClient() {
  let accessToken;
  await MSGraphAuth.getAccessToken().then((token) => {
    accessToken = token;
  });

  // Initialize Graph client
  const client = await graph.Client.init({
    // Use the provided access token to authenticate
    // requests
    authProvider: (done) => {
      done(null, accessToken.accessToken);
    },
  });

  return client;
}

export async function getUserDetails(userId) {
  const client = await getAuthenticatedClient();

  try {
    const user = await client.api(userId ? "/users/" + userId : "/me").get();
    return user;
  } catch (error) {
    // TODO log error
    return undefined;
  }
}

const readPhotoAsUrl = (blobUrl) => {
  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.onerror = () => {
      reader.abort();
      reject(new DOMException("Problem parsing photo"));
    };

    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(blobUrl);
  });
};

export async function getUserPhotoSmall(userId) {
  try {
    var token = await MSGraphAuth.getAccessToken(AzureGraphParams);
    var response = await axios.get(
      `https://graph.windows.net/${AzureGraphParams.tenantId}` +
        (userId ? "/users/" + userId : "/me") +
        "/thumbnailPhoto?api-version=1.6",
      {
        headers: {
          Authorization: `Bearer ${token.accessToken}`,
        },
        responseType: "blob",
      }
    );

    return await readPhotoAsUrl(response.data);
  } catch (error) {
    // TODO log error
    return "";
  }
}

export async function getUserPhotoMedium() {
  const client = await getAuthenticatedClient();

  const photo = await client.api("/me/photos/96x96/$value").get();

  const url = window.URL || window.webkitURL;
  const blobUrl = url.createObjectURL(photo);

  return blobUrl;
}
