import * as dateFns from "date-fns";
import moment from "moment";

var isFilteredNumber = function isFilteredNumber(f) {
  if (f.operator === "between") {
    return (
      f.number1 !== null &&
      f.number2 !== null &&
      f.number1 !== undefined &&
      f.number2 !== undefined &&
      f.number1 !== "" &&
      f.number2 !== ""
    );
  }

  return f.number1 !== null && f.number1 !== undefined && f.number1 !== "";
};

var isFilteredText = function isFilteredText(f) {
  return f.value.length > 0;
};

var isFilteredTaxonomy = function isFilteredTaxonomy(f) {
  return f.value.length > 0;
};
var isFilteredDate = function isFilteredDate(f) {
  if (f.operator === "isBetween") {
    return !!f.date1 && !!f.date2;
  }

  return !!f.date1;
};
var isFilteredByType = {
  number: isFilteredNumber,
  text: isFilteredText,
  date: isFilteredDate,
  taxonomy: isFilteredTaxonomy,
  boolean: function boolean(f) {
    return typeof f.unfilteredValue === "undefined"
      ? f.value !== null
      : f.value !== f.unfilteredValue;
  },
};

var isColumnFiltered = function isColumnFiltered(f) {
  if (!f) return false;
  return isFilteredByType[f.type](f);
};

export var createAzureFilterParam = function createAzureFilterParam(columns) {
  var columnFilterStrings = columns.reduce(function (f, c) {
    if (c.filter) {
      if (c.filter.type === "taxonomy" && isColumnFiltered(c.filter)) {
        f.push("search.in(" + c.key + ", '" + c.filter.value.join(",") + "')");
      } else if (c.filter.type === "boolean" && isColumnFiltered(c.filter)) {
        f.push(c.key + " eq " + c.filter.value);
      } else if (c.filter.type === "text" && isColumnFiltered(c.filter)) {
        f.push(
          "search.ismatch('" +
            ((c.filter.value as string).includes("-")
              ? '"' + c.filter.value + '"'
              : c.filter.value) +
            "', '" +
            c.key +
            "')"
        );
      } else if (c.filter.type === "date" && isColumnFiltered(c.filter)) {
        if (c.filter.operator === "isAfter") {
          f.push(
            c.key +
              " gt " +
              dateFns.formatISO(new Date(c.filter.date1), {
                representation: "date",
              })
          );
        } else if (c.filter.operator === "isBefore") {
          f.push(
            c.key +
              " lt " +
              dateFns.formatISO(new Date(c.filter.date1), {
                representation: "date",
              })
          );
        } else if (c.filter.operator === "isExact") {
          f.push(
            `${c.key} ge ${c.filter.date1.toISOString()} and ${
              c.key
            } lt ${moment(c.filter.date1).add(24, "hour").toISOString()}`
          );
        } else if (c.filter.operator === "isBetween") {
          f.push(
            c.key +
              " ge " +
              dateFns.formatISO(new Date(c.filter.date1), {
                representation: "date",
              }) +
              " and " +
              c.key +
              " le " +
              dateFns.formatISO(new Date(c.filter.date2), {
                representation: "date",
              })
          );
        }
      } else if (c.filter.type === "number" && isColumnFiltered(c.filter)) {
        var operatorStringNumber1 = c.filter.operator;
        var operatorStringNumber2 = c.filter.operator;

        if (c.filter.operator === "gt" && c.filter.includeNumber1) {
          operatorStringNumber1 = "ge";
        } else if (c.filter.operator === "lt" && c.filter.includeNumber1) {
          operatorStringNumber1 = "le";
        } else if (c.filter.operator === "between") {
          if (c.filter.includeNumber1) {
            operatorStringNumber1 = "ge";
          } else {
            operatorStringNumber1 = "gt";
          }

          if (c.filter.includeNumber2) {
            operatorStringNumber2 = "le";
          } else {
            operatorStringNumber2 = "lt";
          }
        }

        if (c.filter.operator === "between") {
          f.push(
            c.key +
              " " +
              operatorStringNumber1 +
              " " +
              c.filter.number1
                .replaceAll(/\s/g, "")
                .replaceAll(".", "")
                .replaceAll(",", ".") +
              " and " +
              c.key +
              " " +
              operatorStringNumber2 +
              " " +
              c.filter.number2
                .replaceAll(/\s/g, "")
                .replaceAll(".", "")
                .replaceAll(",", ".")
          );
        } else {
          f.push(
            c.key +
              " " +
              operatorStringNumber1 +
              " " +
              c.filter.number1
                .replaceAll(/\s/g, "")
                .replaceAll(".", "")
                .replaceAll(",", ".")
          );
        }
      }
    }

    return f;
  }, []);
  return []
    .concat(columnFilterStrings)
    .filter(function (s) {
      return !!s;
    })
    .join(" and ");
};
