import { Button, Tooltip } from "@fluentui/react-components";
import { InfoRegular, ArrowSplitFilled } from "@fluentui/react-icons";
import { HSpace } from "@greco/components/dist/components/utils";
import {
  Cell,
  CellTemplate,
  Compatible,
  Uncertain,
  getCellProperty,
} from "@silevis/reactgrid";
import { HorizontalSpacer } from "components/styled";
import * as React from "react";

export interface ClientBalanceOptionsCell extends Cell {
  type: "clientbalanceoptions";
  link?: string;
  invalidateFn?: any;
  saveFn?: any;
  cancelFn?: any;
  resolvementSuggestFn?: any;
  clearFn?: any;
  infoFn?: any;
  rowId?: any;
  selectedCount?: any;
  clientId?: any;
  edited?: boolean;
  isDisabled?: boolean;
  isCleared?: boolean;
  selected?: boolean;
  highlighted?: boolean;
  t: any;
}

export class ClientBalanceOptionsCellTemplate
  implements CellTemplate<ClientBalanceOptionsCell>
{
  getCompatibleCell(
    uncertainCell: Uncertain<ClientBalanceOptionsCell>
  ): Compatible<ClientBalanceOptionsCell> {
    let link: string | undefined;
    let t: any;
    t = getCellProperty(uncertainCell, "t", "function");

    let clearFn: any;
    try {
      clearFn = getCellProperty(uncertainCell, "clearFn", "function");
    } catch {
      clearFn = undefined;
    }

    let infoFn: any;
    try {
      infoFn = getCellProperty(uncertainCell, "infoFn", "function");
    } catch {
      infoFn = undefined;
    }

    let selectedCount: any;

    try {
      selectedCount = getCellProperty(uncertainCell, "selectedCount", "number");
    } catch {
      selectedCount = undefined;
    }

    let rowId: any;

    try {
      rowId = getCellProperty(uncertainCell, "rowId", "number");
    } catch {
      rowId = undefined;
    }

    let isDisabled = true;
    try {
      isDisabled = getCellProperty(uncertainCell, "isDisabled", "boolean");
    } catch {
      isDisabled = false;
    }

    let isCleared = true;
    try {
      isCleared = getCellProperty(uncertainCell, "isCleared", "boolean");
    } catch {
      isCleared = false;
    }

    let edited = false;
    try {
      edited = getCellProperty(uncertainCell, "edited", "boolean");
    } catch {
      edited = false;
    }

    let selected = true;
    try {
      selected = getCellProperty(uncertainCell, "selected", "boolean");
    } catch {
      selected = false;
    }
    let highlighted: boolean | undefined;
    try {
      highlighted = getCellProperty(uncertainCell, "highlighted", "boolean");
    } catch {
      highlighted = false;
    }
    // let inputValue: string | undefined;
    // try {
    //   inputValue = getCellProperty(uncertainCell, "inputValue", "string");
    // } catch {
    //   inputValue = undefined;
    // }

    const text = link;
    return {
      ...uncertainCell,
      link,
      text,
      rowId,
      clearFn,
      value: NaN,
      isDisabled,
      isCleared,
      selected,
      highlighted,
      selectedCount,
      edited,
      t, // isOpen,
      // inputValue,
    };
  }

  getClassName(
    cell: Compatible<ClientBalanceOptionsCell>,
    isInEditMode: boolean
  ): string {
    return (
      (cell.className ? cell.className : "") +
      (cell.selected ? " rg-selected" : "") +
      (cell.highlighted ? " highlighted-selected-row" : "")
    );
  }

  render(
    cell: Compatible<ClientBalanceOptionsCell>,
    isInEditMode: boolean,
    onCellChanged: (
      cell: Compatible<ClientBalanceOptionsCell>,
      commit: boolean
    ) => void
  ): React.ReactNode {
    return <OptionsCell cell={cell} />;
  }
}

interface DIProps {
  cell: Record<string, any>;
}

const OptionsCell: React.FC<DIProps> = ({ cell }) => {
  if (cell.isCleared) {
    return null;
  }
  return (
    <div style={{ width: "100%", textAlign: "center" }}>
      <Tooltip
        content={cell.t("pct.clientSaldoInfo.label")}
        relationship="label"
      >
        <Button
          appearance="subtle"
          size="small"
          icon={<InfoRegular />}
          onClick={(e) => {
            cell.infoFn();
          }}
        ></Button>
      </Tooltip>
      <Tooltip
        content={cell.t("pct.clearClientSaldo.label")}
        relationship="label"
      >
        <Button
          appearance="subtle"
          size="small"
          icon={<ArrowSplitFilled />}
          onClick={(e) => {
            cell.clearFn();
          }}
        ></Button>
      </Tooltip>
    </div>
  );
};
