import { Button, Spinner } from "@fluentui/react-components";
import React from "react";
import {
  usePaymentPageStore,
  usePaymentPageStoreActions,
} from "store/PaymentPageStore";
import { DocumentTableRegular } from "@fluentui/react-icons";
import { useTranslation } from "react-i18next";
import {
  useDeclarePaymentsDialog,
  useDeclarePaymentsStatus,
  useDeclaredPayments,
  useGenerateExcelStatus,
  useInsurerReportDialog,
  usePaymentDeclarationDialogStoreActions,
} from "store/PaymentDeclarationDialogStore";
import { useAppStore } from "store/AppStore";
import moment from "moment";

const InsurerReportButton = ({ disabled }) => {
  const { t } = useTranslation();
  const paymentPageStore = usePaymentPageStore();
  const [
    {
      fromDateFilter,
      toDateFilter,
      searchInsurersSelectedItem,
      searchClientsSelectedItem,
      searchServiceSegmentItem,
      searchCurrencyItem,
      searchPolicyNumber,
      searchIsDiscountPaidItem,
      searchDateFilterField,
    },
    { loadPayments },
  ] = paymentPageStore;
  const [{ generateExcelStatus }] = useGenerateExcelStatus();
  const [{ declaredPayments }] = useDeclaredPayments();

  const [{ declarePaymentsDialog }] = useDeclarePaymentsDialog();
  const [{ declarePaymentsStatus }] = useDeclarePaymentsStatus();
  const [{ updatedGrecoCompanies }] = useAppStore();
  const { generateExcel } = usePaymentDeclarationDialogStoreActions();

  return (
    <Button
      appearance="primary"
      disabled={
        generateExcelStatus === "loading" ||
        declarePaymentsStatus === "loading" ||
        disabled
      }
      icon={
        generateExcelStatus === "loading" ? (
          <Spinner size="tiny" />
        ) : (
          <DocumentTableRegular fontSize={24} />
        )
      }
      onClick={() => {
        generateExcel(
          {
            PaymentQueryRequest: {
              IncludeOnlyIds: declaredPayments.map((el) =>
                Number(el.paymentId)
              ),
              ClientIds: searchClientsSelectedItem?.value?.id
                ? [searchClientsSelectedItem?.value?.id]
                : [],
              InsurerIds: searchInsurersSelectedItem?.value?.id
                ? [searchInsurersSelectedItem?.value?.id]
                : [],
              From: fromDateFilter
                ? moment(fromDateFilter).format("YYYY-MM-DD")
                : null,
              To: toDateFilter
                ? moment(toDateFilter).format("YYYY-MM-DD")
                : null,
              ServiceSegmentCodes: searchServiceSegmentItem
                ? [searchServiceSegmentItem]
                : [],
              IsBrokerPremiumCollection: false,
              CurrencyCode: Number(searchCurrencyItem),
              PolicyNumbers:
                searchPolicyNumber && searchPolicyNumber !== ""
                  ? [searchPolicyNumber]
                  : [],
              PolicySubnumbers: [],
              DateFilterField: searchDateFilterField,
              GrecoCompanyNumbers: [declarePaymentsDialog.grecoCompanyNumber],
              IsDiscountQuery:
                searchIsDiscountPaidItem === "yes"
                  ? true
                  : searchIsDiscountPaidItem === "no"
                  ? false
                  : null,
            },
            SpecialPaymentDeclarationInfo: Object.fromEntries(
              new Map(
                declaredPayments.map((obj) => [
                  obj.paymentId + "",
                  obj.declaredAmount,
                ])
              )
            ),
          },
          (data) => {
            loadPayments(updatedGrecoCompanies);
          }
        );
      }}
    >
      {t("pct.generateReport.label")}
    </Button>
  );
};

export default React.memo(InsurerReportButton);
