import { Button } from "@fluentui/react-components";
import React, { useMemo } from "react";
import {
  useReminderEntries,
  useRemindersStoreActions,
  useSearchClientsSelectedItem,
} from "store/RemindersStore";
import { ServiceBellRegular } from "@fluentui/react-icons";
import { useAppStore } from "store/AppStore";
import { useTranslation } from "react-i18next";
import {
  useSuggestedReminder,
  useClReminderNumber,
  useClComment,
  useClUnsettledPaymentPlans,
  useClUnsettledPaymentPlanMetadataMap,
  useClGrecoCompanyNumbers,
  useClSearchPayersSelectedItem,
  useCreateReminderDialog,
  useCreateReminderStatus,
  usePopulateCreateReminderDataStatus,
  useRemindersCreateDialogStoreActions,
} from "store/RemindersCreateDialogStore";

const CreateReminderButton = () => {
  const { setRemindersStoreValues, loadReminders } = useRemindersStoreActions();
  const { setRemindersCreateDialogStoreValues } =
    useRemindersCreateDialogStoreActions();

  const { createReminder } = useRemindersCreateDialogStoreActions();
  const [{ clReminderNumber }] = useClReminderNumber();
  const [{ searchClientsSelectedItem }] = useSearchClientsSelectedItem();

  const [{ createReminderDialog }] = useCreateReminderDialog();

  const [{ reminderEntries }] = useReminderEntries();

  const [{ suggestedReminder }] = useSuggestedReminder();

  const [{ clComment }] = useClComment();

  const [{ clUnsettledPaymentPlans }] = useClUnsettledPaymentPlans();

  const [{ createReminderStatus }] = useCreateReminderStatus();

  const [{ populateCreateReminderDataStatus }] =
    usePopulateCreateReminderDataStatus();

  const [{ clUnsettledPaymentPlanMetadataMap }] =
    useClUnsettledPaymentPlanMetadataMap();

  const [{ clGrecoCompanyNumbers }] = useClGrecoCompanyNumbers();

  const [{ clSearchPayersSelectedItem }] = useClSearchPayersSelectedItem();
  const [{ updatedGrecoCompanies }] = useAppStore();
  const { t } = useTranslation();

  const isLoading =
    createReminderStatus === "loading" ||
    populateCreateReminderDataStatus === "loading";

  const isDisabled =
    isLoading ||
    clReminderNumber === "" ||
    clUnsettledPaymentPlans?.filter(
      (el) => clUnsettledPaymentPlanMetadataMap[el.paymentPlanEntryId].selected
    ).length === 0 ||
    clSearchPayersSelectedItem === null;

  return (
    <Button
      appearance="primary"
      disabled={isDisabled}
      icon={<ServiceBellRegular fontSize={16} />}
      onClick={() => {
        const suggestedPayments = clUnsettledPaymentPlans?.filter(
          (el) =>
            clUnsettledPaymentPlanMetadataMap[el.paymentPlanEntryId].selected
        );
        createReminder(
          {
            clientId: searchClientsSelectedItem.value.id,
            reminder: {
              ReminderNumber: clReminderNumber,
              DueDate: suggestedReminder.dueDate,
              ReminderComment: clComment,
              CurrencyCode: suggestedReminder.currencyCode,
              IsBrokerPremiumCollectionReminder:
                suggestedReminder?.isBrokerPremiumCollectionReminder,
              SuggestedPayments: suggestedPayments,
              GrecoCompanyNumber: clGrecoCompanyNumbers[0],
              payerClientId: clSearchPayersSelectedItem.value.id,
            },
          },
          () => {
            setRemindersCreateDialogStoreValues({
              createReminderDialog: {
                isOpen: false,
                ReminderEntry: null,
                reminder: null,
              },
              clInsurersCountryCode: null,
              clUnsettledPaymentPlans: [],
              clUnsettledPaymentPlanMetadataMap: {},
              clGrecoCompanyNumbers: [],
              clTotal: [0, 0],
              clReminder: null,
              clInsurersSelectedItem: null,
              clSearchTerm: "",
              clComment: "",
              clServiceSegmentCode: null,
            });
            setRemindersStoreValues({
              reminderEntries: reminderEntries.map((el) => {
                if (el.reminderEntryId === createReminderDialog.reminderId) {
                  return {
                    ...el,
                    isCleared: true,
                  };
                }
                return el;
              }),
            });
            loadReminders({
              reset: true,
              delay: true,
              updatedGrecoCompanies,
            });
          }
        );
      }}
    >
      {t("pct.createReminder.label")}
    </Button>
  );
};
export default CreateReminderButton;
