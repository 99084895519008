import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useAppStoreState } from "store/AppStore";
import { useClientBalancePageStore } from "store/ClientBalancePageStore";

import { VSpace } from "components/Spacer";
import GrecoReactGrid, {
  standardCellTypeHandlers,
} from "components/grecoreactgrid/GrecoReactGrid";
import { clearanceEntriesColumnConfig } from "./clearanceEntriesColumnConfig";
import SortableHeaderCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/SortableHeaderCellTypeHandler";
import TaxonomyCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/TaxonomyCellTypeHandler";
import CustomTextCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/CustomTextCellTypeHandler";
import CustomNumberCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/CustomNumberCellTypeHandler";
import ClearedAmountAllCellTypeHandler from "./customcelltypehandlers/ClearedAmountAllCellTypeHandler";

import DatePickerCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/DatePickerCellTypeHandler";
import ClearedAmountCellTypeHandler from "./customcelltypehandlers/ClearedAmountCellTypeHandler";
import { useTheme } from "@emotion/react";
import SumHeaderCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/SumHeaderCellTypeHandler";
import OrdinalNumberCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/OrdinalNumberCellTypeHandler";
import {
  DatePicker,
  IDatePicker,
  defaultDatePickerStrings,
} from "@fluentui/react-datepicker-compat";
import {
  compareNumbersUpToTwoDecimals,
  formatNumberForSearch,
  isInvalidDate,
} from "utils/utils";
import moment from "moment";
import Alert from "components/Alert";
import ClearanceCommentField from "./ClearanceCommentField";

import { Search } from "./Search";
import { formatNumber } from "utils/number";

const ClearanceEntriesGrid = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const clPaymentDatePickerRef = React.useRef<IDatePicker>(null);

  const [openDateBeforeAlert, setOpenDateBeforeAlert] = React.useState(false);

  const { taxonomy } = useAppStoreState();
  const clientBalanceStore = useClientBalancePageStore();
  const [
    {
      clPaymentDate,
      clComment,
      clUnsettledPaymentPlans,
      clUnsettledPaymentPlanMetadataMap,
      clTotal,
      clTotalRows,
      clSearchTerm,
      clearClientBalancesSort,
      clearClientSaldoStatus,
      clientSaldoAmount,
      clCurrencyCodesSelectedItem,
    },
    { setClientBalancePageStoreValue, setClientBalancePageStoreValues },
  ] = clientBalanceStore;

  useEffect(() => {
    if (!clUnsettledPaymentPlans) {
      return;
    }
    const totalClearedAmount = clUnsettledPaymentPlans.reduce(
      (acc, el, currentIndex, array) => {
        if (currentIndex !== array.length) {
          acc =
            acc +
            (Number.isNaN(el.suggestedPaymentAmount)
              ? 0
              : el.suggestedPaymentAmount ?? 0);
        }
        return acc;
      },
      0
    );
    const totalClearedRows = clUnsettledPaymentPlans.reduce(
      (acc, el, currentIndex, array) => {
        if (currentIndex !== array.length) {
          acc =
            acc +
            (Number.isNaN(el.suggestedPaymentAmount)
              ? 0
              : el.suggestedPaymentAmount > 0
              ? 1
              : 0);
        }
        return acc;
      },
      0
    );
    setClientBalancePageStoreValues({
      clTotal: totalClearedAmount,
      clTotalRows: totalClearedRows,
    });
  }, [clUnsettledPaymentPlans]);

  const handleChanges = (
    updatedRows: any[],
    updatedRowsMetadataMap: { [key: number]: any }
  ) => {
    setClientBalancePageStoreValues({
      clUnsettledPaymentPlans: clUnsettledPaymentPlans.map((entry) => {
        const updatedEntry = updatedRows.find(
          (row) => row.paymentPlanEntryId === entry.paymentPlanEntryId
        );
        if (updatedEntry) {
          return updatedEntry;
        } else {
          return entry;
        }
      }),
      clUnsettledPaymentPlanMetadataMap: updatedRowsMetadataMap,
    });
  };

  const cellTypeHandlers = {
    ...standardCellTypeHandlers,
    taxonomy: new TaxonomyCellTypeHandler(),
    datepicker: new DatePickerCellTypeHandler(),
    clearedamount: new ClearedAmountCellTypeHandler(),
    sortableheader: new SortableHeaderCellTypeHandler(),
    sumheader: new SumHeaderCellTypeHandler(),
    customnumber: new CustomNumberCellTypeHandler(),
    customtext: new CustomTextCellTypeHandler(),
    clearedamountall: new ClearedAmountAllCellTypeHandler(),
    ordinalnumber: new OrdinalNumberCellTypeHandler(),
  };

  const onFormatDate = (date?: Date): string => {
    return !date
      ? ""
      : date.getDate() + "." + (date.getMonth() + 1) + "." + date.getFullYear();
  };

  const onParsePaymentDateFromString = React.useCallback(
    (newValue: string): Date => {
      const previousValue = clPaymentDate || new Date();
      const newValueParts = (newValue || "").trim().split(".");
      const day =
        newValueParts.length > 0
          ? Math.max(1, Math.min(31, parseInt(newValueParts[0], 10)))
          : previousValue.getDate();
      const month =
        newValueParts.length > 1
          ? Math.max(1, Math.min(12, parseInt(newValueParts[1], 10))) - 1
          : previousValue.getMonth();
      let year =
        newValueParts.length > 2
          ? parseInt(newValueParts[2], 10)
          : previousValue.getFullYear();

      return new Date(year, month, day);
    },
    [clPaymentDate]
  );

  useEffect(() => {
    const retVal = {};
    let matchCount = 0;
    for (const key in clUnsettledPaymentPlanMetadataMap) {
      retVal[key] = {
        ...clUnsettledPaymentPlanMetadataMap[key],
        highlighted: false,
      };
    }
    clUnsettledPaymentPlans.forEach((el) => {
      let isMatch = false;
      if (clSearchTerm && clSearchTerm !== "") {
        isMatch =
          // el.insurerName.toLowerCase().includes(clSearchTerm.toLowerCase()) ||
          el.policyName
            ?.toString()
            .toLowerCase()
            .includes(clSearchTerm.toLowerCase()) ||
          el.clientName
            ?.toString()
            .toLowerCase()
            .includes(clSearchTerm.toLowerCase()) ||
          el.insurerName
            ?.toString()
            .toLowerCase()
            .includes(clSearchTerm.toLowerCase()) ||
          el.policyNumber
            ?.toString()
            .toLowerCase()
            .includes(clSearchTerm.toLowerCase()) ||
          el.policySubNumber
            ?.toString()
            .toLowerCase()
            .includes(clSearchTerm.toLowerCase()) ||
          el.insuranceLineName
            ?.toString()
            .toLowerCase()
            .includes(clSearchTerm.toLowerCase()) ||
          (el.bookingDate
            ? moment(el.bookingDate).format("DD.MM.YYYY")
            : ""
          ).includes(clSearchTerm.toLowerCase()) ||
          (el.premiumFrom
            ? moment(el.premiumFrom).format("DD.MM.YYYY")
            : ""
          ).includes(clSearchTerm.toLowerCase()) ||
          (el.premiumTo
            ? moment(el.premiumTo).format("DD.MM.YYYY")
            : ""
          ).includes(clSearchTerm.toLowerCase()) ||
          formatNumberForSearch(el.premiumAmount).includes(
            clSearchTerm.toLowerCase()
          ) ||
          formatNumberForSearch(el.premiumDiscount).includes(
            clSearchTerm.toLowerCase()
          ) ||
          formatNumberForSearch(el.premiumDiscountPercent).includes(
            clSearchTerm.toLowerCase()
          ) ||
          formatNumberForSearch(el.totalPremiumAmount).includes(
            clSearchTerm.toLowerCase()
          ) ||
          formatNumberForSearch(el.paidPremiumAmount).includes(
            clSearchTerm.toLowerCase()
          ) ||
          formatNumberForSearch(el.paidPremiumDiscountAmount).includes(
            clSearchTerm.toLowerCase()
          ) ||
          formatNumberForSearch(el.premiumDebt).includes(
            clSearchTerm.toLowerCase()
          ) ||
          formatNumberForSearch(el.premiumDiscountDebt).includes(
            clSearchTerm.toLowerCase()
          ) ||
          formatNumberForSearch(el.suggestedPaymentAmount).includes(
            clSearchTerm.toLowerCase()
          );
      }

      matchCount += isMatch ? 1 : 0;
      retVal[el.paymentPlanEntryId] = {
        ...retVal[el.paymentPlanEntryId],
        highlighted: isMatch,
      };
    });
    setClientBalancePageStoreValues({
      clUnsettledPaymentPlanMetadataMap: retVal,
      clHightlightCount: matchCount,
    });
  }, [clSearchTerm, clUnsettledPaymentPlans]);

  const grid = useMemo(() => {
    if (clUnsettledPaymentPlans.filter((el) => !el.isDeleted).length > 0) {
      return (
        <GrecoReactGrid
          rows={clUnsettledPaymentPlans}
          columns={clearanceEntriesColumnConfig(window.innerWidth / 1920)}
          idColumn={"paymentPlanEntryId"}
          rowsMetadataMap={clUnsettledPaymentPlanMetadataMap}
          handleChanges={handleChanges}
          cellTypeHandlers={cellTypeHandlers}
          keyColumn="paymentPlanEntryId"
          stickyColumns={{
            left: 1,
            right: 3,
            top: 1,
            bottom: 1,
          }}
          context={{
            taxonomy: taxonomy,
            t: t,
            clientBalanceStore: clientBalanceStore,
            theme: theme,
            sort: clearClientBalancesSort,
            setSort: (sort: any) => {
              setClientBalancePageStoreValue("clearClientBalancesSort", sort);
            },
          }}
          sort={clearClientBalancesSort}
        />
      );
    } else {
      return null;
    }
  }, [
    clientBalanceStore,
    cellTypeHandlers,
    clUnsettledPaymentPlans,
    clUnsettledPaymentPlanMetadataMap,
  ]);

  return (
    <>
      <div>
        <div style={{ display: "flex" }}>
          <div style={{ flex: 1 }}>{t("pct.paymentDate.label")}: </div>
          <div style={{ flex: 4 }}>
            <DatePicker
              onError={(e) => {
                return;
              }}
              onErrorCapture={(e) => {
                return;
              }}
              style={{ width: "min-content" }}
              componentRef={clPaymentDatePickerRef}
              allowTextInput
              value={clPaymentDate}
              onSelectDate={(date) => {
                setClientBalancePageStoreValue("clPaymentDate", date);
              }}
              parseDateFromString={onParsePaymentDateFromString}
              strings={defaultDatePickerStrings}
              disableAutoFocus={true}
              formatDate={onFormatDate}
              onBlur={(e) => {
                const inputEl: any =
                  e.currentTarget.childNodes[0].childNodes[0].childNodes[0]
                    .childNodes[0];
                setTimeout(() => {
                  inputEl.blur();
                  const val = onParsePaymentDateFromString(inputEl.value);
                  if (
                    !isInvalidDate(val) &&
                    moment(val.getTime()).format("YYYY-MM-DD") !==
                      moment(clPaymentDate?.getTime()).format("YYYY-MM-DD")
                  )
                    if (
                      !isNaN(val.getTime()) &&
                      moment(val).isBefore(moment().subtract(1, "month"))
                    ) {
                      setOpenDateBeforeAlert(true);
                    }
                  // if (!isNaN(val.getTime()) && moment(val).isAfter(moment())) {
                  //   setOpenDateAfterAlert(true);
                  // }
                  setClientBalancePageStoreValue(
                    "clPaymentDate",
                    isNaN(val.getTime()) ? null : moment(val).toDate()
                  );
                }, 300);
              }}
              placeholder={t("pct.selectDate.label")}
            />
          </div>
        </div>
        <VSpace height={10} />
        <div style={{ display: "flex" }}>
          <div style={{ flex: 1 }}>{t("pct.comment.label")}: </div>
          <div style={{ flex: 4 }}>
            <ClearanceCommentField
              comment={clComment}
              setComment={(comment) =>
                setClientBalancePageStoreValue("clComment", comment)
              }
            />
          </div>
        </div>
      </div>
      <VSpace height={10} />
      <div style={{ height: "calc(100vh - 320px)", overflow: "auto" }}>
        <div
          style={{
            width: "calc(100vw - 670px)",
            height: "calc(100vh - 320px)",
            overflow: "scroll",
          }}
        >
          {grid}
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Search
          placeholder="Search"
          value={clSearchTerm}
          onChange={(value) =>
            setClientBalancePageStoreValue("clSearchTerm", value)
          }
        />
        <div style={{ float: "right", paddingTop: "24px" }}>
          {t("pct.toBeClearedAmount.label")}:{" "}
          <span
            style={{ fontWeight: "bold" }}
            className={
              compareNumbersUpToTwoDecimals(clTotal, clientSaldoAmount) > 0
                ? "redcolor"
                : ""
            }
          >
            {formatNumber(clTotal, 2, 2)}
          </span>
          /{formatNumber(clientSaldoAmount, 2, 2)}
          &nbsp;
          {t(taxonomy.Currency.byId[clCurrencyCodesSelectedItem?.value].code)} (
          {t("pct.numberOfRows.label", { count: clTotalRows })})
        </div>
      </div>
      <Alert
        title={t("pct.paymentDateBeforeAlertTitle.label")}
        content={t("pct.paymentDateBeforeAlert.label")}
        opened={openDateBeforeAlert}
        setOpened={setOpenDateBeforeAlert}
      />
    </>
  );
};

export default ClearanceEntriesGrid;
