import React from "react";
import { useTranslation } from "react-i18next";

import { usePaymentPlanPageStore } from "store/PaymentPlanPageStore";
import { Button } from "@fluentui/react-components";
import { ArrowClockwiseFilled } from "@fluentui/react-icons";
import { useAppStore } from "store/AppStore";

export const RefreshButton = () => {
  const [{ paymentPlansLoadStatus }, { loadPaymentPlans }] =
    usePaymentPlanPageStore();
  const [{ updatedGrecoCompanies }] = useAppStore();
  const loading = paymentPlansLoadStatus === "loading";

  return (
    <Button
      disabled={loading}
      appearance="subtle"
      icon={<ArrowClockwiseFilled fontSize={24} />}
      onClick={() => {
        loadPaymentPlans({ reset: true, delay: false, updatedGrecoCompanies });
      }}
    ></Button>
  );
};
