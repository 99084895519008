import { TextCell } from "@silevis/reactgrid";
import { ICellTypeHandler } from "components/grecoreactgrid/ICellTypeHandler";
import { ReminderAmountsStatusCellTemplate } from "../customcells/ReminderAmountsStatusCellTemplate";

class ReminderAmountsStatusCellTypeHandler implements ICellTypeHandler {
  cellType = "reminderamountsstatus";
  isCustom = true;
  instance = new ReminderAmountsStatusCellTemplate();
  determineNewValue = (change: any) => (change.newCell as TextCell).text;
  getEntryRow = (
    entry: any,
    columnConfig: any,
    rowsMetadataMap: any,
    keyColumn: string,
    context: {
      [key: string]: any;
    }
  ) => {
    const { t } = context;
    const remindersStore = context["remindersStore"];
    const [storeState] = remindersStore;
    return {
      type: "reminderamountsstatus", //,
      rowId: columnConfig.additionalInfo?.rowIdField
        ? entry[columnConfig.additionalInfo.rowIdField]
        : "",
      premium_status: entry["premium_paid_amount_status"],
      discount_status: entry["discount_paid_amount_status"],
      isBrokerPremiumCollectionReminder:
        entry["isBrokerPremiumCollectionReminder"] === true ||
        entry["isBrokerPremiumCollectionReminderEntry"] === true,
      edited:
        rowsMetadataMap[entry[columnConfig.additionalInfo?.rowIdField]][
          "inEditMode"
        ],
      selected:
        rowsMetadataMap[entry[columnConfig.additionalInfo?.rowIdField]][
          "selected"
        ],
      t,
    };
  };
}

export default ReminderAmountsStatusCellTypeHandler;
