import GrecoReactGrid, {
  standardCellTypeHandlers,
} from "components/grecoreactgrid/GrecoReactGrid";
import React, { useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useAppStoreState } from "store/AppStore";
import TaxonomyCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/TaxonomyCellTypeHandler";
import DatePickerCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/DatePickerCellTypeHandler";
import SortableHeaderCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/SortableHeaderCellTypeHandler";
import SelectAllCellTypeHandler from "./customcelltypehandlers/SelectAllCellTypeHandler";
import SelectCellTypeHandler from "./customcelltypehandlers/SelectCellTypeHandler";
import CustomTextCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/CustomTextCellTypeHandler";
import CustomNumberCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/CustomNumberCellTypeHandler";
import LastModifiedCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/LastModifiedCellTypeHandler";
import { useTheme } from "@emotion/react";
import SumHeaderCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/SumHeaderCellTypeHandler";
import CustomCheckmarkCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/CustomCheckmarkCellTypeHandler";
import OrdinalNumberCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/OrdinalNumberCellTypeHandler";
import DeclaredCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/DeclaredCellTypeHandler";
import { clientBalanceColumnConfig } from "./clientBalanceColumnConfig";
import { useClientBalancePageStore } from "store/ClientBalancePageStore";
import ClientBalanceOptionsCellTypeHandler from "./customcelltypehandlers/ClientBalanceOptionsCellTypeHandler";
import ClientCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/ClientCellTypeHandler";
import { compareNumbersUpToTwoDecimals } from "utils/utils";

export const ClientBalancePageTable = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const selectAllState = useState(false);
  const { taxonomy } = useAppStoreState();
  const clientBalancePageStore = useClientBalancePageStore();
  const { grecoCompanies, updatedGrecoCompanies } = useAppStoreState();
  const [
    {
      clientBalances,
      clientBalancesMetadataMap,
      searchClientsSelectedItem,
      searchCurrencyItem,
      searchTerm,
      clientBalancesSort,
      showZeroBalanceClients,
    },
    {
      loadClientBalances,

      setClientBalancePageStoreValues,
      filterClientBalances,
      setClientBalancePageStoreValue,
    },
  ] = clientBalancePageStore;

  useEffect(() => {
    if (updatedGrecoCompanies !== null && grecoCompanies?.length > 0) {
      loadClientBalances({
        grecoCompanyNumbers: updatedGrecoCompanies,
        t,
        grecoCompanies,
        taxonomy,
      });
    }
  }, [searchClientsSelectedItem, searchCurrencyItem, updatedGrecoCompanies]);
  useEffect(() => {
    if (clientBalances) {
      filterClientBalances({ searchTerm });
    }
  }, [searchTerm, clientBalances, showZeroBalanceClients]);

  const handleChanges = (
    updatedRows: any[],
    updatedRowsMetadataMap: { [key: number]: any }
  ) => {
    setClientBalancePageStoreValues({
      clientBalances: clientBalances,
      clientBalancesMetadataMap: clientBalances,
    });
  };

  const cellTypeHandlers = {
    ...standardCellTypeHandlers,
    customtext: new CustomTextCellTypeHandler(),
    customnumber: new CustomNumberCellTypeHandler(),
    taxonomy: new TaxonomyCellTypeHandler(),
    selectall: new SelectAllCellTypeHandler(),
    datepicker: new DatePickerCellTypeHandler(),
    clientbalanceoptions: new ClientBalanceOptionsCellTypeHandler(),
    select: new SelectCellTypeHandler(),
    sortableheader: new SortableHeaderCellTypeHandler(),
    sumheader: new SumHeaderCellTypeHandler(),
    lastmodified: new LastModifiedCellTypeHandler(),
    customcheckmark: new CustomCheckmarkCellTypeHandler(),
    ordinalnumber: new OrdinalNumberCellTypeHandler(),
    declared: new DeclaredCellTypeHandler(),
    client: new ClientCellTypeHandler(),
  };
  // console.log(
  //   searchTerm,
  //   "visible clientBalancesMetadataMap",
  //   Object.values(clientBalancesMetadataMap).reduce((acc: number, obj: any) => {
  //     return obj.visible === true ? acc + 1 : acc;
  //   }, 0)
  // );

  // const rows = useMemo(() => {
  //   let clientBalancesMetadataMapCopy = { ...clientBalancesMetadataMap };
  //   console.log(clientBalancesMetadataMapCopy);

  //   const retVal = clientBalances.filter((clientBalance: any) => {
  //     if (
  //       !showZeroBalanceClients &&
  //       compareNumbersUpToTwoDecimals(clientBalance.clientBalanceAmount, 0) ===
  //         0
  //     ) {
  //       return false;
  //     }
  //     console.log(
  //       clientBalancesMetadataMapCopy,

  //       Number(clientBalance.clientBalanceId),

  //       clientBalancesMetadataMapCopy[clientBalance.clientBalanceId]
  //     );
  //     return clientBalancesMetadataMapCopy[clientBalance.clientBalanceId]
  //       .visible;
  //   });
  //   // console.log(clientBalancesMetadataMapCopy);
  //   return retVal;
  // }, [clientBalances, showZeroBalanceClients, clientBalancesMetadataMap]);
  // // console.log("rows", rows);
  return (
    <>
      <div style={{ height: "calc(100vh - 150px)", overflow: "scroll" }}>
        <GrecoReactGrid
          rows={clientBalances.map((clientBalance: any) => {
            return {
              ...clientBalance,
              clientName:
                clientBalance.clientName === null
                  ? clientBalance.clientLastName +
                    " " +
                    clientBalance.clientFirstName
                  : clientBalance.clientName,
            };
          })}
          columns={clientBalanceColumnConfig}
          idColumn={"clientBalanceId"}
          rowsMetadataMap={clientBalancesMetadataMap}
          handleChanges={handleChanges}
          cellTypeHandlers={cellTypeHandlers}
          keyColumn="clientBalanceId"
          stickyColumns={{
            left: 6,
            top: 1,
            bottom: 1,
            right: 1,
          }}
          context={{
            taxonomy: taxonomy,
            t: t,
            clientBalancePageStore: clientBalancePageStore,
            selectAllState: selectAllState,
            theme: theme,
            sort: clientBalancesSort,
            setSort: (sort: any) => {
              setClientBalancePageStoreValue("clientBalancesSort", sort);
            },
          }}
          sort={clientBalancesSort}
        />
      </div>
    </>
  );
};

export default ClientBalancePageTable;
