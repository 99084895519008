export interface ColumnConfig {
  columnId: string;
  width: number;
  reorderable: boolean;
  resizable: boolean;
  sortable: boolean;
  label: string;
  cellType: string;
  additionalInfo?: {
    taxonomy?: string;
    list?: string;
    clientAccountIdField?: string;
    clientCodeField?: string;
    clientIdField?: string;
    clientNameField?: string;
    linkField?: string;
    rowIdField?: string;
    gosLink?: {
      idField: string;
      linkType: "policy" | "client";
    };
  };
  excel?: any;
  align?: string;
}

export const infoEntriesColumnConfig: ColumnConfig[] = [
  {
    columnId: "ordinalNumber",
    width: 50,
    reorderable: false,
    resizable: true,
    label: "",
    cellType: "ordinalnumber",
    sortable: false,
  },
  {
    columnId: "policyName",
    width: 450,
    reorderable: true,
    resizable: true,
    label: "pct.policyName.label",
    sortable: true,
    cellType: "customtext",
    additionalInfo: {
      gosLink: {
        idField: "policyId",
        linkType: "policy",
      },
    },
  },
  {
    columnId: "clientName",
    width: 250,
    reorderable: true,
    resizable: true,
    sortable: true,
    label: "pct.client.label",
    cellType: "customtext",
    additionalInfo: {
      gosLink: {
        idField: "clientId",
        linkType: "client",
      },
    },
  },
  {
    columnId: "insurerNameShort",
    width: 145,
    reorderable: true,
    resizable: true,
    label: "pct.insurer.label",
    sortable: true,
    cellType: "customtext",
    additionalInfo: {
      gosLink: {
        idField: "insurerId",
        linkType: "client",
      },
    },
  },
  {
    columnId: "reminderNumber",
    width: 280,
    reorderable: true,
    resizable: true,
    sortable: true,
    label: "pct.reminderNumber.label",
    cellType: "customtext",
  },
  {
    columnId: "policyNumber",
    width: 150,
    reorderable: true,
    resizable: true,
    label: "pct.policyNumber.label",
    sortable: true,
    cellType: "customtext",
    additionalInfo: undefined,
  },
  {
    columnId: "policySubNumber",
    width: 150,
    reorderable: true,
    resizable: true,
    label: "pct.policySubNumber.label",
    sortable: true,
    cellType: "customtext",
    additionalInfo: undefined,
  },

  {
    columnId: "insuranceLineCode",
    width: 250,
    reorderable: true,
    resizable: true,
    label: "pct.insuranceLineCode.label",
    sortable: true,
    cellType: "taxonomy",
    additionalInfo: {
      taxonomy: "InsuranceLine",
    },
  },

  {
    columnId: "declaredAt",
    width: 120,
    reorderable: true,
    resizable: true,
    label: "pct.declaredAt.label",
    sortable: true,
    cellType: "datepicker",
    additionalInfo: undefined,
  },
  {
    columnId: "paymentDate",
    width: 120,
    reorderable: true,
    resizable: true,
    label: "pct.paymentDate.label",
    sortable: true,
    cellType: "datepicker",
    additionalInfo: undefined,
  },
  {
    columnId: "createdAt",
    width: 120,
    reorderable: true,
    resizable: true,
    label: "pct.clearedAt.label",
    sortable: true,
    cellType: "datepicker",
    additionalInfo: undefined,
  },
  {
    columnId: "bookingDate",
    width: 120,
    reorderable: true,
    resizable: true,
    label: "pct.bookingDate.label",
    sortable: true,
    cellType: "datepicker",
    additionalInfo: undefined,
  },

  {
    columnId: "currencyCode",
    width: 150,
    reorderable: true,
    resizable: true,
    sortable: true,
    label: "pct.currencyCode.label",
    cellType: "taxonomy",
    additionalInfo: {
      taxonomy: "Currency",
    },
  },

  {
    columnId: "paymentComment",
    width: 650,
    reorderable: true,
    resizable: true,
    label: "pct.paymentComment.label",
    sortable: true,
    cellType: "customtext",
    additionalInfo: undefined,
  },
  {
    columnId: "premiumFrom",
    width: 120,
    reorderable: true,
    resizable: true,
    label: "pct.premiumFrom.label",
    sortable: true,
    cellType: "datepicker",
    additionalInfo: undefined,
  },
  {
    columnId: "premiumTo",
    width: 120,
    reorderable: true,
    resizable: true,
    label: "pct.premiumTo.label",
    sortable: true,
    cellType: "datepicker",
    additionalInfo: undefined,
  },
  // {
  //   columnId: "policyPaymentFrequencyCodeId",
  //   width: 150,
  //   reorderable: true,
  //   resizable: true,
  //   label: "pct.policyPaymentFrequencyCodeId.label",
  //   sortable: false, cellType: "taxonomy",
  //   additionalInfo: {
  //     taxonomy: "PaymentFrequency",
  //   },
  // },

  {
    columnId: "premiumAmount",
    width: 120,
    reorderable: true,
    resizable: true,
    label: "pct.premiumAmount.label",
    sortable: true,
    cellType: "customnumber",
    additionalInfo: undefined,
  },

  {
    columnId: "isDiscountPayment",
    width: 200,
    reorderable: true,
    resizable: true,
    label: "pct.isDiscountPayment.label",
    sortable: true,
    cellType: "customcheckmark",
    additionalInfo: undefined,
  },
];
