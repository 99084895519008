import * as MicrosoftGraph from "@microsoft/microsoft-graph-types";
import { getUserDetails } from "./GraphService";

export let user: Promise<MicrosoftGraph.User> | undefined;

export const getUserData = async (): Promise<MicrosoftGraph.User> => {
  if (!user) {
    user = new Promise<MicrosoftGraph.User>((resolve, reject) => {
      getUserDetails()
        .then((user) => {
          resolve(user);
        })
        .catch(() => {
          reject({});
        });
    });
  }

  return user;
};
