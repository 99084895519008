import React, { useEffect } from "react";
import { SearchBox, ISearchBoxStyles } from "@fluentui/react";
import useDebouncedState from "../../../components/useDebouncedState";

type Props = {
  onChange: (value: string) => void;
  placeholder?: string;
  className?: string;
} & {
  [key: string]: any;
};

const searchBoxStyles: Partial<ISearchBoxStyles> = {
  root: {
    height: 30,
    width: 300,
    marginLeft: 10,
    border: "1px solid",
    selectors: {
      "&::after": {
        borderWidth: 1,
      },
    },
  },
};

export const Search = (props: Props) => {
  const [searchTerm, setSearchTerm, debouncedSearchTerm] = useDebouncedState(
    props.value,
    500
  );

  useEffect(() => {
    // Use debouncedSearchTerm here for API calls or other side effects
    onChange(debouncedSearchTerm);
  }, [debouncedSearchTerm]);

  const handleChange = (value) => {
    setSearchTerm(value);
  };

  const { onChange, placeholder } = props;
  return (
    <div>
      <SearchBox
        styles={searchBoxStyles}
        placeholder={placeholder}
        value={searchTerm}
        onChange={(_, value) => handleChange(value || "")}
      />
    </div>
  );
};
