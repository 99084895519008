import { BankStatementOptionsCellTemplate } from "pages/BankStatements/BankStatementsGrid/customcells/BankStatementOptionsCellTemplate";
import { ICellTypeHandler } from "components/grecoreactgrid/ICellTypeHandler";
import { TextCell } from "@silevis/reactgrid";
import { forEach } from "lodash";

class BankStatementOptionsCellTypeHandler implements ICellTypeHandler {
  cellType = "bankstatementoptions";
  isCustom = true;
  instance = new BankStatementOptionsCellTemplate();
  determineNewValue = (change: any) => (change.newCell as TextCell).text;
  getEntryRow = (
    entry: any,
    columnConfig: any,
    rowsMetadataMap: any,
    keyColumn: string,
    context: {
      [key: string]: any;
    }
  ) => {
    const [
      { bankStatementEntries, bankStatementEntryMetadataMap },
      {
        setBankStatementsStoreValues,
        saveBankStatement,
        cancelEditBankStatement,
        addNewEmptyBankStatementEntry,
        resolvementSuggestForStatement,
      },
    ] = context["bankStatementsStore"];
    const { t, taxonomy } = context;

    return {
      type: "bankstatementoptions", //,
      rowId: columnConfig.additionalInfo?.rowIdField
        ? entry[columnConfig.additionalInfo.rowIdField]
        : "",
      link: columnConfig.additionalInfo?.linkField
        ? entry[columnConfig.additionalInfo.linkField]
        : "",
      deleteFn: (rowId) => {
        setBankStatementsStoreValues({
          deleteBankStatementDialog: {
            isOpen: true,
            bankStatementId: rowId,
          },
        });
      },
      saveFn: (rowId) => {
        saveBankStatement(rowId);
      },
      cancelFn: (rowId) => {
        cancelEditBankStatement(rowId);
      },
      addEntryFn: (rowId) => {
        addNewEmptyBankStatementEntry(rowId);
      },
      resolvementSuggestFn: (rowId) => {
        resolvementSuggestForStatement(
          {
            bankStatementId: entry["bankStatementId"],
          },
          (values) => {
            let newEntries = [];
            let newEntriesMetadataMap = { ...bankStatementEntryMetadataMap };
            for (let index = 0; index < bankStatementEntries.length; index++) {
              const bankStatementEntry = bankStatementEntries[index];
              const val = values.find(
                (el) =>
                  el.bankStatementEntryId ===
                  bankStatementEntry.bankStatementEntryId
              );
              if (!bankStatementEntry.clientId && val?.clientInfo) {
                let meta =
                  newEntriesMetadataMap[
                    bankStatementEntry.bankStatementEntryId
                  ];

                let changes = meta.changes ? [...meta.changes] : [];
                if (val.clientInfo) {
                  changes.push({
                    rowId: index,
                    columnId: "clientInfo",
                    newCell: {
                      isDisabled: false,
                      selectedValue: val.clientInfo,
                      t: t,
                      text:
                        val.clientInfo.name +
                        " (" +
                        val.clientInfo.internalNumber +
                        ")",
                      type: "client",
                      value: val.clientInfo,
                    },
                    previousCell: {
                      isDisabled: false,
                      selectedValue: null,
                      t: t,
                      text: "",
                      type: "client",
                      value: null,
                    },
                    type: "client",
                  });
                }
                newEntries.push({
                  ...bankStatementEntry,
                  clientInfo: val.clientInfo,
                });
                newEntriesMetadataMap[bankStatementEntry.bankStatementEntryId] =
                  {
                    ...meta,
                    inEditMode: true,
                    changes: changes,
                    oldRow: bankStatementEntry,
                  };
              } else {
                newEntries.push(bankStatementEntry);
              }
            }
            setBankStatementsStoreValues({
              bankStatementEntries: newEntries,
              bankStatementEntryMetadataMap: newEntriesMetadataMap,
            });
          }
        );
      },
      edited: rowsMetadataMap[entry["bankStatementId"]]["inEditMode"],
      selected: rowsMetadataMap[entry["bankStatementId"]]["selected"],
      t,
    };
  };
}

export default BankStatementOptionsCellTypeHandler;
