import { useEffect } from "react";
import { useAppStore, useAppStoreActions } from "store/AppStore";
import { useBankStatementsStore } from "store/BankStatementsStore";
import { useClientBalancePageStore } from "store/ClientBalancePageStore";
import { usePaymentPageStore } from "store/PaymentPageStore";
import { usePaymentPlanPageStore } from "store/PaymentPlanPageStore";
import { useRemindersStore } from "store/RemindersStore";

const useNotifications = () => {
  const { setNotificationMessage } = useAppStoreActions();

  const [
    { notifications: bankStatementNotifications },
    { setBankStatementsStoreValue },
  ] = useBankStatementsStore();
  const [
    { notifications: clientBalanceNotifications },
    { setClientBalancePageStoreValue },
  ] = useClientBalancePageStore();
  const [
    { notifications: paymentPlanNotifications },
    { setPaymentPlanStoreValue },
  ] = usePaymentPlanPageStore();
  const [{ notifications: reminderNotifications }, { setRemindersStoreValue }] =
    useRemindersStore();
  const [{ notifications: paymentNotifications }, { setPaymentStoreValue }] =
    usePaymentPageStore();

  useEffect(() => {
    if (bankStatementNotifications.length === 0) return;
    bankStatementNotifications.forEach((notification) => {
      setNotificationMessage(notification);
    });
    setBankStatementsStoreValue("notifications", []);
  }, [bankStatementNotifications]);

  useEffect(() => {
    if (clientBalanceNotifications.length === 0) return;
    clientBalanceNotifications.forEach((notification) => {
      setNotificationMessage(notification);
    });
    setClientBalancePageStoreValue("notifications", []);
  }, [clientBalanceNotifications]);

  useEffect(() => {
    if (paymentPlanNotifications.length === 0) return;
    paymentPlanNotifications.forEach((notification) => {
      setNotificationMessage(notification);
    });
    setPaymentPlanStoreValue("notifications", []);
  }, [paymentPlanNotifications]);

  useEffect(() => {
    if (reminderNotifications.length === 0) return;
    reminderNotifications.forEach((notification) => {
      setNotificationMessage(notification);
    });
    setRemindersStoreValue("notifications", []);
  }, [reminderNotifications]);

  useEffect(() => {
    if (paymentNotifications.length === 0) return;
    paymentNotifications.forEach((notification) => {
      setNotificationMessage(notification);
    });
    setPaymentStoreValue("notifications", []);
  }, [paymentNotifications]);
};

export default useNotifications;
