import { useEffect, useMemo } from "react";
import { usePaymentPlanPageStore } from "store/PaymentPlanPageStore";
import { ExpandedColumn } from "types/columns";
import { ColumnOptionsUtils } from "@greco/components";
import { hydrateColumn } from "../../utils/columns";
import { useAppStore, useAppStoreState } from "store/AppStore";
import { columns } from "./columns";
import { set } from "lodash";

const usePaymentPlanPage = () => {
  const [
    {
      selectedViewId,
      settings,
      selectedView,
      cols,
      tempColumns,
      sort,
      searchTerm,
      showInvalidated,
      searchClientsSelectedItem,
      searchInsurersSelectedItem,
      fromBookingDateFilter,
      toBookingDateFilter,
      searchIsBrokerPremiumCollectionItem,
    },
    {
      setPaymentPlanStoreValue,
      loadSettings,
      loadPaymentPlans,
      setPaymentPlanStoreValues,
    },
  ] = usePaymentPlanPageStore();

  const appStore = useAppStore();

  const [
    { taxonomyUad, grecoCompanies, updatedGrecoCompanies },
    { setAppStoreValue },
  ] = appStore;

  useEffect(() => {
    const selectedView = settings.find(
      (s) =>
        s.userAppSettingId === selectedViewId &&
        s.appSettingCodeId === 900000000010303
    );

    setPaymentPlanStoreValue("selectedView", selectedView);
  }, [selectedViewId, settings]);

  useEffect(() => {
    if (!selectedView) {
      setPaymentPlanStoreValue("selectedViewColumns", columns);
      return;
    }

    const columnsValue = JSON.parse(
      selectedView.userAppSettingValue
    ) as ExpandedColumn[];
    const mappedColumns0 = columnsValue.map((c) => {
      const c0Elem = columns.find((c0) => c0.key === c.key);

      return { ...c0Elem, ...c };
    });
    const mappedColumns = mappedColumns0.map((c) =>
      hydrateColumn({ column: c, defaultColumns: columns })
    );
    setPaymentPlanStoreValue("selectedViewColumns", mappedColumns);
    setPaymentPlanStoreValue("cols", mappedColumns);
    setPaymentPlanStoreValue("tempColumns", mappedColumns);
  }, [selectedView]);

  useEffect(() => {
    setPaymentPlanStoreValue(
      "areAnyColumnsFiltered",
      cols.some((c) => ColumnOptionsUtils.isColumnFiltered(c.filter))
    );
  }, [cols]);

  useEffect(() => {
    if (!taxonomyUad) return;
    const applicationCodeId = taxonomyUad?.ApplicationCode?.find(
      (el) => el.code === "Application.PCT"
    ).id;

    const appSettingCodeId = taxonomyUad?.ApplicationSetting?.find(
      (el) => el.code === "AppSetting.ColumnOptions"
    ).id;

    setPaymentPlanStoreValue(
      "columnOptionsSettings",
      settings.filter((s) => {
        return (
          s.appSettingCodeId === Number(appSettingCodeId) &&
          s.applicationCodeId === Number(applicationCodeId)
        );
      })
    );
  }, [settings, taxonomyUad]);

  useEffect(() => {
    if (!taxonomyUad) return;
    const applicationCodeId = taxonomyUad?.ApplicationCode?.find(
      (el) => el.code === "Application.PCT"
    ).id;
    loadSettings(applicationCodeId);
  }, [taxonomyUad]);

  useEffect(() => {
    if (searchClientsSelectedItem === null) return;
    const clientColumn: any = cols.find((c) => c.key === "client");
    if (
      clientColumn.filter &&
      clientColumn.filter.value &&
      clientColumn.filter.value.length > 0
    ) {
      const newCols = cols.map((c) => {
        if (c.key === "client") {
          return {
            ...c,
            filter: {
              ...c.filter,
              value: [],
            },
          };
        }
        return c;
      });
      const newTempCols = tempColumns.map((c) => {
        if (c.key === "client") {
          return {
            ...c,
            filter: {
              ...c.filter,
              value: [],
            },
          };
        }
        return c;
      });
      setPaymentPlanStoreValues({
        cols: newCols,
        tempColumns: newTempCols,
      });
    }
  }, [searchClientsSelectedItem]);

  useEffect(() => {
    if (searchInsurersSelectedItem === null) return;
    const insurerColumn: any = cols.find((c) => c.key === "insurerName");
    if (
      insurerColumn.filter &&
      insurerColumn.filter.value &&
      insurerColumn.filter.value.length > 0
    ) {
      const newCols = cols.map((c) => {
        if (c.key === "insurerName") {
          return {
            ...c,
            filter: {
              ...c.filter,
              value: [],
            },
          };
        }
        return c;
      });
      const newTempCols = tempColumns.map((c) => {
        if (c.key === "insurerName") {
          return {
            ...c,
            filter: {
              ...c.filter,
              value: [],
            },
          };
        }
        return c;
      });
      setPaymentPlanStoreValues({
        cols: newCols,
        tempColumns: newTempCols,
      });
    }
  }, [searchInsurersSelectedItem]);

  useEffect(() => {
    if (updatedGrecoCompanies !== null && grecoCompanies?.length > 0) {
      loadPaymentPlans(
        { reset: false, delay: false, updatedGrecoCompanies },
        (userRole) => {
          setAppStoreValue("userRole", userRole);
        }
      );
    }
    return () => setPaymentPlanStoreValue("rowItems", []);
  }, [
    sort,
    searchTerm,
    cols,
    showInvalidated,
    searchClientsSelectedItem,
    searchInsurersSelectedItem,
    searchIsBrokerPremiumCollectionItem,
    fromBookingDateFilter,
    toBookingDateFilter,
    updatedGrecoCompanies,
  ]);
};

export default usePaymentPlanPage;
