import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Icon } from "office-ui-fabric-react";
import React from "react";
import { useTranslation } from "react-i18next";

type Props = {
  score: number;
  className?: string;
};

export const ResultsScore = ({ score, className }: Props) => {
  const { t } = useTranslation();
  return (
    <div
      className={className}
      style={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <div
        style={{
          marginTop: "-1px",
        }}
      >
        <StyledIcon iconName="CircleFill" />
      </div>
      <ResultsLabel>{t("greco.searchResults")}</ResultsLabel>
      <ResultNumber>{score}</ResultNumber>
    </div>
  );
};

const ResultsLabel = styled.div`
  font-size: 14px;
  color: ${(p) => p.theme.palette.neutralPrimary};
  margin-right: 5px;
  @media (max-width: 800px) {
    display: none;
  }
`;

const ResultNumber = styled.div`
  font-size: 14px;
  color: ${(p) => p.theme.palette.neutralPrimary};
`;

const StyledIcon = styled(Icon)`
  font-size: 8px;
  color: ${(p) => p.theme.palette.themePrimary};
  margin-right: 8px;
`;
