import { ICellTypeHandler } from "components/grecoreactgrid/ICellTypeHandler";
import {
  ClearedAmountCell,
  ClearedAmountCellTemplate,
} from "../customcells/ClearedAmountCellTemplate";

class ClearedAmountCellTypeHandler implements ICellTypeHandler {
  cellType = "clearedamount";
  isCustom = true;
  instance = new ClearedAmountCellTemplate();
  determineNewValue = (change: any) =>
    (change.newCell as ClearedAmountCell).value;
  getEntryRow = (
    entry: any,
    columnConfig: any,
    rowsMetadataMap: any,
    keyColumn: string,
    context: {
      [key: string]: any;
    }
  ) => {
    const { t } = context;
    const [{ clTotal, clBankStatementEntry }, {}] =
      context["bankStatementsStore"];
    return {
      type: "clearedamount",
      value: entry[columnConfig.columnId],
      selected: rowsMetadataMap[entry[keyColumn]]?.selected ?? false,
      highlighted: rowsMetadataMap[entry[keyColumn]]?.highlighted ?? false,

      premiumDebt: clBankStatementEntry?.isGrEcoPayment
        ? entry["premiumDiscountDebt"]
        : entry["premiumDebt"],
      getClearanceDataTotal: () => {
        return clTotal;
      },
      totalToBeCleared: clBankStatementEntry.amount,
      t,
    };
  };
}

export default ClearedAmountCellTypeHandler;
