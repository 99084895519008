import GrecoReactGrid, {
  standardCellTypeHandlers,
} from "components/grecoreactgrid/GrecoReactGrid";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppStoreState } from "store/AppStore";
import { InvalidatePaymentsDialog } from "./InvalidatePaymentsDialog";
import TaxonomyCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/TaxonomyCellTypeHandler";
import DatePickerCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/DatePickerCellTypeHandler";
import SortableHeaderCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/SortableHeaderCellTypeHandler";
import SelectAllCellTypeHandler from "./customcelltypehandlers/SelectAllCellTypeHandler";
import SelectCellTypeHandler from "./customcelltypehandlers/SelectCellTypeHandler";
import CustomTextCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/CustomTextCellTypeHandler";
import CustomNumberCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/CustomNumberCellTypeHandler";
import { usePaymentPageStore } from "store/PaymentPageStore";
import { paymentColumnConfig } from "./paymentsColumnConfig";
import PaymentOptionsCellTypeHandler from "./customcelltypehandlers/PaymentOptionsCellTypeHandler";
import LastModifiedCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/LastModifiedCellTypeHandler";
import { useTheme } from "@emotion/react";
import SumHeaderCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/SumHeaderCellTypeHandler";
import CustomCheckmarkCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/CustomCheckmarkCellTypeHandler";
import OrdinalNumberCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/OrdinalNumberCellTypeHandler";
import DeclaredCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/DeclaredCellTypeHandler";
import { CustomInsurerIconCellTemplate } from "components/grecoreactgrid/customcells/CustomInsurerIconCellTemplate";
import CustomInsurerIconCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/CustomInsurerIconCellTypeHandler";

export const PaymentPageTable = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const selectAllState = useState(false);
  const { taxonomy } = useAppStoreState();
  const paymentPageStore = usePaymentPageStore();
  const { grecoCompanies, updatedGrecoCompanies } = useAppStoreState();
  const [
    {
      invalidatePaymentsDialog,
      payments,
      paymentsSort,
      paymentsMetadataMap,
      fromDateFilter,
      toDateFilter,
      searchInsurersSelectedItem,
      searchClientsSelectedItem,
      searchServiceSegmentItem,
      searchCurrencyItem,
      searchTerm,
      searchPolicyNumber,
      searchIsDiscountPaidItem,
      searchDateFilterField,
      excludeDeclaredPayments,
    },
    {
      loadPayments,
      setPaymentStoreValues,
      setPaymentStoreValue,
      filterPayments,
    },
  ] = paymentPageStore;

  useEffect(() => {
    if (updatedGrecoCompanies !== null && grecoCompanies?.length > 0) {
      loadPayments(updatedGrecoCompanies);
    }
  }, [
    fromDateFilter,
    toDateFilter,
    searchInsurersSelectedItem,
    searchClientsSelectedItem,
    searchServiceSegmentItem,
    searchCurrencyItem,
    searchPolicyNumber,
    searchIsDiscountPaidItem,
    searchDateFilterField,
    updatedGrecoCompanies,
    excludeDeclaredPayments,
  ]);

  useEffect(() => {
    if (payments) {
      filterPayments({ searchTerm });
    }
  }, [searchTerm, payments]);

  const handleChanges = (
    updatedRows: any[],
    updatedRowsMetadataMap: { [key: number]: any }
  ) => {
    setPaymentStoreValues({
      payments: payments,
      paymentsMetadataMap: paymentsMetadataMap,
    });
  };

  const cellTypeHandlers = {
    ...standardCellTypeHandlers,
    customtext: new CustomTextCellTypeHandler(),
    customnumber: new CustomNumberCellTypeHandler(),
    taxonomy: new TaxonomyCellTypeHandler(),
    selectall: new SelectAllCellTypeHandler(),
    datepicker: new DatePickerCellTypeHandler(),
    paymentoptions: new PaymentOptionsCellTypeHandler(),
    select: new SelectCellTypeHandler(),
    sortableheader: new SortableHeaderCellTypeHandler(),
    sumheader: new SumHeaderCellTypeHandler(),
    lastmodified: new LastModifiedCellTypeHandler(),
    customcheckmark: new CustomCheckmarkCellTypeHandler(),
    ordinalnumber: new OrdinalNumberCellTypeHandler(),
    declared: new DeclaredCellTypeHandler(),
    custominsurericon: new CustomInsurerIconCellTypeHandler(),
  };
  // console.log(
  //   searchTerm,
  //   "visible paymentsMetadataMap",
  //   Object.values(paymentsMetadataMap).reduce((acc: number, obj: any) => {
  //     return obj.visible === true ? acc + 1 : acc;
  //   }, 0)
  // );
  return (
    <>
      <div style={{ height: "calc(100vh - 150px)", overflow: "scroll" }}>
        <GrecoReactGrid
          rows={payments.map((payment: any) => {
            return {
              ...payment,
              clientName:
                payment.clientName === null
                  ? payment.clientLastName + " " + payment.clientFirstName
                  : payment.clientName,
            };
          })}
          columns={paymentColumnConfig}
          idColumn={"paymentId"}
          rowsMetadataMap={paymentsMetadataMap}
          handleChanges={handleChanges}
          cellTypeHandlers={cellTypeHandlers}
          keyColumn="paymentId"
          stickyColumns={{
            left: 5,
            top: 1,
            bottom: 1,
            right: 3,
          }}
          context={{
            taxonomy: taxonomy,
            t: t,
            paymentPageStore: paymentPageStore,
            selectAllState: selectAllState,
            theme: theme,
            sort: paymentsSort,
            setSort: (sort: any) => {
              setPaymentStoreValue("paymentsSort", sort);
            },
          }}
          sort={paymentsSort}
        />
      </div>
      {invalidatePaymentsDialog.isOpen && <InvalidatePaymentsDialog />}
    </>
  );
};

export default PaymentPageTable;
