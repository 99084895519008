import {
  TaxonomyCell,
  TaxonomyCellTemplate,
} from "components/grecoreactgrid/customcells/TaxonomyCellTemplate";
import { ICellTypeHandler } from "../ICellTypeHandler";

class TaxonomyCellTypeHandler implements ICellTypeHandler {
  cellType = "taxonomy";
  isCustom = true;
  instance = new TaxonomyCellTemplate();
  determineNewValue = (change: any) => {
    return parseFloat((change.newCell as TaxonomyCell).selectedValue);
  };
  getEntryRow = (
    entry: any,
    columnConfig: any,
    rowsMetadataMap: any,
    keyColumn: string,
    context: {
      [key: string]: any;
    }
  ) => {
    const { t, taxonomy, theme } = context;
    return {
      type: "taxonomy",
      selectedValue:
        taxonomy[columnConfig.additionalInfo.taxonomy].byId[
          Number(entry[columnConfig.columnId])
        ] &&
        taxonomy[columnConfig.additionalInfo.taxonomy].byId[
          Number(entry[columnConfig.columnId])
        ].id + "",
      selectedValueText: t(
        taxonomy[columnConfig.additionalInfo.taxonomy].byId[
          Number(entry[columnConfig.columnId])
        ] &&
          taxonomy[columnConfig.additionalInfo.taxonomy].byId[
            Number(entry[columnConfig.columnId])
          ].code + ""
      ),
      options: taxonomy[columnConfig.additionalInfo.taxonomy].items.map(
        (el) => {
          return { label: t(el.code), value: el.id + "" };
        }
      ),
      selected: rowsMetadataMap[entry[keyColumn]]?.selected ?? false,
      highlighted: rowsMetadataMap[entry[keyColumn]]?.highlighted ?? false,

      editable: rowsMetadataMap[entry[keyColumn]]?.editable ?? true,

      t,
      theme: theme,
    };
  };
}

export default TaxonomyCellTypeHandler;
