import { Button, Tooltip } from "@fluentui/react-components";
import {
  ArrowDownloadRegular,
  DeleteDismissRegular,
  CheckmarkFilled,
  DismissFilled,
  AddFilled,
  PersonQuestionMarkFilled,
} from "@fluentui/react-icons";
import { HSpace } from "@greco/components/dist/components/utils";
import {
  Cell,
  CellTemplate,
  Compatible,
  Uncertain,
  getCellProperty,
} from "@silevis/reactgrid";
import { HorizontalSpacer } from "components/styled";
import * as React from "react";

export interface BankStatementOptionsCell extends Cell {
  type: "bankstatementoptions";
  link?: string;
  deleteFn?: any;
  saveFn?: any;
  cancelFn?: any;
  addEntryFn?: any;
  resolvementSuggestFn?: any;
  rowId?: any;
  edited?: boolean;
  selected?: boolean;
  highlighted?: boolean;
  isDisabled?: boolean;
  t: any;
}

export class BankStatementOptionsCellTemplate
  implements CellTemplate<BankStatementOptionsCell>
{
  getCompatibleCell(
    uncertainCell: Uncertain<BankStatementOptionsCell>
  ): Compatible<BankStatementOptionsCell> {
    let link: string | undefined;
    let t: any;
    t = getCellProperty(uncertainCell, "t", "function");

    try {
      link = getCellProperty(uncertainCell, "link", "string");
    } catch {
      link = undefined;
    }
    let saveFn: any;

    try {
      saveFn = getCellProperty(uncertainCell, "saveFn", "function");
    } catch {
      saveFn = undefined;
    }

    let addEntryFn: any;

    try {
      addEntryFn = getCellProperty(uncertainCell, "addEntryFn", "function");
    } catch {
      addEntryFn = undefined;
    }

    let cancelFn: any;

    try {
      cancelFn = getCellProperty(uncertainCell, "cancelFn", "function");
    } catch {
      cancelFn = undefined;
    }

    let resolvementSuggestFn: any;
    try {
      resolvementSuggestFn = getCellProperty(
        uncertainCell,
        "resolvementSuggestFn",
        "function"
      );
    } catch {
      resolvementSuggestFn = undefined;
    }

    let deleteFn: any;

    try {
      deleteFn = getCellProperty(uncertainCell, "deleteFn", "function");
    } catch {
      deleteFn = undefined;
    }

    let rowId: any;

    try {
      rowId = getCellProperty(uncertainCell, "rowId", "number");
    } catch {
      rowId = undefined;
    }

    let isDisabled = true;
    try {
      isDisabled = getCellProperty(uncertainCell, "isDisabled", "boolean");
    } catch {
      isDisabled = false;
    }

    let edited = false;
    try {
      edited = getCellProperty(uncertainCell, "edited", "boolean");
    } catch {
      edited = false;
    }

    let selected = false;
    try {
      selected = getCellProperty(uncertainCell, "selected", "boolean");
    } catch {
      selected = false;
    }
    let highlighted: boolean | undefined;
    try {
      highlighted = getCellProperty(uncertainCell, "highlighted", "boolean");
    } catch {
      highlighted = false;
    }
    // let inputValue: string | undefined;
    // try {
    //   inputValue = getCellProperty(uncertainCell, "inputValue", "string");
    // } catch {
    //   inputValue = undefined;
    // }

    const text = link;
    return {
      ...uncertainCell,
      link,
      text,
      rowId,
      deleteFn,
      cancelFn,
      resolvementSuggestFn,
      saveFn,
      addEntryFn,
      value: NaN,
      isDisabled,
      edited,
      selected,
      highlighted,
      t, // isOpen,
      // inputValue,
    };
  }

  getClassName(
    cell: Compatible<BankStatementOptionsCell>,
    isInEditMode: boolean
  ): string {
    return (
      (cell.className ? cell.className : "") +
      (cell.selected ? " rg-selected" : "") +
      (cell.highlighted ? " highlighted-selected-row" : "")
    );
  }

  render(
    cell: Compatible<BankStatementOptionsCell>,
    isInEditMode: boolean,
    onCellChanged: (
      cell: Compatible<BankStatementOptionsCell>,
      commit: boolean
    ) => void
  ): React.ReactNode {
    return <OptionsCell cell={cell} />;
  }
}

interface DIProps {
  cell: Record<string, any>;
}

const OptionsCell: React.FC<DIProps> = ({ cell }) => {
  return (
    <div style={{ width: "100%", textAlign: "center" }}>
      <>
        {cell.edited ? (
          <>
            {cell.saveFn && cell.edited ? (
              <Tooltip content={cell.t("greco.save")} relationship="label">
                <Button
                  appearance="subtle"
                  size="small"
                  id={"saveBtn" + cell.rowId}
                  icon={cell.edited ? <CheckmarkFilled /> : <CheckmarkFilled />}
                  style={{ color: "green" }}
                  onClick={(e) => {
                    cell.saveFn(cell.rowId);
                  }}
                ></Button>
              </Tooltip>
            ) : null}
            {cell.cancelFn ? (
              <Tooltip content={cell.t("greco.cancel")} relationship="label">
                <Button
                  appearance="subtle"
                  size="small"
                  id={"cancelBtn" + cell.rowId}
                  icon={cell.edited ? <DismissFilled /> : <DismissFilled />}
                  style={{ color: "red" }}
                  onClick={(e) => {
                    cell.cancelFn(cell.rowId);
                  }}
                ></Button>
              </Tooltip>
            ) : null}
          </>
        ) : null}
        {!cell.edited ? (
          <>
            {cell.selected ? (
              <>
                <Tooltip
                  content={cell.t("pct.suggestClientResolvement.label")}
                  relationship="label"
                >
                  <Button
                    appearance="subtle"
                    size="small"
                    icon={<PersonQuestionMarkFilled />}
                    onClick={(e) => {
                      cell.resolvementSuggestFn(cell.rowId);
                    }}
                  ></Button>
                </Tooltip>
                {cell.link ? (
                  <Tooltip
                    content={cell.t("pct.bankStatementDocumentPath.label")}
                    relationship="label"
                  >
                    <Button
                      appearance="subtle"
                      size="small"
                      icon={<ArrowDownloadRegular />}
                      onClick={(e) => {
                        window.open(cell.link, "_blank");
                      }}
                    ></Button>
                  </Tooltip>
                ) : (
                  <Tooltip
                    content={cell.t("pct.addBankStatementEntry.label")}
                    relationship="label"
                  >
                    <Button
                      appearance="subtle"
                      size="small"
                      icon={<AddFilled />}
                      onClick={(e) => {
                        cell.addEntryFn(cell.rowId);
                      }}
                    ></Button>
                  </Tooltip>
                )}
              </>
            ) : null}
            {cell.deleteFn ? (
              <Tooltip
                content={cell.t("pct.deleteBankStatement.label")}
                relationship="label"
              >
                <Button
                  appearance="subtle"
                  size="small"
                  icon={<DeleteDismissRegular />}
                  onClick={(e) => {
                    cell.deleteFn(cell.rowId);
                  }}
                ></Button>
              </Tooltip>
            ) : null}
          </>
        ) : null}
      </>
    </div>
  );
};
