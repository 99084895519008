import { ColumnConfig } from "types/reminders";

export const reminderColumnConfig: ColumnConfig[] = [
  {
    columnId: "ordinalNumber",
    width: 50,
    reorderable: false,
    resizable: true,
    label: "",
    cellType: "ordinalnumber",
    sortable: false,
  },
  {
    columnId: "select",
    width: 40,
    reorderable: false,
    resizable: false,
    label: "",
    cellType: "selectsingle",
    sortable: false,
    additionalInfo: {
      rowIdField: "reminderId",
    },
  },
  {
    columnId: "lastModified",
    width: 50,
    reorderable: true,
    resizable: false,
    sortable: true,
    label: "",
    cellType: "lastmodified",
  },
  {
    columnId: "paid_amount_status",
    width: 70,
    reorderable: true,
    resizable: true,
    sortable: true,
    label: "pct.reminderAmountStatus.label",
    cellType: "reminderamountsstatus",
    additionalInfo: {
      rowIdField: "reminderId",
    },
  },
  {
    columnId: "reminderNumber",
    width: 180,
    reorderable: true,
    resizable: true,
    sortable: true,
    label: "pct.reminderNumber.label",
    cellType: "customtext",
  },
  {
    columnId: "clientName",
    width: 300,
    reorderable: true,
    resizable: true,
    sortable: true,
    label: "pct.clientName.label",
    cellType: "customtext",
    additionalInfo: {
      gosLink: {
        idField: "clientId",
        linkType: "client",
      },
    },
  },
  {
    columnId: "payerName",
    width: 300,
    reorderable: true,
    resizable: true,
    sortable: true,
    label: "pct.payerName.label",
    cellType: "customtext",
    additionalInfo: {
      gosLink: {
        idField: "clientId",
        linkType: "client",
      },
    },
  },
  {
    columnId: "insurerInfos",
    width: 150,
    reorderable: true,
    resizable: true,
    sortable: true,
    label: "pct.insurer.label",
    cellType: "insurerinfo",
  },
  {
    columnId: "createdAt",
    width: 120,
    reorderable: true,
    resizable: true,
    sortable: true,
    label: "pct.createdAt.label",
    cellType: "datepicker",
  },
  {
    columnId: "lastPaymentAt",
    width: 120,
    reorderable: true,
    resizable: true,
    sortable: true,
    label: "pct.lastPaymentAt.label",
    cellType: "datepicker",
  },
  {
    columnId: "dueDate",
    width: 120,
    reorderable: true,
    resizable: true,
    sortable: true,
    label: "pct.dueDate.label",
    cellType: "datepicker",
  },
  {
    columnId: "currencyCode",
    width: 70,
    reorderable: true,
    resizable: true,
    sortable: true,
    label: "pct.currencyCode.label",
    cellType: "taxonomy",
    additionalInfo: {
      taxonomy: "Currency",
    },
  },
  // {
  //   columnId: "totalBookedAmount",
  //   width: 150,
  //   reorderable: true,
  //   resizable: true,
  //   sortable: false,
  //   label: "pct.totalBookedAmount.label",
  //   cellType: "customnumber",
  // },

  // {
  //   columnId: "totalAmount",
  //   width: 150,
  //   reorderable: true,
  //   resizable: true,
  //   sortable: false,
  //   label: "pct.totalAmount.label",
  //   cellType: "customnumber",
  // },
  // {
  //   columnId: "totalDiscountAmount",
  //   width: 150,
  //   reorderable: true,
  //   resizable: true,
  //   sortable: false,
  //   label: "pct.totalDiscountAmount.label",
  //   cellType: "customnumber",
  // },
  // {
  //   columnId: "totalPaidBookedAmount",
  //   width: 150,
  //   reorderable: true,
  //   resizable: true,
  //   sortable: false,
  //   label: "pct.totalPaidBookedAmount.label",
  //   cellType: "customnumber",
  // },
  // {
  //   columnId: "totalPaidAmount",
  //   width: 150,
  //   reorderable: true,
  //   resizable: true,
  //   sortable: false,
  //   label: "pct.totalPaidAmount.label",
  //   cellType: "customnumber",
  // },

  // {
  //   columnId: "totalPaidDiscountAmount",
  //   width: 150,
  //   reorderable: true,
  //   resizable: true,
  //   sortable: false,
  //   label: "pct.totalPaidDiscountAmount.label",
  //   cellType: "customnumber",
  // },

  {
    columnId: "booked_paid_amount",
    width: 150,
    reorderable: true,
    resizable: true,
    sortable: false,
    label: "pct.bookedPaidAndAmount.label",
    cellType: "amounts",
    additionalInfo: {
      rowIdField: "reminderId",
    },
  },

  {
    columnId: "total_paid_amount",
    width: 150,
    reorderable: true,
    resizable: true,
    sortable: false,
    label: "pct.totalPaidAndAmount.label",
    cellType: "amounts",
    additionalInfo: {
      rowIdField: "reminderId",
    },
  },
  {
    columnId: "discount_paid_amount",
    width: 150,
    reorderable: true,
    resizable: true,
    sortable: false,
    label: "pct.discountPaidAndAmount.label",
    cellType: "amounts",
    additionalInfo: {
      rowIdField: "reminderId",
    },
  },

  {
    columnId: "reminderComment",
    width: 580,
    reorderable: true,
    resizable: true,
    sortable: true,
    label: "pct.reminderComment.label",
    cellType: "customtext",
  },

  {
    columnId: "isBrokerPremiumCollectionReminder",
    width: 80,
    reorderable: true,
    resizable: true,
    sortable: true,
    label: "pct.isBrokerPremiumCollectionReminder.label",
    cellType: "customcheckmark",
  },
  {
    columnId: "options",
    width: 120,
    reorderable: true,
    resizable: true,
    sortable: false,
    label: "pct.options.label",
    cellType: "reminderoptions",
    additionalInfo: {
      rowIdField: "reminderId",
      linkField: "reminderDocumentPath",
      linkField2: "additionalReminderDocumentPath",
    },
  },
];
