import { ICellTypeHandler } from "components/grecoreactgrid/ICellTypeHandler";

import {
  OrdinalNumberCell,
  OrdinalNumberCellTemplate,
} from "../customcells/OrdinalNumberCellTemplate";

class OrdinalNumberCellTypeHandler implements ICellTypeHandler {
  cellType = "ordinalnumber";
  isCustom = true;
  instance = new OrdinalNumberCellTemplate();
  determineNewValue = (change: any) => {
    return (change.newCell as OrdinalNumberCell).value;
  };
  getEntryRow = (
    entry: any,
    columnConfig: any,
    rowsMetadataMap: any,
    keyColumn: string,
    context: {
      [key: string]: any;
    }
  ) => {
    return {
      type: "ordinalnumber",
      selected: rowsMetadataMap[entry[keyColumn]]?.selected ?? false,
      highlighted: rowsMetadataMap[entry[keyColumn]]?.highlighted ?? false,
      value: context.index + 1,
      editable: rowsMetadataMap[entry[keyColumn]]?.editable ?? true,
    };
  };
}

export default OrdinalNumberCellTypeHandler;
