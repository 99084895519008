import { CheckboxCell, CheckboxCellTemplate } from "@silevis/reactgrid";
import { ICellTypeHandler } from "components/grecoreactgrid/ICellTypeHandler";
import { SelectCellTemplate } from "../customcells/SelectSingleCellTemplate";
import { parseFloatBasedOnLocale } from "utils/utils";

const formatNumber = (value: number) => {
  const locale = window.navigator.languages[0];

  const groupingFormat = new Intl.NumberFormat(locale, {
    useGrouping: true,
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });
  return Number.isNaN(value) ? "" : groupingFormat.format(value);
};

class SelectSingleCellTypeHandler implements ICellTypeHandler {
  cellType = "select";
  isCustom = true;
  instance = new SelectCellTemplate();
  determineNewValue = (change: any) => {
    return (change.newCell as CheckboxCell).checked;
  };
  getEntryRow = (
    entry: any,
    columnConfig: any,
    rowsMetadataMap: any,
    keyColumn: string,
    context: {
      [key: string]: any;
    }
  ) => {
    const [
      { reminderEntries, reminderMetadataMap },
      { setRemindersStoreValues, getReminder },
    ] = context["remindersStore"];

    return {
      type: "selectsingle",
      checked:
        rowsMetadataMap[entry[columnConfig.additionalInfo.rowIdField]].selected,
      selected: rowsMetadataMap[entry[keyColumn]]?.selected ?? false,
      highlighted: rowsMetadataMap[entry[keyColumn]]?.highlighted ?? false,

      onChecked: (checked: boolean) => {
        const reminderMetadataMapCopy = { ...reminderMetadataMap };
        if (checked) {
          getReminder(
            {
              clientId: entry["clientId"],
              reminderId: entry[columnConfig.additionalInfo.rowIdField],
            },
            (data) => {
              for (let item in reminderMetadataMapCopy) {
                if (entry[columnConfig.additionalInfo.rowIdField] !== item) {
                  reminderMetadataMapCopy[item].selected = false;
                } else {
                  reminderMetadataMapCopy[item].selected = checked;
                }
              }
              const reminderEntriesRetVal = data.map((item) => {
                // const detailedValue = data.find(
                //   (item2) =>
                //     item2.paymentPlanEntryId === item.paymentPlanEntryId
                // );
                // if (detailedValue) {
                return {
                  ...item,
                  ...item.paymentPlanEntry,
                  premiumAmount: item.premiumAmount,
                  paidAmount: item.paidAmount,
                };
                // } else {
                //   return item;
                // }
              });

              let reminderEntryMetadataMap = {};
              reminderEntriesRetVal
                .filter((el) => el.isDeleted === false)
                .forEach((entry) => {
                  reminderEntryMetadataMap[entry.reminderEntryId] = {
                    inEditMode: false,
                    changes: [],
                    deleted: entry.isDeleted,
                    editable: false,
                    visible: true,
                    oldRow: null,
                    resolved: entry.clientId !== null,
                    cleared: entry.isCleared,
                    selected: true,
                  };
                });

              setRemindersStoreValues({
                reminderEntries: reminderEntriesRetVal.map((el) => {
                  return {
                    ...el,
                    booked_paid_amount:
                      formatNumber(el.paidBookedAmount) +
                      " / " +
                      formatNumber(el.bookedAmount),
                    total_paid_amount:
                      formatNumber(el.paidAmount) +
                      " / " +
                      formatNumber(el.premiumAmount),
                    discount_paid_amount:
                      formatNumber(el.paidDiscountAmount) +
                      " / " +
                      formatNumber(el.premiumDiscountAmount),
                    paid_amount_status:
                      Number(el.paidBookedAmount.toFixed(2)) ===
                      Number(el.bookedAmount.toFixed(2))
                        ? "green"
                        : Number(el.paidDiscountAmount.toFixed(2)) ===
                            Number(el.premiumDiscountAmount.toFixed(2)) &&
                          Number(el.premiumDiscountAmount.toFixed(2)) > 0
                        ? "orange"
                        : Number(el.paidAmount.toFixed(2)) ===
                          Number(el.premiumAmount.toFixed(2))
                        ? "gold"
                        : "red",

                    premium_paid_amount_status:
                      Number(el.paidAmount.toFixed(2)) >=
                      Number(el.premiumAmount.toFixed(2))
                        ? "green"
                        : Number(el.paidAmount.toFixed(2)) > 0
                        ? "orange"
                        : "red",
                    // Create indicator for totalDiscountAmount. If it is zero, then make it red, if it is partially paid make it orange, and if it is
                    // fully paid (totalPaidDiscountAmount === totalDiscountAmount) make it green.
                    discount_paid_amount_status:
                      Number(el.premiumDiscountAmount.toFixed(2)) === 0
                        ? "gray"
                        : Number(el.paidDiscountAmount.toFixed(2)) >=
                          Number(el.premiumDiscountAmount.toFixed(2))
                        ? "green"
                        : Number(el.paidDiscountAmount.toFixed(2)) > 0
                        ? "orange"
                        : "red",
                  };
                }),

                reminderMetadataMap: {
                  ...reminderMetadataMapCopy,
                  [entry[columnConfig.additionalInfo.rowIdField]]: {
                    ...reminderMetadataMap[
                      entry[columnConfig.additionalInfo.rowIdField]
                    ],
                    selected: checked,
                  },
                },
                reminderEntryMetadataMap: reminderEntryMetadataMap,
              });
            }
          );
        }
      },
    };
  };
}

export default SelectSingleCellTypeHandler;
