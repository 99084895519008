import { getShortInsurerName } from "utils/utils";

export const sortFunctionMap = {
  lastmodified: (a, b) => {
    // sort by lastModifiedByUserName first and then by lastModifiedAt
    if (a["lastModifiedByUserName"] < b["lastModifiedByUserName"]) {
      return -1;
    }
    if (a["lastModifiedByUserName"] > b["lastModifiedByUserName"]) {
      return 1;
    }
    if (a["lastModifiedAt"] < b["lastModifiedAt"]) {
      return -1;
    }
    if (a["lastModifiedAt"] > b["lastModifiedAt"]) {
      return 1;
    }
    return 0;
  },
  declared: (a, b) => {
    // sort by lastModifiedByUserName first and then by lastModifiedAt
    if (
      (a["declaredByUserName"] === null ? "" : a["declaredByUserName"]) <
      (b["declaredByUserName"] === null ? "" : b["declaredByUserName"])
    ) {
      return -1;
    }
    if (
      (a["declaredByUserName"] === null ? "" : a["declaredByUserName"]) >
      (b["declaredByUserName"] === null ? "" : b["declaredByUserName"])
    ) {
      return 1;
    }
    if (
      (a["declaredAt"] === null ? "" : a["declaredAt"]) <
      (b["declaredAt"] === null ? "" : b["declaredAt"])
    ) {
      return -1;
    }
    if (
      (a["declaredAt"] === null ? "" : a["declaredAt"]) >
      (b["declaredAt"] === null ? "" : b["declaredAt"])
    ) {
      return 1;
    }
    return 0;
  },
  datepicker: (a, b) => {
    if (a["selectedValue"] < b["selectedValue"]) {
      return -1;
    }
    if (a["selectedValue"] > b["selectedValue"]) {
      return 1;
    }
    return 0;
  },
  customtext: (a, b) => {
    if (a["text"] < b["text"]) {
      return -1;
    }
    if (a["text"] > b["text"]) {
      return 1;
    }
    return 0;
  },
  custominsurericon: (a, b) => {
    if (a["text"] < b["text"]) {
      return -1;
    }
    if (a["text"] > b["text"]) {
      return 1;
    }
    return 0;
  },
  customnumber: (a, b) => {
    if (a["value"] === null) return -1;
    if (b["value"] === null) return 1;
    if (a["value"] < b["value"]) {
      return -1;
    }
    if (a["value"] > b["value"]) {
      return 1;
    }
    return 0;
  },
  taxonomy: (a, b) => {
    if (a["selectedValueText"] < b["selectedValueText"]) {
      return -1;
    }
    if (a["selectedValueText"] > b["selectedValueText"]) {
      return 1;
    }
    return 0;
  },
  grecocompany: (a, b) => {
    if (a["selectedValueText"] < b["selectedValueText"]) {
      return -1;
    }
    if (a["selectedValueText"] > b["selectedValueText"]) {
      return 1;
    }
    return 0;
  },
  bankstatemententrystatus: (a, b) => {
    const av = a["isCleared"] === true ? 2 : a["isResolved"] === true ? 1 : 0;
    const bv = b["isCleared"] === true ? 2 : b["isResolved"] === true ? 1 : 0;
    if (av < bv) {
      return -1;
    }
    if (av > bv) {
      return 1;
    }
    return 0;
  },
  infonumber: (a, b) => {
    if (a["value"] < b["value"]) {
      return -1;
    }
    if (a["value"] > b["value"]) {
      return 1;
    }
    return 0;
  },
  clearedamount: (a, b) => {
    if (a["value"] < b["value"]) {
      return -1;
    }
    if (a["value"] > b["value"]) {
      return 1;
    }
    return 0;
  },
  client: (a, b) => {
    if (a["text"] < b["text"]) {
      return -1;
    }
    if (a["text"] > b["text"]) {
      return 1;
    }
    return 0;
  },
  insurerinfo: (a, b) => {
    if (
      (getShortInsurerName(a["value"][0]["id"] + "") === ""
        ? a["value"][0]["name"]
        : getShortInsurerName(a["value"][0]["id"] + "")) +
        a["value"].length <
      (getShortInsurerName(b["value"][0]["id"] + "") === ""
        ? b["value"][0]["name"]
        : getShortInsurerName(b["value"][0]["id"] + "")) +
        b["value"].length
    ) {
      return -1;
    }
    if (
      (getShortInsurerName(a["value"][0]["id"] + "") === ""
        ? a["value"][0]["name"]
        : getShortInsurerName(a["value"][0]["id"] + "")) +
        a["value"].length >
      (getShortInsurerName(b["value"][0]["id"] + "") === ""
        ? b["value"][0]["name"]
        : getShortInsurerName(b["value"][0]["id"] + "")) +
        b["value"].length
    ) {
      return 1;
    }
    return 0;
  },
  customcheckmark: (a, b) => {
    if (a["checked"] < b["checked"]) {
      return -1;
    }
    if (a["checked"] > b["checked"]) {
      return 1;
    }
    return 0;
  },
  reminderamountsstatus: (a, b) => {
    const av =
      a["premium_status"] === "red"
        ? 2
        : a["premium_status"] === "orange"
        ? 1
        : 0;
    const bv =
      b["premium_status"] === "red"
        ? 2
        : b["premium_status"] === "orange"
        ? 1
        : 0;

    if (av < bv) {
      return -1;
    }
    if (av > bv) {
      return 1;
    }

    const aw =
      a["discount_status"] === "red"
        ? 2
        : a["discount_status"] === "orange"
        ? 1
        : 0;
    const bw =
      b["discount_status"] === "red"
        ? 2
        : b["discount_status"] === "orange"
        ? 1
        : 0;

    if (aw < bw) {
      return -1;
    }
    if (aw > bw) {
      return 1;
    }

    return 0;
  },
};
