import { ICellTypeHandler } from "../ICellTypeHandler";
import {
  CustomNumberCell,
  CustomNumberCellTemplate,
} from "../customcells/CustomNumberCellTemplate";

class CustomNumberCellTypeHandler implements ICellTypeHandler {
  cellType = "customnumber";
  isCustom = true;
  instance = new CustomNumberCellTemplate();
  determineNewValue = (change: any) => {
    return (change.newCell as CustomNumberCell).value;
  };
  getEntryRow = (
    entry: any,
    columnConfig: any,
    rowsMetadataMap: any,
    keyColumn: string,
    context: {
      [key: string]: any;
    }
  ) => {
    let redColor = false;
    if (
      columnConfig.additionalInfo &&
      columnConfig.additionalInfo?.redColorFn
    ) {
      redColor = columnConfig.additionalInfo.redColorFn(entry);
    }
    return {
      type: "customnumber",
      value: entry[columnConfig.columnId],
      selected: rowsMetadataMap[entry[keyColumn]]?.selected ?? false,
      highlighted: rowsMetadataMap[entry[keyColumn]]?.highlighted ?? false,
      redColor: redColor,
      editable: rowsMetadataMap[entry[keyColumn]]?.editable ?? true,
    };
  };
}

export default CustomNumberCellTypeHandler;
