import { ICellTypeHandler } from "components/grecoreactgrid/ICellTypeHandler";

import {
  CustomCheckmarkCell,
  CustomCheckmarkCellTemplate,
} from "../customcells/CustomCheckmarkCellTemplate";

class CustomCheckmarkCellTypeHandler implements ICellTypeHandler {
  cellType = "customcheckmark";
  isCustom = true;
  instance = new CustomCheckmarkCellTemplate();
  determineNewValue = (change: any) => {
    return (change.newCell as CustomCheckmarkCell).checked;
  };
  getEntryRow = (
    entry: any,
    columnConfig: any,
    rowsMetadataMap: any,
    keyColumn: string,
    context: {
      [key: string]: any;
    }
  ) => {
    return {
      type: "customcheckmark",
      tooltip: null,
      checked: entry[columnConfig.columnId],
      selected: rowsMetadataMap[entry[keyColumn]]?.selected ?? false,
      highlighted: rowsMetadataMap[entry[keyColumn]]?.highlighted ?? false,

      checkedText: "",
      editable: rowsMetadataMap[entry[keyColumn]]?.editable ?? true,
    };
  };
}

export default CustomCheckmarkCellTypeHandler;
