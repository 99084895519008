import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppStoreState } from "store/AppStore";
import { useRemindersStore } from "store/RemindersStore";

import { VSpace } from "components/Spacer";
import ReminderEntryOptionsCellTypeHandler from "pages/Reminders/ReminderEntriesGrid/customcelltypehandlers/ReminderEntryOptionsCellTypeHandler";
import GrecoReactGrid, {
  standardCellTypeHandlers,
} from "components/grecoreactgrid/GrecoReactGrid";
import { DeleteReminderEntryDialog } from "./DeleteReminderEntryDialog";
import { reminderEntriesColumnConfig } from "./reminderEntriesColumnConfig";
import TaxonomyCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/TaxonomyCellTypeHandler";
import DatePickerCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/DatePickerCellTypeHandler";
import ClientCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/ClientCellTypeHandler";
import SortableHeaderCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/SortableHeaderCellTypeHandler";
import CustomTextCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/CustomTextCellTypeHandler";
import CustomNumberCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/CustomNumberCellTypeHandler";
import { CreateReminderDialog } from "../CreateReminderDialog/CreateReminderDialog";
import LastModifiedCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/LastModifiedCellTypeHandler";
import { useTheme } from "@emotion/react";
import ReminderAmountsStatusCellTypeHandler from "../RemindersGrid/customcelltypehandlers/ReminderAmountsStatusCellTypeHandler";
import SumHeaderCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/SumHeaderCellTypeHandler";
import CustomCheckmarkCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/CustomCheckmarkCellTypeHandler";
import AmountsCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/AmountsCellTypeHandler";
import OrdinalNumberCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/OrdinalNumberCellTypeHandler";
import SelectCellTypeHandler from "./customcelltypehandlers/SelectCellTypeHandler";
import SelectAllCellTypeHandler from "./customcelltypehandlers/SelectAllCellTypeHandler";
import { useCreateReminderDialog } from "store/RemindersCreateDialogStore";

const ReminderEntryGrid = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const selectAllState = useState(false);

  const { taxonomy } = useAppStoreState();
  const remindersStore = useRemindersStore();
  const [
    {
      reminders,
      reminderMetadataMap,
      reminderEntryMetadataMap,
      reminderEntries,
      deleteReminderEntryDialog,
      reminderEntriesSort,
    },
    { setRemindersStoreValues, setRemindersStoreValue },
  ] = remindersStore;

  const [{ createReminderDialog }] = useCreateReminderDialog();
  useEffect(() => {
    if (!reminders) {
      return;
    }
    const newReminderEntryMetadataMap = {
      ...reminderEntryMetadataMap,
    };
    let selectAll = true;

    reminderEntries.forEach((reminderEntry) => {
      newReminderEntryMetadataMap[reminderEntry.reminderEntryId] = {
        ...newReminderEntryMetadataMap[reminderEntry.reminderEntryId],
        visible: reminderMetadataMap[reminderEntry.reminderId].selected,
      };
    });
    for (let item in reminderMetadataMap) {
      const statementMetadata = reminderMetadataMap[item];
      if (
        statementMetadata.visible === true &&
        statementMetadata.selected === false
      ) {
        selectAll = false;
        break;
      }
    }
    setRemindersStoreValues({
      reminderEntryMetadataMap: newReminderEntryMetadataMap,
    });
  }, [reminders, reminderEntries, reminderMetadataMap]);

  const handleChanges = (
    updatedRows: any[],
    updatedRowsMetadataMap: { [key: number]: any }
  ) => {
    setRemindersStoreValues({
      reminderEntries: reminderEntries.map((entry) => {
        const updatedEntry = updatedRows.find(
          (row) => row.reminderEntryId === entry.reminderEntryId
        );
        if (updatedEntry) {
          return updatedEntry;
        } else {
          return entry;
        }
      }),
      reminderEntryMetadataMap: updatedRowsMetadataMap,
    });
  };

  const cellTypeHandlers = {
    ...standardCellTypeHandlers,
    taxonomy: new TaxonomyCellTypeHandler(),
    datepicker: new DatePickerCellTypeHandler(),
    client: new ClientCellTypeHandler(),
    reminderentryoptions: new ReminderEntryOptionsCellTypeHandler(),
    sortableheader: new SortableHeaderCellTypeHandler(),
    sumheader: new SumHeaderCellTypeHandler(),
    customtext: new CustomTextCellTypeHandler(),
    customnumber: new CustomNumberCellTypeHandler(),
    customcheckmark: new CustomCheckmarkCellTypeHandler(),
    lastmodified: new LastModifiedCellTypeHandler(),
    amounts: new AmountsCellTypeHandler(),
    reminderamountsstatus: new ReminderAmountsStatusCellTypeHandler(),
    select: new SelectCellTypeHandler(),
    selectall: new SelectAllCellTypeHandler(),
    ordinalnumber: new OrdinalNumberCellTypeHandler(),
  };
  return (
    <>
      <div style={{ height: "100%", overflow: "auto" }}>
        {/* <VSpace height={20} />
        <h2>Bank statement entries ({reminderEntries.length})</h2>
        */}
        <VSpace height={5} />
        {/* <ReminderEntriesMenubar />*/}
        <VSpace height={10} />
        <div style={{ height: "calc(100% - 18px)", overflow: "scroll" }}>
          <GrecoReactGrid
            rows={reminderEntries}
            columns={reminderEntriesColumnConfig}
            idColumn={"reminderEntryId"}
            rowsMetadataMap={reminderEntryMetadataMap}
            handleChanges={handleChanges}
            cellTypeHandlers={cellTypeHandlers}
            hasSumHeaderRow={true}
            keyColumn="reminderEntryId"
            stickyColumns={{
              left: 4,
              right: 3,
              top: 1,
              bottom: 1,
            }}
            context={{
              taxonomy: taxonomy,
              t: t,
              remindersStore: remindersStore,
              selectAllState: selectAllState,
              theme: theme,
              sort: reminderEntriesSort,
              setSort: (sort: any) => {
                setRemindersStoreValue("reminderEntriesSort", sort);
              },
            }}
            sort={reminderEntriesSort}
          />
        </div>
      </div>
      {deleteReminderEntryDialog.isOpen && <DeleteReminderEntryDialog />}
      {createReminderDialog.isOpen && <CreateReminderDialog />}
    </>
  );
};

export default ReminderEntryGrid;
