import React, { useEffect, useMemo, useRef, useState } from "react";

import { useTheme } from "@emotion/react";
import { Modal, ScrollablePane, mergeStyleSets } from "@fluentui/react";

import { ReactGrid } from "@silevis/reactgrid";
import "./styles.css";

import { DatePickerCellTemplate } from "components/grecoreactgrid/customcells/DatePickerCellTemplate";
import { TaxonomyCellTemplate } from "components/grecoreactgrid/customcells/TaxonomyCellTemplate";
import { useTranslation } from "react-i18next";
import { useAppStoreState } from "store/AppStore";
import { usePaymentPlanPageStore } from "store/PaymentPlanPageStore";
import { getPaymentPlanRows } from "types/paymentPlans";
import { PaymentPlanEntryUi } from "utils/types";
import { CustomTextCellTemplate } from "components/grecoreactgrid/customcells/CustomTextCellTemplate";
import { CustomNumberCellTemplate } from "components/grecoreactgrid/customcells/CustomNumberCellTemplate";
import styled from "@emotion/styled";
import Skeleton from "react-loading-skeleton";
import { SumHeaderCellTemplate } from "components/grecoreactgrid/customcells/SumHeaderCellTemplate";
import { SelectCellTemplate } from "./customcells/SelectCellTemplate";
import { CustomCheckmarkCellTemplate } from "components/grecoreactgrid/customcells/CustomCheckmarkCellTemplate";
import { SortableHeaderCellTemplateAzure } from "components/grecoreactgrid/customcells/SortableHeaderCellTemplateAzure";

export const PaymentPlanPageTable = () => {
  const { t } = useTranslation();
  // console.log(columns);
  const { taxonomy, updatedGrecoCompanies } = useAppStoreState();
  const paymentPlanPageStore = usePaymentPlanPageStore();
  const idColumn = "paymentPlansGrid";
  const [
    { paymentPlansLoadStatus, rowItems, sort, count, cols },
    { setPaymentPlanStoreValue, loadPaymentPlans },
  ] = paymentPlanPageStore;
  const [isLoadingNewFiles, setIsLoadingNewFiles] = useState(false);
  const theme = useTheme();
  const determineCellType = (columnType) => {
    switch (columnType) {
      case "boolean":
        return "customcheckmark";
      case "date":
        return "datepicker";
      case "number":
        return "customnumber";
      case "select":
        return "select";
      default:
        return "customtext";
    }
  };

  const columns = useMemo(() => {
    const withTranslatedHeader = cols
      .filter((c) => c.isVisible)
      .map((column: any) => {
        let iconName = column.iconName;

        if (column.key === sort?.key && column.isSortable) {
          iconName =
            sort?.key === column.key
              ? sort!.dir === "asc"
                ? "SortUp"
                : "SortDown"
              : "Sort";
        }
        return {
          ...column,
          name: t(column.labelKey),
          iconName: iconName,
        };
      })
      .map((column) => {
        return {
          columnId: column.key,
          width: column.width,
          reorderable: column.isSortable,
          sortable: column.isSortable,
          resizable: column.isResizable,
          labelKey: column.labelKey,
          cellType: column.cellOptions
            ? column.cellOptions.cellType
            : determineCellType(
                column.key === "select" ? "select" : column.filter?.type
              ),
          cellOptions: column.cellOptions,
          additionalInfo: column.additionalInfo,
        };
      });
    // .concat([
    //   {
    //     key: "filler",
    //     label: "",
    //     width: 0,
    //   },
    // ]);
    return [...withTranslatedHeader];
  }, [sort, cols, t]);

  function usePrevious<T>(state: T): T | undefined {
    const ref = useRef<T>();

    useEffect(() => {
      ref.current = state;
    });

    return ref.current;
  }

  const fetchMoreData = async () => {
    if (paymentPlansLoadStatus === "loading" || isLoadingNewFiles) return;
    setIsLoadingNewFiles(true);
    loadPaymentPlans({
      skip: rowItems.length,
      top: 200,
      reset: false,
      updatedGrecoCompanies,
    });
  };

  const endReached = rowItems.length >= count;
  const fetchNewFiles = (ev) => {
    const element = ev.target;
    if (element && paymentPlansLoadStatus !== "loading") {
      let remaningPixels =
        element.scrollHeight - element.scrollTop - element.clientHeight <= 150;
      if (remaningPixels && !endReached) {
        fetchMoreData();
      }
    }
  };

  const prevItemsLength = usePrevious(rowItems.length);
  useEffect(() => {
    if (prevItemsLength && prevItemsLength !== rowItems.length) {
      setIsLoadingNewFiles(false);
    }
  }, [rowItems, prevItemsLength]);

  const classes = getClassNames(theme);

  const handleColumnResize = (ci: any, width: number) => {
    const updatedColumns = cols.map((column) => {
      if (column.key === ci) {
        return {
          ...column,
          width,
        };
      }
      return column;
    });
    setPaymentPlanStoreValue("cols", updatedColumns);
  };
  const [rowSelectionMap, setRowSelectionMap] = useState(new Map());

  const columnSums = useMemo(() => {
    const rows = [...rowItems.filter((el) => !el.isDeleted)];
    const retVal: { [key: string]: any } = {};
    for (let column of columns) {
      if (column.cellType === "customnumber") {
        retVal[column.columnId] = rows
          .filter(
            (row) =>
              rowSelectionMap.get(row["paymentPlanEntryId"] + "") === true
          )
          .reduce((acc, row) => {
            return (
              acc +
              //?
              row[column.columnId]
              // : 0)
            );
          }, 0);
      }
    }
    return retVal;
  }, [rowItems, columns, rowSelectionMap]);

  const gridRows = useMemo(() => {
    const retVal = getPaymentPlanRows<PaymentPlanEntryUi>(
      false,
      columns.map((el) => {
        return {
          ...el,
          label: el.labelKey,
          cellType: el.cellType,
          editable: false,
        };
      }),
      rowItems.filter((el) => !el.isDeleted),
      {}, //selectAllValues,
      paymentPlanPageStore,
      t,
      taxonomy,
      columnSums,
      rowSelectionMap,
      setRowSelectionMap
    );

    return retVal;
  }, [rowItems, columns, taxonomy, columnSums, rowSelectionMap]);
  const [highlightedRowId, setHighlightedRowId] = React.useState<number>(-1);

  function mouseEnterEventListener(event) {
    //console.log(event.target);
    if (event.target.dataset.cellRowidx) {
      setHighlightedRowId(event.target.dataset.cellRowidx);
    }
    // console.log(event.target);
  }

  function mouseLeaveEventListener(event) {
    setHighlightedRowId(-1);
  }

  useEffect(() => {
    const gridDiv = document.getElementById(idColumn);
    if (!gridDiv) return;
    const rowCells = gridDiv.querySelectorAll(
      `.rg-cell[data-cell-rowidx="${highlightedRowId}"]`
    );
    // Add the 'highlighted-row' class
    rowCells.forEach((cell) => {
      cell.classList.add("highlighted-row");
    });

    // Cleanup function to remove the class
    return () => {
      rowCells.forEach((cell) => {
        cell.classList.remove("highlighted-row");
      });
    };
  }, [highlightedRowId, idColumn]);

  useEffect(() => {
    setTimeout(() => {
      const gridDiv = document.getElementById(idColumn);
      if (!gridDiv) return;

      gridDiv.removeEventListener("mouseover", mouseEnterEventListener);
      gridDiv.addEventListener("mouseover", mouseEnterEventListener);

      gridDiv.addEventListener("mouseleave", mouseLeaveEventListener);

      return () => {
        document.removeEventListener("mouseover", mouseEnterEventListener);
      };
    }, 20);
  }, [gridRows]);

  if (gridRows.length === 0) return null;

  return (
    <>
      {/* {searchClientsSelectedItem && rowItems && rowItems.length > 0 ? ( */}
      <div className={classes.container} id="scrollableDiv">
        <ScrollablePane
          onScroll={(ev) => {
            if (!endReached && paymentPlansLoadStatus !== "loading") {
              fetchNewFiles(ev);
            }
          }}
        >
          {paymentPlansLoadStatus !== "loading" || isLoadingNewFiles ? ( //rowItems && rowItems.length > 0 ? (
            <div id={idColumn}>
              <ReactGrid
                rows={gridRows}
                columns={columns}
                // onCellsChanged={handleChanges}
                onColumnResized={handleColumnResize}
                onFocusLocationChanged={(location) => {}}
                onFocusLocationChanging={(props) => {
                  return true;
                }}
                stickyLeftColumns={5}
                // stickyRightColumns={2}
                stickyTopRows={2}
                customCellTemplates={{
                  customtext: new CustomTextCellTemplate(),
                  customnumber: new CustomNumberCellTemplate(),
                  customcheckmark: new CustomCheckmarkCellTemplate(),
                  taxonomy: new TaxonomyCellTemplate(),
                  datepicker: new DatePickerCellTemplate(),
                  sortableheaderazure: new SortableHeaderCellTemplateAzure(),
                  sumheader: new SumHeaderCellTemplate(),
                  select: new SelectCellTemplate(),
                }}

                // enableFullWidthHeader
              />
            </div>
          ) : (
            <SkeletonTableBody cols={columns} />
          )}
        </ScrollablePane>
      </div>
      <Modal
        styles={{
          root: {
            opacity: 0,
          },
        }}
        isOpen={paymentPlansLoadStatus === "loading"}
        isBlocking={paymentPlansLoadStatus === "loading"}
      ></Modal>
    </>
  );
};

const getClassNames = (theme) =>
  mergeStyleSets({
    container: {
      height: "calc(100vh - 159px )", //- 158px
      marginBottom: "20px",
      position: "relative",
      overflow: "auto",
      width: "100%",
    },

    table: {
      ".ms-PaymentPlan-cell:nth-child(odd) .ms-DetailsRow": {
        background: theme.isDark
          ? "rgb(2, 7, 10)"
          : theme.palette.themeLighterAlt,
      },
      ".ms-DetailsRow ": {
        height: "30px !important",
      },

      ".ms-PaymentPlan-cell:hover, .ms-PaymentPlan-cell .is-selected": {
        background: theme.isDark
          ? "rgb(9, 27, 41) !important"
          : "rgb(187, 187, 187) !important",
      },
      ".ms-PaymentPlan-cell .ms-DetailsRow:hover": {
        background: theme.isDark
          ? "rgb(9, 27, 41) !important"
          : "rgb(187, 187, 187) !important",
      },

      ".ms-DetailsHeader-cellTitle": {
        justifyContent: "flex-start",
      },

      ".ms-DetailsRow-cell": {
        display: "flex",
        justifyContent: "flex-start",
      },

      ".ms-DetailsHeader-cellName ": {
        overflow: "visible",
      },

      ".ms-DetailsHeader-cellName i": {
        marginRight: "5px",
        fontSize: "smaller",
        fontWeight: "400",
      },
    },
  });

const Cell = styled.td`
  background-color: ${(p) => p.theme.palette.white};
  padding: 3px 10px;
  border: 1px solid #eee;
  width: 500px;
`;

const SkeletonTableBody = ({
  cols,
  rows = 37,
}: {
  cols?: any;
  rows?: number;
}) => {
  return (
    <table>
      <tbody>
        {Array(rows)
          .fill(0)
          .map((_, idx) => {
            return (
              <tr key={idx}>
                {cols.map((col, idx) => {
                  return (
                    <Cell key={idx}>
                      <Skeleton width={col.width} />
                    </Cell>
                  );
                })}
              </tr>
            );
          })}
      </tbody>
    </table>
  );
};
export default PaymentPlanPageTable;
