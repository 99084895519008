import { Checkbox } from "@fluentui/react-components";
import {
  Cell,
  CellStyle,
  CellTemplate,
  Compatible,
  Uncertain,
  UncertainCompatible,
  getCellProperty,
  keyCodes,
} from "@silevis/reactgrid";
import * as React from "react";

export interface SelectAllCell extends Cell {
  type: "selectall";
  checked: boolean;
  checkedText?: string;
  uncheckedText?: string;
  onChecked: (checked: boolean) => void;
}

export class SelectAllCellTemplate implements CellTemplate<SelectAllCell> {
  getCompatibleCell(
    uncertainCell: Uncertain<SelectAllCell>
  ): Compatible<SelectAllCell> {
    const checked = getCellProperty(uncertainCell, "checked", "boolean");
    const text = checked
      ? uncertainCell.checkedText
        ? uncertainCell.checkedText
        : "1"
      : uncertainCell.uncheckedText
      ? uncertainCell.uncheckedText
      : "";
    return {
      ...uncertainCell,
      onChecked: uncertainCell.onChecked,
      checked: !!checked,
      value: checked ? 1 : NaN,
      text,
    };
  }

  handleKeyDown(
    cell: Compatible<SelectAllCell>,
    keyCode: number,
    ctrl: boolean,
    shift: boolean,
    alt: boolean
  ): { cell: Compatible<SelectAllCell>; enableEditMode: boolean } {
    if (!shift && (keyCode === keyCodes.SPACE || keyCode === keyCodes.ENTER))
      return {
        cell: this.getCompatibleCell(this.toggleSelectAllCell(cell)),
        enableEditMode: false,
      };
    return { cell, enableEditMode: false };
  }

  private toggleSelectAllCell(
    cell: Compatible<SelectAllCell>
  ): Compatible<SelectAllCell> {
    return this.getCompatibleCell({ ...cell, checked: !cell.checked });
  }

  update(
    cell: Compatible<SelectAllCell>,
    cellToMerge: UncertainCompatible<SelectAllCell>
  ): Compatible<SelectAllCell> {
    const checked =
      cellToMerge.type === "selectall"
        ? cellToMerge.checked
        : !!cellToMerge.value;
    return this.getCompatibleCell({ ...cell, checked });
  }

  getClassName(cell: Compatible<SelectAllCell>): string {
    return cell.className ? cell.className : "";
  }

  render(
    cell: Compatible<SelectAllCell>,
    isInEditMode: boolean,
    onCellChanged: (cell: Compatible<SelectAllCell>, commit: boolean) => void
  ): React.ReactNode {
    return (
      <Checkbox
        id="selectAllClientBalanceCheckbox"
        size="medium"
        checked={cell.checked}
        onChange={(e) => cell.onChecked(!cell.checked)}
      />
    );
  }
  getStyle = (cell: Compatible<SelectAllCell>): CellStyle => ({
    background: "rgba(128, 128, 128, 0.1)",
  });
}
