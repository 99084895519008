import React from "react";
import { ExpandedColumn, ITag } from "../../types/columns";

import YesNoText from "components/YesNoText";
import moment from "moment";
import { searchClients, searchInsurers } from "api/api";
import { getShortInsurerName } from "utils/utils";

const onSearchClients = async (
  input: string,
  selectedItems?: ITag[]
): Promise<ITag[]> => {
  if (!input) return [];
  const clients: any = await searchClients(input);

  if (clients.isAxiosError) {
    return [];
  }

  return clients.data.map((c) => {
    return {
      name: c.name + (c.internalNumber ? ` (${c.internalNumber})` : ""),
      key: c.id,
    };
  });
};

const onSearchInsurers = async (
  input: string,
  selectedItems?: ITag[]
): Promise<ITag[]> => {
  if (!input) return [];
  const insurers: any = await searchInsurers(input);

  if (insurers.isAxiosError) {
    return [];
  }

  return insurers.data.map((c) => {
    let name = c.name;
    const shortName = getShortInsurerName(c.id + "");
    if (shortName !== "") name = shortName;

    return {
      name: name + (c.internalNumber ? ` (${c.internalNumber})` : ""),
      key: c.id,
    };
  });
};

export const columns: ExpandedColumn[] = [
  {
    key: "select",
    name: "select",
    labelKey: "",
    isResizable: false,
    isFixed: true,
    width: 60,
    isVisible: true,
    isSortable: false,
    iconName: "",
    filter: null,
    onRender: (row) => {
      if (!row) return;
      return <YesNoText value={row["hasPayments"]} />;
    },
  },
  {
    key: "hasPayments",
    name: "hasPayments",
    labelKey: "pct.hasPayments.label",
    isResizable: true,
    isFixed: true,
    width: 60,
    isVisible: true,
    isSortable: true,
    iconName: "Sort",
    filter: { type: "boolean", value: null },
    onRender: (row) => {
      if (!row) return;
      return <YesNoText value={row["hasPayments"]} />;
    },
  },
  {
    key: "isPaid",
    name: "isPaid",
    labelKey: "pct.isPaid.label",
    isResizable: true,
    isFixed: true,
    width: 60,
    isVisible: true,
    isSortable: true,
    iconName: "Sort",
    filter: { type: "boolean", value: null },
    onRender: (row) => {
      if (!row) return;
      return <YesNoText value={row["isPaid"]} />;
    },
  },
  {
    key: "hasDiscountPayments",
    name: "hasDiscountPayments",
    labelKey: "pct.hasDiscountPayments.label",
    isResizable: true,
    isFixed: true,
    width: 60,
    isVisible: true,
    isSortable: true,
    iconName: "Sort",
    filter: { type: "boolean", value: null },
    onRender: (row) => {
      if (!row) return;
      return <YesNoText value={row["hasDiscountPayments"]} />;
    },
  },
  {
    key: "isDiscountPaid",
    name: "isDiscountPaid",
    labelKey: "pct.isDiscountPaid.label",
    isResizable: true,
    isFixed: true,
    width: 60,
    isVisible: true,
    isSortable: true,
    iconName: "Sort",
    filter: { type: "boolean", value: null },
    onRender: (row) => {
      if (!row) return;
      return <YesNoText value={row["isDiscountPaid"]} />;
    },
  },

  {
    key: "reminderNumber",
    name: "reminderNumber",
    labelKey: "pct.reminderNumber.label",
    isResizable: true,
    isFixed: true,
    width: 180,
    minWidth: 180,
    maxWidth: 640,
    isVisible: true,
    isSortable: true,
    iconName: "Sort",
    filter: { type: "text", value: "" },
    onRender: (row) => {
      if (!row) return;
      return row["reminderNumber"];
    },
  },

  {
    key: "licensePlateNumber",
    name: "licensePlateNumber",
    labelKey: "pct.licensePlateNumber.label",
    isResizable: true,
    isFixed: true,
    width: 120,
    minWidth: 120,
    maxWidth: 640,
    isVisible: true,
    isSortable: true,
    iconName: "Sort",
    filter: { type: "text", value: "" },
    onRender: (row) => {
      if (!row) return;
      return row["licensePlateNumber"];
    },
  },

  {
    key: "policyName",
    name: "policyName",
    labelKey: "pct.policyName.label",
    isResizable: true,
    isFixed: true,
    width: 250,
    minWidth: 250,
    maxWidth: 640,
    isVisible: true,
    isSortable: true,
    iconName: "Sort",
    filter: { type: "text", value: "" },
    onRender: (row) => {
      if (!row) return;
      return row["policyName"];
    },
    additionalInfo: {
      gosLink: {
        idField: "policyID",
        linkType: "policy",
      },
    },
  },
  {
    key: "policyNumber",
    name: "policyNumber",
    labelKey: "pct.policyNumber.label",
    isResizable: true,
    isFixed: true,
    width: 140,
    isVisible: true,
    isSortable: true,
    iconName: "Sort",
    filter: { type: "text", value: "" },
    onRender: (row) => {
      if (!row) return;
      return row["policyNumber"];
    },
  },
  {
    key: "policySubNumber",
    name: "policySubNumber",
    labelKey: "pct.policySubNumber.label",
    isResizable: true,
    width: 140,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: { type: "text", value: "" },
    onRender: (row) => {
      if (!row) return;
      return row["policySubNumber"];
    },
  },
  {
    key: "premiumPaymentMeanCodeID",
    name: "premiumPaymentMeanCodeID",
    labelKey: "pct.premiumPaymentMeanCodeID.label",
    isResizable: true,
    width: 240,
    isVisible: true,
    isSortable: true,
    iconName: "Sort",
    // filter: {
    //   type: "taxonomy",
    //   taxonomyKey: "PremiumPaymentMean",
    //   value: [],
    // },
    cellOptions: {
      cellType: "taxonomy",
      additionalInfo: {
        taxonomy: "PremiumPaymentMean",
      },
    },
    excel: {
      render: ({ row, taxonomy, t }: { row: any; taxonomy: any; t: any }) => {
        const code =
          taxonomy.PremiumPaymentMean.byId[row.premiumPaymentMeanCodeID]?.code;
        return t(code);
      },
    },
    pdf: {
      render: ({ row, taxonomy, t }: { row: any; taxonomy: any; t: any }) => {
        const code =
          taxonomy.PremiumPaymentMean.byId[row.premiumPaymentMeanCodeID]?.code;
        return {
          text: t(code),
          style: {
            bold: true,
            alignment: "center",
          },
        };
      },
    },
    onRender: (row) => {
      if (!row) return;
      return row["premiumPaymentMeanCodeID"];
    },
  },
  {
    key: "grecoCompanyNumber",
    name: "grecoCompanyNumber",
    labelKey: "pct.grecoCompanyNumber.label",
    isResizable: true,
    width: 140,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",

    cellOptions: {
      cellType: "taxonomy",
      additionalInfo: {
        taxonomy: "Company",
      },
    },
    excel: {
      render: ({ row, taxonomy, t }: { row: any; taxonomy: any; t: any }) => {
        const code = taxonomy.Company.byId[row.grecoCompanyNumber]?.code;
        return t(code);
      },
    },
    pdf: {
      render: ({ row, taxonomy, t }: { row: any; taxonomy: any; t: any }) => {
        const code = taxonomy.Company.byId[row.grecoCompanyNumber]?.code;
        return {
          text: t(code),
          style: {
            bold: true,
            alignment: "center",
          },
        };
      },
    },
    onRender: (row) => {
      if (!row) return;
      return row["grecoCompanyNumber"];
    },
  },
  {
    key: "client",
    name: "client",
    labelKey: "pct.client.label",
    isResizable: true,
    width: 240,
    isVisible: true,
    isSortable: false,
    iconName: null,
    filter: {
      type: "taxonomy",
      taxonomyKey: "client",
      isSelect: true,
      onResolveSuggestions: onSearchClients,
      isUncontrolledSelect: true,
      value: [],
    },
    onRender: (row) => {
      if (!row) return;
      return row["client"] + " (" + row["clientNumber"] + ")";
    },
    additionalInfo: {
      gosLink: {
        idField: "clientId",

        linkType: "client",
      },
    },
  },
  {
    key: "insurerName",
    name: "insurerName",
    labelKey: "pct.insurer.label",
    isResizable: true,
    width: 150,
    isVisible: true,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "taxonomy",
      taxonomyKey: "insurer",
      isSelect: true,
      onResolveSuggestions: onSearchInsurers,
      isUncontrolledSelect: true,
      value: [],
    },
    onRender: (row) => {
      if (!row) return;
      return row["insurerName"] + " (" + row["insurerNumber"] + ")";
    },
    additionalInfo: {
      gosLink: {
        idField: "insurerId",

        linkType: "client",
      },
    },
  },
  {
    key: "bookingDate",
    name: "bookingDate",
    labelKey: "pct.bookingDate.label",
    isResizable: true,
    width: 140,
    isVisible: true,
    isSortable: true,
    iconName: "Sort",
    filter: { type: "date", date1: null, date2: null, operator: "isAfter" },
    onRender: (row) => {
      if (!row) return;
      return moment(row["bookingDate"]).format("DD.MM.YYYY");
    },
    pdf: {
      render: ({ row, taxonomy, t }: { row: any; taxonomy: any; t: any }) => {
        return {
          text: moment(row["bookingDate"]).format("DD.MM.YYYY"),
          style: {
            bold: true,
            alignment: "center",
          },
        };
      },
    },
    excel: {
      render: ({ row, taxonomy, t }: { row: any; taxonomy: any; t: any }) => {
        return moment(row["bookingDate"]).format("DD.MM.YYYY");
      },
    },
  },

  {
    key: "premiumFrom",
    name: "premiumFrom",
    labelKey: "pct.premiumFrom.label",
    isResizable: true,
    width: 140,
    isVisible: true,
    isSortable: true,
    iconName: "Sort",
    filter: { type: "date", date1: null, date2: null, operator: "isAfter" },
    onRender: (row) => {
      if (!row) return;
      return moment(row["premiumFrom"]).format("DD.MM.YYYY");
    },
    pdf: {
      render: ({ row, taxonomy, t }: { row: any; taxonomy: any; t: any }) => {
        return {
          text: moment(row["premiumFrom"]).format("DD.MM.YYYY"),
          style: {
            bold: true,
            alignment: "center",
          },
        };
      },
    },
    excel: {
      render: ({ row, taxonomy, t }: { row: any; taxonomy: any; t: any }) => {
        return moment(row["premiumFrom"]).format("DD.MM.YYYY");
      },
    },
  },
  {
    key: "premiumTo",
    name: "premiumTo",
    labelKey: "pct.premiumTo.label",
    isResizable: true,
    width: 140,
    isVisible: true,
    isSortable: true,
    iconName: "Sort",
    filter: { type: "date", date1: null, date2: null, operator: "isAfter" },
    onRender: (row) => {
      if (!row) return;
      return moment(row["premiumTo"]).format("DD.MM.YYYY");
    },
    pdf: {
      render: ({ row, taxonomy, t }: { row: any; taxonomy: any; t: any }) => {
        return {
          text: moment(row["premiumTo"]).format("DD.MM.YYYY"),
          style: {
            bold: true,
            alignment: "center",
          },
        };
      },
    },
    excel: {
      render: ({ row, taxonomy, t }: { row: any; taxonomy: any; t: any }) => {
        return moment(row["premiumTo"]).format("DD.MM.YYYY");
      },
    },
  },
  {
    key: "premiumAmount",
    name: "premiumAmount",
    labelKey: "pct.premiumAmount.label",
    isResizable: true,
    width: 140,
    isVisible: true,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "number",
      number1: null,
      number2: null,
      operator: "gt",
      format: "float",
      includeThousandSeparators: false,
    },
    onRender: (row) => {
      if (!row) return;
      return row["premiumAmount"];
    },
    excel: {
      render: ({ row, taxonomy, t }: { row: any; taxonomy: any; t: any }) => {
        return Number(row["premiumAmount"]);
      },
    },
  },
  {
    key: "premiumDiscount",
    name: "premiumDiscount",
    labelKey: "pct.premiumDiscount.label",
    isResizable: true,
    width: 140,
    isVisible: true,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "number",
      number1: null,
      number2: null,
      operator: "gt",
      format: "float",
      includeThousandSeparators: false,
    },
    onRender: (row) => {
      if (!row) return;
      return row["premiumDiscount"];
    },
  },
  {
    key: "premiumDiscountPercent",
    name: "premiumDiscountPercent",
    labelKey: "pct.premiumDiscountPercent.label",
    isResizable: true,
    width: 140,
    isVisible: true,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "number",
      number1: null,
      number2: null,
      operator: "gt",
      format: "float",
      includeThousandSeparators: false,
    },
    onRender: (row) => {
      if (!row) return;
      return row["premiumDiscountPercent"];
    },
  },

  {
    key: "paidPremiumDiscountAmount",
    name: "paidPremiumDiscountAmount",
    labelKey: "pct.paidPremiumDiscountAmount.label",
    isResizable: true,
    width: 140,
    isVisible: true,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "number",
      number1: null,
      number2: null,
      operator: "gt",
      format: "float",
      includeThousandSeparators: false,
    },
    onRender: (row) => {
      if (!row) return;
      return row["paidPremiumDiscountAmount"];
    },
  },

  {
    key: "premiumDiscountDebt",
    name: "premiumDiscountDebt",
    labelKey: "pct.premiumDiscountDebt.label",
    isResizable: true,
    width: 140,
    isVisible: true,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "number",
      number1: null,
      number2: null,
      operator: "gt",
      format: "float",
      includeThousandSeparators: false,
    },
    onRender: (row) => {
      if (!row) return;
      return row["premiumDiscountDebt"];
    },
  },

  {
    key: "totalPremiumAmount",
    name: "totalPremiumAmount",
    labelKey: "pct.totalPremiumAmount.label",
    isResizable: true,
    width: 140,
    isVisible: true,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "number",
      number1: null,
      number2: null,
      operator: "gt",
      format: "float",
      includeThousandSeparators: false,
    },
    onRender: (row) => {
      if (!row) return;
      return row["totalPremiumAmount"];
    },
  },
  {
    key: "paidPremiumAmount",
    name: "paidPremiumAmount",
    labelKey: "pct.paidPremiumAmount.label",
    isResizable: true,
    width: 140,
    isVisible: true,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "number",
      number1: null,
      number2: null,
      operator: "gt",
      format: "float",
      includeThousandSeparators: false,
    },
    onRender: (row) => {
      if (!row) return;
      return row["paidPremiumAmount"];
    },
  },
  {
    key: "premiumDebt",
    name: "premiumDebt",
    labelKey: "pct.premiumDebt.label",
    isResizable: true,
    width: 140,
    isVisible: true,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "number",
      number1: null,
      number2: null,
      operator: "gt",
      format: "float",
      includeThousandSeparators: false,
    },
    onRender: (row) => {
      if (!row) return;
      return row["premiumDebt"];
    },
  },
  {
    key: "commissionRate",
    name: "commissionRate",
    labelKey: "pct.commissionRate.label",
    isResizable: true,
    width: 140,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "number",
      number1: null,
      number2: null,
      operator: "gt",
      format: "float",
      includeThousandSeparators: false,
    },
    onRender: (row) => {
      if (!row) return;
      return row["commissionRate"];
    },
  },
  {
    key: "commissionAmount",
    name: "commissionAmount",
    labelKey: "pct.commissionAmount.label",
    isResizable: true,
    width: 140,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "number",
      number1: null,
      number2: null,
      operator: "gt",
      format: "float",
      includeThousandSeparators: false,
    },
    onRender: (row) => {
      if (!row) return;
      return row["commissionAmount"];
    },
  },
  {
    key: "paidCommissionAmount",
    name: "paidCommissionAmount",
    labelKey: "pct.paidCommissionAmount.label",
    isResizable: true,
    width: 140,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "number",
      number1: null,
      number2: null,
      operator: "gt",
      format: "float",
      includeThousandSeparators: false,
    },
    onRender: (row) => {
      if (!row) return;
      return row["paidCommissionAmount"];
    },
  },

  {
    key: "internalNumber",
    name: "internalNumber",
    labelKey: "pct.internalNumber.label",
    isResizable: true,
    width: 140,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",

    onRender: (row) => {
      if (!row) return;
      return row["internalNumber"];
    },
  },
  {
    key: "documentDate",
    name: "documentDate",
    labelKey: "pct.documentDate.label",
    isResizable: true,
    width: 140,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: { type: "date", date1: null, date2: null, operator: "isAfter" },
    onRender: (row) => {
      if (!row) return;
      return moment(row["documentDate"]).format("DD.MM.YYYY");
    },
    pdf: {
      render: ({ row, taxonomy, t }: { row: any; taxonomy: any; t: any }) => {
        return {
          text: moment(row["documentDate"]).format("DD.MM.YYYY"),
          style: {
            bold: true,
            alignment: "center",
          },
        };
      },
    },
    excel: {
      render: ({ row, taxonomy, t }: { row: any; taxonomy: any; t: any }) => {
        return moment(row["documentDate"]).format("DD.MM.YYYY");
      },
    },
  },
  {
    key: "currencyCode",
    name: "currencyCode",
    labelKey: "pct.currencyCodeID.label",
    isResizable: true,
    width: 140,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "taxonomy",
      taxonomyKey: "Currency",
      value: [],
    },
    cellOptions: {
      cellType: "taxonomy",
      additionalInfo: {
        taxonomy: "Currency",
      },
    },
    onRender: (row) => {
      if (!row) return;
      return row["currencyCode"];
    },
    excel: {
      render: ({ row, taxonomy, t }: { row: any; taxonomy: any; t: any }) => {
        const code = taxonomy.Currency.byId[row.currencyCode]?.code;
        return t(code);
      },
    },
    pdf: {
      render: ({ row, taxonomy, t }: { row: any; taxonomy: any; t: any }) => {
        const code = taxonomy.Currency.byId[row.currencyCode]?.code;
        return {
          text: t(code),
          style: {
            bold: true,
            alignment: "center",
          },
        };
      },
    },
  },

  {
    key: "clientId",
    name: "clientId",
    labelKey: "pct.clientPartnerId.label",
    isResizable: true,
    width: 140,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: null,

    onRender: (row) => {
      if (!row) return;
      return row["clientPartnerId"];
    },
  },

  {
    key: "clientName",
    name: "clientName",
    labelKey: "pct.clientCompanyName.label",
    isResizable: true,
    width: 140,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: { type: "text", value: "" },
    onRender: (row) => {
      if (!row) return;
      return row["clientName"];
    },
  },

  {
    key: "clientLastName",
    name: "clientLastName",
    labelKey: "pct.clientLastName.label",
    isResizable: true,
    width: 140,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: { type: "text", value: "" },
    onRender: (row) => {
      if (!row) return;
      return row["clientLastName"];
    },
  },
  {
    key: "clientFirstName",
    name: "clientFirstName",
    labelKey: "pct.clientFirstName.label",
    isResizable: true,
    width: 140,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: { type: "text", value: "" },
    onRender: (row) => {
      if (!row) return;
      return row["clientFirstName"];
    },
  },
  {
    key: "clientNumber",
    name: "clientNumber",
    labelKey: "pct.clientNumber.label",
    isResizable: true,
    width: 140,
    isVisible: false,
    isSortable: false,
    iconName: "Sort",
    filter: null,
    onRender: (row) => {
      if (!row) return;
      return row["clientNumber"];
    },
  },
  {
    key: "clientInternalNumber",
    name: "clientInternalNumber",
    labelKey: "pct.clientInternalNumber.label",
    isResizable: true,
    width: 140,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: null,

    onRender: (row) => {
      if (!row) return;
      return row["clientInternalNumber"];
    },
  },
  {
    key: "clientCompanyRegisterNumber",
    name: "clientCompanyRegisterNumber",
    labelKey: "pct.clientCompanyRegisterNumber.label",
    isResizable: true,
    width: 140,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: null,
    onRender: (row) => {
      if (!row) return;
      return row["clientCompanyRegisterNumber"];
    },
  },
  {
    key: "clientPersonIdentificationNumber",
    name: "clientPersonIdentificationNumber",
    labelKey: "pct.clientPersonIdentificationNumber.label",
    isResizable: true,
    width: 140,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: null,
    onRender: (row) => {
      if (!row) return;
      return row["clientPersonIdentificationNumber"];
    },
  },
  {
    key: "clientCity",
    name: "clientCity",
    labelKey: "pct.clientCity.label",
    isResizable: true,
    width: 140,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: { type: "text", value: "" },
    onRender: (row) => {
      if (!row) return;
      return row["clientCity"];
    },
  },
  {
    key: "clientStreet",
    name: "clientStreet",
    labelKey: "pct.clientStreet.label",
    isResizable: true,
    width: 140,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: { type: "text", value: "" },
    onRender: (row) => {
      if (!row) return;
      return row["clientStreet"];
    },
  },

  {
    key: "clientZipCode",
    name: "clientZipCode",
    labelKey: "pct.clientZipCode.label",
    isResizable: true,
    width: 140,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: { type: "text", value: "" },
    onRender: (row) => {
      if (!row) return;
      return row["clientZipCode"];
    },
  },
  {
    key: "clientEmailAddress",
    name: "clientEmailAddress",
    labelKey: "pct.clientEmailAddress.label",
    isResizable: true,
    width: 140,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: { type: "text", value: "" },
    onRender: (row) => {
      if (!row) return;
      return row["clientEmailAddress"];
    },
  },

  {
    key: "clientPhoneNumber",
    name: "clientPhoneNumber",
    labelKey: "pct.clientPhoneNumber.label",
    isResizable: true,
    width: 140,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: { type: "text", value: "" },
    onRender: (row) => {
      if (!row) return;
      return row["clientPhoneNumber"];
    },
  },

  {
    key: "insurerId",
    name: "insurerId",
    labelKey: "pct.insurerPartnerId.label",
    isResizable: true,
    width: 140,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: null,

    onRender: (row) => {
      if (!row) return;
      return row["insurerId"];
    },
  },
  {
    key: "insurerInternalNumber",
    name: "insurerInternalNumber",
    labelKey: "pct.insurerInternalNumber.label",
    isResizable: true,
    width: 140,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: null,

    onRender: (row) => {
      if (!row) return;
      return row["insurerInternalNumber"];
    },
  },
  // {
  //   key: "policyVersionLineOneTimePremiumID",
  //   name: "policyVersionLineOneTimePremiumID",
  //   labelKey: "pct.policyVersionLineOneTimePremiumID.label",
  //   isResizable: true,
  //   width: 140,
  //   isVisible: false,
  //   isSortable: true,
  //   iconName: "Sort",
  //   filter: { type: "text", value: "" },
  //   onRender: (row) => {
  //     if (!row) return;
  //     return row["policyVersionLineOneTimePremiumID"];
  //   },
  // },
  // {
  //   key: "grossPremium",
  //   name: "grossPremium",
  //   labelKey: "pct.grossPremium.label",
  //   isResizable: true,
  //   width: 140,
  //   isVisible: false,
  //   isSortable: true,
  //   iconName: "Sort",
  //   filter: { type: "text", value: "" },
  //   onRender: (row) => {
  //     if (!row) return;
  //     return row["grossPremium"];
  //   },
  // },
  {
    key: "premiumTypeCodeID",
    name: "premiumTypeCodeID",
    labelKey: "pct.premiumTypeCodeID.label",
    isResizable: true,
    width: 140,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "taxonomy",
      taxonomyKey: "PremiumType",
      value: [],
    },
    cellOptions: {
      cellType: "taxonomy",
      additionalInfo: {
        taxonomy: "PremiumType",
      },
    },
    excel: {
      render: ({ row, taxonomy, t }: { row: any; taxonomy: any; t: any }) => {
        const code = taxonomy.PremiumType.byId[row.premiumTypeCodeID]?.code;
        return t(code);
      },
    },
    pdf: {
      render: ({ row, taxonomy, t }: { row: any; taxonomy: any; t: any }) => {
        const code = taxonomy.PremiumType.byId[row.premiumTypeCodeID]?.code;
        return {
          text: t(code),
          style: {
            bold: true,
            alignment: "center",
          },
        };
      },
    },
    onRender: (row) => {
      if (!row) return;
      return row["premiumTypeCodeID"];
    },
  },
  {
    key: "comment",
    name: "comment",
    labelKey: "pct.comment.label",
    isResizable: true,
    width: 140,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: null,
    onRender: (row) => {
      if (!row) return;
      return row["comment"];
    },
  },
  // {
  //   key: "policyVersionLineID",
  //   name: "policyVersionLineID",
  //   labelKey: "pct.policyVersionLineID.label",
  //   isResizable: true,
  //   width: 140,
  //   isVisible: false,
  //   isSortable: true,
  //   iconName: "Sort",
  //   filter: {
  //     type: "number",
  //     number1: null,
  //     number2: null,
  //     operator: "gt",
  //     format: "integer",
  //     includeThousandSeparators: false,
  //   },
  //   onRender: (row) => {
  //     if (!row) return;
  //     return row["policyVersionLineID"];
  //   },
  // },
  // {
  //   key: "policyID",
  //   name: "policyID",
  //   labelKey: "pct.policyID.label",
  //   isResizable: true,
  //   width: 140,
  //   isVisible: false,
  //   isSortable: true,
  //   iconName: "Sort",
  //   filter: {
  //     type: "number",
  //     number1: null,
  //     number2: null,
  //     operator: "gt",
  //     format: "integer",
  //     includeThousandSeparators: false,
  //   },
  //   onRender: (row) => {
  //     if (!row) return;
  //     return row["policyID"];
  //   },
  // },
  {
    key: "policyProcessStatusCodeID",
    name: "policyProcessStatusCodeID",
    labelKey: "pct.policyProcessStatusCodeID.label",
    isResizable: true,
    width: 140,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "taxonomy",
      taxonomyKey: "PolicyProcessStatus",
      value: [],
    },
    excel: {
      render: ({ row, taxonomy, t }: { row: any; taxonomy: any; t: any }) => {
        const code =
          taxonomy.PolicyProcessStatus.byId[row.policyProcessStatusCodeID]
            ?.code;
        return t(code);
      },
    },
    pdf: {
      render: ({ row, taxonomy, t }: { row: any; taxonomy: any; t: any }) => {
        const code =
          taxonomy.PolicyProcessStatus.byId[row.policyProcessStatusCodeID]
            ?.code;
        return {
          text: t(code),
          style: {
            bold: true,
            alignment: "center",
          },
        };
      },
    },
    cellOptions: {
      cellType: "taxonomy",
      additionalInfo: {
        taxonomy: "PolicyProcessStatus",
      },
    },
    onRender: (row) => {
      if (!row) return;
      return row["policyProcessStatusCodeID"];
    },
  },
  {
    key: "policyStatusCodeID",
    name: "policyStatusCodeID",
    labelKey: "pct.policyStatusCodeID.label",
    isResizable: true,
    width: 140,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "taxonomy",
      taxonomyKey: "PolicyStatus",
      value: [],
    },
    cellOptions: {
      cellType: "taxonomy",
      additionalInfo: {
        taxonomy: "PolicyStatus",
      },
    },
    excel: {
      render: ({ row, taxonomy, t }: { row: any; taxonomy: any; t: any }) => {
        const code = taxonomy.PolicyStatus.byId[row.policyStatusCodeID]?.code;
        return t(code);
      },
    },
    pdf: {
      render: ({ row, taxonomy, t }: { row: any; taxonomy: any; t: any }) => {
        const code = taxonomy.PolicyStatus.byId[row.policyStatusCodeID]?.code;
        return {
          text: t(code),
          style: {
            bold: true,
            alignment: "center",
          },
        };
      },
    },
    onRender: (row) => {
      if (!row) return;
      return row["policyStatusCodeID"];
    },
  },
  // {
  //   key: "policyVersionID",
  //   name: "policyVersionID",
  //   labelKey: "pct.policyVersionID.label",
  //   isResizable: true,
  //   width: 140,
  //   isVisible: false,
  //   isSortable: true,
  //   iconName: "Sort",
  //   filter: {
  //     type: "number",
  //     number1: null,
  //     number2: null,
  //     operator: "gt",
  //     format: "integer",
  //     includeThousandSeparators: false,
  //   },
  //   onRender: (row) => {
  //     if (!row) return;
  //     return row["policyVersionID"];
  //   },
  // },
  {
    key: "policyPremiumCollectionTypeCodeID",
    name: "policyPremiumCollectionTypeCodeID",
    labelKey: "pct.policyPremiumCollectionTypeCodeID.label",
    isResizable: true,
    width: 140,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "taxonomy",
      taxonomyKey: "PolicyPremiumCollectionType",
      value: [],
    },
    cellOptions: {
      cellType: "taxonomy",
      additionalInfo: {
        taxonomy: "PolicyPremiumCollectionType",
      },
    },
    excel: {
      render: ({ row, taxonomy, t }: { row: any; taxonomy: any; t: any }) => {
        const code =
          taxonomy.PolicyPremiumCollectionType.byId[
            row.policyPremiumCollectionTypeCodeID
          ]?.code;
        return t(code);
      },
    },
    pdf: {
      render: ({ row, taxonomy, t }: { row: any; taxonomy: any; t: any }) => {
        const code =
          taxonomy.PolicyPremiumCollectionType.byId[
            row.policyPremiumCollectionTypeCodeID
          ]?.code;
        return {
          text: t(code),
          style: {
            bold: true,
            alignment: "center",
          },
        };
      },
    },
    onRender: (row) => {
      if (!row) return;
      return row["policyPremiumCollectionTypeCodeID"];
    },
  },
  {
    key: "policyVersionStatusCodeID",
    name: "policyVersionStatusCodeID",
    labelKey: "pct.policyVersionStatusCodeID.label",
    isResizable: true,
    width: 140,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "taxonomy",
      taxonomyKey: "PolicyVersionStatus",
      value: [],
    },
    cellOptions: {
      cellType: "taxonomy",
      additionalInfo: {
        taxonomy: "PolicyVersionStatus",
      },
    },
    excel: {
      render: ({ row, taxonomy, t }: { row: any; taxonomy: any; t: any }) => {
        const code =
          taxonomy.PolicyVersionStatus.byId[row.policyVersionStatusCodeID]
            ?.code;
        return t(code);
      },
    },
    pdf: {
      render: ({ row, taxonomy, t }: { row: any; taxonomy: any; t: any }) => {
        const code =
          taxonomy.PolicyVersionStatus.byId[row.policyVersionStatusCodeID]
            ?.code;
        return {
          text: t(code),
          style: {
            bold: true,
            alignment: "center",
          },
        };
      },
    },
    onRender: (row) => {
      if (!row) return;
      return row["policyVersionStatusCodeID"];
    },
  },

  {
    key: "validFrom",
    name: "validFrom",
    labelKey: "pct.validFrom.label",
    isResizable: true,
    width: 140,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: { type: "date", date1: null, date2: null, operator: "isAfter" },
    onRender: (row) => {
      if (!row) return;
      return moment(row["validFrom"]).format("DD.MM.YYYY");
    },
    pdf: {
      render: ({ row, taxonomy, t }: { row: any; taxonomy: any; t: any }) => {
        return {
          text: moment(row["validFrom"]).format("DD.MM.YYYY"),
          style: {
            bold: true,
            alignment: "center",
          },
        };
      },
    },
    excel: {
      render: ({ row, taxonomy, t }: { row: any; taxonomy: any; t: any }) => {
        return moment(row["validFrom"]).format("DD.MM.YYYY");
      },
    },
  },
  {
    key: "costCenterCodeID",
    name: "costCenterCodeID",
    labelKey: "pct.costCenterCodeID.label",
    isResizable: true,
    width: 140,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "taxonomy",
      taxonomyKey: "CostCenter",
      isSelect: true,
      value: [],
    },

    cellOptions: {
      cellType: "taxonomy",
      additionalInfo: {
        taxonomy: "CostCenter",
      },
    },
    excel: {
      render: ({ row, taxonomy, t }: { row: any; taxonomy: any; t: any }) => {
        const code = taxonomy.CostCenter.byId[row.costCenterCodeID]?.code;
        return t(code);
      },
    },
    pdf: {
      render: ({ row, taxonomy, t }: { row: any; taxonomy: any; t: any }) => {
        const code = taxonomy.CostCenter.byId[row.costCenterCodeID]?.code;
        return {
          text: t(code),
          style: {
            bold: true,
            alignment: "center",
          },
        };
      },
    },
    onRender: (row) => {
      if (!row) return;
      const formatter = new Intl.NumberFormat("hu-HU", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      });
      return formatter.format(row["costCenterCodeID"]);
    },
  },
  {
    key: "expiry",
    name: "expiry",
    labelKey: "pct.expiry.label",
    isResizable: true,
    width: 140,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: { type: "date", date1: null, date2: null, operator: "isAfter" },
    onRender: (row) => {
      if (!row) return;
      return moment(row["expiry"]).format("DD.MM.YYYY");
    },
    pdf: {
      render: ({ row, taxonomy, t }: { row: any; taxonomy: any; t: any }) => {
        return {
          text: moment(row["expiry"]).format("DD.MM.YYYY"),
          style: {
            bold: true,
            alignment: "center",
          },
        };
      },
    },
    excel: {
      render: ({ row, taxonomy, t }: { row: any; taxonomy: any; t: any }) => {
        return moment(row["expiry"]).format("DD.MM.YYYY");
      },
    },
  },
  {
    key: "grossPremiumPVL",
    name: "grossPremiumPVL",
    labelKey: "pct.grossPremiumPVL.label",
    isResizable: true,
    width: 140,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "number",
      number1: null,
      number2: null,
      operator: "gt",
      format: "float",
      includeThousandSeparators: false,
    },
    onRender: (row) => {
      if (!row) return;
      return row["grossPremiumPVL"];
    },
    excel: {
      render: ({ row, taxonomy, t }: { row: any; taxonomy: any; t: any }) => {
        return Number(row["grossPremiumPVL"]);
      },
    },
  },
  {
    key: "premiumInclCommissionExclTaxes",
    name: "premiumInclCommissionExclTaxes",
    labelKey: "pct.premiumInclCommissionExclTaxes.label",
    isResizable: true,
    width: 140,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "number",
      number1: null,
      number2: null,
      operator: "gt",
      format: "float",
      includeThousandSeparators: false,
    },
    onRender: (row) => {
      if (!row) return;
      return row["premiumInclCommissionExclTaxes"];
    },
    excel: {
      render: ({ row, taxonomy, t }: { row: any; taxonomy: any; t: any }) => {
        return Number(row["premiumInclCommissionExclTaxes"]);
      },
    },
  },

  {
    key: "insuranceLineCode",
    name: "insuranceLineCode",
    labelKey: "pct.insuranceLineCodeID.label",
    isResizable: true,
    width: 140,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "taxonomy",
      taxonomyKey: "InsuranceLine",
      value: [],
    },
    cellOptions: {
      cellType: "taxonomy",
      additionalInfo: {
        taxonomy: "InsuranceLine",
      },
    },
    excel: {
      render: ({ row, taxonomy, t }: { row: any; taxonomy: any; t: any }) => {
        const code = taxonomy.InsuranceLine.byId[row.insuranceLineCode]?.code;
        return t(code);
      },
    },
    pdf: {
      render: ({ row, taxonomy, t }: { row: any; taxonomy: any; t: any }) => {
        const code = taxonomy.InsuranceLine.byId[row.insuranceLineCode]?.code;
        return {
          text: t(code),
          style: {
            bold: true,
            alignment: "center",
          },
        };
      },
    },
    onRender: (row) => {
      if (!row) return;
      return row["insuranceLineCode"];
    },
  },
  {
    key: "policyPaymentFrequencyCodeID",
    name: "policyPaymentFrequencyCodeID",
    labelKey: "pct.policyPaymentFrequencyCodeID.label",
    isResizable: true,
    width: 140,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "taxonomy",
      taxonomyKey: "PolicyPaymentFrequency",
      value: [],
    },
    cellOptions: {
      cellType: "taxonomy",
      additionalInfo: {
        taxonomy: "PolicyPaymentFrequency",
      },
    },
    excel: {
      render: ({ row, taxonomy, t }: { row: any; taxonomy: any; t: any }) => {
        const code =
          taxonomy.PolicyPaymentFrequency.byId[row.policyPaymentFrequencyCodeID]
            ?.code;
        return t(code);
      },
    },
    pdf: {
      render: ({ row, taxonomy, t }: { row: any; taxonomy: any; t: any }) => {
        const code =
          taxonomy.PolicyPaymentFrequency.byId[row.policyPaymentFrequencyCodeID]
            ?.code;
        return {
          text: t(code),
          style: {
            bold: true,
            alignment: "center",
          },
        };
      },
    },
    onRender: (row) => {
      if (!row) return;
      const formatter = new Intl.NumberFormat("hu-HU", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      });
      return formatter.format(row["policyPaymentFrequencyCodeID"]);
    },
  },
];
