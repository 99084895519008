import { DirectionalHint, TooltipHost } from "@fluentui/react";
import { Button, Tooltip } from "@fluentui/react-components";
import {
  CheckmarkCircleRegular,
  CheckmarkCircleFilled,
  EditRegular,
} from "@fluentui/react-icons";
import { HSpace } from "@greco/components/dist/components/utils";
import {
  Cell,
  CellTemplate,
  Compatible,
  Uncertain,
  getCellProperty,
} from "@silevis/reactgrid";
import { HorizontalSpacer } from "components/styled";
import * as React from "react";
import { formatNumber } from "utils/number";

export interface BankStatementPaidReceivedCell extends Cell {
  type: "bankstatementpaidreceived";
  link?: string;
  deleteFn?: any;
  saveFn?: any;
  cancelFn?: any;
  resolvementSuggestFn?: any;
  clearFn?: any;
  rowId?: any;
  clientId?: any;
  edited?: boolean;
  selected?: boolean;
  highlighted?: boolean;
  isDisabled?: boolean;
  numPaid?: boolean;
  numReceived?: boolean;
  t: any;
}

export class BankStatementPaidReceivedCellTemplate
  implements CellTemplate<BankStatementPaidReceivedCell>
{
  getCompatibleCell(
    uncertainCell: Uncertain<BankStatementPaidReceivedCell>
  ): Compatible<BankStatementPaidReceivedCell> {
    let t: any;
    t = getCellProperty(uncertainCell, "t", "function");

    let rowId: any;

    try {
      rowId = getCellProperty(uncertainCell, "rowId", "number");
    } catch {
      rowId = undefined;
    }

    let isDisabled = true;
    try {
      isDisabled = getCellProperty(uncertainCell, "isDisabled", "boolean");
    } catch {
      isDisabled = false;
    }

    let numPaid = true;
    try {
      numPaid = getCellProperty(uncertainCell, "numPaid", "number");
    } catch {
      numPaid = false;
    }

    let numReceived = true;
    try {
      numReceived = getCellProperty(uncertainCell, "numReceived", "number");
    } catch {
      numReceived = false;
    }

    let edited = false;
    try {
      edited = getCellProperty(uncertainCell, "edited", "boolean");
    } catch {
      edited = false;
    }

    let selected = false;
    try {
      selected = getCellProperty(uncertainCell, "selected", "boolean");
    } catch {
      selected = false;
    }
    let highlighted: boolean | undefined;
    try {
      highlighted = getCellProperty(uncertainCell, "highlighted", "boolean");
    } catch {
      highlighted = false;
    }

    // let inputValue: string | undefined;
    // try {
    //   inputValue = getCellProperty(uncertainCell, "inputValue", "string");
    // } catch {
    //   inputValue = undefined;
    // }

    const text = "";
    return {
      ...uncertainCell,
      text,
      rowId,

      value: NaN,
      isDisabled,
      numPaid,
      numReceived,
      edited,
      selected,
      highlighted,
      t, // isOpen,
      // inputValue,
    };
  }

  getClassName(
    cell: Compatible<BankStatementPaidReceivedCell>,
    isInEditMode: boolean
  ): string {
    return (
      (cell.className ? cell.className : "") +
      (cell.selected ? " rg-selected" : "") +
      (cell.highlighted ? " highlighted-selected-row" : "")
    );
  }

  render(
    cell: Compatible<BankStatementPaidReceivedCell>,
    isInEditMode: boolean,
    onCellChanged: (
      cell: Compatible<BankStatementPaidReceivedCell>,
      commit: boolean
    ) => void
  ): React.ReactNode {
    return <OptionsCell cell={cell} />;
  }
}

interface DIProps {
  cell: Record<string, any>;
}

const OptionsCell: React.FC<DIProps> = ({ cell }) => {
  return (
    <div style={{ width: "100%", textAlign: "center" }}>
      <TooltipHost
        content={cell.t("pct.bankStatementPaidReceivedCellTooltip.label")}
        directionalHint={DirectionalHint.rightCenter}
        hidden={false}
      >
        {formatNumber(cell.numPaid, 2, 2)} /{" "}
        {formatNumber(cell.numReceived, 2, 2)}
      </TooltipHost>
    </div>
  );
};
