import { DirectionalHint, TooltipHost } from "@fluentui/react";
import { Button, Tooltip } from "@fluentui/react-components";
import {
  CircleHalfFill20Regular,
  CheckmarkCircle20Filled,
  Circle20Regular,
  Question20Regular,
  LineHorizontal1Regular,
} from "@fluentui/react-icons";
import { HSpace } from "@greco/components/dist/components/utils";
import {
  Cell,
  CellTemplate,
  Compatible,
  Uncertain,
  getCellProperty,
} from "@silevis/reactgrid";
import { HorizontalSpacer } from "components/styled";
import * as React from "react";

export interface ReminderAmountsStatusCell extends Cell {
  type: "reminderamountsstatus";
  link?: string;
  rowId?: any;
  edited?: boolean;
  isDisabled?: boolean;
  premium_status?: string;
  discount_status?: string;
  isBrokerPremiumCollectionReminder?: boolean;
  selected?: boolean;
  highlighted?: boolean;
  t: any;
}

export class ReminderAmountsStatusCellTemplate
  implements CellTemplate<ReminderAmountsStatusCell>
{
  getCompatibleCell(
    uncertainCell: Uncertain<ReminderAmountsStatusCell>
  ): Compatible<ReminderAmountsStatusCell> {
    let t: any;
    t = getCellProperty(uncertainCell, "t", "function");

    let rowId: any;

    try {
      rowId = getCellProperty(uncertainCell, "rowId", "number");
    } catch {
      rowId = undefined;
    }

    let isDisabled = true;
    try {
      isDisabled = getCellProperty(uncertainCell, "isDisabled", "boolean");
    } catch {
      isDisabled = false;
    }
    let selected = false;
    try {
      selected = getCellProperty(uncertainCell, "selected", "boolean");
    } catch {
      selected = false;
    }
    let highlighted: boolean | undefined;
    try {
      highlighted = getCellProperty(uncertainCell, "highlighted", "boolean");
    } catch {
      highlighted = false;
    }
    let isBrokerPremiumCollectionReminder = true;
    try {
      isBrokerPremiumCollectionReminder = getCellProperty(
        uncertainCell,
        "isBrokerPremiumCollectionReminder",
        "boolean"
      );
    } catch {
      isBrokerPremiumCollectionReminder = false;
    }

    let premium_status = null;
    try {
      premium_status = getCellProperty(
        uncertainCell,
        "premium_status",
        "string"
      );
    } catch {
      premium_status = null;
    }

    let discount_status = null;
    try {
      discount_status = getCellProperty(
        uncertainCell,
        "discount_status",
        "string"
      );
    } catch {
      discount_status = null;
    }

    let edited = false;
    try {
      edited = getCellProperty(uncertainCell, "edited", "boolean");
    } catch {
      edited = false;
    }

    // let inputValue: string | undefined;
    // try {
    //   inputValue = getCellProperty(uncertainCell, "inputValue", "string");
    // } catch {
    //   inputValue = undefined;
    // }

    const text = "";
    return {
      ...uncertainCell,
      text,
      rowId,
      selected,
      highlighted,
      value: NaN,
      isDisabled,
      premium_status,
      discount_status,
      isBrokerPremiumCollectionReminder,
      edited,
      t, // isOpen,
      // inputValue,
    };
  }

  getClassName(
    cell: Compatible<ReminderAmountsStatusCell>,
    isInEditMode: boolean
  ): string {
    return `${
      (cell.className ? cell.className : "") +
      (cell.selected ? " rg-selected" : "") +
      (cell.highlighted ? " highlighted-selected-row" : "")
    }`;
  }

  render(
    cell: Compatible<ReminderAmountsStatusCell>,
    isInEditMode: boolean,
    onCellChanged: (
      cell: Compatible<ReminderAmountsStatusCell>,
      commit: boolean
    ) => void
  ): React.ReactNode {
    return <OptionsCell cell={cell} />;
  }
}

interface DIProps {
  cell: Record<string, any>;
}

const OptionsCell: React.FC<DIProps> = ({ cell }) => {
  return (
    <>
      <div style={{ width: "100%", textAlign: "center", marginTop: "5px" }}>
        <TooltipHost
          content={
            !cell.isBrokerPremiumCollectionReminder
              ? cell.t("pct.premiumNotCollectedByBroker.label")
              : cell.t(`pct.${cell.premium_status}PremiumStatusTooltip.label`)
          }
          directionalHint={DirectionalHint.rightCenter}
          hidden={false}
        >
          {!cell.isBrokerPremiumCollectionReminder ? (
            <Question20Regular />
          ) : cell.premium_status === "green" ? (
            <CheckmarkCircle20Filled color={cell.premium_status} />
          ) : cell.premium_status === "orange" ? (
            <CircleHalfFill20Regular color={cell.premium_status} />
          ) : cell.premium_status === "gold" ? (
            <CircleHalfFill20Regular color={cell.premium_status} />
          ) : (
            <Circle20Regular color={cell.premium_status} />
          )}
        </TooltipHost>
      </div>
      <div style={{ width: "100%", textAlign: "center", marginTop: "5px" }}>
        <TooltipHost
          content={cell.t(
            `pct.${cell.discount_status}DiscountStatusTooltip.label`
          )}
          directionalHint={DirectionalHint.rightCenter}
          hidden={false}
        >
          {cell.discount_status === "gray" ? (
            <CheckmarkCircle20Filled color={cell.discount_status} />
          ) : cell.discount_status === "green" ? (
            <CheckmarkCircle20Filled color={cell.discount_status} />
          ) : cell.discount_status === "orange" ? (
            <CircleHalfFill20Regular color={cell.discount_status} />
          ) : cell.discount_status === "gold" ? (
            <CircleHalfFill20Regular color={cell.discount_status} />
          ) : (
            <Circle20Regular color={cell.discount_status} />
          )}
        </TooltipHost>
      </div>
    </>
  );
};
