import React from "react";
import { useTranslation } from "react-i18next";

import { usePaymentPlanPageStore } from "store/PaymentPlanPageStore";
import { Button } from "@fluentui/react-components";
import { ArrowClockwiseFilled } from "@fluentui/react-icons";
import { useBankStatementsStore } from "store/BankStatementsStore";
import { useAppStoreState } from "store/AppStore";

export const RefreshButton = () => {
  const [{ bankStatementsLoadStatus }, { loadBankStatements }] =
    useBankStatementsStore();
  const { updatedGrecoCompanies, taxonomy, grecoCompanies } =
    useAppStoreState();
  const { t } = useTranslation();

  return (
    <Button
      disabled={bankStatementsLoadStatus === "loading"}
      appearance="subtle"
      icon={<ArrowClockwiseFilled fontSize={24} />}
      onClick={() => {
        loadBankStatements({
          reset: true,
          delay: false,
          updatedGrecoCompanies,
          taxonomy,
          grecoCompanies,
          t,
        });
      }}
    ></Button>
  );
};
