import { AsyncSelect } from "@greco/components";
import debounce from "debounce-promise";
import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { searchClients } from "api/api";
import { usePaymentPlanPageStore } from "store/PaymentPlanPageStore";
import { usePaymentPageStoreActions } from "store/PaymentPageStore";

const SearchClients = () => {
  const { t } = useTranslation();
  const { clientId } = useParams() as any;
  const [{ searchClientsSelectedItem }, { setPaymentPlanStoreValue }] =
    usePaymentPlanPageStore();
  const { setPaymentStoreValue } = usePaymentPageStoreActions();
  const isDisabled = !!clientId;

  return (
    <>
      <AsyncSelect
        id="clientsSearch"
        loadOptions={debounce(async (input) => {
          const trimmedInput = input.trim();
          if (trimmedInput.length === 0) return [];
          const clients = await searchClients(trimmedInput);
          return clients.data.map((c) => {
            return {
              label:
                c.name + (c.internalNumber ? ` (${c.internalNumber})` : ""),
              value: c,
            };
          });
        }, 300)}
        clearable
        placeholder={t("pct.searchClients.label")}
        noOptionsMessage={() => t("greco.noResults")}
        isClearable
        isDisabled={isDisabled}
        loadingMessage={() => t("greco.searching")}
        value={searchClientsSelectedItem}
        onChange={(value) => {
          setPaymentPlanStoreValue("searchClientsSelectedItem", value);
        }}
      />
    </>
  );
};

export default SearchClients;
