import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppStoreState } from "store/AppStore";
import { useBankStatementsStore } from "store/BankStatementsStore";

import { VSpace } from "components/Spacer";
import BankStatementEntryOptionsCellTypeHandler from "pages/BankStatements/BankStatementEntriesGrid/customcelltypehandlers/BankStatementEntryOptionsCellTypeHandler";
import GrecoReactGrid, {
  standardCellTypeHandlers,
} from "components/grecoreactgrid/GrecoReactGrid";
import { ClearBankStatementEntryDialog } from "./ClearBankStatementEntryDialog/ClearBankStatementEntryDialog";
import { DeleteBankStatementEntryDialog } from "./DeleteBankStatementEntryDialog";
import { bankStatementEntriesColumnConfig } from "./bankStatementEntriesColumnConfig";
import TaxonomyCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/TaxonomyCellTypeHandler";
import DatePickerCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/DatePickerCellTypeHandler";
import ClientCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/ClientCellTypeHandler";
import SortableHeaderCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/SortableHeaderCellTypeHandler";
import BankStatementEntryStatusCellTypeHandler from "./customcelltypehandlers/BankStatementEntryStatusCellTypeHandler";
import CustomTextCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/CustomTextCellTypeHandler";
import CustomNumberCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/CustomNumberCellTypeHandler";
import LastModifiedCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/LastModifiedCellTypeHandler";
import { InfoBankStatementEntryDialog } from "./InfoBankStatementEntryDialog/InfoBankStatementEntryDialog";
import { useTheme } from "@emotion/react";
import { ClearToSaldoBankStatementEntryDialog } from "./ClearToSaldoBankStatementEntryDialog";
import SumHeaderCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/SumHeaderCellTypeHandler";
import SelectCellTypeHandler from "./customcelltypehandlers/SelectCellTypeHandler";
import SelectAllCellTypeHandler from "./customcelltypehandlers/SelectAllCellTypeHandler";
import OrdinalNumberCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/OrdinalNumberCellTypeHandler";
import InfoNumberCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/InfoNumberCellTypeHandler";

const BankStatementEntryGrid = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const selectAllState = useState(false);
  const { taxonomy } = useAppStoreState();
  const bankStatementsStore = useBankStatementsStore();
  const [
    {
      bankStatements,
      bankStatementEntriesSort,
      bankStatementMetadataMap,
      bankStatementEntryMetadataMap,
      bankStatementEntries,
      clearToSaldoBankStatementEntryDialog,
      deleteBankStatementEntryDialog,
      clearBankStatementEntryDialog,
      infoBankStatementEntryDialog,
    },
    { setBankStatementsStoreValues, setBankStatementsStoreValue },
  ] = bankStatementsStore;

  useEffect(() => {
    if (!bankStatements) {
      return;
    }
    const newBankStatementEntryMetadataMap = {
      ...bankStatementEntryMetadataMap,
    };
    let selectAll = true;
    bankStatementEntries.forEach((bankStatementEntry) => {
      newBankStatementEntryMetadataMap[
        bankStatementEntry.bankStatementEntryId
      ] = {
        ...newBankStatementEntryMetadataMap[
          bankStatementEntry.bankStatementEntryId
        ],
        selected:
          newBankStatementEntryMetadataMap[
            bankStatementEntry.bankStatementEntryId
          ].selected &&
          bankStatementMetadataMap[bankStatementEntry.bankStatementId]
            .selected &&
          bankStatementEntries.find((el) => {
            return (
              el.bankStatementEntryId ===
              bankStatementEntry.bankStatementEntryId
            );
          }).isDeleted === false,
        visible:
          bankStatementMetadataMap[bankStatementEntry.bankStatementId]
            .selected &&
          bankStatementEntries.find((el) => {
            return (
              el.bankStatementEntryId ===
              bankStatementEntry.bankStatementEntryId
            );
          }).isDeleted === false,
      };
      for (let item in bankStatementMetadataMap) {
        const statementMetadata = bankStatementMetadataMap[item];
        if (
          statementMetadata.visible === true &&
          statementMetadata.selected === false
        ) {
          selectAll = false;
          break;
        }
      }
    });
    setBankStatementsStoreValues({
      bankStatementEntryMetadataMap: newBankStatementEntryMetadataMap,
      bankStatementsSelectAll: selectAll,
    });
  }, [bankStatementMetadataMap]);

  const handleChanges = (
    updatedRows: any[],
    updatedRowsMetadataMap: { [key: number]: any }
  ) => {
    setBankStatementsStoreValues({
      bankStatementEntries: bankStatementEntries.map((entry) => {
        const updatedEntry = updatedRows.find(
          (row) => row.bankStatementEntryId === entry.bankStatementEntryId
        );
        if (updatedEntry) {
          return updatedEntry;
        } else {
          return entry;
        }
      }),
      bankStatementEntryMetadataMap: updatedRowsMetadataMap,
    });
  };

  const cellTypeHandlers = {
    ...standardCellTypeHandlers,
    taxonomy: new TaxonomyCellTypeHandler(),
    datepicker: new DatePickerCellTypeHandler(),
    client: new ClientCellTypeHandler(),
    bankstatemententryoptions: new BankStatementEntryOptionsCellTypeHandler(),
    bankstatemententrystatus: new BankStatementEntryStatusCellTypeHandler(),
    sortableheader: new SortableHeaderCellTypeHandler(),
    sumheader: new SumHeaderCellTypeHandler(),
    customtext: new CustomTextCellTypeHandler(),
    customnumber: new CustomNumberCellTypeHandler(),
    lastmodified: new LastModifiedCellTypeHandler(),
    select: new SelectCellTypeHandler(),
    selectall: new SelectAllCellTypeHandler(),
    ordinalnumber: new OrdinalNumberCellTypeHandler(),
    infonumber: new InfoNumberCellTypeHandler(),
  };
  return (
    <>
      <div style={{ height: "100%", overflow: "auto" }}>
        {/* <VSpace height={20} />
        <h2>Bank statement entries ({bankStatementEntries.length})</h2>
        */}
        <VSpace height={5} />
        <VSpace height={10} />
        <div style={{ height: "calc(100% - 74px)", overflow: "scroll" }}>
          <GrecoReactGrid
            rows={bankStatementEntries}
            columns={bankStatementEntriesColumnConfig}
            idColumn={"bankStatementEntryId"}
            rowsMetadataMap={bankStatementEntryMetadataMap}
            handleChanges={handleChanges}
            cellTypeHandlers={cellTypeHandlers}
            hasSumHeaderRow={true}
            keyColumn="bankStatementEntryId"
            stickyColumns={{
              left: 5,
              right: 1,
              top: 1,
              bottom: 1,
            }}
            context={{
              taxonomy: taxonomy,
              t: t,
              bankStatementsStore: bankStatementsStore,
              selectAllState: selectAllState,
              theme: theme,
              sort: bankStatementEntriesSort,
              setSort: (sort: any) => {
                setBankStatementsStoreValue("bankStatementEntriesSort", sort);
              },
            }}
            sort={bankStatementEntriesSort}
          />
        </div>
      </div>
      {deleteBankStatementEntryDialog.isOpen && (
        <DeleteBankStatementEntryDialog />
      )}
      {clearToSaldoBankStatementEntryDialog.isOpen && (
        <ClearToSaldoBankStatementEntryDialog />
      )}
      {clearBankStatementEntryDialog.isOpen && (
        <ClearBankStatementEntryDialog />
      )}
      {infoBankStatementEntryDialog.isOpen && <InfoBankStatementEntryDialog />}
    </>
  );
};

export default BankStatementEntryGrid;
