import {
  createActionsHook,
  createHook,
  createStateHook,
  createStore,
} from "react-sweet-state";
import { LoadStatus, Notification } from "types/types";
import {
  BankStatement,
  BankStatementEntry,
  BankStatementEntryMetadataMap,
  BankStatementMetadataMap,
  SettledPaymentMetadataMap,
  UnsettledPaymentPlanMetadataMap,
} from "utils/types";
import {
  addNewEmptyBankStatement,
  addNewEmptyBankStatementEntry,
  cancelEditBankStatement,
  cancelEditBankStatementEntry,
  clearBankStatementEntry,
  deleteBankStatement,
  filterBankStatements,
  importBankStatement,
  loadBankStatements,
  populateClearanceData,
  resolvementSuggest,
  resolvementSuggestForStatement,
  saveBankStatement,
  saveBankStatementEntry,
  setStoreValue,
  setStoreValues,
  deleteBankStatementEntry,
  populateBankStatementEntryInfoData,
  getInsurersByCountry,
} from "./BankStatementsStoreActions";
import moment from "moment";
import { setNotificationMessage } from "./AppStoreActions";

export type StoreState = {
  notifications: Notification[];
  bankStatements: BankStatement[];
  bankStatementEntries: BankStatementEntry[];
  // Map that contains additional bank statement info like filtered entries, selected entries, readonly entries, etc.
  bankStatementMetadataMap: BankStatementMetadataMap;
  // Map that contains additional info for bank statement entries for each bank statement like filtered entries, selected entries, readonly entries, etc.
  bankStatementEntryMetadataMap: BankStatementEntryMetadataMap;
  bankStatementsSelectAll: boolean;
  bankStatementEntriesSelectAll: boolean;
  bankStatementsLoadStatus: LoadStatus;
  importBankStatementStatus: LoadStatus;
  deleteBankStatementStatus: LoadStatus;
  deleteBankStatementEntryStatus: LoadStatus;
  clearToSaldoBankStatementEntryStatus: LoadStatus;
  bankStatementSaveStatus: LoadStatus;
  bankStatementEntrySaveStatus: LoadStatus;
  resolvementLoadStatus: LoadStatus;
  clearBankStatementEntryStatus: LoadStatus;
  infoBankStatementEntryStatus: LoadStatus;
  getInsurersByCountryStatus: LoadStatus;
  searchTerm: string;
  fromDateFilter: Date;
  toDateFilter: Date;

  clUnsettledPaymentPlans: any[];
  clUnsettledPaymentPlanMetadataMap: UnsettledPaymentPlanMetadataMap;
  clTotal: number;
  clTotalRows: number;
  clBankStatementEntry: any;
  clInsurersCountryCode: any;
  clInsurersSelectedItem: any[];
  clInsurersOptions: any[];
  clClientsSelectedItem: any[];
  clPoliciesSelectedItem: any[];
  clSubpoliciesSelectedItem: any[];
  clServiceSegmentsSelectedItem: any[];
  clPaymentReferenceNumbersSelectedItem: any[];
  clSearchTerm: string;
  clServiceSegmentCode: number;
  clFromDateFilter: Date;
  clToDateFilter: Date;
  clIsBrokerPremiumCollection: boolean;
  clearanceRequest: any;
  clHightlightCount: number;

  bankStatementsSort: any;
  bankStatementEntriesSort: any;
  infoBankStatementEntrySort: any;
  clearBankStatementEntrySort: any;

  importBankStatementDialog: {
    isOpen: boolean;
  };
  deleteBankStatementDialog: {
    isOpen: boolean;
    bankStatementId: any;
  };
  deleteBankStatementEntryDialog: {
    isOpen: boolean;
    bankStatementId: any;
    bankStatementEntryId: any;
  };
  clearToSaldoBankStatementEntryDialog: {
    isOpen: boolean;
    bankStatementId: any;
    bankStatementEntryId: any;
  };
  clearBankStatementEntryDialog: {
    isOpen: boolean;
    bankStatementEntryId: any;
  };
  infoBankStatementEntryDialog: {
    isOpen: boolean;
    bankStatementEntryId: any;
  };
  infoSettledPayments: any[];
  infoSettledPaymentsMetadataMap: SettledPaymentMetadataMap;
  paymentPlanRequest: any;
  searchDateFilterField: "statementCreatedAt" | "periodFrom" | "periodTo";
};

// This is the value of the store on initialisation
const initialState: StoreState = {
  notifications: [],
  bankStatements: [],
  bankStatementEntries: [],
  bankStatementMetadataMap: {},
  bankStatementEntryMetadataMap: {},
  bankStatementsLoadStatus: "none",
  bankStatementsSelectAll: false,
  bankStatementEntriesSelectAll: false,
  searchDateFilterField: "statementCreatedAt",
  importBankStatementDialog: {
    isOpen: false,
  },
  importBankStatementStatus: "none",
  deleteBankStatementDialog: {
    isOpen: false,
    bankStatementId: null,
  },
  deleteBankStatementStatus: "none",
  deleteBankStatementEntryDialog: {
    isOpen: false,
    bankStatementId: null,
    bankStatementEntryId: null,
  },
  clearToSaldoBankStatementEntryDialog: {
    isOpen: false,
    bankStatementId: null,
    bankStatementEntryId: null,
  },
  deleteBankStatementEntryStatus: "none",
  clearBankStatementEntryDialog: {
    isOpen: false,
    bankStatementEntryId: null,
  },
  infoBankStatementEntryDialog: {
    isOpen: false,
    bankStatementEntryId: null,
  },
  infoSettledPayments: [],
  infoSettledPaymentsMetadataMap: {},

  bankStatementSaveStatus: "none",
  bankStatementEntrySaveStatus: "none",
  resolvementLoadStatus: "none",
  clearBankStatementEntryStatus: "none",
  infoBankStatementEntryStatus: "none",
  clearToSaldoBankStatementEntryStatus: "none",
  getInsurersByCountryStatus: "none",
  clUnsettledPaymentPlans: [],
  clUnsettledPaymentPlanMetadataMap: {},
  clTotal: 0,
  clTotalRows: 0,
  clBankStatementEntry: null,
  clInsurersSelectedItem: [],
  clInsurersOptions: [],
  clInsurersCountryCode: null,
  clClientsSelectedItem: [],
  clPoliciesSelectedItem: [],
  clSubpoliciesSelectedItem: [],
  clServiceSegmentsSelectedItem: [],
  clPaymentReferenceNumbersSelectedItem: [],
  clSearchTerm: "",
  clServiceSegmentCode: null,
  clIsBrokerPremiumCollection: true,
  clearanceRequest: null,
  clHightlightCount: 0,

  bankStatementsSort: null,
  bankStatementEntriesSort: null,
  infoBankStatementEntrySort: null,
  clearBankStatementEntrySort: null,

  clFromDateFilter: moment()
    .subtract(3, "months")
    .startOf("day")

    .toDate(),
  clToDateFilter: moment().toDate(),

  searchTerm: "",
  fromDateFilter: moment()
    .subtract(3, "months")
    .startOf("day")

    .toDate(),
  toDateFilter: moment().toDate(),
  paymentPlanRequest: null,
};

// All the actions that mutate the store
const actions = {
  setBankStatementsStoreValue: setStoreValue,
  setBankStatementsStoreValues: setStoreValues,
  loadBankStatements: loadBankStatements,
  filterBankStatements: filterBankStatements,
  importBankStatement: importBankStatement,
  deleteBankStatement: deleteBankStatement,
  deleteBankStatementEntry: deleteBankStatementEntry,
  addNewEmptyBankStatement: addNewEmptyBankStatement,
  addNewEmptyBankStatementEntry: addNewEmptyBankStatementEntry,
  cancelEditBankStatement: cancelEditBankStatement,
  cancelEditBankStatementEntry: cancelEditBankStatementEntry,
  saveBankStatement: saveBankStatement,
  saveBankStatementEntry: saveBankStatementEntry,
  resolvementSuggest: resolvementSuggest,
  resolvementSuggestForStatement: resolvementSuggestForStatement,
  populateClearanceData: populateClearanceData,
  populateBankStatementEntryInfoData: populateBankStatementEntryInfoData,
  clearBankStatementEntry: clearBankStatementEntry,
  setNotificationMessage: setNotificationMessage,
  getInsurersByCountry: getInsurersByCountry,
};

const BankStatementsStore = createStore({
  initialState,
  actions,
  name: "BankStatementsStore",
});
export const useBankStatementsStore = createHook(BankStatementsStore);
export const useBankStatementsStoreState = createStateHook(BankStatementsStore);
export const useBankStatementsStoreActions =
  createActionsHook(BankStatementsStore);

export const useClInsurersOptions = createHook(BankStatementsStore, {
  selector: (state) => ({
    clInsurersOptions: state.clInsurersOptions,
  }),
});

export const useInsurersCountryCode = createHook(BankStatementsStore, {
  selector: (state) => ({ insurersCountryCode: state.clInsurersCountryCode }),
});
