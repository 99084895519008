import React, { useEffect, useRef } from "react";
import { useAppStore } from "store/AppStore";
import { usePaymentPageStoreState } from "store/PaymentPageStore";
import PaymentPageNavigation from "./PaymentPageNavigation";
import PaymentPageTable from "./PaymentPageTable";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { ThemeProvider } from "@greco/components";
import LoadingOverlay from "components/LoadingOverlay";
import { ExportExcel } from "./ExportExcel";
import { useTranslation } from "react-i18next";
import i18n from "i18n";
import DeclarePaymentsDialog from "./DeclarePaymentsDialog";
import { useDeclarePaymentsDialog } from "store/PaymentDeclarationDialogStore";

const Payment = () => {
  const refExcelExport = useRef<ExcelExport>();
  const { paymentsLoadStatus } = usePaymentPageStoreState();
  const [{ declarePaymentsDialog }] = useDeclarePaymentsDialog();
  const [{ appState }, { setAppStoreValue }] = useAppStore();
  const { t } = useTranslation();

  useEffect(() => {
    setAppStoreValue(
      "headerTitle",
      t("pct.premiumCollectionTool.label") +
        " - " +
        t("pct.paymentsToInsurers.label")
    );
  }, [i18n.language]);

  return (
    <ThemeProvider isDarkMode={appState.darkMode}>
      <PaymentPageNavigation refExcelExport={refExcelExport?.current} />
      <PaymentPageTable />
      <ExportExcel ref={refExcelExport} />
      {paymentsLoadStatus === "loading" ? <LoadingOverlay /> : null}
      {declarePaymentsDialog.isOpen && <DeclarePaymentsDialog />}
    </ThemeProvider>
  );
};

export default Payment;
