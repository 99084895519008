import { ExpandedColumn } from "../types/columns";

export const hydrateColumn = ({
  column,
  defaultColumns,
}: {
  column: ExpandedColumn;
  defaultColumns: ExpandedColumn[];
}) => {
  const defaultCol = defaultColumns.find((dc) => dc.key === column.key)!;
  const mappedCol = { ...column };
  if (defaultCol?.filter && mappedCol.filter) {
    Object.keys(defaultCol.filter).forEach((key) => {
      const field = defaultCol?.filter![key];
      if (typeof field === "function") {
        mappedCol.filter![key] = field;
      }
    });
  }
  if (mappedCol.filter && mappedCol.filter.type === "date") {
    if (mappedCol.filter.date1) {
      mappedCol.filter.date1 = new Date(mappedCol.filter.date1);
    }
    if (mappedCol.filter.date2) {
      mappedCol.filter.date2 = new Date(mappedCol.filter.date2);
    }
  }
  // mappedCol.detailsListColumn = defaultCol.detailsListColumn; dusanov
  mappedCol.onRender = defaultCol?.onRender;

  mappedCol.excel = defaultCol?.excel;
  return mappedCol;
};
