import { DirectionalHint, TooltipHost } from "@fluentui/react";
import { Input } from "@fluentui/react-components";
import {
  Cell,
  CellTemplate,
  Compatible,
  Uncertain,
  UncertainCompatible,
  getCellProperty,
  getCharFromKeyCode,
  isAlphaNumericKey,
  isNavigationKey,
  keyCodes,
} from "@silevis/reactgrid";
import * as React from "react";
import { getShortInsurerName, gosLinkTypeToPath } from "utils/utils";

function trimStringWithEllipsis(input: string, maxLength: number = 20): string {
  // Check if the string needs to be trimmed
  if (input.length > maxLength) {
    // Trim the string to the maximum length minus 3 to account for the ellipsis
    return input.substring(0, maxLength - 3) + "...";
  } else {
    // Return the original string if it's short enough
    return input;
  }
}

export interface InsurerInfoCell extends Cell {
  type: "insurerinfo";
  text: string;
  value: any[];
  editable?: boolean;
  selected?: boolean;
  highlighted?: boolean;
  placeholder?: string;
  validator?: (text: string) => boolean;
  renderer?: (text: string) => React.ReactNode;
  errorMessage?: string;
}
export class InsurerInfoCellTemplate implements CellTemplate<InsurerInfoCell> {
  getCompatibleCell(
    uncertainCell: Uncertain<InsurerInfoCell>
  ): Compatible<InsurerInfoCell> {
    const text = getCellProperty(uncertainCell, "text", "string");
    const value = getCellProperty(uncertainCell, "value", "object");
    let placeholder: string | undefined;
    try {
      placeholder = getCellProperty(uncertainCell, "placeholder", "string");
    } catch {
      placeholder = "";
    }
    let editable: boolean | undefined;
    try {
      editable = getCellProperty(uncertainCell, "editable", "boolean");
    } catch {
      editable = true;
    }
    let selected = false;
    try {
      selected = getCellProperty(uncertainCell, "selected", "boolean");
    } catch {
      selected = false;
    }
    let highlighted: boolean | undefined;
    try {
      highlighted = getCellProperty(uncertainCell, "highlighted", "boolean");
    } catch {
      highlighted = false;
    }
    return { ...uncertainCell, text, value, selected, editable, placeholder };
  }
  update(
    cell: Compatible<InsurerInfoCell>,
    cellToMerge: UncertainCompatible<InsurerInfoCell>
  ): Compatible<InsurerInfoCell> {
    return this.getCompatibleCell({
      ...cell,
      text: cellToMerge.text,
      value: cellToMerge.value,
      placeholder: cellToMerge.placeholder,
      editable: cellToMerge.editable,
    });
  }
  handleKeyDown(
    cell: Compatible<InsurerInfoCell>,
    keyCode: number,
    ctrl: boolean,
    shift: boolean,
    alt: boolean
  ): { cell: Compatible<InsurerInfoCell>; enableEditMode: boolean } {
    const char = getCharFromKeyCode(keyCode, shift);
    if (
      !ctrl &&
      !alt &&
      isAlphaNumericKey(keyCode) &&
      !(shift && keyCode === keyCodes.SPACE) &&
      cell.editable
    )
      return {
        cell: this.getCompatibleCell({
          ...cell,
          text: shift ? char : char.toLowerCase(),
        }),
        enableEditMode: true,
      };
    return {
      cell,
      enableEditMode:
        keyCode === keyCodes.POINTER || keyCode === keyCodes.ENTER,
    };
  }

  getClassName(
    cell: Compatible<InsurerInfoCell>,
    isInEditMode: boolean
  ): string {
    const isValid = cell.validator ? cell.validator(cell.text) : true;
    const className =
      (cell.className ? cell.className : "") +
      (cell.selected ? " rg-selected" : "") +
      (cell.highlighted ? " highlighted-selected-row" : "");
    return `${isValid ? "valid" : "rg-invalid"} ${
      cell.placeholder && cell.text === "" ? "placeholder" : ""
    } ${className}`;
  }

  //

  render(
    cell: Compatible<InsurerInfoCell>,
    isInEditMode: boolean,
    onCellChanged: (cell: Compatible<InsurerInfoCell>, commit: boolean) => void
  ): React.ReactNode {
    if (!isInEditMode) {
      const isValid = cell.validator ? cell.validator(cell.text) : true;
      let insurerName = cell.value[0].name;
      const shortName = getShortInsurerName(cell.value[0].id + "");
      if (shortName !== "") {
        insurerName = shortName!;
      }

      const cellText =
        cell.value.length === 1 ? (
          <TooltipHost
            content={insurerName + " (" + cell.value[0].internalNumber + ")"}
            directionalHint={DirectionalHint.topAutoEdge}
            hidden={false}
          >
            <span
              className="gos-link"
              onClick={(e) => {
                window.open(
                  gosLinkTypeToPath("client", cell.value[0].id),
                  "_blank"
                );
              }}
            >
              {insurerName + " (" + cell.value[0].internalNumber + ")"}
            </span>
          </TooltipHost>
        ) : (
          <TooltipHost
            content={cell.value
              .map((el) => {
                let insurerName = el.name;
                const shortName = getShortInsurerName(el.id + "");
                if (shortName !== "") {
                  insurerName = shortName!;
                }

                return (
                  trimStringWithEllipsis(insurerName, 13) +
                  " (" +
                  el.internalNumber +
                  ")"
                );
              })
              .join("; ")}
            directionalHint={DirectionalHint.topAutoEdge}
            hidden={false}
          >
            {cell.value.map((el) => {
              let insurerName = el.name;
              const shortName = getShortInsurerName(el.id + "");
              if (shortName !== "") {
                insurerName = shortName!;
              }

              return (
                <>
                  <span
                    className="gos-link"
                    onClick={(e) => {
                      window.open(gosLinkTypeToPath("client", el.id), "_blank");
                    }}
                  >
                    {trimStringWithEllipsis(insurerName, 13) +
                      " (" +
                      el.internalNumber +
                      ")"}
                  </span>
                  {"; "}
                </>
              );
            })}
          </TooltipHost>
        );

      const textToDisplay =
        !isValid && cell.errorMessage ? cell.errorMessage : cellText;
      return (
        <span
          style={
            {
              // padding: isInEditMode ? "0px 2px" : "0 0px",
              // opacity: cell.editable ? 1 : 0.5,
            }
          }
        >
          {cell.renderer
            ? cell.renderer(textToDisplay === null ? "" : textToDisplay + "")
            : textToDisplay === null
            ? ""
            : textToDisplay}
        </span>
      );
    }
    const textToDisplay =
      cell.value.length === 1
        ? cell.value[0].name + " (" + cell.value[0].internalNumber + ")"
        : cell.value
            .map((el) => el.name + " (" + el.internalNumber + ")")
            .join(", ");
    return (
      <Input
        style={{ width: "100%", fontSize: "1em" }}
        size="small"
        //disabled={}
        ref={(input) => {
          if (input) {
            input.focus();
            input.setSelectionRange(input.value.length, input.value.length);
            input.classList.add("cell-input");
          }
        }}
        defaultValue={textToDisplay}
        onChange={(e) => {
          if (cell.editable) {
            onCellChanged(
              this.getCompatibleCell({ ...cell, text: e.currentTarget.value }),
              false
            );
          }
        }}
        onBlur={(e) => {
          if (cell.editable) {
            onCellChanged(
              this.getCompatibleCell({ ...cell, text: e.currentTarget.value }),
              (e as any).view?.event?.keyCode !== keyCodes.ESCAPE
            );
          }
        }}
        onCopy={(e) => e.stopPropagation()}
        onCut={(e) => e.stopPropagation()}
        onPaste={(e) => e.stopPropagation()}
        onPointerDown={(e) => e.stopPropagation()}
        placeholder={cell.placeholder}
        onKeyDown={(e) => {
          if (isAlphaNumericKey(e.keyCode) || isNavigationKey(e.keyCode))
            e.stopPropagation();
        }}
      />
    );
  }
}
