import { TextCell } from "@silevis/reactgrid";
import { ICellTypeHandler } from "components/grecoreactgrid/ICellTypeHandler";
import { SumHeaderCellTemplate } from "../customcells/SumHeaderCellTemplate";
import { formatNumber } from "utils/number";

class SumHeaderCellTypeHandler implements ICellTypeHandler {
  cellType = "sumheader";
  isCustom = true;
  instance = new SumHeaderCellTemplate();
  determineNewValue = (change: any) => (change.newCell as TextCell).text;
  getEntryRow = (
    entry: any,
    columnConfig: any,
    rowsMetadataMap: any,
    keyColumn: string,
    context: {
      [key: string]: any;
    }
  ) => {
    const { t } = context;
    const columnSums = context["columnSums"];
    let amounts = null;

    let align = "right";
    if (
      typeof columnSums[columnConfig.columnId] === "number" ||
      typeof columnSums[columnConfig.columnId] === "string"
    ) {
      amounts = columnSums[columnConfig.columnId];
    }

    if (columnConfig.columnId === "ordinalNumber") {
      align = "center";
      amounts =
        Object.entries(rowsMetadataMap)
          .map(([key, value]) => value)
          .filter((el) => (el as any).visible).length + "";
    }
    if (columnConfig.columnId === "select") {
      align = "center";
      amounts =
        Object.entries(rowsMetadataMap)
          .map(([key, value]) => value)
          .filter((el) => (el as any).selected).length + "";
    }

    if (columnConfig.columnId.toLowerCase().includes("percent")) {
      amounts = null;
    }

    if (columnConfig.columnId === "currentBalance") {
      amounts = null;
    }

    if (columnConfig.summable === false) {
      amounts = null;
    }

    //if (amounts !== null) amounts = Math.round(amounts * 100.0) / 100.0;
    if (amounts && amounts < 0 && amounts > -0.005) amounts = 0;

    // const [storeState] = remindersStore;
    return {
      type: "sumheader", //,
      // rowId: columnConfig.additionalInfo?.rowIdField
      //   ? entry[columnConfig.additionalInfo.rowIdField]
      //   : "",
      align: align,
      amounts:
        amounts !== null
          ? typeof amounts === "number"
            ? formatNumber(amounts, 2, 2)
            : amounts
          : "",
      // edited:
      //   rowsMetadataMap[entry[columnConfig.additionalInfo?.rowIdField]][
      //     "inEditMode"
      //   ],
      // selected:
      //   rowsMetadataMap[entry[columnConfig.additionalInfo?.rowIdField]][
      //     "selected"
      //   ],
      t,
    };
  };
}

export default SumHeaderCellTypeHandler;
