import React from "react";
import { Spinner } from "@fluentui/react-components";

function LoadingOverlay() {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: "rgba(0,0,0,0.5)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 1000,
      }}
    >
      <Spinner size="extra-large" />
    </div>
  );
}

export default LoadingOverlay;
