import {
  Button,
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
} from "@fluentui/react-components";
import { HorizontalSpacer } from "components/styled";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useAppStore } from "store/AppStore";
import { DocumentCheckmarkRegular } from "@fluentui/react-icons";
import { usePaymentPageStore } from "store/PaymentPageStore";
import { usePaymentDeclarationDialogStoreActions } from "store/PaymentDeclarationDialogStore";
const DeclarePaymentsButton = () => {
  const [{ updatedGrecoCompanies, taxonomy }] = useAppStore();

  const companiesInfo = updatedGrecoCompanies?.filter((el) => el.selected);
  const { t } = useTranslation();

  const paymentPageStore = usePaymentPageStore();

  const [
    {
      payments,
      paymentsMetadataMap,
      fromDateFilter,
      toDateFilter,
      searchInsurersSelectedItem,
    },
  ] = paymentPageStore;

  const { setPaymentDeclarationStoreValues } =
    usePaymentDeclarationDialogStoreActions();

  const selectedPaymentsCount = useMemo(() => {
    let count = 0;
    for (const value in paymentsMetadataMap) {
      if (paymentsMetadataMap[value].selected) {
        count++;
      }
    }
    return count;
  }, [paymentsMetadataMap]);

  // if (fromDateFilter === null || toDateFilter === null) {
  //   return null;
  // }
  return (
    <>
      {Object.keys(companiesInfo ?? {}).length > 0 ? (
        Object.keys(companiesInfo ?? {}).length === 1 ? (
          <Button
            title={
              selectedPaymentsCount === 0
                ? t("pct.paymentsPageOperationsWarning.label")
                : t("pct.declarePayments.label")
            }
            disabled={
              !searchInsurersSelectedItem || selectedPaymentsCount === 0
            }
            appearance="primary"
            icon={<DocumentCheckmarkRegular fontSize={24} />}
            onClick={() => {
              setPaymentDeclarationStoreValues({
                declarePaymentsDialog: {
                  isOpen: true,
                  grecoCompanyNumber: companiesInfo[0].companyNumber,
                  paymentIds:
                    Object.keys(paymentsMetadataMap ?? {}).filter(
                      (el) => paymentsMetadataMap[el].selected
                    ).length === Object.keys(paymentsMetadataMap ?? {}).length
                      ? []
                      : Object.keys(paymentsMetadataMap ?? {}).filter(
                          (el) => paymentsMetadataMap[el].selected
                        ),
                },
              });
            }}
          >
            {t("pct.declarePayments.label")}
          </Button>
        ) : (
          <>
            <Menu>
              <MenuTrigger disableButtonEnhancement>
                <Button
                  title={
                    selectedPaymentsCount === 0
                      ? t("pct.paymentsPageOperationsWarning.label")
                      : t("pct.declarePayments.label")
                  }
                  disabled={
                    !searchInsurersSelectedItem || selectedPaymentsCount === 0
                  }
                  appearance="primary"
                  icon={<DocumentCheckmarkRegular fontSize={24} />}
                >
                  {t("pct.declarePayments.label")}
                </Button>
              </MenuTrigger>

              <MenuPopover>
                <MenuList>
                  {/* iterate through companiesInfo keys */}
                  {companiesInfo.map((item) => {
                    return (
                      <MenuItem
                        key={item.companyNumber}
                        onClick={() => {
                          const orderMap = new Map(
                            payments.map((item, index) => [
                              item.paymentId,
                              index,
                            ])
                          );
                          setPaymentDeclarationStoreValues({
                            declarePaymentsDialog: {
                              isOpen: true,
                              grecoCompanyNumber: item.companyNumber,
                              paymentIds:
                                Object.keys(paymentsMetadataMap ?? {}).filter(
                                  (el) => paymentsMetadataMap[el].selected
                                ).length ===
                                Object.keys(paymentsMetadataMap ?? {}).length
                                  ? []
                                  : Object.keys(
                                      paymentsMetadataMap ?? {}
                                    ).filter(
                                      (el) => paymentsMetadataMap[el].selected
                                    ),
                            },
                          });
                        }}
                      >
                        {t(taxonomy?.Company.byId[item.companyNumber].code)}
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </MenuPopover>
            </Menu>

            <HorizontalSpacer width={10} />
          </>
        )
      ) : null}
    </>
  );
};

export default DeclarePaymentsButton;
