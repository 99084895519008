import { ColumnConfig } from "types/bankStatements";

export const bankStatementEntriesColumnConfig: ColumnConfig[] = [
  {
    columnId: "ordinalNumber",
    width: 50,
    reorderable: false,
    resizable: true,
    label: "",
    cellType: "ordinalnumber",
    sortable: false,
  },
  {
    columnId: "select",
    width: 40,
    reorderable: false,
    resizable: false,
    label: "",
    cellType: "select",
    sortable: false,
    additionalInfo: {
      rowIdField: "bankStatementEntryId",
    },
  },

  {
    columnId: "status",
    width: 30,
    reorderable: true,
    resizable: true,
    sortable: true,
    label: "",
    cellType: "bankstatemententrystatus",
  },
  {
    columnId: "lastModified",
    width: 40,
    reorderable: true,
    resizable: false,
    sortable: true,
    label: "",
    cellType: "lastmodified",
  },
  {
    columnId: "bookingDate",
    width: 120,
    reorderable: true,
    resizable: true,
    sortable: true,
    label: "pct.paymentDate.label",
    cellType: "datepicker",
  },
  // {
  //   columnId: "valueDate",
  //   width: 120,
  //   reorderable: true,
  //   resizable: true,
  //   sortable: false,
  //   label: "pct.valueDate.label",
  //   cellType: "datepicker",
  // },
  {
    columnId: "amount",
    width: 120,
    reorderable: true,
    resizable: true,
    sortable: true,
    label: "pct.amount.label",
    cellType: "customnumber",
  },

  {
    columnId: "clientInfo",
    width: 300,
    reorderable: true,
    resizable: true,
    sortable: true,
    label: "pct.resolvedClient.label",
    cellType: "client",
    additionalInfo: {
      clientCodeField: "internalNumber",
      clientIdField: "id",
      clientNameField: "name",
      gosLink: {
        idField: "clientId",
        linkType: "client",
      },
    },
  },
  {
    columnId: "paymentReferenceNumber",
    width: 400,
    reorderable: true,
    resizable: true,
    sortable: true,
    label: "pct.paymentReferenceNumber.label",

    cellType: "customtext",
  },
  {
    columnId: "clientAccountId",
    width: 220,
    reorderable: true,
    resizable: true,
    sortable: true,
    label: "pct.clientAccountId.label",

    cellType: "customtext",
  },
  {
    columnId: "clientName",
    width: 250,
    reorderable: true,
    resizable: true,
    sortable: true,
    label: "pct.clientName.label",

    cellType: "customtext",
  },
  {
    columnId: "clientCode",
    width: 120,
    reorderable: true,
    resizable: true,
    sortable: true,
    label: "pct.clientCode.label",

    cellType: "customtext",
  },

  {
    columnId: "bankStatementEntryComment",
    width: 260,
    reorderable: true,
    resizable: true,
    sortable: true,
    label: "pct.bankStatementEntryComment.label",
    cellType: "customtext",
  },
  // {
  //   columnId: "isCleared",
  //   width: 80,
  //   reorderable: true,
  //   resizable: true,
  //   label: "pct.isCleared.label",
  //   cellType: "checkbox",
  // },
  // {
  //   columnId: "isDeleted",
  //   width: 80,
  //   reorderable: true,
  //   resizable: true,
  //   label: "pct.isDeleted.label",
  //   cellType: "checkbox",
  // },
  {
    columnId: "options",
    width: 80,
    reorderable: true,
    resizable: true,
    sortable: false,
    label: "pct.options.label",
    cellType: "bankstatemententryoptions",
    additionalInfo: {
      rowIdField: "bankStatementEntryId",
    },
  },
];
