import { CheckboxCell, CheckboxCellTemplate } from "@silevis/reactgrid";
import { ICellTypeHandler } from "components/grecoreactgrid/ICellTypeHandler";
import { SelectCellTemplate } from "../customcells/SelectCellTemplate";
import { toast } from "components/FluentToast";

class SelectCellTypeHandler implements ICellTypeHandler {
  cellType = "select";
  isCustom = true;
  instance = new SelectCellTemplate();
  determineNewValue = (change: any) => {
    return (change.newCell as CheckboxCell).checked;
  };
  getEntryRow = (
    entry: any,
    columnConfig: any,
    rowsMetadataMap: any,
    keyColumn: string,
    context: {
      [key: string]: any;
    }
  ) => {
    const [
      { bankStatementMetadataMap, bankStatementEntryMetadataMap },
      { setBankStatementsStoreValues },
    ] = context["bankStatementsStore"];
    const { t } = context;

    return {
      type: "select",
      selected: rowsMetadataMap[entry[keyColumn]]?.selected ?? false,
      highlighted: rowsMetadataMap[entry[keyColumn]]?.highlighted ?? false,

      checked:
        rowsMetadataMap[entry[columnConfig.additionalInfo.rowIdField]].selected,
      onChecked: (checked: boolean) => {
        if (
          [
            ...new Map(Object.entries(bankStatementEntryMetadataMap)).values(),
          ].reduce((acc: boolean, curr: any) => acc || curr.inEditMode, false)
        ) {
          toast.error(
            t(
              "pct.please_save_or_cancel_your_changes_before_selecting_a_row.label"
            )
          );
        } else {
          setBankStatementsStoreValues({
            bankStatementMetadataMap: {
              ...bankStatementMetadataMap,
              [entry[columnConfig.additionalInfo.rowIdField]]: {
                ...bankStatementMetadataMap[
                  entry[columnConfig.additionalInfo.rowIdField]
                ],
                selected: checked,
              },
            },
            bankStatementEntriesSelectAll: false,
          });
        }
      },
    };
  };
}

export default SelectCellTypeHandler;
