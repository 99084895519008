import { DirectionalHint, TooltipHost } from "@fluentui/react";
import { Tooltip } from "@fluentui/react-components";
import {
  Cell,
  CellTemplate,
  Compatible,
  Uncertain,
  getCellProperty,
} from "@silevis/reactgrid";
import * as React from "react";

export interface AmountsCell extends Cell {
  type: "amounts";
  link?: string;
  rowId?: any;
  isDisabled?: boolean;
  amounts?: string;
  selected?: boolean;
  highlighted?: boolean;
  t: any;
}

export class AmountsCellTemplate implements CellTemplate<AmountsCell> {
  getCompatibleCell(
    uncertainCell: Uncertain<AmountsCell>
  ): Compatible<AmountsCell> {
    let t: any;
    t = getCellProperty(uncertainCell, "t", "function");

    let rowId: any;

    try {
      rowId = getCellProperty(uncertainCell, "rowId", "number");
    } catch {
      rowId = undefined;
    }

    let isDisabled = true;
    try {
      isDisabled = getCellProperty(uncertainCell, "isDisabled", "boolean");
    } catch {
      isDisabled = false;
    }

    let amounts = null;
    try {
      amounts = getCellProperty(uncertainCell, "amounts", "string");
    } catch {
      amounts = null;
    }
    let selected: boolean | undefined;

    try {
      selected = getCellProperty(uncertainCell, "selected", "boolean");
    } catch {
      selected = true;
    }

    let highlighted: boolean | undefined;
    try {
      highlighted = getCellProperty(uncertainCell, "highlighted", "boolean");
    } catch {
      highlighted = false;
    }
    // let inputValue: string | undefined;
    // try {
    //   inputValue = getCellProperty(uncertainCell, "inputValue", "string");
    // } catch {
    //   inputValue = undefined;
    // }

    const text = "";
    return {
      ...uncertainCell,
      text,
      rowId,
      selected,
      highlighted,
      value: NaN,
      isDisabled,
      amounts,

      t, // isOpen,
      // inputValue,
    };
  }

  getClassName(cell: Compatible<AmountsCell>, isInEditMode: boolean): string {
    return `${
      (cell.className ? cell.className : "") +
      (cell.selected ? " rg-selected" : "") +
      (cell.highlighted ? " highlighted-selected-row" : "")
    }`;
  }

  render(
    cell: Compatible<AmountsCell>,
    isInEditMode: boolean,
    onCellChanged: (cell: Compatible<AmountsCell>, commit: boolean) => void
  ): React.ReactNode {
    return <OptionsCell cell={cell} />;
  }
}

interface DIProps {
  cell: Record<string, any>;
}

const OptionsCell: React.FC<DIProps> = ({ cell }) => {
  return (
    <div style={{ width: "100%", textAlign: "center" }}>
      <Tooltip content={cell.amounts} relationship="label">
        <span>{cell.amounts}</span>
      </Tooltip>
    </div>
  );
};
