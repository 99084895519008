import { Checkbox } from "@fluentui/react-components";
import {
  Cell,
  CellTemplate,
  Compatible,
  Uncertain,
  UncertainCompatible,
  getCellProperty,
  keyCodes,
} from "@silevis/reactgrid";
import * as React from "react";

// NOTE: all modules imported below may be imported from '@silevis/reactgrid'

export interface SelectCell extends Cell {
  type: "select";
  checked: boolean;
  checkedText?: string;
  uncheckedText?: string;
  selected?: boolean;
  highlighted?: boolean;
  onChecked: (checked: boolean) => void;
}

export class SelectCellTemplate implements CellTemplate<SelectCell> {
  getCompatibleCell(
    uncertainCell: Uncertain<SelectCell>
  ): Compatible<SelectCell> {
    const checked = getCellProperty(uncertainCell, "checked", "boolean");
    const text = checked
      ? uncertainCell.checkedText
        ? uncertainCell.checkedText
        : "1"
      : uncertainCell.uncheckedText
      ? uncertainCell.uncheckedText
      : "";
    let selected = false;
    try {
      selected = getCellProperty(uncertainCell, "selected", "boolean");
    } catch {
      selected = false;
    }
    let highlighted: boolean | undefined;
    try {
      highlighted = getCellProperty(uncertainCell, "highlighted", "boolean");
    } catch {
      highlighted = false;
    }
    return {
      ...uncertainCell,
      checked: !!checked,
      selected,
      highlighted,
      value: checked ? 1 : NaN,
      onChecked: uncertainCell.onChecked,
      text,
    };
  }

  handleKeyDown(
    cell: Compatible<SelectCell>,
    keyCode: number,
    ctrl: boolean,
    shift: boolean,
    alt: boolean
  ): { cell: Compatible<SelectCell>; enableEditMode: boolean } {
    if (!shift && (keyCode === keyCodes.SPACE || keyCode === keyCodes.ENTER))
      return {
        cell: this.getCompatibleCell(this.toggleSelectCell(cell)),
        enableEditMode: false,
      };
    return { cell, enableEditMode: false };
  }

  private toggleSelectCell(
    cell: Compatible<SelectCell>
  ): Compatible<SelectCell> {
    return this.getCompatibleCell({ ...cell, checked: !cell.checked });
  }

  update(
    cell: Compatible<SelectCell>,
    cellToMerge: UncertainCompatible<SelectCell>
  ): Compatible<SelectCell> {
    const checked =
      cellToMerge.type === "select" ? cellToMerge.checked : !!cellToMerge.value;
    return this.getCompatibleCell({ ...cell, checked });
  }

  getClassName(cell: Compatible<SelectCell>): string {
    return (
      (cell.className ? cell.className : "") +
      (cell.selected ? " rg-selected" : "") +
      (cell.highlighted ? " highlighted-selected-row" : "")
    );
  }

  render(
    cell: Compatible<SelectCell>,
    isInEditMode: boolean,
    onCellChanged: (cell: Compatible<SelectCell>, commit: boolean) => void
  ): React.ReactNode {
    return (
      <label>
        <Checkbox
          size="medium"
          checked={cell.checked}
          onChange={(e) => cell.onChecked(!cell.checked)}
        />
        <span></span>
      </label>
    );
  }
}
