import GrecoReactGrid, {
  standardCellTypeHandlers,
} from "components/grecoreactgrid/GrecoReactGrid";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppStore, useAppStoreState } from "store/AppStore";
import { useRemindersStore } from "store/RemindersStore";
import { DeleteReminderDialog } from "./DeleteReminderDialog";
import { reminderColumnConfig } from "./reminderColumnConfig";
import TaxonomyCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/TaxonomyCellTypeHandler";
import DatePickerCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/DatePickerCellTypeHandler";
import ReminderOptionsCellTypeHandler from "pages/Reminders/RemindersGrid/customcelltypehandlers/ReminderOptionsCellTypeHandler";
import SortableHeaderCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/SortableHeaderCellTypeHandler";
import DownloadCellTypeHandler from "./customcelltypehandlers/DownloadCellTypeHandler";
import ReminderStatusCellTypeHandler from "./customcelltypehandlers/ReminderStatusCellTypeHandler";
import CustomTextCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/CustomTextCellTypeHandler";
import CustomNumberCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/CustomNumberCellTypeHandler";
import SelectSingleCellTypeHandler from "./customcelltypehandlers/SelectSingleCellTypeHandler";
import LastModifiedCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/LastModifiedCellTypeHandler";
import { useTheme } from "@emotion/react";
import ReminderAmountsStatusCellTypeHandler from "./customcelltypehandlers/ReminderAmountsStatusCellTypeHandler";
import SumHeaderCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/SumHeaderCellTypeHandler";
import CustomCheckmarkCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/CustomCheckmarkCellTypeHandler";
import AmountsCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/AmountsCellTypeHandler";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { ExportExcel } from "./ExportExcel";
import OrdinalNumberCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/OrdinalNumberCellTypeHandler";
import { update } from "lodash";
import InsurerInfoCellTypeHandler from "./customcelltypehandlers/InsurerInfoCellTypeHandler";

const ReminderGrid = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const selectAllState = useState(false);
  const { taxonomy } = useAppStoreState();
  const remindersStore = useRemindersStore();

  const refExcelExport = useRef<ExcelExport>();
  const [{ grecoCompanies, updatedGrecoCompanies }, {}] = useAppStore();
  const [
    {
      searchClientsSelectedItem,
      deleteReminderDialog,
      reminders,
      reminderMetadataMap,
      searchTerm,
      remindersSort,
      fromDateFilter,
      toDateFilter,
      searchPolicyNumbers,
      searchPolicySubNumbers,
    },
    {
      loadReminders,
      setRemindersStoreValues,
      setRemindersStoreValue,
      filterReminders,
    },
  ] = remindersStore;

  useEffect(() => {
    if (updatedGrecoCompanies !== null && grecoCompanies?.length > 0) {
      loadReminders({ reset: true, delay: true, updatedGrecoCompanies });
    }
  }, [
    searchClientsSelectedItem,
    searchPolicyNumbers,
    searchPolicySubNumbers,
    updatedGrecoCompanies,
    fromDateFilter,
    toDateFilter,
    searchTerm,
  ]);

  useEffect(() => {
    if (reminders) {
      filterReminders({ searchTerm });
    }
  }, [searchTerm, reminders]);

  const handleChanges = (
    updatedRows: any[],
    updatedRowsMetadataMap: { [key: number]: any }
  ) => {
    setRemindersStoreValues({
      reminders: reminders.map((entry) => {
        const updatedEntry = updatedRows.find(
          (row) => row.reminderId === entry.reminderId
        );
        if (updatedEntry) {
          return updatedEntry;
        } else {
          return entry;
        }
      }),
      reminderMetadataMap: updatedRowsMetadataMap,
    });
  };

  const cellTypeHandlers = {
    ...standardCellTypeHandlers,
    customtext: new CustomTextCellTypeHandler(),
    customnumber: new CustomNumberCellTypeHandler(),
    customcheckmark: new CustomCheckmarkCellTypeHandler(),
    taxonomy: new TaxonomyCellTypeHandler(),
    download: new DownloadCellTypeHandler(),
    datepicker: new DatePickerCellTypeHandler(),
    reminderoptions: new ReminderOptionsCellTypeHandler(),
    reminderstatus: new ReminderStatusCellTypeHandler(),
    selectsingle: new SelectSingleCellTypeHandler(),
    sortableheader: new SortableHeaderCellTypeHandler(),
    sumheader: new SumHeaderCellTypeHandler(),
    lastmodified: new LastModifiedCellTypeHandler(),
    amounts: new AmountsCellTypeHandler(),
    reminderamountsstatus: new ReminderAmountsStatusCellTypeHandler(),
    ordinalnumber: new OrdinalNumberCellTypeHandler(),
    insurerinfo: new InsurerInfoCellTypeHandler(),
  };

  return (
    <>
      <div style={{ height: "100%", overflow: "auto" }}>
        <div style={{ height: "calc(100% - 10px)", overflow: "scroll" }}>
          <GrecoReactGrid
            rows={reminders}
            columns={reminderColumnConfig}
            idColumn={"reminderId"}
            rowsMetadataMap={reminderMetadataMap}
            handleChanges={handleChanges}
            cellTypeHandlers={cellTypeHandlers}
            keyColumn="reminderId"
            hasSumHeaderRow={false}
            stickyColumns={{
              left: 5,
              right: 2,
              top: 1,
            }}
            context={{
              taxonomy: taxonomy,
              t: t,
              remindersStore: remindersStore,
              selectAllState: selectAllState,
              theme: theme,
              refExcelExport: refExcelExport,
              sort: remindersSort,
              setSort: (sort: any) => {
                setRemindersStoreValue("remindersSort", sort);
              },
            }}
            sort={remindersSort}
          />
        </div>
      </div>
      {deleteReminderDialog.isOpen && <DeleteReminderDialog />}
      <ExportExcel ref={refExcelExport} />
    </>
  );
};

export default ReminderGrid;
