import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import { useAppStoreState } from "store/AppStore";
import {
  useCreateReminderStatus,
  useRemindersCreateDialogStore,
} from "store/RemindersCreateDialogStore";

const SearchSubpolicies = () => {
  const { t } = useTranslation();
  const { clientId } = useParams() as any;
  const [
    { clSubpoliciesSelectedItem },
    { setRemindersCreateDialogStoreValue },
  ] = useRemindersCreateDialogStore();

  const [{ createReminderStatus }] = useCreateReminderStatus();

  const isDisabled = !!clientId || createReminderStatus === "loading";
  const { appState } = useAppStoreState();

  const customStyles = {
    menu: (baseStyles, state) => ({
      ...baseStyles,
      backgroundColor: appState.darkMode ? "#222222" : "white", // Or any desired color
      color: appState.darkMode ? "white" : "#222222", // Or any desired color
    }),
    control: (baseStyles, state) => ({
      ...baseStyles,
      // minHeight: "30px", // reduce the height
      // height: "30px",
      // padding: "0 6px",

      backgroundColor: appState.darkMode ? "#222222" : "white", // Or any desired color
      color: appState.darkMode ? "white" : "white", // Or any desired color
    }),
    valueContainer: (provided) => ({
      ...provided,
      // height: "30px",
      // padding: "0 6px",
      // alignItems: "center", // vertically center the text
    }),
    input: (baseStyles, state) => ({
      ...baseStyles,
      color: appState.darkMode ? "white" : "#222222",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: appState.darkMode ? "white" : "#222222",
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? data.color
          : isFocused
          ? appState.darkMode
            ? "#000000"
            : "#eeeeee"
          : undefined,
        color: isDisabled
          ? "#ccc"
          : isSelected
          ? appState.darkMode
            ? "white"
            : "#222222"
          : appState.darkMode
          ? "white"
          : "#222222",
        cursor: isDisabled ? "not-allowed" : "default",

        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled
            ? isSelected
              ? "yellow"
              : appState.darkMode
              ? "white"
              : "white"
            : undefined,
        },
      };
    },
  };

  return (
    <div>
      <CreatableSelect
        isMulti
        id="subpoliciesSearch"
        styles={customStyles}
        menuPlacement={"auto"}
        placeholder={t("pct.searchSubpolicies.label")}
        noOptionsMessage={() => t("greco.noResults")}
        isClearable
        isDisabled={isDisabled}
        loadingMessage={() => t("greco.searching")}
        value={clSubpoliciesSelectedItem}
        onChange={(value) => {
          setRemindersCreateDialogStoreValue(
            "clSubpoliciesSelectedItem",
            value
          );
        }}
      />
    </div>
  );
};

export default SearchSubpolicies;
