import { DirectionalHint, TooltipHost } from "@fluentui/react";
import { Tooltip } from "@fluentui/react-components";
import {
  Cell,
  CellTemplate,
  Compatible,
  Uncertain,
  getCellProperty,
} from "@silevis/reactgrid";
import * as React from "react";

export interface SumHeaderCell extends Cell {
  type: "sumheader";
  rowId?: any;
  edited?: boolean;
  isDisabled?: boolean;
  amounts?: string;
  align: "left" | "center" | "right";
}

export class SumHeaderCellTemplate implements CellTemplate<SumHeaderCell> {
  getCompatibleCell(
    uncertainCell: Uncertain<SumHeaderCell>
  ): Compatible<SumHeaderCell> {
    let isDisabled = true;
    try {
      isDisabled = getCellProperty(uncertainCell, "isDisabled", "boolean");
    } catch {
      isDisabled = false;
    }

    let amounts = null;
    try {
      amounts = getCellProperty(uncertainCell, "amounts", "string");
    } catch {
      amounts = null;
    }

    let align = null;
    try {
      align = getCellProperty(uncertainCell, "align", "string");
    } catch {
      align = null;
    }

    let edited = false;
    try {
      edited = getCellProperty(uncertainCell, "edited", "boolean");
    } catch {
      edited = false;
    }

    // let inputValue: string | undefined;
    // try {
    //   inputValue = getCellProperty(uncertainCell, "inputValue", "string");
    // } catch {
    //   inputValue = undefined;
    // }

    const text = "";
    return {
      ...uncertainCell,
      text,
      align,
      value: NaN,
      isDisabled,
      amounts,

      edited,
      // inputValue,
    };
  }

  getClassName(cell: Compatible<SumHeaderCell>, isInEditMode: boolean): string {
    return `${cell.className ? cell.className : ""}`;
  }

  render(
    cell: Compatible<SumHeaderCell>,
    isInEditMode: boolean,
    onCellChanged: (cell: Compatible<SumHeaderCell>, commit: boolean) => void
  ): React.ReactNode {
    return <OptionsCell cell={cell} />;
  }
}

interface DIProps {
  cell: Record<string, any>;
}

const OptionsCell: React.FC<DIProps> = ({ cell }) => {
  return (
    <div
      style={{
        width: "100%",
        textAlign:
          (cell.amounts + "").includes("/") || cell.align === "center"
            ? "center"
            : "right",
        fontWeight: "bold",
      }}
    >
      <Tooltip content={cell.amounts} relationship="label">
        <span>{cell.amounts}</span>
      </Tooltip>
    </div>
  );
};
