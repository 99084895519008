import React from "react";
import { Input } from "@fluentui/react-components";

const ClearanceCommentField = ({ comment, setComment }) => {
  const [commentState, setCommentState] = React.useState(comment);

  const handleCommentChange = (newValue) => {
    setComment(newValue);
  };

  return (
    <Input
      value={commentState ?? ""}
      style={{ width: "90%" }}
      placeholder=""
      onChange={(e, data) => setCommentState(data.value)}
      onBlur={(e) => {
        handleCommentChange(commentState);
      }}
      aria-label="inline"
      onCopy={(e) => e.stopPropagation()}
      onCut={(e) => e.stopPropagation()}
      onPaste={(e) => e.stopPropagation()}
    />
  );
};

export default ClearanceCommentField;
