import { ColumnConfig } from "types/reminders";

export const reminderEntriesColumnConfig: ColumnConfig[] = [
  {
    columnId: "ordinalNumber",
    width: 50,
    reorderable: false,
    resizable: true,
    label: "",
    cellType: "ordinalnumber",
    sortable: false,
  },
  {
    columnId: "select",
    width: 40,
    reorderable: false,
    resizable: false,
    label: "",
    cellType: "select",
    sortable: false,
    additionalInfo: {
      rowIdField: "reminderEntryId",
    },
  },
  {
    columnId: "lastModified",
    width: 40,
    reorderable: true,
    resizable: false,
    sortable: true,
    label: "",
    cellType: "lastmodified",
  },
  {
    columnId: "paid_amount_status",
    width: 70,
    reorderable: true,
    resizable: true,
    sortable: true,
    label: "pct.reminderAmountStatus.label",
    cellType: "reminderamountsstatus",
    additionalInfo: {
      rowIdField: "reminderEntryId",
    },
  },
  {
    columnId: "policyName",
    width: 250,
    reorderable: true,
    resizable: true,
    sortable: true,
    label: "pct.policyName.label",
    cellType: "customtext",
    additionalInfo: {
      gosLink: {
        idField: "policyId",
        linkType: "policy",
      },
    },
  },

  {
    columnId: "insurerNameShort",
    width: 250,
    reorderable: true,
    resizable: true,
    sortable: true,
    label: "pct.insurerName.label",
    cellType: "customtext",
    additionalInfo: {
      gosLink: {
        idField: "insurerId",
        linkType: "client",
      },
    },
  },
  {
    columnId: "policyNumber",
    width: 250,
    reorderable: true,
    resizable: true,
    sortable: true,
    label: "pct.policyNumber.label",
    cellType: "customtext",
    additionalInfo: undefined,
  },

  {
    columnId: "policySubNumber",
    width: 150,
    reorderable: true,
    resizable: true,
    sortable: true,
    label: "pct.policySubNumber.label",
    cellType: "customtext",
    additionalInfo: undefined,
  },
  {
    columnId: "insuranceLineCode",
    width: 250,
    reorderable: true,
    resizable: true,
    sortable: true,
    label: "pct.insuranceLineCode.label",
    cellType: "taxonomy",
    additionalInfo: {
      taxonomy: "InsuranceLine",
    },
  },
  {
    columnId: "bookingDate",
    width: 120,
    reorderable: true,
    resizable: true,
    sortable: true,
    label: "pct.bookingDate.label",
    cellType: "datepicker",
    additionalInfo: undefined,
  },
  {
    columnId: "premiumFrom",
    width: 120,
    reorderable: true,
    resizable: true,
    sortable: true,
    label: "pct.premiumFrom.label",
    cellType: "datepicker",
    additionalInfo: undefined,
  },
  {
    columnId: "premiumTo",
    width: 120,
    reorderable: true,
    resizable: true,
    sortable: true,
    label: "pct.premiumTo.label",
    cellType: "datepicker",
    additionalInfo: undefined,
  },

  {
    columnId: "booked_paid_amount",
    width: 150,
    reorderable: true,
    resizable: true,
    sortable: false,
    label: "pct.bookedPaidAndAmount.label",
    cellType: "amounts",
    additionalInfo: {
      rowIdField: "reminderEntryId",
    },
  },

  {
    columnId: "total_paid_amount",
    width: 150,
    reorderable: true,
    resizable: true,
    sortable: false,
    label: "pct.totalPaidAndAmount.label",
    cellType: "amounts",
    additionalInfo: {
      rowIdField: "reminderEntryId",
    },
  },
  {
    columnId: "discount_paid_amount",
    width: 150,
    reorderable: true,
    resizable: true,
    sortable: false,
    label: "pct.discountPaidAndAmount.label",
    cellType: "amounts",
    additionalInfo: {
      rowIdField: "reminderEntryId",
    },
  },
];
