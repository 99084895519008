export interface ColumnConfig {
  columnId: string;
  width: number;
  reorderable: boolean;
  resizable: boolean;
  sortable: boolean;
  label: string;
  cellType: string;
  additionalInfo?: {
    taxonomy?: string;
    list?: string;
    clientAccountIdField?: string;
    clientCodeField?: string;
    clientIdField?: string;
    clientNameField?: string;
    linkField?: string;
    rowIdField?: string;
    gosLink?: {
      idField: string;
      linkType: "policy" | "client";
    };
  };
  excel?: any;
  align?: string;
}

export const clientBalanceColumnConfig: ColumnConfig[] = [
  {
    columnId: "ordinalNumber",
    width: 50,
    reorderable: false,
    resizable: true,
    label: "",
    cellType: "ordinalnumber",
    sortable: false,
  },
  {
    columnId: "select",
    width: 40,
    reorderable: false,
    resizable: false,
    label: "",
    cellType: "select",
    sortable: false,
    additionalInfo: {
      rowIdField: "clientBalanceId",
    },
  },

  {
    columnId: "clientName",
    width: 300,
    reorderable: true,
    resizable: true,
    sortable: true,
    label: "pct.client.label",
    cellType: "customtext",
    additionalInfo: {
      gosLink: {
        idField: "clientId",
        linkType: "client",
      },
    },
  },
  // {
  //   columnId: "clientName",
  //   width: 450,
  //   reorderable: true,
  //   resizable: true,
  //   label: "pct.clientName.label",
  //   sortable: false,
  //   cellType: "customtext",
  //   additionalInfo: undefined,
  // },

  {
    columnId: "companyName",
    width: 450,
    reorderable: true,
    resizable: true,
    label: "pct.company.label",
    sortable: true,
    cellType: "customtext",
    additionalInfo: undefined,
  },

  {
    columnId: "currencyName",
    width: 150,
    reorderable: true,
    resizable: true,
    label: "pct.currency.label",
    sortable: true,
    cellType: "customtext",
    additionalInfo: undefined,
  },
  {
    columnId: "clientBalanceAmount",
    width: 200,
    reorderable: true,
    resizable: true,
    label: "pct.clientSaldoAmount.label",
    sortable: true,
    cellType: "customnumber",
    additionalInfo: undefined,
  },
  {
    columnId: "lastModifiedAt",
    width: 200,
    reorderable: true,
    resizable: true,
    label: "pct.lastModifiedAt.label",
    sortable: true,
    cellType: "datepicker",
    additionalInfo: undefined,
  },
  {
    columnId: "clientBalanceOptions",
    width: 80,
    reorderable: true,
    resizable: true,
    label: "pct.clientBalanceOptions.label",
    sortable: false,
    cellType: "clientbalanceoptions",
    additionalInfo: {
      rowIdField: "clientBalanceId",
    },
  },
];
