import { ExcelExport } from "@progress/kendo-react-excel-export";
import React from "react";
import PaymentPageNavigationFirstLine from "./PaymentPageNavigationFirstLine";
import PaymentPageNavigationSecondLine from "./PaymentPageNavigationSecondLine";

type Props = {
  refExcelExport: ExcelExport;
};

const ListPageNavigation = ({ refExcelExport }: Props) => {
  return (
    <>
      <PaymentPageNavigationFirstLine refExcelExport={refExcelExport} />
      <PaymentPageNavigationSecondLine />
    </>
  );
};

export default ListPageNavigation;
