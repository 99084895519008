import * as React from "react";

// NOTE: all modules imported below may be imported from '@silevis/reactgrid'
import {
  Cell,
  CellStyle,
  CellTemplate,
  Compatible,
  Span,
  Uncertain,
  getCellProperty,
} from "@silevis/reactgrid";
import {
  ArrowSortRegular,
  ArrowSortUpRegular,
  ArrowSortDownRegular,
} from "@fluentui/react-icons";
import { Button, Tooltip } from "@fluentui/react-components";
import { DirectionalHint, TooltipHost } from "@fluentui/react";

export interface SortableHeaderCellAzure extends Cell, Span {
  type: "sortableheaderazure";
  text: string;
  tooltip?: string;
  key?: string;
  sort?: any;
  sortFn?: any;
  sortable: boolean;
  darkMode?: boolean;
}

export class SortableHeaderCellTemplateAzure
  implements CellTemplate<SortableHeaderCellAzure>
{
  getCompatibleCell(
    uncertainCell: Uncertain<SortableHeaderCellAzure>
  ): Compatible<SortableHeaderCellAzure> {
    const text = getCellProperty(uncertainCell, "text", "string");
    const tooltip = getCellProperty(uncertainCell, "tooltip", "string");

    let key: any;
    try {
      key = getCellProperty(uncertainCell, "key", "string");
    } catch {
      key = undefined;
    }

    let sortable: any;
    try {
      sortable = getCellProperty(uncertainCell, "sortable", "boolean");
    } catch {
      sortable = undefined;
    }

    let darkMode: any;
    try {
      darkMode = getCellProperty(uncertainCell, "darkMode", "boolean");
    } catch {
      darkMode = undefined;
    }

    let sort: any;
    try {
      sort = getCellProperty(uncertainCell, "sort", "object");
    } catch {
      sort = undefined;
    }

    let sortFn: any;
    try {
      sortFn = getCellProperty(uncertainCell, "sortFn", "function");
    } catch {
      sortFn = undefined;
    }
    const value = parseFloat(text);
    return {
      ...uncertainCell,
      text,
      tooltip,
      key,
      sort,
      sortFn,
      value,
      darkMode,
      sortable,
    };
  }

  render(
    cell: Compatible<SortableHeaderCellAzure>,
    isInEditMode: boolean,
    onCellChanged: (
      cell: Compatible<SortableHeaderCellAzure>,
      commit: boolean
    ) => void
  ): React.ReactNode {
    let content: any = cell.text;
    let tooltip: string = cell.text;

    if (
      cell.tooltip &&
      cell.tooltip !== "" &&
      !cell.tooltip.includes(".tooltip")
    ) {
      tooltip = cell.tooltip;
    }
    content = (
      <Tooltip content={tooltip} relationship="label">
        <span>{cell.text}</span>
      </Tooltip>
    );

    return (
      <>
        {cell.sortable ? (
          <Button
            appearance="subtle"
            size="small"
            icon={
              cell.sort?.key === cell.key ? (
                cell.sort?.dir === "asc" ? (
                  <ArrowSortDownRegular />
                ) : cell.sort?.dir === "" ? (
                  <ArrowSortRegular />
                ) : (
                  <ArrowSortUpRegular />
                )
              ) : (
                <ArrowSortRegular />
              )
            }
            onClick={(e) => {
              cell.sortFn(
                cell.sort.dir === "" ? "" : cell.key,
                cell.sort.key === cell.key && cell.sort.dir !== ""
                  ? cell.sort.dir === "asc"
                    ? "desc"
                    : ""
                  : "asc"
              );
            }}
          ></Button>
        ) : null}
        {content}
      </>
    );
  }

  isFocusable = (cell: Compatible<SortableHeaderCellAzure>): boolean => false;

  getClassName(
    cell: Compatible<SortableHeaderCellAzure>,
    isInEditMode: boolean
  ): string {
    return cell.className ? cell.className : "";
  }

  getStyle = (cell: Compatible<SortableHeaderCellAzure>): CellStyle => ({
    background: cell.darkMode
      ? "rgba(50, 50, 50, 0.1)"
      : "rgba(128, 128, 128, 0.1)",
  });
}
