import {
  Breadcrumb,
  Checkbox,
  DatePicker,
  DefaultButton,
  Icon,
  IconButton,
  Nav,
  Pivot,
  PrimaryButton,
  Slider,
  Spinner,
  TextField,
} from "@fluentui/react";
import { ChoiceGroup } from "office-ui-fabric-react";
import { Link } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import { neutrals } from "../config/colors";
import { financePivotHeight } from "../config/ui";
import { lightenDarkenColor } from "../utils/colors";

export const StyledLink = styled(Link)`
  flex: 1;
  text-align: left;
  min-width: 0;
  color: ${(p) => p.theme.palette.neutralPrimary};
  &:visited {
    color: ${(p) => p.theme.palette.neutralPrimary};
  }
  &:hover {
    text-decoration: underline;
    color: ${(p) => p.theme.palette.themePrimary};
  }
`;

export const StyledDatePicker = styled(DatePicker)<{
  hasError: boolean;
  background?: string;
}>`
  .ms-TextField-fieldGroup {
    border: none;
    &::after {
      display: none;
    }
  }
  .ms-TextField-wrapper {
    border-bottom: 1px solid ${(p) => p.theme.palette.neutralPrimary};
    &:focus-within {
      border-color: ${(p) =>
        p.hasError ? "red" : p.theme.palette.themePrimary};
    }
  }
  .ms-TextField-suffix {
    padding-right: 32px;
    background: transparent;
    .ms-Icon {
      color: ${(p) => p.theme.palette.neutralSecondary};
    }
  }
  .ms-TextField.is-disabled {
    .ms-TextField-wrapper {
      border-bottom: 1px solid ${(p) => p.theme.palette.neutralTertiary};
    }
    .ms-TextField-fieldGroup {
      background-color: ${(p) => p.background ?? undefined};
    }
    input {
      background-color: transparent;
    }
  }
`;

export const ErrorMessage = styled.div<{ pt?: number }>`
  font-size: 12px;
  font-weight: 400;
  color: rgb(164, 38, 44);
  padding-top: ${(p) => (p.pt !== undefined ? `${p.pt}px` : `5px`)};
`;

export const StyledTextField = styled(TextField)<{
  hasError?: boolean;
  background?: string;
}>`
  &.is-active {
    .ms-TextField-fieldGroup {
      border-color: ${(p) =>
        p.hasError ? "red" : p.theme.palette.themePrimary};
    }
  }
  &.is-active.hasError {
    .ms-TextField-fieldGroup {
      border-color: red;
    }
  }
  .ms-TextField-fieldGroup {
    border-bottom: 1px solid ${(p) => p.theme.palette.neutralPrimary};
    border-radius: 0;
  }
  &&& .ms-TextField-suffix {
    border: 0;
  }
  &.is-disabled {
    .ms-TextField-fieldGroup {
      border-bottom: 1px solid ${(p) => p.theme.palette.neutralTertiary};
      border-radius: 0;
    }
    input {
      background: ${(p) => p.theme.palette.white};
    }
  }
  &.noMargin {
    margin-bottom: 0;
  }
  textarea[disabled] {
    background: ${(p) => p.theme.palette.white};
  }
`;

export const CenteredCell = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

// export const LinkItem = styled.div`
//   cursor: pointer !important;
//   text-decoration: underline !important;
// `;

export const StyledCheckbox = styled(Checkbox)<{ background?: string }>`
  & > label > div {
    width: 16px;
    height: 16px;
    margin-top: 2px;
  }
  & > label > span {
    font-size: 14px;
    font-weight: 600;
  }
`;

export const HorizontalChoiceGroup = styled(ChoiceGroup)`
  .ms-ChoiceFieldGroup {
    display: block;
  }
  .ms-ChoiceFieldGroup-flexContainer {
    display: flex;
    flex-wrap: wrap;
  }
  .ms-ChoiceField {
    width: 25%;
    flex-grow: 1;
  }
`;

export const ListContextMenuIconButton = styled(IconButton)`
  &:hover {
    background: transparent;
    border: 1px solid ${(p) => p.theme.palette.themePrimary};
  }
  &.is-expanded {
    background: transparent;
    border: 1px solid ${(p) => p.theme.palette.themePrimary};
  }
`;

export const StyledSlider = styled(Slider)`
  width: 300px;
  .ms-Slider-slideBox:hover {
    .ms-Slider-inactive {
      background-color: #c7e0f4;
    }
  }
  .ms-Slider-inactive {
    background-color: #c8c6c4;
  }
`;

export const Concatenate = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const IndicatorIcon = styled(Icon)`
  color: #a4262c;
`;

export const StyledBreadcrumb = styled(Breadcrumb)`
  margin-top: 0;
  padding: 5px 20px;
  border-bottom: 1px solid #efefef;
  .ms-Breadcrumb-listItem:first-child {
    & > span {
      padding-left: 0;
    }
    .ms-Breadcrumb-item {
      padding-left: 0;
    }
  }
  .ms-Breadcrumb-item {
    font-size: 14px;
  }
  .ms-Breadcrumb-listItem:last-child {
    & > span {
      font-weight: 600;
      padding-right: 0;
    }
  }
`;

export const StatusCellSpinner = styled(Spinner)`
  .ms-Spinner-circle {
    width: 15px;
    height: 15px;
    border-width: 2.5px;
  }
`;
// export const Flex = styled.div<{
//   gap?: number;
//   direction?: "row" | "column";
//   justifyContent?: "center" | "flex-end" | "flex-start";
// }>`
//   display: flex;
//   align-items: center;
//   gap: ${(p) => (p.gap !== undefined ? `${p.gap}px` : undefined)};
//   flex-direction: ${(p) => p.direction};
//   justify-content: ${(p) => p.justifyContent};
// `;

export const StyledSearchBoxTextField = styled(TextField)<{
  hasError?: boolean;
}>`
  width: 212px;

  &.is-active {
    .ms-TextField-fieldGroup {
      border-color: ${(p) =>
        p.hasError ? "red" : p.theme.palette.themePrimary};
    }
  }
  &.is-active.hasError {
    .ms-TextField-fieldGroup {
      border-color: red;
    }
  }
  .ms-TextField-fieldGroup {
    border-bottom: 1px solid ${(p) => p.theme.palette.neutralPrimary};
    border-radius: 0;
  }
  .ms-TextField-prefix {
    background: ${(p) => p.theme.palette.white};
  }

  &&& .ms-TextField-suffix {
    border: 0;
  }
  &.is-disabled {
    input {
      background: ${(p) => p.theme.palette.white};
    }
  }
  &.noMargin {
    margin-bottom: 0;
  }
`;

export const spinAnimation = keyframes`
  from {
    transform: rotateZ(0deg)
  }
  to {
    transform: rotateZ(360deg)
  }
`;

export const StyledPrimaryButton = styled(PrimaryButton)<{
  background?: string;
}>`
  && {
    background-color: ${(p) => p.theme.palette.themePrimary};
    &.ms-Button--hasMenu + .ms-Button {
      background-color: ${(p) => p.theme.palette.themePrimary};
      .ms-Icon {
        color: unset;
      }
      &:hover {
        background-color: #106ebe;
      }
    }
    &:hover {
      background-color: #106ebe;
    }
  }
  // &.isLoading {
  //   i {
  //     animation: ${spinAnimation} 500ms linear infinite;
  //   }
  // }
  &&.is-disabled {
    background-color: ${(p) => p.theme.palette.neutralLighter};
    &.ms-Button--hasMenu + .ms-Button.is-disabled {
      background-color: ${(p) => p.theme.palette.neutralLighter};
      .ms-Icon {
        color: #a19f9d;
      }
    }
  }
  border: 0;
  &:hover {
    border: 0;
  }
`;

export const StyledDefaultButton = styled(DefaultButton)``;

export const NavigationBar = styled.div`
  height: 44px;
  background: ${(p) => p.theme.palette.white};
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 10px;
  border-bottom: 1px solid ${(p) => p.theme.palette.neutralLight};
  position: sticky;
  top: 0;
  z-index: 2;
`;

export const Truncate = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const StyledNav = styled(Nav)`
  button {
    border-top: 0;
    border-left: 0;
    border-radius: 0;
    &::after {
      border-color: ${(p) => p.theme.palette.themePrimary};
    }
    &:hover {
      background: ${neutrals.neutralLighterAlt};
    }
  }
  .ms-Nav-compositeLink {
    &.is-selected {
      button {
        background: ${neutrals.neutralLighter};
      }
      &:hover {
        button {
          background: ${neutrals.neutralLighter};
        }
      }
    }
    &:hover {
      button {
        background: ${neutrals.neutralLighterAlt};
      }
    }
  }
  .ms-Nav-linkText {
    color: ${(p) => p.theme.palette.neutralPrimary};
  }
`;

export const ClearButton = styled(IconButton)`
  color: ${(p) => p.theme.palette.neutralPrimary};
  &:hover {
    color: ${(p) => p.theme.palette.neutralPrimary};
  }
`;

export const NoData = styled.div`
  color: ${neutrals.neutralSecondaryAlt};
  font-size: 12px;
  font-style: italic;
`;

// export const HorizontalSpacer = styled.div<{ width?: number }>`
//   width: ${(p) => (p.width ? `${p.width}px` : "5px")};
// `;

export const PageBackground = styled.div`
  position: relative;
  max-width: 1300px;
  padding: 20px 0 0px 0;
  margin: 0 auto;
`;

export const Page = styled.div`
  position: relative;
  max-width: 1300px;
  margin: 0 auto;
  background: ${(p) => p.theme.palette.white};
  box-shadow: 0px 3.2px 7.2px rgba(0, 0, 0, 0.132),
    0px 0.6px 1.8px rgba(0, 0, 0, 0.108);
  border-radius: 4px;
  border-top: 4px solid #0060a8;
  overflow-y: auto;
`;

export const SearchPage = styled.div`
  position: relative;
  max-width: 301px;
  margin: 0 auto;
  background: ${(p) => p.theme.palette.white};
  box-shadow: 0px 3.2px 7.2px rgba(0, 0, 0, 0.132),
    0px 0.6px 1.8px rgba(0, 0, 0, 0.108);
  border-radius: 4px 0 0 0;
  border-top: 4px solid #0060a8;
  overflow-y: auto;
`;

export const InsideContent = styled.div`
  height: calc((100vh - 94px) - 140px);
  overflow-y: scroll;
  position: relative;
  padding: 15px 41px;
`;

export const StyledPivot = styled(Pivot)`
  width: 100%;
  height: ${financePivotHeight}px;
  .ms-Pivot-link {
    &:before {
      left: 0;
      right: 0;
    }
    &.is-selected:before {
      background-color: ${(p) => p.theme.palette.themePrimary};
    }
    margin: 0;
    padding: 0 12px;
    &:hover {
      background: ${neutrals.neutralLighter};
    }
  }
`;
// export const FlexRight = styled.div`
//   margin-left: auto;
// `;

export const StatusCircle = styled(Icon).attrs({
  iconName: "CircleFill",
})`
  font-size: 8px;
`;

export const StyledIconButton = styled(IconButton)<{
  background?: string;
  color?: string;
}>`
  background-color: ${(p) => p.background ?? undefined};
  &:hover {
    background: ${(p) => {
      if (p.background) {
        return lightenDarkenColor(p.background, 15);
      } else {
        return undefined;
      }
    }};
    border: 1px solid ${(p) => p.background ?? undefined};
  }
`;

export const Flex = styled.div<{
  gap?: number;
  direction?: "row" | "column";
  justifyContent?: "center" | "flex-end" | "flex-start";
}>`
  display: flex;
  align-items: center;
  gap: ${(p) => (p.gap !== undefined ? `${p.gap}px` : undefined)};
  flex-direction: ${(p) => p.direction};
  justify-content: ${(p) => p.justifyContent};
  flex-wrap: wrap;
`;

export const FlexRight = styled.div`
  margin-left: auto;
`;

export const HorizontalSpacer = styled.div<{ width?: number }>`
  width: ${(p) => (p.width ? `${p.width}px` : "5px")};
`;
