import {
  CheckboxCell,
  DateCell,
  DefaultCellTypes,
  DropdownCell,
  NumberCell,
  Row,
  TextCell,
} from "@silevis/reactgrid";
import { ClearedAmountCell } from "pages/BankStatements/BankStatementEntriesGrid/ClearBankStatementEntryDialog/customcells/ClearedAmountCellTemplate";
import { ClientCell } from "components/grecoreactgrid/customcells/ClientCellTemplate";
import { DatePickerCell } from "components/grecoreactgrid/customcells/DatePickerCellTemplate";
import { SelectAllCell } from "pages/BankStatements/BankStatementsGrid/customcells/SelectAllCellTemplate";
import { SortableHeaderCellAzure } from "components/grecoreactgrid/customcells/SortableHeaderCellTemplateAzure";
import { TaxonomyCell } from "components/grecoreactgrid/customcells/TaxonomyCellTemplate";
import moment from "moment";
import { DownloadCell } from "pages/BankStatements/BankStatementsGrid/customcells/DownloadCellTemplate";
import { CustomTextCell } from "components/grecoreactgrid/customcells/CustomTextCellTemplate";
import { CustomNumberCell } from "components/grecoreactgrid/customcells/CustomNumberCellTemplate";
import { SumHeaderCell } from "components/grecoreactgrid/customcells/SumHeaderCellTemplate";
import { last, sum } from "lodash";
import { formatNumber } from "utils/number";
import { SelectCell } from "pages/PaymentPlan/customcells/SelectCellTemplate";
import { CustomCheckmarkCell } from "components/grecoreactgrid/customcells/CustomCheckmarkCellTemplate";
import { gosLinkTypeToPath } from "utils/utils";

export interface ColumnConfig {
  columnId: string;
  width: number;
  reorderable: boolean;
  resizable: boolean;
  sortable?: boolean;
  label: string;
  cellType: string;
  additionalInfo?: {
    taxonomy?: string;
    list?: string;
    clientAccountIdField?: string;
    clientCodeField?: string;
    clientIdField?: string;
    clientNameField?: string;
    linkField?: string;
    rowIdField?: string;
    gosLink: {
      idField: string;
      linkType: "policy" | "client";
    };
  };
  cellOptions?: any;
}
type AdditinalCellTypes =
  | TaxonomyCell
  | DownloadCell
  | DatePickerCell
  | SelectAllCell
  | ClientCell
  | ClearedAmountCell
  | CustomTextCell
  | CustomNumberCell
  | SortableHeaderCellAzure
  | CustomCheckmarkCell
  | SelectCell
  | SumHeaderCell;

type AllCellTypes = DefaultCellTypes | AdditinalCellTypes;

export const headerRow = (
  hasSelect: boolean,
  columnConfigs: ColumnConfig[],
  t: any,
  selectAllValues: { [key: string]: boolean },
  paymentPlanPageStore
): Row<AllCellTypes> => {
  return {
    rowId: "sortableheaderazure",
    cells: columnConfigs.map((columnConfig, index) => {
      const [{ sort }, { setPaymentPlanStoreValue }] = paymentPlanPageStore;
      return {
        type: "sortableheaderazure",
        text: t(columnConfig.label),
        tooltip: t(columnConfig.label.replace(".label", ".tooltip")),
        selectable: false,
        sortable: columnConfig.sortable,
        key: columnConfig.columnId,
        sortFn: (key, dir) => {
          setPaymentPlanStoreValue("sort", {
            key: dir === "" ? "" : columnConfig.columnId,
            dir: dir,
          });
        },
        sort: sort,
      };
    }),
  };
};

export const sumHeaderRow = (
  hasSelect: boolean,
  columnConfigs: ColumnConfig[],
  t: any,
  selectAllValues: { [key: string]: boolean },
  paymentPlanPageStore,
  columnSums
): Row<AllCellTypes> => {
  return {
    rowId: "sumheader",
    cells: columnConfigs.map((columnConfig, index) => {
      const [{ sort }, { setPaymentPlanStoreValue }] = paymentPlanPageStore;
      let amounts = null;
      if (typeof columnSums[columnConfig.columnId] === "number") {
        amounts = columnSums[columnConfig.columnId];
      }

      return {
        type: "sumheader",
        align: "right",
        text: t(columnConfig.label),
        amounts:
          !columnConfig.columnId.toLowerCase().includes("percent") &&
          amounts !== null
            ? formatNumber(amounts, 2, 2)
            : "",
        selectable: false,
        sortable: columnConfig.sortable,
        key: columnConfig.columnId,
        sortFn: (key, dir) => {
          setPaymentPlanStoreValue("sort", {
            key: dir === "" ? "" : columnConfig.columnId,
            dir: dir,
          });
        },
        sort: sort,
      };
    }),
  };
};

export const clearanceHeaderRow = (
  hasSelect: boolean,
  columnConfigs: ColumnConfig[],
  t: any,
  selectAllValues: { [key: string]: boolean }
): Row<AllCellTypes> => {
  return {
    rowId: "sortableheaderazure",
    cells: columnConfigs.map((columnConfig, index) => {
      return {
        type: "sortableheaderazure",
        text: t(columnConfig.label),
        selectable: false,
        sortable: false,
      };
    }),
  };
};

export const getPaymentPlanRows = <T>(
  hasSelect: boolean,
  columnConfigs: ColumnConfig[],
  entries: T[],
  selectAllValues: { [key: string]: boolean },
  paymentPlanPageStore,
  t: any,
  taxonomy: any,
  columnSums: any,
  rowSelectionMap: any,
  setRowSelectionMap: any
): Row<AllCellTypes>[] => [
  sumHeaderRow(
    hasSelect,
    columnConfigs,
    t,
    selectAllValues,
    paymentPlanPageStore,
    columnSums
  ),
  headerRow(hasSelect, columnConfigs, t, selectAllValues, paymentPlanPageStore),
  ...entries.map<Row<AllCellTypes>>((entry, index) => {
    return {
      rowId: index,
      cells: [
        ...columnConfigs
          .map<AllCellTypes>((columnConfig) => {
            let gosLink = null;
            if (
              columnConfig.additionalInfo &&
              columnConfig.additionalInfo.gosLink
            ) {
              const linkType = columnConfig.additionalInfo.gosLink.linkType;
              const idField = columnConfig.additionalInfo.gosLink.idField;
              gosLink = gosLinkTypeToPath(
                linkType,
                entry[idField],
                entry[columnConfig.columnId].startsWith(" ")
                  ? "private"
                  : "corporate"
              );
            }

            switch (columnConfig.cellType) {
              case "customtext":
                return {
                  type: "customtext", //,
                  text: entry[columnConfig.columnId]
                    ? entry[columnConfig.columnId] + ""
                    : "",
                  editable: false,
                  gosLink: gosLink,
                } as AllCellTypes;
              case "customnumber":
                return {
                  type: "customnumber",
                  value: entry[columnConfig.columnId],
                  editable: false,
                  redColor: false,
                } as AllCellTypes;

              case "date":
                return {
                  type: "date",
                  date: moment(entry[columnConfig.columnId]).toDate(),
                  format: new Intl.DateTimeFormat("de-DE"),
                } as AllCellTypes;
              case "datepicker":
                return {
                  type: "datepicker",
                  selectedValue: entry[columnConfig.columnId]
                    ? moment(entry[columnConfig.columnId]).toDate()
                    : null,
                  format: "DD.MM.YYYY",
                  editable: false,
                  t,
                } as AllCellTypes;

              case "customcheckmark":
                let tooltip = null;
                if (
                  columnConfig.columnId === "hasPayments" &&
                  entry["hasPayments"] === true
                ) {
                  tooltip = t("pct.lastPaymentOn.tooltip", [
                    moment(entry["lastPaymentDate"]).format("DD.MM.YYYY"),
                  ]);
                }
                if (
                  columnConfig.columnId === "isPaid" &&
                  entry["isPaid"] === true
                ) {
                  tooltip = t("pct.lastPaymentOn.tooltip", [
                    moment(entry["lastPaymentDate"]).format("DD.MM.YYYY"),
                  ]);
                }
                if (
                  columnConfig.columnId === "hasDiscountPayments" &&
                  entry["hasDiscountPayments"] === true
                ) {
                  tooltip = t("pct.lastPaymentOn.tooltip", [
                    moment(entry["lastDiscountPaymentDate"]).format(
                      "DD.MM.YYYY"
                    ),
                  ]);
                }
                if (
                  columnConfig.columnId === "isDiscountPaid" &&
                  entry["isDiscountPaid"] === true
                ) {
                  tooltip = t("pct.lastPaymentOn.tooltip", [
                    moment(entry["lastDiscountPaymentDate"]).format(
                      "DD.MM.YYYY"
                    ),
                  ]);
                }
                return {
                  type: "customcheckmark",
                  checked: entry[columnConfig.columnId],
                  checkedText: entry[columnConfig.columnId] + "",
                  tooltip: tooltip,
                  editable: false,
                  onChecked: () => {},
                } as AllCellTypes;
              case "select":
                const rowId = entry["paymentPlanEntryId"] + "";

                return {
                  type: "select",
                  checked:
                    rowSelectionMap.get(rowId) &&
                    rowSelectionMap.get(rowId) === true
                      ? true
                      : false,
                  onChecked: (checked: boolean) => {
                    setRowSelectionMap((prevMap) => {
                      return new Map(prevMap).set(
                        entry["paymentPlanEntryId"],
                        checked
                      );
                    });
                  },
                } as AllCellTypes;
              case "dropdown":
                return {
                  type: "dropdown",
                  selectedValue:
                    taxonomy[columnConfig.additionalInfo.taxonomy].byId[
                      Number(entry[columnConfig.columnId])
                    ] &&
                    taxonomy[columnConfig.additionalInfo.taxonomy].byId[
                      Number(entry[columnConfig.columnId])
                    ].id + "",
                  values: taxonomy[
                    columnConfig.additionalInfo.taxonomy
                  ].items.map((el) => {
                    return { label: t(el.code), value: el.id + "" };
                  }),
                  inputValue: t(
                    taxonomy[columnConfig.additionalInfo.taxonomy].byId[
                      Number(entry[columnConfig.columnId])
                    ] &&
                      taxonomy[columnConfig.additionalInfo.taxonomy].byId[
                        Number(entry[columnConfig.columnId])
                      ].code
                  ),
                  isDisabled: false,
                };
              case "taxonomy":
                return {
                  type: "taxonomy",
                  selectedValue:
                    taxonomy[columnConfig.cellOptions.additionalInfo.taxonomy]
                      .byId[Number(entry[columnConfig.columnId])] &&
                    taxonomy[columnConfig.cellOptions.additionalInfo.taxonomy]
                      .byId[Number(entry[columnConfig.columnId])].id + "",
                  options: taxonomy[
                    columnConfig.cellOptions.additionalInfo.taxonomy
                  ].items.map((el) => {
                    return { label: t(el.code), value: el.id + "" };
                  }),
                  editable: false,
                  gosLink: gosLink,

                  isDisabled: false,
                  t,
                };

              default:
                return {
                  type: "text",
                  text: entry[columnConfig.columnId]
                    ? entry[columnConfig.columnId]
                    : "",
                };
            }
          })
          .map((el) => {
            return {
              ...el,
              selected:
                rowSelectionMap.get(entry["paymentPlanEntryId"] + "") === true,
            };
          }),
      ],
    };
  }),
];

export const getClearanceRows = <T>(
  hasSelect: boolean,
  columnConfigs: ColumnConfig[],
  entries: T[],
  selectAllValues: { [key: string]: boolean },
  paymentPlanPageStore,
  t: any,
  taxonomy: any
): Row<AllCellTypes>[] => [
  clearanceHeaderRow(hasSelect, columnConfigs, t, selectAllValues),
  ...entries.map<Row<AllCellTypes>>((entry, index) => {
    return {
      rowId: index,
      cells: [
        ...columnConfigs.map<AllCellTypes>((columnConfig) => {
          const [{ clientSaldo, clearanceData, currencyCode }, {}] =
            paymentPlanPageStore;

          switch (columnConfig.cellType) {
            case "text":
              return {
                type: "text", //,
                text: entry[columnConfig.columnId]
                  ? entry[columnConfig.columnId] + ""
                  : "",
                editable: false,
              } as AllCellTypes;
            case "customtext":
              return {
                type: "text", //,
                text: entry[columnConfig.columnId]
                  ? entry[columnConfig.columnId] + ""
                  : "",
                editable: false,
              } as AllCellTypes;
            case "number":
              return {
                type: "number",
                value: entry[columnConfig.columnId],
                editable: false,
              };
            case "customnumber":
              return {
                type: "number",
                value: entry[columnConfig.columnId],
                editable: false,
              } as AllCellTypes;
            case "clearedamount":
              return {
                type: "clearedamount",
                value: entry[columnConfig.columnId],
                premiumDebt: entry["premiumDebt"],
                getClearanceDataTotal: () => {
                  return clearanceData.total;
                },
                totalToBeCleared:
                  clientSaldo?.clientSaldoAmountAndCurrency?.find(
                    (el) => el.currencyCode === currencyCode
                  ).clientSaldoAmount,
                t,
              } as AllCellTypes;
            case "date":
              return {
                type: "date",
                date: moment(entry[columnConfig.columnId]).toDate(),
                format: new Intl.DateTimeFormat("de-DE"),
              } as AllCellTypes;
            case "datepicker":
              return {
                type: "datepicker",
                selectedValue: entry[columnConfig.columnId]
                  ? moment(entry[columnConfig.columnId]).toDate()
                  : null,
                format: "DD.MM.YYYY",
                t,
              } as AllCellTypes;
            case "checkbox":
              return {
                type: "checkbox",
                checked: entry[columnConfig.columnId],
              } as AllCellTypes;

            case "dropdown":
              return {
                type: "dropdown",
                selectedValue:
                  taxonomy[columnConfig.additionalInfo.taxonomy].byId[
                    Number(entry[columnConfig.columnId])
                  ] &&
                  taxonomy[columnConfig.additionalInfo.taxonomy].byId[
                    Number(entry[columnConfig.columnId])
                  ].id + "",
                values: taxonomy[
                  columnConfig.additionalInfo.taxonomy
                ].items.map((el) => {
                  return { label: t(el.code), value: el.id + "" };
                }),
                inputValue: t(
                  taxonomy[columnConfig.additionalInfo.taxonomy].byId[
                    Number(entry[columnConfig.columnId])
                  ] &&
                    taxonomy[columnConfig.additionalInfo.taxonomy].byId[
                      Number(entry[columnConfig.columnId])
                    ].code
                ),
                isDisabled: false,
              };
            case "taxonomy":
              return {
                type: "taxonomy",
                selectedValue:
                  taxonomy[columnConfig.additionalInfo.taxonomy].byId[
                    Number(entry[columnConfig.columnId])
                  ] &&
                  taxonomy[columnConfig.additionalInfo.taxonomy].byId[
                    Number(entry[columnConfig.columnId])
                  ].id + "",
                options: taxonomy[
                  columnConfig.additionalInfo.taxonomy
                ].items.map((el) => {
                  return { label: t(el.code), value: el.id + "" };
                }),

                isDisabled: false,
                t,
              };
            case "client":
              return {
                type: "client",
                text: entry[columnConfig.columnId]
                  ? entry[columnConfig.columnId][
                      columnConfig.additionalInfo.clientNameField
                    ] +
                    " (" +
                    entry[columnConfig.columnId][
                      columnConfig.additionalInfo.clientCodeField
                    ] +
                    ")"
                  : "",
                selectedValue: entry[columnConfig.columnId],
                isDisabled: false,
                t,
              };
            case "download":
              return {
                type: "download", //,
                link: entry[columnConfig.columnId],
              };

            default:
              return {
                type: "text",
                text: entry[columnConfig.columnId]
                  ? entry[columnConfig.columnId]
                  : "",
              };
          }
        }),
      ],
    };
  }),
];

export const determineNewValue = (change: any) => {
  let newValue: any;
  switch (change.newCell.type) {
    case "text":
      newValue = (change.newCell as TextCell).text;
      break;
    case "customtext":
      newValue = (change.newCell as CustomTextCell).text;

      break;
    case "number":
      newValue = (change.newCell as NumberCell).value;
      break;
    case "customnumber":
      newValue = (change.newCell as CustomNumberCell).value;
      break;
    case "clearedamount":
      newValue = (change.newCell as ClearedAmountCell).value;
      break;
    case "checkbox":
      newValue = (change.newCell as CheckboxCell).checked;
      break;
    case "selectall":
      newValue = (change.newCell as SelectAllCell).checked;
      break;
    case "date":
      newValue = (change.newCell as DateCell).date;
      break;
    case "datepicker":
      newValue = (change.newCell as DatePickerCell).selectedValue;
      break;
    case "dropdown":
      newValue = (change.newCell as DropdownCell).inputValue;
      break;
    case "taxonomy":
      newValue = parseFloat((change.newCell as TaxonomyCell).selectedValue);
      break;
    case "client":
      newValue = (change.newCell as ClientCell).selectedValue;
      break;
    case "download":
      newValue = (change.newCell as DownloadCell).link;
      break;
    case "bankstatementoptions":
      newValue = (change.newCell as TextCell).text;
      break;
    case "bankstatemententryoptions":
      newValue = (change.newCell as TextCell).text;
      break;
    default:
      newValue = (change.newCell as TextCell).text;
      break;
  }
  return newValue;
};
