import GrecoReactGrid, {
  standardCellTypeHandlers,
} from "components/grecoreactgrid/GrecoReactGrid";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppStoreState } from "store/AppStore";
import { useBankStatementsStore } from "store/BankStatementsStore";
import { DeleteBankStatementDialog } from "./DeleteBankStatementDialog";
import { ImportBankStatementDialog } from "./ImportBankStatementDialog";
import { bankStatementColumnConfig } from "./bankStatementColumnConfig";
import TaxonomyCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/TaxonomyCellTypeHandler";
import DatePickerCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/DatePickerCellTypeHandler";
import BankStatementOptionsCellTypeHandler from "pages/BankStatements/BankStatementsGrid/customcelltypehandlers/BankStatementOptionsCellTypeHandler";
import SortableHeaderCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/SortableHeaderCellTypeHandler";
import SelectAllCellTypeHandler from "./customcelltypehandlers/SelectAllCellTypeHandler";
import DownloadCellTypeHandler from "./customcelltypehandlers/DownloadCellTypeHandler";
import BankStatementStatusCellTypeHandler from "./customcelltypehandlers/BankStatementStatusCellTypeHandler";
import SelectCellTypeHandler from "./customcelltypehandlers/SelectCellTypeHandler";
import CustomTextCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/CustomTextCellTypeHandler";
import CustomNumberCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/CustomNumberCellTypeHandler";
import { entriesIn, set } from "lodash";
import LastModifiedCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/LastModifiedCellTypeHandler";
import { useTheme } from "@emotion/react";
import SumHeaderCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/SumHeaderCellTypeHandler";
import AmountsCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/AmountsCellTypeHandler";
import GrecoCompanyCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/GrecoCompanyCellTypeHandler";
import BankStatementPaidReceivedCellTypeHandler from "./customcelltypehandlers/BankStatementPaidReceivedCellTypeHandler";
import OrdinalNumberCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/OrdinalNumberCellTypeHandler";

const BankStatementGrid = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const selectAllState = useState(false);
  const { taxonomy } = useAppStoreState();
  const bankStatementsStore = useBankStatementsStore();
  const { grecoCompanies, updatedGrecoCompanies } = useAppStoreState();
  const [
    {
      importBankStatementDialog,
      deleteBankStatementDialog,
      bankStatements,
      bankStatementMetadataMap,
      bankStatementEntryMetadataMap,
      searchTerm,
      fromDateFilter,
      toDateFilter,
      bankStatementsSort,
      bankStatementEntriesSort,
      searchDateFilterField,
    },
    {
      loadBankStatements,
      setBankStatementsStoreValues,
      setBankStatementsStoreValue,
      filterBankStatements,
    },
  ] = bankStatementsStore;

  useEffect(() => {
    if (updatedGrecoCompanies !== null && grecoCompanies?.length > 0) {
      loadBankStatements({
        t,
        grecoCompanies,
        reset: true,
        delay: true,
        updatedGrecoCompanies,
        taxonomy,
      });
    }
  }, [
    fromDateFilter,
    toDateFilter,
    updatedGrecoCompanies,
    taxonomy,
    searchDateFilterField,
  ]);

  useEffect(() => {
    let newBankStatementMetadataMap = { ...bankStatementMetadataMap };
    if (bankStatements) {
      for (let key in bankStatementMetadataMap) {
        newBankStatementMetadataMap[key].selected = false;
      }

      setBankStatementsStoreValue(
        "bankStatementMetadataMap",
        newBankStatementMetadataMap
      );
      filterBankStatements({ searchTerm });
    }
  }, [searchTerm, bankStatements]);

  const handleChanges = (
    updatedRows: any[],
    updatedRowsMetadataMap: { [key: number]: any }
  ) => {
    setBankStatementsStoreValues({
      bankStatements: bankStatements.map((entry) => {
        const updatedEntry = updatedRows.find(
          (row) => row.bankStatementId === entry.bankStatementId
        );
        if (updatedEntry) {
          return updatedEntry;
        } else {
          return entry;
        }
      }),
      bankStatementMetadataMap: updatedRowsMetadataMap,
    });
  };

  const cellTypeHandlers = {
    ...standardCellTypeHandlers,
    customtext: new CustomTextCellTypeHandler(),
    customnumber: new CustomNumberCellTypeHandler(),
    taxonomy: new TaxonomyCellTypeHandler(),
    download: new DownloadCellTypeHandler(),
    selectall: new SelectAllCellTypeHandler(),
    datepicker: new DatePickerCellTypeHandler(),
    bankstatementoptions: new BankStatementOptionsCellTypeHandler(),
    bankstatementstatus: new BankStatementStatusCellTypeHandler(),
    select: new SelectCellTypeHandler(),
    sortableheader: new SortableHeaderCellTypeHandler(),
    sumheader: new SumHeaderCellTypeHandler(),
    lastmodified: new LastModifiedCellTypeHandler(),
    amounts: new AmountsCellTypeHandler(),
    grecocompany: new GrecoCompanyCellTypeHandler(),
    bankstatementpaidreceived: new BankStatementPaidReceivedCellTypeHandler(),
    ordinalnumber: new OrdinalNumberCellTypeHandler(),
  };

  return (
    <>
      <div style={{ height: "100%", overflow: "auto" }}>
        <div style={{ height: "calc(100% - 10px)", overflow: "scroll" }}>
          <GrecoReactGrid
            rows={bankStatements}
            columns={bankStatementColumnConfig}
            idColumn={"bankStatementId"}
            rowsMetadataMap={bankStatementMetadataMap}
            handleChanges={handleChanges}
            cellTypeHandlers={cellTypeHandlers}
            keyColumn="bankStatementId"
            hasSumHeaderRow={false}
            stickyColumns={{
              left: 6,
              right: 1,
              top: 1,
            }}
            context={{
              taxonomy: taxonomy,
              t: t,
              bankStatementsStore: bankStatementsStore,
              selectAllState: selectAllState,
              theme: theme,
              grecoCompanies: grecoCompanies,
              sort: bankStatementsSort,
              setSort: (sort: any) => {
                setBankStatementsStoreValue("bankStatementsSort", sort);
              },
            }}
            sort={bankStatementsSort}
          />
        </div>
      </div>
      {importBankStatementDialog.isOpen && <ImportBankStatementDialog />}
      {deleteBankStatementDialog.isOpen && <DeleteBankStatementDialog />}
    </>
  );
};

export default BankStatementGrid;
