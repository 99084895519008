import React, { useEffect } from "react";
import { Toolbar } from "@fluentui/react-components";
import { useAppStoreState } from "store/AppStore";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { DatePicker, IDatePicker } from "@fluentui/react-datepicker-compat";
import { defaultDatePickerStrings } from "@fluentui/react";
import { usePaymentPageStore } from "store/PaymentPageStore";
import moment from "moment";

import { isInvalidDate } from "utils/utils";
import SearchDateFilterField from "./SearchDateFilterField";

const onFormatDate = (date?: Date): string => {
  return !date
    ? ""
    : date.getDate() + "." + (date.getMonth() + 1) + "." + date.getFullYear();
};

const PaymentsMenubar = (props) => {
  const [
    { paymentsLoadStatus, fromDateFilter, toDateFilter },
    { setPaymentStoreValue },
  ] = usePaymentPageStore();
  const { t } = useTranslation();

  const fromDatePickerRef = React.useRef<IDatePicker>(null);
  const toDatePickerRef = React.useRef<IDatePicker>(null);

  const onParseFromDateFromString = React.useCallback(
    (newValue: string): Date => {
      const previousValue = fromDateFilter || new Date();
      const newValueParts = (newValue || "").trim().split(".");
      const day =
        newValueParts.length > 0
          ? Math.max(1, Math.min(31, parseInt(newValueParts[0], 10)))
          : previousValue.getDate();
      const month =
        newValueParts.length > 1
          ? Math.max(1, Math.min(12, parseInt(newValueParts[1], 10))) - 1
          : previousValue.getMonth();
      let year =
        newValueParts.length > 2
          ? parseInt(newValueParts[2], 10)
          : previousValue.getFullYear();

      return new Date(year, month, day);
    },
    [fromDateFilter]
  );

  const onParseToDateFromString = React.useCallback(
    (newValue: string): Date => {
      const previousValue = toDateFilter || new Date();
      const newValueParts = (newValue || "").trim().split(".");
      const day =
        newValueParts.length > 0
          ? Math.max(1, Math.min(31, parseInt(newValueParts[0], 10)))
          : previousValue.getDate();
      const month =
        newValueParts.length > 1
          ? Math.max(1, Math.min(12, parseInt(newValueParts[1], 10))) - 1
          : previousValue.getMonth();
      let year =
        newValueParts.length > 2
          ? parseInt(newValueParts[2], 10)
          : previousValue.getFullYear();

      return new Date(year, month, day);
    },
    [toDateFilter]
  );

  return (
    <Toolbar
      {...props}
      aria-label="Small"
      size="small"
      style={{
        display: "flex",
        gap: "8px",
      }}
    >
      <DatePicker
        className="narrow-datepicker"
        onError={(e) => {
          return;
        }}
        onErrorCapture={(e) => {
          return;
        }}
        id="fromDatePicker"
        componentRef={fromDatePickerRef}
        allowTextInput
        value={fromDateFilter}
        onSelectDate={(date: Date | undefined) => {
          setPaymentStoreValue("fromDateFilter", date);
        }}
        parseDateFromString={(dateStr) => onParseFromDateFromString(dateStr)}
        strings={defaultDatePickerStrings}
        disableAutoFocus={true}
        formatDate={onFormatDate}
        onBlur={(e) => {
          const inputEl: any =
            e.currentTarget.childNodes[0].childNodes[0].childNodes[0]
              .childNodes[0];
          setTimeout(() => {
            inputEl.blur();
            const val = onParseFromDateFromString(inputEl.value);
            if (
              !isInvalidDate(val) &&
              moment(val.getTime()).format("YYYY-MM-DD") !==
                moment(fromDateFilter?.getTime()).format("YYYY-MM-DD")
            )
              setPaymentStoreValue(
                "fromDateFilter",
                isNaN(val.getTime()) ? null : val
              );
          }, 300);
        }}
        placeholder="Select from date..."
      />

      <DatePicker
        className="narrow-datepicker"
        id="toDatePicker"
        onError={(e) => {
          return;
        }}
        onErrorCapture={(e) => {
          return;
        }}
        componentRef={toDatePickerRef}
        allowTextInput
        value={toDateFilter}
        onSelectDate={(date) => {
          setPaymentStoreValue("toDateFilter", date);
        }}
        parseDateFromString={onParseToDateFromString}
        strings={defaultDatePickerStrings}
        disableAutoFocus={true}
        formatDate={onFormatDate}
        onBlur={(e) => {
          const inputEl: any =
            e.currentTarget.childNodes[0].childNodes[0].childNodes[0]
              .childNodes[0];
          setTimeout(() => {
            inputEl.blur();
            const val = onParseFromDateFromString(inputEl.value);
            if (
              !isInvalidDate(val) &&
              moment(val.getTime()).format("YYYY-MM-DD") !==
                moment(toDateFilter?.getTime()).format("YYYY-MM-DD")
            )
              setPaymentStoreValue(
                "toDateFilter",
                isNaN(val.getTime()) ? null : moment(val).toDate()
              );
          }, 300);
        }}
        placeholder="Select to date..."
      />
      <SearchDateFilterField
        name="searchDateFilterField"
        isLoading={paymentsLoadStatus === "loading"}
        visible={true}
      />
    </Toolbar>
  );
};

export default PaymentsMenubar;
