import { TextCell } from "@silevis/reactgrid";
import { ICellTypeHandler } from "components/grecoreactgrid/ICellTypeHandler";
import { LastModifiedCellTemplate } from "../customcells/LastModifiedCellTemplate";

class LastModifiedCellTypeHandler implements ICellTypeHandler {
  cellType = "lastmodified";
  isCustom = true;
  instance = new LastModifiedCellTemplate();
  determineNewValue = (change: any) => (change.newCell as TextCell).text;
  getEntryRow = (
    entry: any,
    columnConfig: any,
    rowsMetadataMap: any,
    keyColumn: string,
    context: {
      [key: string]: any;
    }
  ) => {
    const { t } = context;
    return {
      type: "lastmodified", //,
      lastModifiedAt: entry["lastModifiedAt"],
      lastModifiedByUserEmail: entry["lastModifiedByUserEmail"],
      lastModifiedByUserId: entry["lastModifiedByUserId"],
      lastModifiedByUserName: entry["lastModifiedByUserName"],
      selected: rowsMetadataMap[entry[keyColumn]]?.selected ?? false,
      highlighted: rowsMetadataMap[entry[keyColumn]]?.highlighted ?? false,

      t,
    };
  };
}

export default LastModifiedCellTypeHandler;
