import { DirectionalHint, TooltipHost } from "@fluentui/react";
import { Avatar, Button, Tooltip } from "@fluentui/react-components";
import { GuestRegular } from "@fluentui/react-icons";
import { HSpace } from "@greco/components/dist/components/utils";
import {
  Cell,
  CellTemplate,
  Compatible,
  Uncertain,
  getCellProperty,
} from "@silevis/reactgrid";
import { HorizontalSpacer } from "components/styled";
import moment from "moment";
import * as React from "react";

export interface LastModifiedCell extends Cell {
  type: "lastmodified";
  link?: string;
  deleteFn?: any;
  saveFn?: any;
  cancelFn?: any;
  resolvementSuggestFn?: any;
  clearFn?: any;
  rowId?: any;
  clientId?: any;
  edited?: boolean;
  isDisabled?: boolean;
  lastModifiedAt: string;
  lastModifiedByUserEmail: string;
  lastModifiedByUserId: string;
  lastModifiedByUserName: string;
  selected?: boolean;
  highlighted?: boolean;
  t: any;
}

export class LastModifiedCellTemplate
  implements CellTemplate<LastModifiedCell>
{
  getCompatibleCell(
    uncertainCell: Uncertain<LastModifiedCell>
  ): Compatible<LastModifiedCell> {
    let t: any;
    t = getCellProperty(uncertainCell, "t", "function");
    let selected: boolean | undefined;
    try {
      selected = getCellProperty(uncertainCell, "selected", "boolean");
    } catch {
      selected = true;
    }
    let highlighted: boolean | undefined;
    try {
      highlighted = getCellProperty(uncertainCell, "highlighted", "boolean");
    } catch {
      highlighted = false;
    }
    let lastModifiedAt = null;
    try {
      lastModifiedAt = getCellProperty(
        uncertainCell,
        "lastModifiedAt",
        "string"
      );
    } catch {
      lastModifiedAt = null;
    }

    let lastModifiedByUserEmail = null;
    try {
      lastModifiedByUserEmail = getCellProperty(
        uncertainCell,
        "lastModifiedByUserEmail",
        "string"
      );
    } catch {
      lastModifiedByUserEmail = null;
    }

    let lastModifiedByUserId = null;
    try {
      lastModifiedByUserId = getCellProperty(
        uncertainCell,
        "lastModifiedByUserId",
        "string"
      );
    } catch {
      lastModifiedByUserId = null;
    }

    let lastModifiedByUserName = null;
    try {
      lastModifiedByUserName = getCellProperty(
        uncertainCell,
        "lastModifiedByUserName",
        "string"
      );
    } catch {
      lastModifiedByUserName = null;
    }

    const text = "";
    return {
      ...uncertainCell,
      text,

      value: NaN,
      lastModifiedAt,
      lastModifiedByUserEmail,
      lastModifiedByUserId,
      lastModifiedByUserName,
      selected,
      highlighted,
      t, // isOpen,
      // inputValue,
    };
  }

  getClassName(
    cell: Compatible<LastModifiedCell>,
    isInEditMode: boolean
  ): string {
    return (
      (cell.className ? cell.className : "") +
      (cell.selected ? " rg-selected" : "") +
      (cell.highlighted ? " highlighted-selected-row" : "")
    );
  }

  render(
    cell: Compatible<LastModifiedCell>,
    isInEditMode: boolean,
    onCellChanged: (cell: Compatible<LastModifiedCell>, commit: boolean) => void
  ): React.ReactNode {
    return <OptionsCell cell={cell} />;
  }
}

interface DIProps {
  cell: Record<string, any>;
}

const OptionsCell: React.FC<DIProps> = ({ cell }) => {
  return (
    <div style={{ width: "100%", textAlign: "center" }}>
      <TooltipHost
        content={cell.t("pct.lastModifiedCellTooltip.label", [
          cell.lastModifiedByUserName ??
            cell.lastModifiedByUserEmail ??
            cell.lastModifiedByUserId ??
            "Unknown",
          moment(cell.lastModifiedAt).format("DD.MM.YYYY HH:mm"),
        ])}
        directionalHint={DirectionalHint.rightCenter}
        hidden={false}
      >
        {cell.lastModifiedByUserName ||
        cell.lastModifiedByUserEmail ||
        cell.lastModifiedByUserId ||
        cell.lastModifiedAt ? (
          <Avatar
            color="colorful"
            size={20}
            icon={<GuestRegular />}
            name={
              cell.lastModifiedByUserName
                ? cell.lastModifiedByUserName.split(" - ")[0]
                : cell.lastModifiedByUserEmail ??
                  cell.lastModifiedByUserId ??
                  "Unknown"
            }
          />
        ) : null}
      </TooltipHost>
    </div>
  );
};
