import { Button, Tooltip } from "@fluentui/react-components";
import {
  InfoRegular,
  DeleteDismissRegular,
  CheckmarkFilled,
  DismissFilled,
  PersonQuestionMarkRegular,
  ArrowSplitFilled,
} from "@fluentui/react-icons";
import { HSpace } from "@greco/components/dist/components/utils";
import {
  Cell,
  CellTemplate,
  Compatible,
  Uncertain,
  getCellProperty,
} from "@silevis/reactgrid";
import { HorizontalSpacer } from "components/styled";
import * as React from "react";

export interface BankStatementEntryOptionsCell extends Cell {
  type: "bankstatemententryoptions";
  link?: string;
  deleteFn?: any;
  saveFn?: any;
  cancelFn?: any;
  resolvementSuggestFn?: any;
  clearFn?: any;
  rowId?: any;
  clientId?: any;
  edited?: boolean;
  isDisabled?: boolean;
  isCleared?: boolean;
  isInsurerPayment?: boolean;
  selected?: boolean;
  highlighted?: boolean;
  t: any;
}

export class BankStatementEntryOptionsCellTemplate
  implements CellTemplate<BankStatementEntryOptionsCell>
{
  getCompatibleCell(
    uncertainCell: Uncertain<BankStatementEntryOptionsCell>
  ): Compatible<BankStatementEntryOptionsCell> {
    let link: string | undefined;
    let t: any;
    t = getCellProperty(uncertainCell, "t", "function");

    try {
      link = getCellProperty(uncertainCell, "link", "string");
    } catch {
      link = undefined;
    }
    let clientId: any;

    try {
      clientId = getCellProperty(uncertainCell, "clientId", "number");
    } catch {
      clientId = undefined;
    }

    let saveFn: any;
    try {
      saveFn = getCellProperty(uncertainCell, "saveFn", "function");
    } catch {
      saveFn = undefined;
    }

    let cancelFn: any;
    try {
      cancelFn = getCellProperty(uncertainCell, "cancelFn", "function");
    } catch {
      cancelFn = undefined;
    }

    let resolvementSuggestFn: any;
    try {
      resolvementSuggestFn = getCellProperty(
        uncertainCell,
        "resolvementSuggestFn",
        "function"
      );
    } catch {
      resolvementSuggestFn = undefined;
    }

    let clearFn: any;
    try {
      clearFn = getCellProperty(uncertainCell, "clearFn", "function");
    } catch {
      clearFn = undefined;
    }

    let deleteFn: any;
    try {
      deleteFn = getCellProperty(uncertainCell, "deleteFn", "function");
    } catch {
      deleteFn = undefined;
    }

    let rowId: any;

    try {
      rowId = getCellProperty(uncertainCell, "rowId", "number");
    } catch {
      rowId = undefined;
    }

    let isDisabled = true;
    try {
      isDisabled = getCellProperty(uncertainCell, "isDisabled", "boolean");
    } catch {
      isDisabled = false;
    }

    let isInsurerPayment = false;
    try {
      isInsurerPayment = getCellProperty(
        uncertainCell,
        "isInsurerPayment",
        "boolean"
      );
    } catch {
      isInsurerPayment = false;
    }

    let isCleared = true;
    try {
      isCleared = getCellProperty(uncertainCell, "isCleared", "boolean");
    } catch {
      isCleared = false;
    }

    let edited = false;
    try {
      edited = getCellProperty(uncertainCell, "edited", "boolean");
    } catch {
      edited = false;
    }

    let selected = false;
    try {
      selected = getCellProperty(uncertainCell, "selected", "boolean");
    } catch {
      selected = false;
    }
    let highlighted: boolean | undefined;
    try {
      highlighted = getCellProperty(uncertainCell, "highlighted", "boolean");
    } catch {
      highlighted = false;
    }

    // let inputValue: string | undefined;
    // try {
    //   inputValue = getCellProperty(uncertainCell, "inputValue", "string");
    // } catch {
    //   inputValue = undefined;
    // }

    const text = link;
    return {
      ...uncertainCell,
      link,
      text,
      rowId,
      clientId,
      deleteFn,
      clearFn,
      cancelFn,
      resolvementSuggestFn,
      saveFn,
      value: NaN,
      isDisabled,
      isCleared,
      isInsurerPayment,
      edited,
      selected,
      highlighted,
      t, // isOpen,
      // inputValue,
    };
  }

  getClassName(
    cell: Compatible<BankStatementEntryOptionsCell>,
    isInEditMode: boolean
  ): string {
    return (
      (cell.className ? cell.className : "") +
      (cell.selected ? " rg-selected" : "") +
      (cell.highlighted ? " highlighted-selected-row" : "")
    );
  }

  render(
    cell: Compatible<BankStatementEntryOptionsCell>,
    isInEditMode: boolean,
    onCellChanged: (
      cell: Compatible<BankStatementEntryOptionsCell>,
      commit: boolean
    ) => void
  ): React.ReactNode {
    return <OptionsCell cell={cell} />;
  }
}

interface DIProps {
  cell: Record<string, any>;
}

const OptionsCell: React.FC<DIProps> = ({ cell }) => {
  if (cell.isCleared) {
    return (
      <div style={{ width: "100%", textAlign: "center" }}>
        {!cell.isInsurerPayment ? (
          <Tooltip
            content={cell.t("pct.infoBankStatementEntryPayments.label")}
            relationship="label"
          >
            <Button
              appearance="subtle"
              size="small"
              icon={<InfoRegular />}
              onClick={(e) => {
                cell.infoFn(cell.rowId);
              }}
            ></Button>
          </Tooltip>
        ) : null}
        {cell.deleteFn ? (
          <Tooltip
            content={cell.t("pct.deleteBankStatementEntry.label")}
            relationship="label"
          >
            <Button
              appearance="subtle"
              size="small"
              icon={<DeleteDismissRegular />}
              onClick={(e) => {
                cell.deleteFn(cell.rowId);
              }}
            ></Button>
          </Tooltip>
        ) : (
          <div
            style={{
              width: "24px",
              height: "24px",
              alignItems: "center",
              boxSizing: "border-box",
              display: "inline-flex",
              justifyContent: "center",
              textDecorationLine: "none",
              verticalAlign: "middle",
              margin: "0px",
              overflow: "hidden",
            }}
          />
        )}
      </div>
    );
  }
  return (
    <div style={{ width: "100%", textAlign: "center" }}>
      {cell.edited ? (
        <>
          {cell.saveFn && cell.edited ? (
            <Tooltip content={cell.t("greco.save")} relationship="label">
              <Button
                appearance="subtle"
                size="small"
                icon={cell.edited ? <CheckmarkFilled /> : <CheckmarkFilled />}
                style={{ color: "green" }}
                onClick={(e) => {
                  cell.saveFn(cell.rowId);
                }}
              ></Button>
            </Tooltip>
          ) : null}
          {cell.cancelFn ? (
            <Tooltip content={cell.t("greco.cancel")} relationship="label">
              <Button
                appearance="subtle"
                size="small"
                icon={cell.edited ? <DismissFilled /> : <DismissFilled />}
                style={{ color: "red" }}
                onClick={(e) => {
                  cell.cancelFn(cell.rowId);
                }}
              ></Button>
            </Tooltip>
          ) : null}
        </>
      ) : null}
      {!cell.edited ? (
        <>
          {cell.clientInfo ? (
            <Tooltip
              content={cell.t("pct.clearEntry.label")}
              relationship="label"
            >
              <Button
                appearance="subtle"
                size="small"
                icon={<ArrowSplitFilled />}
                onClick={(e) => {
                  window.open(cell.link, "_blank");
                }}
              ></Button>
            </Tooltip>
          ) : (
            <>
              {!cell.clientId ? (
                <Tooltip
                  content={cell.t("pct.suggestClientResolvement.label")}
                  relationship="label"
                >
                  <Button
                    appearance="subtle"
                    size="small"
                    icon={<PersonQuestionMarkRegular />}
                    onClick={(e) => {
                      cell.resolvementSuggestFn(cell.rowId);
                    }}
                  ></Button>
                </Tooltip>
              ) : (
                <Tooltip
                  content={cell.t("pct.clearPaymentsToEntries.label")}
                  relationship="label"
                >
                  <Button
                    appearance="subtle"
                    size="small"
                    icon={<ArrowSplitFilled />}
                    onClick={(e) => {
                      cell.clearFn(cell.rowId);
                    }}
                  ></Button>
                </Tooltip>
              )}
            </>
          )}

          {cell.deleteFn ? (
            <Tooltip
              content={cell.t("pct.deleteBankStatementEntry.label")}
              relationship="label"
            >
              <Button
                appearance="subtle"
                size="small"
                icon={<DeleteDismissRegular />}
                onClick={(e) => {
                  cell.deleteFn(cell.rowId);
                }}
              ></Button>
            </Tooltip>
          ) : (
            <div
              style={{
                width: "24px",
                height: "24px",
                alignItems: "center",
                boxSizing: "border-box",
                display: "inline-flex",
                justifyContent: "center",
                textDecorationLine: "none",
                verticalAlign: "middle",
                margin: "0px",
                overflow: "hidden",
              }}
            />
          )}
        </>
      ) : null}
    </div>
  );
};
