import React from "react";
import { useTranslation } from "react-i18next";

import { usePaymentPlanPageStore } from "store/PaymentPlanPageStore";
import { Button } from "@fluentui/react-components";
import { ArrowClockwiseFilled } from "@fluentui/react-icons";
import { useRemindersStore } from "store/RemindersStore";
import { useAppStore } from "store/AppStore";

export const RefreshButton = () => {
  const [{ remindersLoadStatus }, { loadReminders }] = useRemindersStore();
  const [{ updatedGrecoCompanies, taxonomy }] = useAppStore();

  return (
    <Button
      disabled={remindersLoadStatus === "loading"}
      appearance="subtle"
      icon={<ArrowClockwiseFilled fontSize={24} />}
      onClick={() => {
        loadReminders({ reset: true, delay: false, updatedGrecoCompanies });
      }}
    ></Button>
  );
};
