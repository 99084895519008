import {
  ClientCell,
  ClientCellTemplate,
} from "components/grecoreactgrid/customcells/ClientCellTemplate";
import { ICellTypeHandler } from "../ICellTypeHandler";
import { gosLinkTypeToPath } from "utils/utils";

class ClientCellTypeHandler implements ICellTypeHandler {
  cellType = "client";
  isCustom = true;
  instance = new ClientCellTemplate();
  determineNewValue = (change: any) => {
    return (change.newCell as ClientCell).selectedValue;
  };
  getEntryRow = (
    entry: any,
    columnConfig: any,
    rowsMetadataMap: any,
    keyColumn: string,
    context: {
      [key: string]: any;
    }
  ) => {
    const { t } = context;
    let gosLink = null;
    if (columnConfig.additionalInfo && columnConfig.additionalInfo.gosLink) {
      const linkType = columnConfig.additionalInfo.gosLink.linkType;
      let personType: string = "corporate";
      if (linkType === "client") {
        if (entry[columnConfig.columnId]) {
          if (entry["clientInfo"]["isCompany"] === false) {
            personType = "private";
          }
        }
      }
      const idField = columnConfig.additionalInfo.gosLink.idField;
      if (entry[idField]) {
        gosLink = gosLinkTypeToPath(linkType, entry[idField], personType);
      }
    }
    return {
      type: "client",
      text: entry[columnConfig.columnId]
        ? entry[columnConfig.columnId][
            columnConfig.additionalInfo.clientNameField
          ] +
          " (" +
          entry[columnConfig.columnId][
            columnConfig.additionalInfo.clientCodeField
          ] +
          ")"
        : "",
      selectedValue: entry[columnConfig.columnId],
      isGrecoPayment: entry.isGrEcoPayment ? entry.isGrEcoPayment : false,
      isInsurerPayment: entry.isInsurerPayment ? entry.isInsurerPayment : false,
      isDisabled: false,
      selected: rowsMetadataMap[entry[keyColumn]]?.selected ?? false,
      highlighted: rowsMetadataMap[entry[keyColumn]]?.highlighted ?? false,
      gosLink: gosLink,

      editable:
        !entry["isCleared"] ||
        (rowsMetadataMap[entry[keyColumn]]?.editable ?? true),
      t,
    };
  };
}

export default ClientCellTypeHandler;
