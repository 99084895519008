import styled from "@emotion/styled";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import MainToolbar from "components/MainToolbar";
import { Flex, FlexRight, HorizontalSpacer } from "components/styled";
import React from "react";
import { usePaymentPageStore } from "store/PaymentPageStore";
import PaymentsMenubar from "./PaymentsMenubar";
import { ExportExcelButton } from "./navigationbuttons/ExportExcelButton";
import { RefreshButton } from "./navigationbuttons/RefreshButton";
import { Search } from "./navigationbuttons/Search";

import CompaniesMenu from "components/CompaniesMenu";

import DeclarePaymentsButton from "./DeclarePaymentsButton";
import { ExcludeDeclaredPaymentsButton } from "./ExcludeDeclaredPaymentsButton";
export const NavigationBar = styled.div`
  height: 44px;
  background: ${(p) => p.theme.palette.white};
  display: flex;
  align-items: center;
  padding: 0 10px;
  border-bottom: 1px solid ${(p) => p.theme.palette.neutralLight};
  position: sticky;
  top: 0;
  z-index: 2;
`;

type Props = {
  refExcelExport: ExcelExport;
};

const PaymentPageNavigationFirstLine = ({ refExcelExport }: Props) => {
  const [{ searchTerm }, { setPaymentStoreValue }] = usePaymentPageStore();

  return (
    <NavigationBar style={{ zIndex: 334 }}>
      <MainToolbar pageName={"paymentPage"} />
      <RefreshButton />
      <Search
        placeholder="Search"
        value={searchTerm}
        onChange={(value) => setPaymentStoreValue("searchTerm", value)}
      />
      <PaymentsMenubar />
      <ExportExcelButton refExcelExport={refExcelExport} />

      <HorizontalSpacer width={10} />

      <DeclarePaymentsButton />
      <ExcludeDeclaredPaymentsButton />
      <FlexRight>
        <Flex>
          <CompaniesMenu />
        </Flex>
      </FlexRight>
    </NavigationBar>
  );
};

export default PaymentPageNavigationFirstLine;
