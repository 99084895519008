import {
  ByCodeStateByTaxonomy,
  ByIdStateByTaxonomy,
  Notification,
} from "types/types";
import { handleAxiosError, prepareNotificationObject } from "utils/utils";
import * as API from "../api/api";
import { StoreState } from "./AppStore";

export const setAppStoreValue =
  (name: keyof StoreState, value) =>
  ({ setState }) => {
    setState({
      [name]: value,
    });
  };

export const loadGrecoCompanies = async ({ setState, getState, dispatch }) => {
  if (getState().loadGrecoCompanies === "loading") return;
  try {
    setState({
      loadGrecoCompanies: "loading",
    });
    const res = await API.getGrecoCompanies();
    const companies = res.data.map((item) => ({
      ...item,
      selected: true,
    }));
    setState({
      grecoCompanies: companies,
      updatedGrecoCompanies: companies,
      loadGrecoCompanies: "success",
    });
  } catch (err) {
    handleAxiosError(err, (message) => {
      dispatch(
        setNotificationMessage(
          prepareNotificationObject("loadGrecoCompanies", message)
        )
      );
    });
    setState({
      loadGrecoCompanies: "error",
    });
  }
};

export const loadUADTaxonomy = async ({ setState, getState, dispatch }) => {
  if (getState().taxonomyUadLoadStatus === "loading") return;
  try {
    setState({
      taxonomyUadLoadStatus: "loading",
    });
    const res = await API.getUADTaxonomy();
    setState({
      taxonomyUad: res.data,
      taxonomyUadLoadStatus: "success",
    });
  } catch (err) {
    handleAxiosError(err, (message) => {
      dispatch(
        setNotificationMessage(
          prepareNotificationObject("loadUADTaxonomy", message)
        )
      );
    });
    setState({
      taxonomyUadLoadStatus: "error",
    });
  }
};

export const loadTaxonomy = async ({ setState, getState, dispatch }) => {
  if (getState().taxonomyLoadStatus === "loading") return;
  try {
    setState({
      taxonomyLoadStatus: "loading",
    });
    const retVal = {};
    const res = await API.getTaxonomy();
    let taxonomies = res.data;
    taxonomies = {
      ...taxonomies,
      client: [],
      insurer: [],
    };
    Object.entries(taxonomies).forEach(([key, taxonomyGroup]) => {
      const taxonomyStateByTaxonomy = {
        byId: taxonomyGroup.reduce((byId: any, t: any) => {
          byId[t.id] = t;
          return byId;
        }, {} as ByIdStateByTaxonomy),
        byCode: taxonomyGroup.reduce((byCode: any, t: any) => {
          byCode[t.code] = t;
          return byCode;
        }, {} as ByCodeStateByTaxonomy),
        items: taxonomyGroup,
      };
      retVal[key] = taxonomyStateByTaxonomy;
    });
    setState({
      taxonomy: retVal,
      taxonomyResponse: taxonomies,
      taxonomyLoadStatus: "success",
    });
  } catch (err) {
    handleAxiosError(err, (message) => {
      dispatch(
        setNotificationMessage(
          prepareNotificationObject("loadTaxonomy", message)
        )
      );
    });
    setState({
      taxonomyLoadStatus: "error",
    });
  }
};
export const setNotificationMessage =
  (notificationMessage: any) =>
  async ({ setState, getState, dispatch }) => {
    let prev = getState().notifications;

    if (Array.isArray(notificationMessage)) {
      const items = notificationMessage.map((item, index) => {
        return {
          errors: item.errors,
          key: item.key + index,
          fieldName: item.fieldName,
          handleNotificationClick: item.handleNotificationClick,
          messageBarType: item.messageBarType,
          notify: item.notify,
        };
      });
      setState({ notifications: [...prev, ...items] });
    } else {
      const f = (prev) => {
        const alreadyExist = prev.some(
          (item) => item.key === notificationMessage.key
        );

        if (alreadyExist) {
          return prev;
        }

        return [
          ...prev,
          {
            errors: notificationMessage.errors,
            key: notificationMessage.key,
            fieldName: notificationMessage.fieldName,
            handleNotificationClick:
              notificationMessage.handleNotificationClick,
            messageBarType: notificationMessage.messageBarType,
            notify: notificationMessage.notify,
          },
        ];
      };
      setState({ notifications: f(prev) });
    }
  };

export const filterDuplicates =
  (errors) =>
  async ({ setState, getState, dispatch }) => {
    let prev = getState().notifications;
    prev = prev.filter((item) => Object.keys(errors).includes(item.fieldName));
    setState({ notifications: prev });
  };

export const removeNotification =
  (notificationKey) =>
  async ({ setState, getState, dispatch }) => {
    let prev = getState().notifications;

    setState({
      notifications: prev.filter((item) => item.key !== notificationKey),
    });
  };

export const clearAllNotifications =
  () =>
  async ({ setState, getState, dispatch }) => {
    setState({ notifications: [] });
  };

export const clearFormNotifications =
  () =>
  async ({ setState, getState, dispatch }) => {
    let prev = getState().notifications;

    setState({ notifications: prev.filter((item) => !item.fieldName) });
  };
