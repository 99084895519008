import { css, Global } from "@emotion/react";
import { useTheme } from "@fluentui/react";
import React from "react";

export const GlobalStyles = () => {
  const theme = useTheme();
  return (
    <Global
      styles={css`
        * {
          box-sizing: border-box;
          margin: 0;
          padding: 0;
        }

        body {
          margin: 0;
          padding: 0;
          font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI",
            -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue",
            sans-serif;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          background-color: ${theme.palette.neutralLighterAlt};
          overflow: hidden;
        }

        #root {
          background-size: auto;
          background-position-x: right;
          background-position-y: bottom;
          background-repeat: no-repeat;
          background-color: ${theme.palette.neutralLighterAlt};
        }

        code {
          font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
            monospace;
        }

        input[type="number"]::-webkit-inner-spin-button,
        input[type="number"]::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        input[type="number"] {
          -moz-appearance: textfield; /* Firefox */
        }

        ::-webkit-scrollbar {
          width: 5px;
          height: 5px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
          background: ${theme.palette.white};
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
          background: ${theme.palette.neutralTertiary};
          border-radius: 5px;
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
          background: ${theme.palette.neutralSecondary};
        }

        .ReactVirtualized__Grid {
          background: ${theme.palette.white};
          div[role="rowgroup"] {
            color: ${theme.palette.neutralPrimary};
            background: ${theme.palette.white};
          }
          .cell {
            color: ${theme.palette.neutralPrimary};
            border-bottom: 1px solid ${theme.palette.neutralLighterAlt};
          }
          .cell.even {
            background: ${theme.palette.themeLighterAlt};
          }
          .cell.odd {
            background: ${theme.palette.white};
          }
          .cell.hover {
            background: ${theme.palette.themeLighter};
          }
          .header-cell {
            border: 0;
            border-bottom: 1px solid ${theme.palette.neutralLighterAlt};
            &:hover {
              background: ${theme.palette.neutralLighter};
              color: ${theme.palette.neutralPrimary};
            }
            i {
              color: ${theme.palette.neutralPrimary};
              font-size: 0.71rem;
            }
          }

          &:focus {
            outline: none;
          }
        }

        .Toastify__toast-body {
          margin: 0;
        }

        .Toastify__toast {
          padding: 0;
          min-height: 0;
          border-radius: 2px;
        }
        .Toastify__close-button {
          display: none;
        }

        .Toastify__toast-container {
          width: auto;
          max-width: 500px;
          min-width: 300px;
          z-index: 1000001;
        }

        .Toastify__progress-bar {
          height: 2px;
        }

        .Toastify__toast--error {
          .Toastify__progress-bar {
            background-color: ${theme.palette.red};
          }
        }

        .Toastify__toast--success {
          .Toastify__progress-bar {
            background-color: ${theme.palette.green};
          }
        }

        .middle-content__title.middle-content__title span {
          font-size: 14px;
        }
      `}
    />
  );
};
