import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  Spinner,
} from "@fluentui/react-components";
import { ArrowSplitFilled } from "@fluentui/react-icons";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useBankStatementsStore } from "store/BankStatementsStore";

import { compareNumbersUpToTwoDecimals } from "utils/utils";
import { useAppStore } from "store/AppStore";
import ClearanceMenubar from "./ClearanceMenubar";
import { formatNumber } from "utils/number";
import moment from "moment";
import { setYupLocale, useYupValidationResolver } from "utils/setYupLocale";
import { useForm } from "react-hook-form";
import * as yup from "yup";

export const ClearBankStatementEntryDialog = () => {
  const [
    {
      clearBankStatementEntryDialog,
      clearBankStatementEntryStatus,
      bankStatementEntries,
      clBankStatementEntry,
      clTotal,
      clTotalRows,
      clServiceSegmentCode,
      paymentPlanRequest,

      clUnsettledPaymentPlans,
    },
    { clearBankStatementEntry, setBankStatementsStoreValues },
  ] = useBankStatementsStore();

  const [{ taxonomy }] = useAppStore();
  const isLoading = clearBankStatementEntryStatus === "loading";
  const { t } = useTranslation();

  const useFormValidationSchema = (yup, t) => {
    setYupLocale(t);
    return yup.object().shape({});
  };

  const schema = useFormValidationSchema(yup, t);

  const resolver = useYupValidationResolver(schema);
  const form = useForm({
    resolver,
    // defaultValues: initialOfferMemo,
    values: {
      serviceSegmentCode: clServiceSegmentCode,
      paymentReferenceNumber: clBankStatementEntry?.paymentReferenceNumber,
    },
  });
  useEffect(() => {
    const bankStetementEntry = bankStatementEntries.find((el) => {
      return (
        el.bankStatementEntryId ===
        clearBankStatementEntryDialog.bankStatementEntryId
      );
    });
    setBankStatementsStoreValues({
      clBankStatementEntry: bankStetementEntry,
    });
  }, [
    bankStatementEntries,
    clearBankStatementEntryDialog.bankStatementEntryId,
  ]);

  return (
    <Dialog open={clearBankStatementEntryDialog.isOpen}>
      <DialogSurface style={{ maxWidth: "96%", transform: "none" }}>
        <DialogBody>
          <DialogTitle>
            {t("pct.clearBankStatementEntry.label")}{" "}
            {clBankStatementEntry?.isGrEcoPayment
              ? t("pct.discounts.label")
              : null}{" "}
            - {clBankStatementEntry?.clientInfo?.name}
            &nbsp;(
            {clBankStatementEntry?.clientInfo?.internalNumber})
          </DialogTitle>
          <DialogContent>
            <ClearanceMenubar form={form} />
          </DialogContent>
          <DialogActions>
            <Button
              appearance="secondary"
              disabled={isLoading}
              onClick={() => {
                setBankStatementsStoreValues({
                  clearBankStatementEntryDialog: {
                    isOpen: false,
                    bankStatementEntry: null,
                    bankStatement: null,
                  },
                  clInsurersCountryCode: null,
                  clUnsettledPaymentPlans: [],
                  clUnsettledPaymentPlanMetadataMap: {},
                  clTotal: 0,
                  clTotalRows: 0,
                  clBankStatementEntry: null,
                  clInsurersSelectedItem: null,
                  clSearchTerm: "",
                  clServiceSegmentCode: null,
                  paymentPlanRequest: null,
                  clFromDateFilter: moment()
                    .subtract(3, "months")
                    .startOf("day")

                    .toDate(),
                  clToDateFilter: moment().toDate(),
                });
              }}
            >
              {t("pct.close.label")}
            </Button>
            {clBankStatementEntry !== null && (
              <Button
                appearance="primary"
                disabled={
                  isLoading ||
                  paymentPlanRequest === null ||
                  (clBankStatementEntry.amount >= 0 &&
                    compareNumbersUpToTwoDecimals(
                      clTotal,
                      clBankStatementEntry.amount
                    ) > 0) ||
                  (clBankStatementEntry.amount < 0 && clTotal !== 0)
                }
                icon={<ArrowSplitFilled fontSize={16} />}
                onClick={() => {
                  const suggestedPayments = clUnsettledPaymentPlans
                    ?.filter(
                      (el) =>
                        el.paymentPlanEntryId &&
                        !el.isDeleted &&
                        el.suggestedPaymentAmount > 0
                    )
                    .map((el) => {
                      return {
                        ClientId: el.clientId,
                        InsurerId: el.insurerId,
                        PolicyId: el.policyId,
                        PaymentPlanEntryId: el.paymentPlanEntryId,
                        ServiceSegmentCode: el.serviceSegmentCode,
                        BookingDate: el.bookingDate,
                        DocumentDate: el.documentDate,
                        PremiumFrom: el.premiumFrom,
                        PremiumTo: el.premiumTo,
                        PremiumAmount: el.suggestedPaymentAmount,
                        CurrencyCode: el.currencyCode,
                        PaymentComment: el.paymentComment,
                      };
                    });
                  clearBankStatementEntry(
                    {
                      bankStatementEntry: clBankStatementEntry,
                      suggestedPayments,
                      paymentPlanRequest,
                    },
                    () => {
                      setBankStatementsStoreValues({
                        clearBankStatementEntryDialog: {
                          isOpen: false,
                          bankStatementEntry: null,
                          bankStatement: null,
                        },
                        clInsurersCountryCode: null,
                        clearanceData: {},
                        clUnsettledPaymentPlans: [],
                        clearBankStatementEntry: null,
                      });
                    }
                  );
                }}
              >
                {t("pct.clear.label")}
              </Button>
            )}
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};
