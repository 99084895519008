import { ExcelExport } from "@progress/kendo-react-excel-export";
import MainToolbar from "components/MainToolbar";
import { Flex, FlexRight, HorizontalSpacer } from "components/styled";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { usePaymentPlanPageStore } from "store/PaymentPlanPageStore";
import { ExportExcelButton } from "./navigationbuttons/ExportExcelButton";
import { ExportPDFButton } from "./navigationbuttons/ExportPDFButton";
import { RefreshButton } from "./navigationbuttons/RefreshButton";
import { Search } from "./navigationbuttons/Search";
import styled from "styled-components";
import PaymentPlanMenubar from "./PaymentPlanMenubar";
import CompaniesMenu from "components/CompaniesMenu";

type Props = {
  refExcelExport: ExcelExport;
};

export const NavigationBar = styled.div`
  height: 44px;
  background: ${(p) => p.theme.palette.white};
  display: flex;
  align-items: center;
  padding: 0 10px;
  border-bottom: 1px solid ${(p) => p.theme.palette.neutralLight};
  position: sticky;
  top: 0;
  z-index: 2;
`;

const PaymentPlanPageNavigationFirstLine = ({ refExcelExport }: Props) => {
  const [{ searchTerm }, { setPaymentPlanStoreValue }] =
    usePaymentPlanPageStore();

  return (
    <NavigationBar style={{ zIndex: 334 }}>
      <MainToolbar pageName={"paymentPlanPage"} />
      <RefreshButton />
      <Search
        placeholder="Search"
        value={searchTerm}
        onChange={(value) => setPaymentPlanStoreValue("searchTerm", value)}
      />{" "}
      <PaymentPlanMenubar />
      <ExportExcelButton refExcelExport={refExcelExport} /> <ExportPDFButton />
      <HorizontalSpacer width={10} />
      <FlexRight>
        <Flex>
          <CompaniesMenu />
        </Flex>
      </FlexRight>
    </NavigationBar>
  );
};

export default PaymentPlanPageNavigationFirstLine;
