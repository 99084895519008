import React from "react";
import {
  ExcelExportColumn,
  ExcelExport,
} from "@progress/kendo-react-excel-export";
import { useTranslation } from "react-i18next";
import { ExpandedColumn } from "types/columns";
import {
  usePaymentPlanPageStore,
  usePaymentPlanPageStoreState,
} from "store/PaymentPlanPageStore";
import { formatDateWithTime } from "utils/pdf";
import { transformRowsForExcel } from "utils/excel";
import { useAppStoreState } from "store/AppStore";

type Props = {
  columns: ExpandedColumn[];
};

export const ExportExcel = React.forwardRef<ExcelExport, Props>(
  (props, ref) => {
    const { columns } = props;
    const { t } = useTranslation();
    // console.log("COLUMNS",columns);
    const actualColumns = columns.filter(
      (c) =>
        c.isVisible === true && c.key !== "ordinalNumber" && c.key !== "select"
    );
    const { taxonomy } = useAppStoreState();
    const { xlsRowItems, cols } = usePaymentPlanPageStoreState();

    return (
      <ExcelExport
        data={transformRowsForExcel({
          rows: xlsRowItems,
          columns: cols.filter(
            (c) =>
              c.isVisible === true &&
              c.key !== "ordinalNumber" &&
              c.key !== "select"
          ),
          t,
          taxonomy,
        })}
        ref={ref}
        fileName={`Payment Plans ${formatDateWithTime(new Date())}.xlsx`}
      >
        {actualColumns.map((col) => {
          if (col.excel && col.excel.isVisible === false) return null;
          let width = col.width;
          if (col.excel && col.excel.width !== undefined) {
            width = col.excel.width;
          }
          //console.log(col);
          let format = null;
          let align: "center" | "left" | "right" = "center";
          if (col.filter) {
            const filterType = col.filter.type;
            if (filterType === "date") {
              align = "center";
              format = "dd.MM.yyyy";
            } else if (filterType === "number") {
              align = "right";
              format = "#,##0.00";
            } else if (filterType === "boolean") {
              align = "left";
              format = "text";
            } else if (filterType === "taxonomy") {
              align = "left";
              format = "text";
            } else if (filterType === "text") {
              align = "left";
              format = "text";
            }
          }

          return (
            <ExcelExportColumn
              key={col.key}
              field={col.key}
              title={t(col.labelKey)}
              cellOptions={{ format: format }}
              width={width}
              headerCellOptions={{
                background: "#005aa1",
                color: "#fff",
                textAlign: col.align ?? ("center" as any),
              }}
            />
          );
        })}
      </ExcelExport>
    );
  }
);
