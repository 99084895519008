import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useAppStoreState } from "store/AppStore";

import Select from "react-select";
import {
  useBankStatementsStore,
  useInsurersCountryCode,
} from "store/BankStatementsStore";

export const SearchInsurerCountry = () => {
  const { t } = useTranslation();
  const { taxonomy } = useAppStoreState();
  const taxonomyKey = "Country";

  const [
    {
      bankStatements,
      clBankStatementEntry,

      clearBankStatementEntryStatus,
      getInsurersByCountryStatus,
    },
    { setBankStatementsStoreValue },
  ] = useBankStatementsStore();

  const isDisabled =
    clearBankStatementEntryStatus === "loading" ||
    getInsurersByCountryStatus === "loading";

  const [{ insurersCountryCode }] = useInsurersCountryCode();
  const options = useMemo(() => {
    if (!taxonomy || !taxonomy[taxonomyKey]) return [];
    const options = taxonomy[taxonomyKey]?.items
      .map((tax) => {
        return {
          value: tax.id,
          label: t(tax.code),
        };
      })
      .sort((a, b) => a.label.localeCompare(b.label));

    return options;
  }, [t, taxonomy, taxonomyKey]);

  const { appState, grecoCompanies } = useAppStoreState();

  const customStyles = {
    menu: (baseStyles, state) => ({
      ...baseStyles,
      backgroundColor: appState.darkMode ? "#222222" : "white", // Or any desired color
      color: appState.darkMode ? "white" : "#222222", // Or any desired color
    }),
    control: (baseStyles, state) => ({
      ...baseStyles,
      // minHeight: "30px", // reduce the height
      // height: "30px",
      // padding: "0 6px",

      backgroundColor: appState.darkMode ? "#222222" : "white", // Or any desired color
      color: appState.darkMode ? "white" : "white", // Or any desired color
    }),
    valueContainer: (provided) => ({
      ...provided,
      // height: "30px",
      // padding: "0 6px",
      // alignItems: "center", // vertically center the text
    }),
    input: (baseStyles, state) => ({
      ...baseStyles,
      color: appState.darkMode ? "white" : "#222222",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: appState.darkMode ? "white" : "#222222",
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? data.color
          : isFocused
          ? appState.darkMode
            ? "#000000"
            : "#eeeeee"
          : undefined,
        color: isDisabled
          ? "#ccc"
          : isSelected
          ? appState.darkMode
            ? "white"
            : "#222222"
          : appState.darkMode
          ? "white"
          : "#222222",
        cursor: isDisabled ? "not-allowed" : "default",

        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled
            ? isSelected
              ? "yellow"
              : appState.darkMode
              ? "white"
              : "white"
            : undefined,
        },
      };
    },
  };

  useEffect(() => {
    if (grecoCompanies?.length === 0) return;
    if (!clBankStatementEntry) return;
    const companyCountryCodeId = grecoCompanies?.find(
      (el) =>
        el.companyRegisterNumber ===
        bankStatements.find(
          (bs) => bs.bankStatementId === clBankStatementEntry?.bankStatementId
        ).companyRegisterNumber
    ).companyCountryCodeId;
    setBankStatementsStoreValue(
      "clInsurersCountryCode",
      options.find((option) => option.value === companyCountryCodeId)
    );
  }, [grecoCompanies, bankStatements, options, clBankStatementEntry]);

  return (
    <>
      <Select
        isDisabled={isDisabled}
        required={true}
        onChange={(data) => {
          setBankStatementsStoreValue("clInsurersCountryCode", data);
        }}
        value={insurersCountryCode || ""}
        styles={customStyles}
        placeholder={t("pct.taxonomy.selectTitle.label")}
        options={options}
      />
    </>
  );
};

export default SearchInsurerCountry;
