export class BankStatement {
  bankStatementId: number;
  statementId: string;
  accountId: string;
  accountOwnerId: string;
  accountOwnerName: string;
  accountServicerBIC: string;
  accountServicerId: string;
  accountServicerName: string;
  accountOPBD: number;
  accountCLBD: number;
  accountCurrencyCode: number;
  bankStatementComment: string;
  bankStatementDocumentPath: string;
  companyRegisterNumber: string;
  msgId: string;
  statementCreatedAt: string;
  isDeleted: boolean;
  periodFrom: string;
  periodTo: string;
  paidAmount: number;
  receivedAmount: number;
  bankStatementEntries: BankStatementEntry[];

  createdAt: string;
  createdByUserId: string;
  lastModifiedAt: string;
  lastModifiedByUserId: string;
  rowVersion: string;
}

export type BankStatementMetadataMap = {
  [key: number]: BankStatementInfo;
};

export type PaymentPlanEntryMetadataMap = {
  [key: number]: PaymentPlanEntryInfo;
};

export type PaymentsMetadataMap = {
  [key: number]: PaymentInfo;
};

export type ClientBalancesMetadataMap = {
  [key: number]: ClientBalanceInfo;
};

export type ReminderMetadataMap = {
  [key: number]: ReminderInfo;
};

export interface BankStatementInfo {
  selected: boolean;
  inEditMode: boolean;
  changes: any[];
  deleted: boolean;
  oldRow: BankStatement;
  editable: boolean;
  visible: boolean;
}

export interface PaymentPlanEntryInfo {
  selected: boolean;
}

export interface PaymentInfo {
  selected: boolean;
  inEditMode: boolean;
  changes: any[];
  deleted: boolean;
  oldRow: BankStatement;
  editable: boolean;
  visible: boolean;
}

export interface ClientBalanceInfo {
  selected: boolean;
  inEditMode: boolean;
  changes: any[];
  oldRow: any;
  editable: boolean;
  visible: boolean;
}
export interface ReminderInfo {
  selected: boolean;
  inEditMode: boolean;
  changes: any[];
  deleted: boolean;
  oldRow: BankStatement;
  editable: boolean;
  visible: boolean;
}

export interface BankStatementEntryInfo {
  selected: boolean;
  inEditMode: boolean;
  changes: any[];
  deleted: boolean;
  oldRow: BankStatementEntry;
  editable: boolean;
  visible: boolean;
  resolved: boolean;
  cleared: boolean;
}

export interface ReminderEntryInfo {
  selected: boolean;
  inEditMode: boolean;
  changes: any[];
  deleted: boolean;
  oldRow: ReminderEntry;
  editable: boolean;
  visible: boolean;
  resolved: boolean;
  cleared: boolean;
}

export type ReminderEntryMetadataMap = {
  [key: number]: ReminderEntryInfo;
};

export type BankStatementEntryMetadataMap = {
  [key: number]: BankStatementEntryInfo;
};

export type UnsettledPaymentPlanMetadataMap = {
  [key: number]: UnsettledPaymentPlanInfo;
};

export type SettledPaymentMetadataMap = {
  [key: number]: SettledPaymentInfo;
};

export interface SettledPaymentInfo {
  visible: boolean;
  editable: boolean;
  changes: any[];
  selected?: boolean;
}

export interface UnsettledPaymentPlanInfo {
  visible: boolean;
  editable: boolean;
  changes: any[];
  selected?: boolean;
  highlighted: boolean;
}

export class BankStatementEntry {
  bankStatementEntryId: number;
  bankStatementId: number;
  companyRegisterNumber: string;
  accountId: string;
  accountCurrencyCode: number;
  accountOwnerName: string;
  amount: number;
  bookingDate: string;
  valueDate: string;
  clientName: string;
  clientCode: string;
  clientAccountId: string;
  paymentReferenceNumber: string;
  clientId: number;
  insuranceLineCode: number;
  bankStatementEntryComment: string;
  createdAt: string;
  createdByUserId: string;
  lastModifiedAt: string;
  lastModifiedByUserId: string;
  isCleared: boolean;
  isDeleted: boolean;
  rowVersion: string;
  clientInfo: any;
}

export class PaymentPlanEntry {
  bookingDate: string;
  clientBuildingFloorDoor: string;
  clientCity: string;
  clientCompanyRegisterNumber: string;
  clientEmailAddress: string;
  clientFaxArea: string;
  clientFaxCountry: string;
  clientFaxExtension: string;
  clientFaxNumber: string;
  clientFirstName: string;
  clientHouse: string;
  clientId: number;
  clientInternalNumber: number;
  clientLastName: string;
  clientName: string;
  clientPersonIdentificationNumber: string;
  clientPhoneArea: string;
  clientPhoneCountry: string;
  clientPhoneExtension: string;
  clientPhoneNumber: string;
  clientStreet: string;
  clientTaxNumber: string;
  clientUidNumber: string;
  clientZipCode: string;
  comment: string;
  costCenterCodeId: number;
  currencyCode: number;
  documentDate: string;
  expiry: string;
  grecoCompanyNumber: number;
  grossPremium: number;
  grossPremiumPvl: number;
  hasPayments: boolean;
  insuranceLineCode: number;
  insurerId: number;
  insurerInternalNumber: number;
  insurerName: string;
  internalNumber: number;
  isDeleted: boolean;
  isPaid: boolean;
  paidPremiumAmount: number;
  paymentPlanEntryId: number;
  policyId: number;
  policyName: string;
  policyNumber: string;
  policyPaymentFrequencyCodeId: number;
  policyPremiumCollectionTypeCodeId: number;
  policyProcessStatusCodeId: number;
  policyStatusCodeId: number;
  policySubNumber: string;
  policyVersionId: number;
  policyVersionLineId: number;
  policyVersionStatusCodeId: number;
  premiumAmount: number;
  premiumDebt: number;
  premiumDiscount: number;
  premiumDiscountPercent: number;
  premiumFrom: string;
  premiumInclCommissionExclTaxes: number;
  premiumPaymentMeanCodeId: number;
  premiumTo: string;
  premiumTypeCodeId: number;
  rowNumber: number;
  totalPremiumAmount: number;
  validFrom: string;
}

export class Payment {
  paymentId: number;
  bookingDate: string;
  paymentDate: string;
  declaredDate: string;
  createdAt: string;
  clientBuildingFloorDoor: string;
  clientCity: string;
  clientCompanyRegisterNumber: string;
  clientEmailAddress: string;
  clientFaxArea: string;
  clientFaxCountry: string;
  clientFaxExtension: string;
  clientFaxNumber: string;
  clientFirstName: string;
  clientHouse: string;
  clientId: number;
  clientInternalNumber: number;
  clientLastName: string;
  clientName: string;
  clientPersonIdentificationNumber: string;
  clientPhoneArea: string;
  clientPhoneCountry: string;
  clientPhoneExtension: string;
  clientPhoneNumber: string;
  clientStreet: string;
  clientTaxNumber: string;
  clientUidNumber: string;
  clientZipCode: string;
  comment: string;
  costCenterCodeId: number;
  currencyCode: number;
  documentDate: string;
  reminderNumber: string;
  expiry: string;
  grecoCompanyNumber: number;
  grossPremium: number;
  grossPremiumPvl: number;
  hasPayments: boolean;
  insuranceLineCode: number;
  insurerId: number;
  insurerInternalNumber: number;
  insurerName: string;
  internalNumber: number;
  isDeleted: boolean;
  isPaid: boolean;
  paidPremiumAmount: number;
  paymentPlanEntryId: number;
  policyId: number;
  policyName: string;
  policyNumber: string;
  policyPaymentFrequencyCodeId: number;
  policyPremiumCollectionTypeCodeId: number;
  policyProcessStatusCodeId: number;
  policyStatusCodeId: number;
  policySubNumber: string;
  policyVersionId: number;
  policyVersionLineId: number;
  policyVersionStatusCodeId: number;
  premiumAmount: number;
  commissionAmount: number;
  bookedPremiumAmount: number;
  premiumDebt: number;
  premiumDiscount: number;
  premiumDiscountPercent: number;
  premiumFrom: string;
  premiumInclCommissionExclTaxes: number;
  premiumPaymentMeanCodeId: number;
  premiumTo: string;
  premiumTypeCodeId: number;
  rowNumber: number;
  totalPremiumAmount: number;
  validFrom: string;
  rowVersion: string;
  premiumDiscountByGrECo: number;
  paymentComment: string;
}

export class PaymentEntry {
  clientId: number;
  insurerId: number;
  policyId: number;
  paymentPlanEntryId: number;
  insuranceLineCode: number;
  bookingDate: string;
  documentDate: string;
  premiumFrom: string;
  premiumTo: string;
  premiumAmount: number;
  currencyCode: number;
  paymentComment: string;
}

export class Reminder {
  reminderNumber: string;
  dueDate: string;
  reminderComment: string;
  currencyCode: number;
  isBrokerPremiumCollectionReminder: boolean;
  suggestedPayments: PaymentEntry[];
  createdAt: string;
  createdBy: string;
  createdByUsername: string;
  createdByUserFullName: string;
  lastModifiedAt: string;
  lastModifiedBy: string;
  lastModifiedByUsername: string;
  lastModifiedByUserFullName: string;
  reminderId: number;
  clientId: number;
  generatedDate: string;
  totalPaidAmount: number;
  rowVersion: string;

  reminderEntries: ReminderEntry[];
}
export class ReminderEntry {
  clientId: number;
  insurerId: number;
  policyId: number;
  paymentPlanEntryId: number;
  insuranceLineCode: number;
  bookingDate: string;
  documentDate: string;
  premiumFrom: string;
  premiumTo: string;
  premiumAmount: number;
  currencyCode: number;
  createdAt: string;
  createdBy: string;
  createdByUsername: string;
  createdByUserFullName: string;
  lastModifiedAt: string;
  lastModifiedBy: string;
  lastModifiedByUsername: string;
  lastModifiedByUserFullName: string;
  reminderEntryId: number;
  reminderId: number;
  reminderEntryComment: string;
  isBrokerPremiumCollectionReminderEntry: boolean;
  isPaid: boolean;
  isDeleted: boolean;
  clientInfo: any;
  rowVersion: string;
}
export class PaymentPlanEntryUi extends PaymentPlanEntry {
  suggestedPaymentAmount: number;
}

export class PaymentEntryUi extends PaymentEntry {
  suggestedPaymentAmount: number;
}

export class PaymentUi extends Payment {
  suggestedPaymentAmount: number;
}

export class ReminderUi extends Reminder {
  selected: boolean;
  edited: boolean;
  changes: any[];
  oldRow: ReminderUi;
}

export class ReminderEntryUi extends ReminderEntry {
  selected: boolean;
  edited: boolean;
  changes: any[];
  clientInfo: Client | undefined;
  oldRow: BankStatementEntry;
}

export class Client {
  clientId: number;
  clientName: string;
  clientCode: string;
}
