import { ReminderEntryOptionsCellTemplate } from "pages/Reminders/ReminderEntriesGrid/customcells/ReminderEntryOptionsCellTemplate";
import { ICellTypeHandler } from "../../../../components/grecoreactgrid/ICellTypeHandler";
import { TextCell } from "@silevis/reactgrid";

class ReminderEntryOptionsCellTypeHandler implements ICellTypeHandler {
  cellType = "reminderentryoptions";
  isCustom = true;
  instance = new ReminderEntryOptionsCellTemplate();
  determineNewValue = (change: any) => (change.newCell as TextCell).text;
  getEntryRow = (
    entry: any,
    columnConfig: any,
    rowsMetadataMap: any,
    keyColumn: string,
    context: {
      [key: string]: any;
    }
  ) => {
    const [
      {
        reminders,
        reminderEntries,
        reminderEntryMetadataMap,
        fromDateFilter,
        toDateFilter,
      },
      {
        setRemindersStoreValues,
        saveReminderEntry,
        cancelEditReminderEntry,
        resolvementSuggest,
        populateClearanceData,
      },
    ] = context["remindersStore"];
    const { t, taxonomy } = context;
    const reminder = reminders?.find((st) => {
      return st.reminderId === entry["reminderId"];
    });

    return {
      type: "reminderentryoptions", //,
      rowId: columnConfig.additionalInfo?.rowIdField
        ? entry[columnConfig.additionalInfo.rowIdField]
        : "",
      link: columnConfig.additionalInfo?.linkField
        ? entry[columnConfig.additionalInfo.linkField]
        : "",
      clientId: entry["clientId"],
      deleteFn: !reminder?.reminderDocumentPath
        ? (rowId) => {
            setRemindersStoreValues({
              deleteReminderEntryDialog: {
                isOpen: true,
                ReminderEntryId: entry["ReminderEntryId"],
                reminderId: entry["reminderId"],
              },
            });
          }
        : undefined,
      saveFn: (rowId) => {
        saveReminderEntry(rowId);
      },
      cancelFn: (rowId) => {
        cancelEditReminderEntry(rowId);
      },
      clearFn: (rowId) => {
        setRemindersStoreValues({
          clearReminderEntryDialog: {
            isOpen: true,
            ReminderEntryId: entry["ReminderEntryId"],
            reminderId: entry["reminderId"],
            insuranceLineCode: entry["insuranceLineCode"],
          },
        });
        populateClearanceData({
          ReminderEntryId: entry["ReminderEntryId"],
          taxonomy,
          fromDate: fromDateFilter,
          toDate: toDateFilter,
          insuranceLineCode: entry["insuranceLineCode"],
          t,
        });
      },
      resolvementSuggestFn: (rowId) => {
        resolvementSuggest(
          {
            ReminderEntryId: entry["ReminderEntryId"],
            reminderId: entry["reminderId"],
          },
          (val) => {
            let newEntries = [];
            let newEntriesMetadataMap = { ...reminderEntryMetadataMap };
            for (let index = 0; index < reminderEntries.length; index++) {
              const ReminderEntry = reminderEntries[index];

              if (
                ReminderEntry.reminderEntryId === val.reminderEntryId &&
                !ReminderEntry.clientId &&
                val?.clientInfo
              ) {
                let meta = newEntriesMetadataMap[ReminderEntry.reminderEntryId];

                let changes = meta.changes ? [...meta.changes] : [];
                if (val.clientInfo) {
                  changes.push({
                    rowId: index,
                    columnId: "clientInfo",
                    newCell: {
                      isDisabled: false,
                      selectedValue: val.clientInfo,
                      t: t,
                      text:
                        val.clientInfo.name +
                        " (" +
                        val.clientInfo.internalNumber +
                        ")",
                      type: "client",
                      value: val.clientInfo,
                    },
                    previousCell: {
                      isDisabled: false,
                      selectedValue: null,
                      t: t,
                      text: "",
                      type: "client",
                      value: null,
                    },
                    type: "client",
                  });
                }
                newEntries.push({
                  ...ReminderEntry,
                  clientInfo: val.clientInfo,
                });
                newEntriesMetadataMap[ReminderEntry.reminderEntryId] = {
                  ...meta,
                  inEditMode: true,
                  changes: changes,
                  oldRow: ReminderEntry,
                };
              } else {
                newEntries.push(ReminderEntry);
              }
            }
            setRemindersStoreValues({
              reminderEntries: newEntries,
              reminderEntryMetadataMap: newEntriesMetadataMap,
            });
          }
        );
      },
      edited: rowsMetadataMap[entry["reminderEntryId"]]["inEditMode"],
      selected: rowsMetadataMap[entry["reminderEntryId"]]["selected"],
      isCleared: entry["isCleared"],
      t,
    };
  };
}

export default ReminderEntryOptionsCellTypeHandler;
