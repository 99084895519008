import { TextCell } from "@silevis/reactgrid";
import { ICellTypeHandler } from "components/grecoreactgrid/ICellTypeHandler";
import { AmountsCellTemplate } from "../customcells/AmountsCellTemplate";

class AmountsCellTypeHandler implements ICellTypeHandler {
  cellType = "amounts";
  isCustom = true;
  instance = new AmountsCellTemplate();
  determineNewValue = (change: any) => (change.newCell as TextCell).text;
  getEntryRow = (
    entry: any,
    columnConfig: any,
    rowsMetadataMap: any,
    keyColumn: string,
    context: {
      [key: string]: any;
    }
  ) => {
    const { t } = context;
    return {
      type: "amounts", //,
      rowId: columnConfig.additionalInfo?.rowIdField
        ? entry[columnConfig.additionalInfo.rowIdField]
        : "",
      selected: rowsMetadataMap[entry[keyColumn]]?.selected ?? false,
      highlighted: rowsMetadataMap[entry[keyColumn]]?.highlighted ?? false,

      amounts: entry[columnConfig.columnId],

      t,
    };
  };
}

export default AmountsCellTypeHandler;
