import MainToolbar from "components/MainToolbar";
import RemindersMenubar from "./RemindersGrid/RemindersMenubar";
import { VSpace } from "components/Spacer";
import { RefreshButton } from "./RemindersGrid/RefreshButton";
import { Label } from "@fluentui/react-components";
import {
  Flex,
  FlexRight,
  HorizontalSpacer,
  NavigationBar,
} from "components/styled";
import React from "react";
import SearchClients from "./SearchClients";

import { useTranslation } from "react-i18next";
import { useRemindersStoreState } from "store/RemindersStore";

import CompaniesMenu from "components/CompaniesMenu";
import CreateReminderButton from "./CreateReminderButton";
import { ResultsScore } from "components/ResultsScore";
import SearchPolicies from "./SearchPolicies";
import SearchSubpolicies from "./SearchSubpolicies";

const Navigation = () => {
  const { t } = useTranslation();

  const { reminderMetadataMap } = useRemindersStoreState();
  return (
    <>
      <NavigationBar style={{ zIndex: 333 }}>
        <MainToolbar pageName={"remindersPage"} />
        <>
          <RefreshButton />

          <RemindersMenubar />
          <VSpace height={5} />
        </>
        <FlexRight>
          <Flex>
            <CompaniesMenu />
          </Flex>
        </FlexRight>
      </NavigationBar>
      <NavigationBar style={{ zIndex: 333 }}>
        <Label>{t("pct.client.label")}:</Label>
        <HorizontalSpacer width={10} />
        <SearchClients />
        <HorizontalSpacer width={10} />
        <CreateReminderButton />
        <HorizontalSpacer width={10} />

        <SearchPolicies />
        <HorizontalSpacer width={10} />
        <SearchSubpolicies />
        <FlexRight>
          <Flex>
            <ResultsScore
              score={
                [
                  ...new Map(Object.entries(reminderMetadataMap)).values(),
                ].filter((e: any) => {
                  return e.visible === true;
                }).length
              }
            />
          </Flex>
        </FlexRight>
      </NavigationBar>
    </>
  );
};

export default Navigation;
