import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  Spinner,
  Textarea,
} from "@fluentui/react-components";
import { DeleteDismissFilled } from "@fluentui/react-icons";
import { toast } from "components/FluentToast";
import { VSpace } from "components/Spacer";
import React from "react";
import { useTranslation } from "react-i18next";
import { useAppStoreState } from "store/AppStore";
import { useBankStatementsStore } from "store/BankStatementsStore";

export const DeleteBankStatementDialog = () => {
  const [
    { bankStatements, deleteBankStatementDialog, deleteBankStatementStatus },
    {
      setBankStatementsStoreValue,
      setBankStatementsStoreValues,
      deleteBankStatement,
      loadBankStatements,
    },
  ] = useBankStatementsStore();
  const { taxonomy, grecoCompanies, updatedGrecoCompanies } =
    useAppStoreState();

  const isLoading = deleteBankStatementStatus === "loading";
  const [comment, setComment] = React.useState("");
  const { t } = useTranslation();

  return (
    <Dialog open={deleteBankStatementDialog.isOpen}>
      <DialogSurface>
        <DialogBody>
          <DialogTitle>{t("pct.deleteBankStatement.label")}</DialogTitle>
          <DialogContent>
            {t("pct.deleteBankStatementConfirm.label")}
            <VSpace height={20} />
            <div>
              <label>{t("pct.comment.label")}</label>
              <VSpace height={5} />

              <Textarea
                style={{ width: "100%" }}
                rows={6}
                value={comment}
                disabled={isLoading}
                onChange={(e) => setComment(e.target.value)}
              />
            </div>
            <VSpace height={20} />
          </DialogContent>
          <DialogActions>
            <Button
              appearance="secondary"
              disabled={isLoading}
              onClick={() => {
                setBankStatementsStoreValue("deleteBankStatementDialog", {
                  isOpen: false,
                  bankStatementId: null,
                });
              }}
            >
              {t("pct.close.label")}
            </Button>
            <Button
              appearance="primary"
              disabled={isLoading}
              icon={
                isLoading ? (
                  <Spinner size="tiny" />
                ) : (
                  <DeleteDismissFilled fontSize={16} />
                )
              }
              onClick={() => {
                deleteBankStatement(
                  {
                    bankStatementId: deleteBankStatementDialog.bankStatementId,
                    comment: comment,
                    rowVersion: bankStatements.find(
                      (statement) =>
                        statement.bankStatementId ===
                        deleteBankStatementDialog.bankStatementId
                    )?.rowVersion,
                  },
                  () => {
                    setBankStatementsStoreValues({
                      deleteBankStatementDialog: {
                        isOpen: false,
                        bankStatementId: null,
                      },
                    });
                    toast.success(t("greco.success"));
                    loadBankStatements({
                      taxonomy,
                      t,
                      grecoCompanies,
                      reset: true,
                      delay: true,
                      updatedGrecoCompanies,
                    });
                  }
                );
              }}
            >
              {t("pct.delete.label")}
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};
