import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useAppStoreState } from "store/AppStore";
import Select from "react-select";
import { useClientBalancePageStore } from "store/ClientBalancePageStore";
import moment from "moment";

const SearchCurrencyCodes = () => {
  const { t } = useTranslation();
  const { clientId } = useParams() as any;
  const [
    {
      clearClientSaldoStatus,
      clientSaldo,
      clCurrencyCodesSelectedItem,
      clCurrencyCodesItems,
      clFromDateFilter,
      clToDateFilter,
      clClientsSelectedItem,
      clSearchTerm,
      clServiceSegmentsSelectedItem,
      clInsurersSelectedItem,
      searchClientsSelectedItem,
      clientSaldoItems,
      clPoliciesSelectedItem,
      clSubpoliciesSelectedItem,
      clPaymentReferenceNumbersSelectedItem,
      clIsBrokerPremiumCollection,
      clGrecoCompanyNumbers,
    },
    { populateClearanceData, setClientBalancePageStoreValues },
  ] = useClientBalancePageStore();
  const { taxonomy } = useAppStoreState();
  const taxonomyKey = "Currency";

  const isDisabled = !!clientId || clearClientSaldoStatus === "loading";
  const { appState } = useAppStoreState();

  const customStyles = {
    menu: (baseStyles, state) => ({
      ...baseStyles,
      backgroundColor: appState.darkMode ? "#222222" : "white", // Or any desired color
      color: appState.darkMode ? "white" : "#222222", // Or any desired color
    }),
    control: (baseStyles, state) => ({
      ...baseStyles,
      // minHeight: "30px", // reduce the height
      // height: "30px",
      // padding: "0 6px",

      backgroundColor: appState.darkMode ? "#222222" : "white", // Or any desired color
      color: appState.darkMode ? "white" : "white", // Or any desired color
    }),
    valueContainer: (provided) => ({
      ...provided,
      // height: "30px",
      // padding: "0 6px",
      // alignItems: "center", // vertically center the text
    }),
    input: (baseStyles, state) => ({
      ...baseStyles,
      color: appState.darkMode ? "white" : "#222222",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: appState.darkMode ? "white" : "#222222",
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? data.color
          : isFocused
          ? appState.darkMode
            ? "#000000"
            : "#eeeeee"
          : undefined,
        color: isDisabled
          ? "#ccc"
          : isSelected
          ? appState.darkMode
            ? "white"
            : "#222222"
          : appState.darkMode
          ? "white"
          : "#222222",
        cursor: isDisabled ? "not-allowed" : "default",

        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled
            ? isSelected
              ? "yellow"
              : appState.darkMode
              ? "white"
              : "white"
            : undefined,
        },
      };
    },
  };

  return (
    <div>
      <Select
        options={clCurrencyCodesItems}
        id="searchCurrencySearch"
        styles={customStyles}
        menuPlacement={"auto"}
        placeholder={t("pct.searchCurrencyCodes.label")}
        noOptionsMessage={() => t("greco.noResults")}
        isDisabled={isDisabled}
        loadingMessage={() => t("greco.searching")}
        value={clCurrencyCodesSelectedItem}
        onChange={(value) => {
          const item = clientSaldoItems.find(
            (el) => el.currencyCode === value.value
          );
          setClientBalancePageStoreValues({
            clCurrencyCodesSelectedItem: value,
            clientSaldoAmount: item.clientSaldoAmount,
          });
          let params = {
            t,
            clientId: searchClientsSelectedItem.value.id,
            clientIds: clClientsSelectedItem
              ? clClientsSelectedItem.map((i) => i.value)
              : null,

            taxonomy,
            fromDate: clFromDateFilter,
            toDate: clToDateFilter,
            serviceSegmentCodes: clServiceSegmentsSelectedItem
              ? clServiceSegmentsSelectedItem.map((i) => i.value)
              : null,
            searchTerm: null,
            currencyCode: value.value,
            policyNumbers: clPoliciesSelectedItem
              ? clPoliciesSelectedItem.map((i) => i.value)
              : null,
            policySubnumbers: clSubpoliciesSelectedItem
              ? clSubpoliciesSelectedItem.map((i) => i.value)
              : null,
            insurerIds: clInsurersSelectedItem
              ? clInsurersSelectedItem.map((i) => i.value)
              : null,
            paymentReferenceNumbers: clPaymentReferenceNumbersSelectedItem
              ? clPaymentReferenceNumbersSelectedItem.map((i) => i.value)
              : null,
            isBrokerPremiumCollection: clIsBrokerPremiumCollection,
            grecoCompanyNumbers: clGrecoCompanyNumbers,
          };
          if (!clientSaldo?.isGrECoClient) {
            delete params.isBrokerPremiumCollection;
          }
          populateClearanceData(params);
        }}
      />
    </div>
  );
};

export default SearchCurrencyCodes;
