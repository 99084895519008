import { ReminderOptionsCellTemplate } from "pages/Reminders/RemindersGrid/customcells/ReminderOptionsCellTemplate";
import { ICellTypeHandler } from "components/grecoreactgrid/ICellTypeHandler";
import { TextCell } from "@silevis/reactgrid";

class ReminderOptionsCellTypeHandler implements ICellTypeHandler {
  cellType = "reminderoptions";
  isCustom = true;
  instance = new ReminderOptionsCellTemplate();
  determineNewValue = (change: any) => (change.newCell as TextCell).text;
  getEntryRow = (
    entry: any,
    columnConfig: any,
    rowsMetadataMap: any,
    keyColumn: string,
    context: {
      [key: string]: any;
    }
  ) => {
    const [{}, { setRemindersStoreValues }] = context["remindersStore"];
    const { t, refExcelExport } = context;

    const saveExcel = (component) => {
      if (!component) return;

      const options = component.workbookOptions();
      const data = component.props.data;
      const rows = options.sheets[0].rows;
      if (rows.length === 0) return;
      if (data.length === 0) return;
      for (let index = 1; index < rows.length; index++) {
        const item = data[index - 1];
        const row = rows[index];
        if (row.type === "data" && item["Status"] === "2") {
          row.cells.forEach((cell) => {
            cell.background = "#fed9cc";
          });
        }
      }
      component.save(options);
    };

    return {
      type: "reminderoptions", //,
      rowId: columnConfig.additionalInfo?.rowIdField
        ? entry[columnConfig.additionalInfo.rowIdField]
        : "",
      link: columnConfig.additionalInfo?.linkField
        ? entry[columnConfig.additionalInfo.linkField]
        : "",
      link2: columnConfig.additionalInfo?.linkField2
        ? entry[columnConfig.additionalInfo.linkField2]
        : "",
      deleteFn: (rowId) => {
        setRemindersStoreValues({
          deleteReminderDialog: {
            isOpen: true,
            reminderId: rowId,
            clientId: entry["clientId"],
          },
        });
      },
      exportToExcelFn: () => {
        saveExcel(refExcelExport.current);
      },
      edited: rowsMetadataMap[entry["reminderId"]]["inEditMode"],
      selected: rowsMetadataMap[entry["reminderId"]]["selected"],
      t,
    };
  };
}

export default ReminderOptionsCellTypeHandler;
