import React, { useEffect } from "react";

import { useTranslation } from "react-i18next";
import { usePaymentPageStore } from "store/PaymentPageStore";
import { useAppStoreState } from "store/AppStore";
import GrecoReactGrid, {
  standardCellTypeHandlers,
} from "components/grecoreactgrid/GrecoReactGrid";
import { declaredPaymentsColumnConfig } from "./declarePaymentsColumnConfig";
import { useTheme } from "@emotion/react";
import CustomCheckmarkCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/CustomCheckmarkCellTypeHandler";
import CustomInsurerIconCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/CustomInsurerIconCellTypeHandler";
import CustomNumberCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/CustomNumberCellTypeHandler";
import CustomTextCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/CustomTextCellTypeHandler";
import DatePickerCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/DatePickerCellTypeHandler";
import DeclaredCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/DeclaredCellTypeHandler";
import LastModifiedCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/LastModifiedCellTypeHandler";
import OrdinalNumberCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/OrdinalNumberCellTypeHandler";
import SortableHeaderCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/SortableHeaderCellTypeHandler";
import SumHeaderCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/SumHeaderCellTypeHandler";
import TaxonomyCellTypeHandler from "components/grecoreactgrid/customcelltypehandlers/TaxonomyCellTypeHandler";
import PaymentOptionsCellTypeHandler from "./customcelltypehandlers/PaymentOptionsCellTypeHandler";
import SelectAllCellTypeHandler from "./customcelltypehandlers/SelectAllCellTypeHandler";
import SelectCellTypeHandler from "./customcelltypehandlers/SelectCellTypeHandler";
import DeclaredAmountCellTypeHandler from "./customcelltypehandlers/DeclaredAmountCellTypeHandler";
import DeclaredAmountAllCellTypeHandler from "./customcelltypehandlers/DeclaredAmountAllCellTypeHandler";
import {
  useDeclaredPayments,
  useDeclaredPaymentsMetadataMap,
  useDeclaredPaymentsSort,
  usePaymentDeclarationDialogStore,
  usePaymentDeclarationDialogStoreActions,
} from "store/PaymentDeclarationDialogStore";

const DeclarePaymentsGrid = () => {
  const { t } = useTranslation();
  const paymentDeclarationDialogStore = usePaymentDeclarationDialogStore();
  const { taxonomy } = useAppStoreState();
  const theme = useTheme();

  const { setPaymentDeclarationStoreValue, setPaymentDeclarationStoreValues } =
    usePaymentDeclarationDialogStoreActions();
  const [{ declaredPaymentsMetadataMap }] = useDeclaredPaymentsMetadataMap();
  const [{ declaredPaymentsSort }] = useDeclaredPaymentsSort();
  const [{ declaredPayments }] = useDeclaredPayments();
  useEffect(() => {
    if (!declaredPayments) {
      return;
    }
    const totalDeclaredAmount = declaredPayments.reduce(
      (acc, el, currentIndex, array) => {
        if (currentIndex !== array.length) {
          acc =
            acc +
            (Number.isNaN(el.declaredAmount) ? 0 : el.declaredAmount ?? 0);
        }
        return acc;
      },
      0
    );
    const declareTotalAmount = declaredPayments.reduce(
      (acc, el, currentIndex, array) => {
        if (currentIndex !== array.length) {
          acc =
            acc + (Number.isNaN(el.premiumAmount) ? 0 : el.premiumAmount ?? 0);
        }
        return acc;
      },
      0
    );
    setPaymentDeclarationStoreValues({
      declaredTotal: Math.round(totalDeclaredAmount * 100) / 100,
      declareTotalAmount: Math.round(declareTotalAmount * 100) / 100,
    });
  }, [declaredPayments]);

  const handleChanges = (
    updatedRows: any[],
    updatedRowsMetadataMap: { [key: number]: any }
  ) => {
    setPaymentDeclarationStoreValues({
      declaredPayments: declaredPayments.map((entry) => {
        const updatedEntry = updatedRows.find(
          (row) => row.paymentId === entry.paymentId
        );
        if (updatedEntry) {
          return updatedEntry;
        } else {
          return entry;
        }
      }),
      declaredPaymentsMetadataMap: updatedRowsMetadataMap,
    });
  };

  const cellTypeHandlers = {
    ...standardCellTypeHandlers,
    customtext: new CustomTextCellTypeHandler(),
    customnumber: new CustomNumberCellTypeHandler(),
    taxonomy: new TaxonomyCellTypeHandler(),
    selectall: new SelectAllCellTypeHandler(),
    datepicker: new DatePickerCellTypeHandler(),
    paymentoptions: new PaymentOptionsCellTypeHandler(),
    select: new SelectCellTypeHandler(),
    sortableheader: new SortableHeaderCellTypeHandler(),
    sumheader: new SumHeaderCellTypeHandler(),
    lastmodified: new LastModifiedCellTypeHandler(),
    customcheckmark: new CustomCheckmarkCellTypeHandler(),
    ordinalnumber: new OrdinalNumberCellTypeHandler(),
    declared: new DeclaredCellTypeHandler(),
    custominsurericon: new CustomInsurerIconCellTypeHandler(),
    declaredamount: new DeclaredAmountCellTypeHandler(),
    declaredamountall: new DeclaredAmountAllCellTypeHandler(),
  };

  return (
    <div
      style={{
        width: "calc(100vw - 130px)",
        height: "calc(100vh - 240px)",
        overflow: "scroll",
      }}
    >
      <GrecoReactGrid
        rows={declaredPayments.map((payment: any) => {
          return {
            ...payment,
            clientName:
              payment.clientName === null
                ? payment.clientLastName + " " + payment.clientFirstName
                : payment.clientName,
          };
        })}
        columns={declaredPaymentsColumnConfig}
        idColumn={"declaredPaymentId"}
        rowsMetadataMap={declaredPaymentsMetadataMap}
        handleChanges={handleChanges}
        cellTypeHandlers={cellTypeHandlers}
        keyColumn="declaredPaymentId"
        stickyColumns={{
          left: 6,
          top: 1,
          bottom: 1,
          right: 2,
        }}
        context={{
          taxonomy: taxonomy,
          t: t,
          paymentDeclarationDialogStore: paymentDeclarationDialogStore,
          theme: theme,
          sort: declaredPaymentsSort,
          setSort: (sort: any) => {
            setPaymentDeclarationStoreValue("declaredPaymentsSort", sort);
          },
        }}
        horizontalStickyBreakpoint={90}
        hasSelectColumn={false}
        sort={declaredPaymentsSort}
      />
    </div>
  );
};

export default DeclarePaymentsGrid;
