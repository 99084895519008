import { ICellTypeHandler } from "components/grecoreactgrid/ICellTypeHandler";

import {
  InfoNumberCell,
  InfoNumberCellTemplate,
} from "../customcells/InfoNumberCellTemplate";

class InfoNumberCellTypeHandler implements ICellTypeHandler {
  cellType = "infonumber";
  isCustom = true;
  instance = new InfoNumberCellTemplate();
  determineNewValue = (change: any) => {
    return (change.newCell as InfoNumberCell).value;
  };
  getEntryRow = (
    entry: any,
    columnConfig: any,
    rowsMetadataMap: any,
    keyColumn: string,
    context: {
      [key: string]: any;
    }
  ) => {
    return {
      type: "infonumber",
      selected: rowsMetadataMap[entry[keyColumn]]?.selected ?? false,
      highlighted: rowsMetadataMap[entry[keyColumn]]?.highlighted ?? false,

      value: entry[columnConfig.columnId],
      editable: false,
    };
  };
}

export default InfoNumberCellTypeHandler;
