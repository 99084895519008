import { DirectionalHint, TooltipHost } from "@fluentui/react";
import { Input, Tooltip } from "@fluentui/react-components";
import {
  Cell,
  CellTemplate,
  Compatible,
  Uncertain,
  UncertainCompatible,
  getCellProperty,
  getCharFromKeyCode,
  isAlphaNumericKey,
  isNavigationKey,
  keyCodes,
} from "@silevis/reactgrid";
import * as React from "react";

export interface CustomTextCell extends Cell {
  type: "customtext";
  text: string;
  editable?: boolean;
  placeholder?: string;
  validator?: (text: string) => boolean;
  renderer?: (text: string) => React.ReactNode;
  errorMessage?: string;
  selected?: boolean;
  highlighted?: boolean;
  gosLink?: string;
}
export class CustomTextCellTemplate implements CellTemplate<CustomTextCell> {
  getCompatibleCell(
    uncertainCell: Uncertain<CustomTextCell>
  ): Compatible<CustomTextCell> {
    const text = getCellProperty(uncertainCell, "text", "string");
    let placeholder: string | undefined;
    try {
      placeholder = getCellProperty(uncertainCell, "placeholder", "string");
    } catch {
      placeholder = "";
    }
    let editable: boolean | undefined;
    try {
      editable = getCellProperty(uncertainCell, "editable", "boolean");
    } catch {
      editable = true;
    }

    let gosLink: string | undefined;
    try {
      gosLink = getCellProperty(uncertainCell, "gosLink", "string");
    } catch {
      gosLink = "";
    }

    let selected: boolean | undefined;
    try {
      selected = getCellProperty(uncertainCell, "selected", "boolean");
    } catch {
      selected = true;
    }
    let highlighted: boolean | undefined;
    try {
      highlighted = getCellProperty(uncertainCell, "highlighted", "boolean");
    } catch {
      highlighted = false;
    }
    const value = parseFloat(text); // TODO more advanced parsing for all text based cells
    return {
      ...uncertainCell,
      text,
      value,
      editable,
      selected,
      highlighted,
      placeholder,
      gosLink,
    };
  }
  update(
    cell: Compatible<CustomTextCell>,
    cellToMerge: UncertainCompatible<CustomTextCell>
  ): Compatible<CustomTextCell> {
    return this.getCompatibleCell({
      ...cell,
      text: cellToMerge.text,
      placeholder: cellToMerge.placeholder,
      editable: cellToMerge.editable,
    });
  }
  handleKeyDown(
    cell: Compatible<CustomTextCell>,
    keyCode: number,
    ctrl: boolean,
    shift: boolean,
    alt: boolean
  ): { cell: Compatible<CustomTextCell>; enableEditMode: boolean } {
    const char = getCharFromKeyCode(keyCode, shift);
    if (
      !ctrl &&
      !alt &&
      isAlphaNumericKey(keyCode) &&
      !(shift && keyCode === keyCodes.SPACE) &&
      cell.editable
    )
      return {
        cell: this.getCompatibleCell({
          ...cell,
          text: shift ? char : char.toLowerCase(),
        }),
        enableEditMode: true,
      };
    return {
      cell,
      enableEditMode:
        keyCode === keyCodes.POINTER || keyCode === keyCodes.ENTER,
    };
  }
  getClassName(
    cell: Compatible<CustomTextCell>,
    isInEditMode: boolean
  ): string {
    const isValid = cell.validator ? cell.validator(cell.text) : true;
    const className =
      (cell.className ? cell.className : "") +
      (cell.selected ? " rg-selected" : "") +
      (cell.highlighted ? " highlighted-selected-row" : "");
    return `${isValid ? "valid" : "rg-invalid"} ${
      cell.placeholder && cell.text === "" ? "placeholder" : ""
    } ${className}`;
  }
  render(
    cell: Compatible<CustomTextCell>,
    isInEditMode: boolean,
    onCellChanged: (cell: Compatible<CustomTextCell>, commit: boolean) => void
  ): React.ReactNode {
    if (!isInEditMode) {
      const isValid = cell.validator ? cell.validator(cell.text) : true;
      const cellText = cell.text || cell.placeholder || "";
      const textToDisplay =
        !isValid && cell.errorMessage ? cell.errorMessage : cellText;

      if (cell.gosLink) {
        return (
          <Tooltip content={textToDisplay} relationship="label">
            <span
              className="gos-link"
              onClick={(e) => {
                window.open(cell.gosLink, "_blank");
              }}
            >
              {cell.renderer
                ? cell.renderer(textToDisplay === null ? "" : textToDisplay)
                : textToDisplay === null
                ? ""
                : textToDisplay}
            </span>
          </Tooltip>
        );
      }
      return (
        <Tooltip content={textToDisplay} relationship="label">
          <span
            style={
              {
                // padding: isInEditMode ? "0px 2px" : "0 0px",
                // opacity: cell.editable ? 1 : 0.5,
              }
            }
          >
            {cell.renderer
              ? cell.renderer(textToDisplay === null ? "" : textToDisplay)
              : textToDisplay === null
              ? ""
              : textToDisplay}
          </span>
        </Tooltip>
      );
    }
    return (
      <Input
        style={{ width: "100%", fontSize: "1em" }}
        size="small"
        //disabled={}
        ref={(input) => {
          if (input) {
            input.focus();
            input.setSelectionRange(input.value.length, input.value.length);
            input.classList.add("cell-input");
          }
        }}
        defaultValue={cell.text}
        onChange={(e) => {
          if (cell.editable) {
            onCellChanged(
              this.getCompatibleCell({ ...cell, text: e.currentTarget.value }),
              false
            );
          }
        }}
        onBlur={(e) => {
          if (cell.editable) {
            onCellChanged(
              this.getCompatibleCell({ ...cell, text: e.currentTarget.value }),
              (e as any).view?.event?.keyCode !== keyCodes.ESCAPE
            );
          }
        }}
        onCopy={(e) => e.stopPropagation()}
        onCut={(e) => e.stopPropagation()}
        onPaste={(e) => e.stopPropagation()}
        onPointerDown={(e) => e.stopPropagation()}
        placeholder={cell.placeholder}
        onKeyDown={(e) => {
          if (isAlphaNumericKey(e.keyCode) || isNavigationKey(e.keyCode))
            e.stopPropagation();
        }}
      />
    );
  }
}
