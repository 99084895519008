import React, { useEffect, useRef } from "react";
import { useAppStore } from "store/AppStore";
import { useClientBalancePageStoreState } from "store/ClientBalancePageStore";
import ClientBalancePageNavigation from "./ClientBalancePageNavigation";
import ClientBalancePageTable from "./ClientBalancePageTable";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { ThemeProvider } from "@greco/components";
import LoadingOverlay from "components/LoadingOverlay";
import { ExportExcel } from "./ExportExcel";
import { useTranslation } from "react-i18next";
import { ClearClientSaldoDialog } from "./clearance/ClearClientSaldoDialog";
import { InfoClearanceDialog } from "./InfoClearanceDialog/InfoClearanceDialog";
import i18n from "i18n";

const ClientBalance = () => {
  const refExcelExport = useRef<ExcelExport>();
  const {
    infoClearanceDialog,
    clientBalancesLoadStatus,
    clearClientSaldoDialog,
  } = useClientBalancePageStoreState();
  const [{ appState }, { setAppStoreValue }] = useAppStore();
  const { t } = useTranslation();

  useEffect(() => {
    setAppStoreValue(
      "headerTitle",
      t("pct.premiumCollectionTool.label") +
        " - " +
        t("pct.clientBalance.label")
    );
  }, [i18n.language]);

  return (
    <ThemeProvider isDarkMode={appState.darkMode}>
      <ClientBalancePageNavigation refExcelExport={refExcelExport?.current} />
      <ClientBalancePageTable />
      <ExportExcel ref={refExcelExport} />
      {clientBalancesLoadStatus === "loading" ? <LoadingOverlay /> : null}
      {clearClientSaldoDialog.isOpen && <ClearClientSaldoDialog />}
      {infoClearanceDialog.isOpen && <InfoClearanceDialog />}
    </ThemeProvider>
  );
};

export default ClientBalance;
